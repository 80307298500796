import Modal from '@mui/material/Modal'
import { Typography, Box} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {
  Button,
  TextField,
  Autocomplete,
} from '@mui/material'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {toast} from 'react-toastify'

const selectAuth = (state: RootState) => state.auth
let roleId = ''

const ClockInModal = ({title, showClockInModal, onCancel}) => {
  const [showRoleBar, setShowRoleBar] = useState(false)
  const [latestSchedule, setlatestSchedule] = useState({})
  const [hasShiftForToday, setHasShiftForToday] = useState(false)
  const [userLocation, setUserLocation] = useState({
    lat: 0,
    lng: 0,
  })
  const [rolesToShow, setRolesToShow] = useState([])
  const [error, setError] = useState(false)

  const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      flex: 1,
      marginRight: '8px',
    },
  }))

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  const locationSuccessCallBack = (position) => {
    const tempCenter = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
    setUserLocation(tempCenter)
  }

  const locationFailureCallBack = () => {
    toast.error('could not get location')
  }

  const getUserLocation = async () => {
    const location = await navigator.geolocation.getCurrentPosition(
      locationSuccessCallBack,
      locationFailureCallBack,
      options
    )

    return location
  }

  const clockIn = async () => {
    if (roleId == '') {
      setError(true)
      return
    }
    if (userLocation.lat == 0 || userLocation.lng == 0) {
      toast.error('could not get location')
    }
    await axios
      .post('/timesheets/startShift', {
        userId: auth.user._id,
        company_code: auth.user.company_code,
        date: new Date(),
        startTime: new Date(),
        roleId: roleId,
        scheduleId: latestSchedule['scheduleId'],
        dayId: latestSchedule['dayId'],
        shiftId: latestSchedule['schedules']['_id'],
        shiftStartTime: latestSchedule['schedules']['timeStartTimeStamp'],
        shiftEndTime: latestSchedule['schedules']['timeEndTimeStamp'],
        location: userLocation,
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Clocked In Successfully')
          onCancel()
        }
      })
      .catch((e) => {
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't Clock In`)
        onCancel()
      })
  }

  const clockOut = async () => {
    await axios
      .post('/timesheets/endShift', {
        userId: auth.user._id,
        date: new Date(),
        endTime: new Date(),
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Clocked Out Successfully')
          onCancel()
        }
      })
      .catch((e) => {
        toast.error(e)
        onCancel()
      })
  }

  const startBreak = async () => {
    await axios
      .post('/timesheets/startBreak', {
        userId: auth.user._id,
        date: new Date(),
        startTime: new Date(),
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Break started')
          onCancel()
        }
      })
      .catch((e) => {
        toast.error(e)
        onCancel()
      })
  }

  const endBreak = async () => {
    await axios
      .post('/timesheets/endBreak', {
        userId: auth.user._id,
        date: new Date(),
        endTime: new Date(),
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Break ended')
          onCancel()
        }
      })
      .catch((e) => {
        toast.error(e)
        onCancel()
      })
  }

  const [buttons, setButtons] = useState([
    {
      label: 'CLOCK IN',
      variant: 'contained',
      type: 'button',
      onClick: clockIn,
    },
  ])

  const auth = useSelector(selectAuth)

  useEffect(() => {
    roleId = ''
    getUserLocation()
    setHasShiftForToday(false)
    if (auth.user.isCaregiver) {
      axios
        .get(
          `/timesheets/getCurrentShift/${
            auth.user._id
          }?startDate=${new Date()}&endDate=${new Date()}`
        )
        .then(async (res2) => {
          await axios
            .get(`/schedules/getTodayScheduledShifts/${auth.user._id}`)
            .then((res1) => {
              if (res1.status == 200) {
                if (
                  (res1.data.length == 0 ||
                    !res1.data[0].schedules ||
                    res1.data[0].schedules == null) &&
                  ((res2?.data?.latestShift?.startTime && res2?.data?.latestShift?.endTime) ||
                    res2?.data?.latestShift == null)
                  // ||
                  // !res2?.data?.latestShift ||
                  // !res2?.data?.latestShift == null
                ) {
                  if (showClockInModal) {
                    toast.error('You have no shifts for today')
                    onCancel()
                  }
                } else {
                  setlatestSchedule(res1.data[0])
                  setClockButtons(res2.data.latestShift)
                  let roles = []
                  res1.data[0].caregiver[0].roles.map((eachRole) => {
                    roles.push({
                      label: eachRole.role[0].name,
                      value: eachRole.role[0]._id,
                    })
                  })
                  setRolesToShow(roles)
                }
              }
            })
            .catch((e) => console.error(e))
        })
        .catch((e) => console.error(e))
    }
  }, [showClockInModal])

  useEffect(() => {
    if (rolesToShow.length > 0) setHasShiftForToday(true)
  }, [rolesToShow])

  const setClockButtons = (shift) => {
    if (shift == null) {
      setShowRoleBar(true)
      setButtons([
        {
          label: 'CLOCK IN',
          variant: 'contained',
          type: 'button',
          onClick: clockIn,
        },
      ])
    } else {
      if (shift.startTime && !shift.endTime) {
        // check breaks
        if (shift.breaks.length > 0) {
          if (
            shift.breaks[shift.breaks.length - 1].startTime &&
            !shift.breaks[shift.breaks.length - 1].endTime
          ) {
            setButtons([
              {
                label: 'END BREAK',
                variant: 'contained',
                type: 'button',
                onClick: endBreak,
              },
              {
                label: 'CLOCK OUT',
                variant: 'contained',
                type: 'button',
                onClick: clockOut,
              },
            ])
            setShowRoleBar(false)
          } else {
            setButtons([
              {
                label: 'START BREAK',
                variant: 'contained',
                type: 'button',
                onClick: startBreak,
              },
              {
                label: 'CLOCK OUT',
                variant: 'contained',
                type: 'button',
                onClick: clockOut,
              },
            ])
            setShowRoleBar(false)
          }
        } else {
          setButtons([
            {
              label: 'START BREAK',
              variant: 'contained',
              type: 'button',
              onClick: startBreak,
            },
            {
              label: 'CLOCK OUT',
              variant: 'contained',
              type: 'button',
              onClick: clockOut,
            },
          ])
          setShowRoleBar(false)
        }
      } else {
        setShowRoleBar(true)
        setButtons([
          {
            label: 'CLOCK IN',
            variant: 'contained',
            type: 'button',
            onClick: clockIn,
          },
        ])
      }
    }
  }

  // 1) user comes => only start shift button
  // 2) after starting shift => only end shift and start break button
  // 3) after ending shift => only start shift button
  // 4) after starting break => only end break button and end shift button

  const style = {
    position: 'absolute' as 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFFFFF',
    boxShadow: 24,
    p: 4,
    outline: 0,
  }

  const classes = useStyles()

  return (
    <Modal
      onClose={onCancel}
      open={showClockInModal && hasShiftForToday}
      aria-labelledby='add-modal-title'
      aria-describedby='add-modal-description'
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='h3'
            sx={{paddingTop: '2rem', paddingBottom: '1.5rem'}}
            fontSize={20}
            fontWeight={'bold'}
          >
            {title}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1.5rem',
          }}
        >
          {/* roles drop down */}
          {showRoleBar && (
            <div key='showRoleBar' className={classes.fieldContainer}>
              <Autocomplete
                disablePortal
                id={'roleBar'}
                size='medium'
                options={rolesToShow || []}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                sx={{maxWidth: 965}}
                forcePopupIcon={true}
                renderInput={(params) => (
                  <TextField
                    error={error}
                    helperText={'Please select a role'}
                    required={true}
                    {...params}
                    label={'Role'}
                  />
                )}
                onChange={(event, newValue) => {
                  setError(false)
                  roleId = newValue.value
                }}
              />
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {buttons.map((button: any, index: React.Key | null | undefined) => (
            <Button
              key={index}
              type={button.type}
              sx={{padding: '4px', margin: '4px'}}
              style={{height: '5rem', width: '14rem'}}
              variant={button.variant}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </Box>
    </Modal>
  )
}
export default ClockInModal
