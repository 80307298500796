import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ListPrograms from '../../../../../pages/admin/care/enrollment/byProgram/ListPrograms'
import ProgramIndividuals from '../../../../../pages/admin/care/enrollment/programListing/programIndividuals'
import IndividualListing from '../../../../../pages/admin/care/enrollment/byIndividual/IndividualListing'
import IndividualProgramList from '../../../../../pages/admin/care/enrollment/individualListing/IndividualProgramList'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import { permissions } from '../../../../../../_constants/permissions'

const EnrollmentIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;

    const canUpdate_Program = hasPermission(userPermissions, permissions.admin_care_enrollment_byProgram_update);
    const canUpdate_Individual = hasPermission(userPermissions, permissions.admin_care_enrollment_byIndividual_update);
    const enrollment = hasPermission(userPermissions, permissions.admin_care_enrollment_view);

    return (
        <Switch>
            <RouteWithPermission path='/admin/care/enrollment/by-client/:clientID' component={IndividualProgramList} hasPerm={enrollment && canUpdate_Individual} />
            <RouteWithPermission path='/admin/care/enrollment/by-client' component={IndividualListing} hasPerm={enrollment} />
            <RouteWithPermission path='/admin/care/enrollment/by-program/:programID' component={ProgramIndividuals} hasPerm={enrollment && canUpdate_Program} />
            <RouteWithPermission path='/admin/care/enrollment/by-program' component={ListPrograms} hasPerm={enrollment} />
            <Route path='/error/404' component={Error404} />
            {/* <Route path='/admin/care/enrollment/programindividual/' component={ProgramIndividuals} /> */}
           {/*  <Route path='/admin/care/enrollment/individualsprogram/*' component={IndividualProgramList} /> */}
        </Switch>
    )
}

export default EnrollmentIndexRoutes
