
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import EnrollmentHeader from './EnrollmentHeader'
// import ByIndividual from './by-individual/ByIndividual'
// import ByProgram from './by-program/ByProgram'
import ContactList from '../contact/list/List'
import ListPrograms from './byProgram/ListPrograms'
import ImportFromExcel from './import-from-excel/ImportFromExcel'
import SearchImportedExcel from './search-imported-excel/SearchImportedExcel'
import ProgramIndividuals from './programListing/programIndividuals'
import IndividualListing from './byIndividual/IndividualListing'
import IndividualProgramList from './individualListing/IndividualProgramList'

function EnrollmentIndex() {
    return (
        <div>
            <EnrollmentHeader />
        </div>
    )
}

export default EnrollmentIndex