import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getFundingSource } from '../../../../admin/billing/funding-source/store/actions'
import { addServiceAuth, getServiceAuth } from '../store/actions'
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action'
import { getAttendanceType } from '../../../../admin/billing/attendance-type/store/action'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import {
    TextField, Button, Grid,
    FormControlLabel, Autocomplete,
    Typography, Checkbox, Box
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { toast } from 'react-toastify'
import * as validator from '../../../../../reusable-components/Validation/Validations'
import { AppDispatch } from '../../../../../../setup/redux/Store'
import { FormValues, ICD10Response } from '../ServiceAuthorizationTypes'
import { RootState } from '../../../../../../setup'
import RateHistoryDialog from '../../../../../reusable-components/RateHistory/RateHistoryDialog'


const selectAuth = (state: RootState) => state.auth;

/**
 * Validator for adding individual
 */
// const registrationSchema = Yup.object().shape({
//     IDType: Yup.string()
//         .required('ID Type is required'),
//     beginDate: Yup.string()
//         .required('Begin Date is required')
//         .test('beginDate', 'Begin Date cannot be greater than End Date', function (value) {
//             const { endDate } = this.parent;
//             if (!value || !endDate) {
//                 return true; // allow empty values to pass validation
//             }
//             const beginDate = new Date(value);
//             const end = new Date(endDate);
//             return beginDate <= end;
//         }),
//     endDate: Yup.string()
//         .required('End Date is required')
//         .test('endDate', 'End Date cannot be less than Begin Date', function (value) {
//             const { beginDate } = this.parent;
//             if (!value || !beginDate) {
//                 return true; // allow empty values to pass validation
//             }
//             const end = new Date(value);
//             const begin = new Date(beginDate);
//             return end >= begin;
//         }),
//     unitMeasure: Yup.string()
//         .required('Unit Measure value is required'),
//     totalBillable: Yup.string()
//         .required('Total Billable value is required'),
//     unitRate: Yup.string()
//         .required('Default Unit rate value is required'),
//     dataCollectionMethod: Yup.string()
//         .required('Data Collection Method value is required')
// })
const EditClientAuthorization = () => {
    const [fundingSources, setFundingSources] = useState([]);
    const [descriptionCodes, setDescriptionCodes] = useState([]);
    const [desSwitch, setDesSwitch] = useState(false);
    const [selectedDescriptionCode, setSelectedDescriptionCode] = useState('');
    const [attendanceTypes, setAttendanceTypes] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [diagnosisForm, setDiagnosisForm] = useState(undefined)
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code
    const parameters: { programID: string, clientID: string } = useParams()
    const history = useHistory()
    const [selectedDescriptionCodeObj, setSelectedDescriptionCodeObj] = useState({
        company_code: companyid,
        serviceDescription: '',
        serviceCode: '',
        unitMeasure: '',
        unitRate: '',
        claimType: '',
        primaryDiagnosis: '',
        procedureModifier: ['', '', '', ''],
        diagnosisPointer: ['', '', '', ''],
        procedureQualifier: '',
        unitOfMeasure: '',
        roundingAlgorithm: '',
        allowedIncrement: '',
        _id: ''
    })
    const [formValues, setFormValues] = useState<FormValues>({
        company_code: companyid,
        programID: parameters?.programID,
        clientID: parameters?.clientID,
        IDType: '',
        fundingSourceID: '',
        authorizationNumber: '',
        accountingNumber: '',
        beginDate: '',
        endDate: '',
        servfirstName: '',
        servlastName: '',
        organizationAgency: '',
        serviceCoordinatorNumber: '',
        phoneNumber: '',
        rateHistory: false,
        rateHistoryValue: [],
        extension: '',
        comments: '',
        // files: [],
        status: 'Approved',
        unitMeasure: selectedDescriptionCodeObj.unitMeasure || '',
        unitRate: selectedDescriptionCodeObj.unitRate || '',
        claimType: selectedDescriptionCodeObj.claimType || '',
        primaryDiagnosis: selectedDescriptionCodeObj.primaryDiagnosis || '',
        procedureModifier: selectedDescriptionCodeObj.procedureModifier || [],
        diagnosisPointer: selectedDescriptionCodeObj.diagnosisPointer || [],
        procedureQualifier: selectedDescriptionCodeObj.procedureQualifier || '',
        unitOfMeasure: selectedDescriptionCodeObj.unitOfMeasure || '',
        roundingAlgorithm: selectedDescriptionCodeObj.roundingAlgorithm || '',
        allowedIncrement: selectedDescriptionCodeObj.allowedIncrement || '',
        serviceID: selectedDescriptionCodeObj._id || '',
        totalBillable: 0,
        totalAmount: 0,
        priorAuth: '',
        dataCollectionMethod: '',
        attendanceType: '',
        remainingUnits: 0,
    })
    const field = {
        label: "ICD-10",
        name: "primaryDiagnosis",
        type: "asyncSelectICD",
        options: [],
    }

    const dispatch = useDispatch<AppDispatch>()
    const inputFieldRef: any = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const [resultFun, attendanceResult, descriptionResult, serviceAuthsResult] = await Promise.all([
                dispatch(getFundingSource({companyid})),
                dispatch(getAttendanceType({companyid})),
                dispatch(GetDescriptionCode({companyid})),
                dispatch(getServiceAuth({companyid}))
            ])
            let tempFun = resultFun.payload.data;
            const tempAtt = attendanceResult.payload.data;
            let tempDes = descriptionResult.payload.data;
            let tempServAuths : any = serviceAuthsResult.payload.data;
            const updatedFun = [
                ...tempFun.map((fun) => ({ label: fun.name, value: fun._id }))
            ]
            const updatedAT = [
                ...tempAtt.map((AT) => ({ label: AT.name, value: AT._id }))
            ]

            tempDes = tempDes.filter((obj: { deactivateStatus: boolean }) => obj.deactivateStatus === false)
            tempServAuths = tempServAuths.filter((obj: { status: string }) => obj.status === "Approved")
            let selectedServAuths = tempServAuths.filter((servAuth: { clientID: string }) => servAuth.clientID === formValues.clientID);
            let alreadySelectedDesc = selectedServAuths.map((obj: { serviceID: string }) => obj.serviceID)
            tempDes = tempDes.filter((obj: { _id: string }) => (
                !alreadySelectedDesc.includes(obj._id)
            ))
            setFundingSources(updatedFun)
            setAttendanceTypes(updatedAT)
            setDescriptionCodes(tempDes)
        }
        fetchData()
    }, [])
    useEffect(() => {
        if (selectedDescriptionCode !== '') {
            const tempdes: any = descriptionCodes.find((des: { _id: string }) =>
                des._id === selectedDescriptionCode)!
            setSelectedDescriptionCodeObj(tempdes)
            setFormValues(prevState => ({
                ...prevState,
                company_code: companyid,
                serviceDescription: tempdes.serviceDescription,
                serviceCode: tempdes.serviceCode,
                unitMeasure: tempdes.unitMeasure,
                unitRate: tempdes.unitRate,
                claimType: tempdes.claimType,
                primaryDiagnosis: tempdes.primaryDiagnosis,
                procedureModifier: tempdes.procedureModifier,
                diagnosisPointer: tempdes.diagnosisPointer,
                procedureQualifier: tempdes.procedureQualifier,
                unitOfMeasure: tempdes.unitOfMeasure,
                roundingAlgorithm: tempdes.roundingAlgorithm,
                allowedIncrement: tempdes.allowedIncrement,
                serviceID: tempdes._id,
                totalBillable: 10,
                totalAmount: 10 * tempdes.unitRate,
            }))
        }
    }, [desSwitch])
    const getData = async (value: string): Promise<{ label: string; value: string }[]> => {
        try {
            let response;
            if (field.name === 'primaryDiagnosis') {
            response = await axios.post<ICD10Response>(`/icd10/findICD`, {
                    value: value
                })
            }
            return response.data.data.map(item => ({
                label: item.info,
                value: item.info
            }));
        } catch (error) {
            console.error("Error fetching allergies:", error);
            return [];
        }
    };

    const loadOptions = async (inputValue) => {
        if (inputValue.length >= 3) {
            const response = await getData(inputValue)
            setFilteredOptions(response);
        } else {
            setFilteredOptions([]); // Clear options when input length is less than 3
        }
    };

    const handleAutocompleteChange = (_, newValue) => {
        if (newValue) {
            setDiagnosisForm(newValue.value)
        }
    };
    const handleSubmit = async (values) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        const validations = [
            { field: 'servfirstName', validation: validator.NAME_VALIDATION },
            { field: 'servlastName', validation: validator.NAME_VALIDATION },
            { field: 'phoneNumber', validation: validator.PHONE_NUMBER_VALIDATION },
            // { field: 'extension', validation: validator.PHONE_NUMBER_VALIDATION },
        ]
        values.remainingUnits = values.totalBillable;
        values.primaryDiagnosis = diagnosisForm
        values.attendanceType = formValues.attendanceType
        values.fundingSourceID = formValues.fundingSourceID
        values.serviceID = selectedDescriptionCodeObj._id
        values.unitMeasure = formValues.unitMeasure
        values.unitRate = formValues.unitRate
        values.priorAuth = Number(formValues.priorAuth)
        values.phoneNumber = Number(formValues.phoneNumber)
        // values.extension = Number(formValues.extension)
        values.authorizationNumber = Number(formValues.authorizationNumber)
        values.accountingNumber = Number(formValues.accountingNumber)
        values.serviceCoordinatorNumber = Number(formValues.serviceCoordinatorNumber)
        for (const { field, validation } of validations) {
            if (values[field] !== undefined && values[field] !== null && values[field] !== '') {
                if (!validation.pattern.test(values[field])) {
                    toast.error('Form Validation Failed');
                    return;
                }
            }
        }
        try {
            if (values.beginDate <= currentDateFormatted && values.endDate >= values.beginDate) {
                const response = await dispatch(addServiceAuth(values));
                if (response.payload && response.payload.status === 200 && response.payload.success === true) {
                    toast.success('New Service Authorization Created Successfully');
                    history.push('/billing/professionalclaim/service-authorization/list');
                } else {
                    toast.error('Error Creating Service Authorization');
                }
            } else {
                if (values.beginDate > currentDateFormatted) {
                    toast.error('Begin Date should not be later than today');
                } else if (values.endDate < values.beginDate) {
                    toast.error('End Date should not be earlier than Begin Date');
                }
            }
            

        } catch (err) {
            console.log(err)
        }
    }
    // const handleFormChange = (e: { target: { name: string; value: string | number | boolean; type: any; checked: any } }) => {
    //     const { name, value, type, checked } = e.target;
    //     if (type === 'checkbox') {
    //         setFormValues((prevValues) => ({
    //             ...prevValues,
    //             [name]: checked
    //         }));
    //     } else {
    //         setFormValues((prevValues) => ({
    //             ...prevValues,
    //             [name]: value
    //         }));
    //     }
    // };
    return (
        <div className="card">
            <form className="card-body" onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(formValues)
            }} >
                <h2 className='mb-3'>Service Authorization (New)</h2>
                <div className="row">
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <Autocomplete
                            id='IDType'
                            options={['Medicaid Number', 'Medicare Number']}
                            size='small'
                            onChange={(e, newValue) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        IDType: newValue
                                    }
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="ID Type"
                                    variant="outlined"
                                    required
                                />
                            )}
                        />
                        {/* Display error message for IDType here */}
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <Autocomplete
                            id='fundingSourceID'
                            size='small'
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            options={fundingSources}
                            onChange={(e, newValue) => {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        fundingSourceID: newValue ? newValue.value : '',
                                      }));
                                }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name='fundingSourceID'
                                    label="Funding Source ID"
                                    required
                                    variant="outlined"
                                />
                            )}
                        />
                        {/* Display error message for fundingSourceID here */}
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='number'
                            name='authorizationNumber'
                            size='small'
                            fullWidth
                            label='Authorization Number'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        authorizationNumber: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='number'
                            name='accountingNumber'
                            size='small'
                            fullWidth
                            label='Accounting Number'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        accountingNumber: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                name='beginDate'
                                slotProps={{ textField: { label: 'Begin Date', size: 'small', required: true, style: { width: '100%' } } }}
                                value={null}
                                onChange={(newValue) => {
                                if (newValue) {
                                    const newDate = newValue.format('YYYY-MM-DD');
                                    setFormValues((prevState) => ({
                                    ...prevState,
                                    beginDate: newDate,
                                    }));
                                }
                                }}
                            />
                            </LocalizationProvider>
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                slotProps={{ textField: { label: 'End Date', size: 'small', required: true, style: { width: '100%' } } }}
                                name='endDate'
                                value={null}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const newDate = newValue.format('YYYY-MM-DD');
                                        setFormValues((prevState) => ({
                                        ...prevState,
                                        endDate: newDate,
                                        }));
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <h3 className='my-3 text-primary'>Service Coordinator</h3>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='text'
                            name='servfirstName'
                            size='small'
                            fullWidth
                            label='First Name'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        servfirstName: e.target.value
                                    }
                                })
                            }}
                            error={Boolean(formValues.servfirstName && !validator.NAME_VALIDATION.pattern.test(formValues.servfirstName))}
                            helperText={formValues.servfirstName && !validator.NAME_VALIDATION.pattern.test(formValues.servfirstName) ? validator.NAME_VALIDATION.message : ''}
                        />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='text'
                            name='servlastName'
                            size='small'
                            fullWidth
                            label='Last Name'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        servlastName: e.target.value
                                    }
                                })
                            }}
                            error={Boolean(formValues.servlastName && !validator.NAME_VALIDATION.pattern.test(formValues.servlastName))}
                            helperText={formValues.servlastName && !validator.NAME_VALIDATION.pattern.test(formValues.servlastName) ? validator.NAME_VALIDATION.message : ''}
                        />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='text'
                            name='organizationAgency'
                            size='small'
                            fullWidth
                            label='Organization/Agency'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        organizationAgency: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='number'
                            name='serviceCoordinatorNumber'
                            size='small'
                            fullWidth
                            label='Service Coordinator Number'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        serviceCoordinatorNumber: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <TextField
                            type='number'
                            name='phoneNumber'
                            size='small'
                            fullWidth
                            label='Phone Number'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        phoneNumber: e.target.value
                                    }
                                })
                            }}
                            error={Boolean(formValues.phoneNumber && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.phoneNumber))}
                            helperText={formValues.phoneNumber && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.phoneNumber) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                        />
                    </div>
                    {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2'>
                        <div>
                            <TextField
                                type='number'
                                name='extension'
                                size='small'
                                fullWidth
                                label='Extension'
                                autoComplete='off'
                                onChange={(e) => {
                                    handleFormChange(e);
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            extension: e.target.value
                                        }
                                    })
                                }}
                                error={Boolean(formValues.extension && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.extension))}
                                helperText={formValues.extension && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.extension) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                            />
                        </div>
                    </div> */}
                    <div>
                        <div>
                            <div className='row mt-2'>
                                <Grid item style={{ backgroundColor: "BlanchedAlmond", marginLeft: '7px', maxWidth: 'fit-content' }}>
                                    <Typography className='mt-2 mb-2'>
                                        Service Dates that do not fall under any of the following date ranges will use the default Unit Rate.
                                    </Typography>
                                </Grid>
                                <div className='mt-2 mb-2'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='rateHistory'
                                                checked={formValues.rateHistory}
                                                onChange={(e) => {
                                                    setFormValues((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            rateHistory: e.target.checked
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                        label="Enable Rate History"
                                    />
                                </div>
                            </div>
                            <RateHistoryDialog  formValues={formValues} setFormValues={setFormValues} isEnabled={formValues.rateHistory}/>
                        </div>
                    </div>
                    <h3 className='mt-3 text-primary'>Service Information</h3>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-4'>
                        <Autocomplete
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            size='small'
                            options={descriptionCodes.map((des) => ({
                                value: des._id,
                                label: `${des.serviceDescription} / ${des.serviceCode}`,
                            }))}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    setSelectedDescriptionCode(newValue.value);
                                    setDesSwitch(!desSwitch);
                                }
                            }}
                            ref={inputFieldRef}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label='- Select Description Code -'
                                    required
                                />
                            )}
                        />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-4'>
                        <TextField
                            type='text'
                            name='unitMeasure'
                            size='small'
                            required
                            fullWidth
                            label='Unit of Measure (Label)'
                            autoComplete='off'
                            value={formValues.unitMeasure}
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        unitMeasure: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 my-2'>
                        <TextField
                            type='number'
                            name='totalBillable'
                            size='small'
                            value={formValues.totalBillable}
                            fullWidth
                            label='Total Billable Units'
                            autoComplete='off'
                            required
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        totalBillable: parseInt(e.target.value)
                                    }
                                })
                                if (formValues.totalBillable && formValues.unitRate) {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        totalAmount: parseInt(prevState.unitRate) * prevState.totalBillable,
                                    }));
                                }
                            }}
                            variant="outlined"
                        />
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 my-2'>
                        <TextField
                            type='number'
                            name='unitRate'
                            size='small'
                            value={formValues.unitRate}
                            fullWidth
                            required
                            label='Default Unit Rate'
                            autoComplete='off'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        unitRate: e.target.value
                                    }
                                })
                                if (formValues.totalBillable && formValues.unitRate) {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        totalAmount: (parseInt(prevState.unitRate) * prevState.totalBillable),
                                    }));
                                }
                            }}
                            variant="outlined"
                        />
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 my-2'>
                        <TextField
                            type="number"
                            name="totalAmount"
                            size="small"
                            value={formValues.totalAmount}
                            fullWidth
                            disabled={true} // or false if you want it enabled
                            label="Total Authorized Amount ($)"
                            onChange={(e) => {
                                const value = e.target.value;

                                // Regular expression to allow up to two decimal places
                                const validValue = value.match(/^\d*\.?\d{0,2}$/) ? parseInt(value) : formValues.totalAmount;
                                
                                setFormValues((prevState) => ({
                                    ...prevState,
                                    totalAmount: validValue,
                                }));
                            }}
                            variant="outlined"
                        />

                    </div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12'>
                                <label className='fw-bold fs-6'>Procedure Modifiers</label>
                                <TextField
                                    type='text'
                                    name='procedureModifier[0]'
                                    size='small'
                                    fullWidth
                                    placeholder=''
                                    value={formValues.procedureModifier[0]}
                                    onChange={(e) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            procedureModifier: [
                                                e.target.value,
                                                prevValues.procedureModifier[1],
                                                prevValues.procedureModifier[2],
                                                prevValues.procedureModifier[3],
                                                // add more values to update as needed
                                            ],
                                        }));
                                    }}
                                    variant="outlined"
                                />
                            </div>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-3'>
                                <div className='mt-3'>
                                    <TextField
                                        type='text'
                                        name='procedureModifier[1]'
                                        value={formValues.procedureModifier[1]}
                                        size='small'
                                        fullWidth
                                        placeholder=''
                                        onChange={(e) => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                procedureModifier: [
                                                    prevValues.procedureModifier[0],
                                                    e.target.value,
                                                    prevValues.procedureModifier[2],
                                                    prevValues.procedureModifier[3],
                                                    // add more values to update as needed
                                                ],
                                            }));
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-3'>
                                <div className='mt-3'>
                                    <TextField
                                        type='text'
                                        name='procedureModifier[2]'
                                        value={formValues.procedureModifier[2]}
                                        size='small'
                                        fullWidth
                                        placeholder=''
                                        onChange={(e) => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                procedureModifier: [
                                                    prevValues.procedureModifier[0],
                                                    prevValues.procedureModifier[1],
                                                    e.target.value,
                                                    prevValues.procedureModifier[3],
                                                    // add more values to update as needed
                                                ],
                                            }));
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-3'>
                                <div className='mt-3'>
                                    <TextField
                                        type='text'
                                        name='procedureModifier[3]'
                                        value={formValues.procedureModifier[3]}
                                        size='small'
                                        fullWidth
                                        placeholder=''
                                        onChange={(e) => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                procedureModifier: [
                                                    prevValues.procedureModifier[0],
                                                    prevValues.procedureModifier[1],
                                                    prevValues.procedureModifier[2],
                                                    e.target.value,
                                                    // add more values to update as needed
                                                ],
                                            }));
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 my-3'>
                        <Autocomplete
                            id={field.name}
                            size="small"
                            options={filteredOptions}
                            onInputChange={(_, newValue) => loadOptions(newValue)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            forcePopupIcon={true}
                            onChange={handleAutocompleteChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`Search for ICD-10`}
                                    required
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className='row my-2'>
                        <div className="d-flex">
                            <label className='fw-bold fs-6'>Diagnosis Code Pointer</label>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-1'>
                            <TextField
                                type='text'
                                name='diagnosisPointer[0]'
                                size='small'
                                fullWidth
                                placeholder='1'
                                onChange={(e) => {
                                    setFormValues((prevValues) => ({
                                        ...prevValues,
                                        diagnosisPointer: [
                                            e.target.value,
                                            prevValues.diagnosisPointer[1],
                                            prevValues.diagnosisPointer[2],
                                            prevValues.diagnosisPointer[3],
                                            // add more values to update as needed
                                        ],
                                    }));
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-1'>
                            <TextField
                                type='text'
                                name='diagnosisPointer[1]'
                                size='small'
                                fullWidth
                                placeholder=''
                                onChange={(e) => {
                                    setFormValues((prevValues) => ({
                                        ...prevValues,
                                        diagnosisPointer: [
                                            prevValues.diagnosisPointer[0],
                                            e.target.value,
                                            prevValues.diagnosisPointer[2],
                                            prevValues.diagnosisPointer[3],
                                            // add more values to update as needed
                                        ],
                                    }));
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-1'>
                            <TextField
                                type='text'
                                name='diagnosisPointer[2]'
                                size='small'
                                fullWidth
                                placeholder=''
                                onChange={(e) => {
                                    setFormValues((prevValues) => ({
                                        ...prevValues,
                                        diagnosisPointer: [
                                            prevValues.diagnosisPointer[0],
                                            prevValues.diagnosisPointer[1],
                                            e.target.value,
                                            prevValues.diagnosisPointer[3],
                                            // add more values to update as needed
                                        ],
                                    }));
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-1'>
                            <TextField
                                type='text'
                                name='diagnosisPointer[3]'
                                size='small'
                                fullWidth
                                placeholder=''
                                onChange={(e) => {
                                    setFormValues((prevValues) => ({
                                        ...prevValues,
                                        diagnosisPointer: [
                                            prevValues.diagnosisPointer[0],
                                            prevValues.diagnosisPointer[1],
                                            prevValues.diagnosisPointer[2],
                                            e.target.value,
                                        ],
                                    }));
                                }}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-3'>
                        <TextField
                            type='number'
                            name='priorAuth'
                            size='small'
                            fullWidth
                            label='Prior Authorization Number'
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        priorAuth: e.target.value
                                    }
                                })
                            }}
                            variant="outlined"
                        />
                    </div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-3'>
              <Grid
                item
                style={{
                  backgroundColor: 'BlanchedAlmond',
                  maxWidth: 'fit-content',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <Typography style={{fontWeight: 'bold', fontSize:'14px'}} className='pt-2 pb-2'>
                  Unit of Measure (1 hour)
                </Typography>
              </Grid>
            </div>
                    <div>
                        {/* <div className='col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12'>
                            {/* <div className="d-flex">
                                <p className="text-danger">*</p>
                                <h3 className="fw-bold fs-6 mb-2 text-primary">Method of Data Collection</h3>
                            </div> 
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name='dataCollectionMethod'
                                    value={formValues.dataCollectionMethod}
                                    onChange={(e: { target: any }) => {
                                        handleFormChange(e)
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                dataCollectionMethod: e.target.value
                                            }
                                        })
                                        setAttendanceTypeBool(true);
                                    }}
                                >
                                    <FormControlLabel
                                        value='Attendance'
                                        control={<Radio />}
                                        label='Attendance'
                                        required
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div> */}
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 my-3'>
                                <Autocomplete
                                    id='attendanceType'
                                    fullWidth
                                    options={attendanceTypes}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    freeSolo={true}
                                    forcePopupIcon={true}
                                    size='small'
                                    value={
                                      formValues.attendanceType !== undefined ? attendanceTypes.find((option) => option.value === formValues.attendanceType)?.label || '' : ''
                                    }
                                    onChange={(e, newValue) => {
                                       setFormValues((prevState) => ({
                                           ...prevState,
                                           attendanceType: newValue ? newValue.value : '',
                                          }))
                                    }}
                                    renderInput={(params) => (
                                     <TextField
                                        {...params}
                                       variant='outlined'
                                       required
                                       autoComplete='off'
                                       name='attendanceType'
                                       label='- Select Attendance Type -'
                                     />
                                    )}
                                />
                                
                            </div>
                    </div>
                    {/* <div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 mt-2 mb-4'>
                            <Autocomplete
                                options={unitOfMeasureOptions}
                                size='small'
                                fullWidth
                                renderInput={(params) => <TextField {...params} variant='outlined' label='Unit of Measure (Calculation)' />}
                                onChange={(_, newValue) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            unitOfMeasure: newValue ? newValue : ''
                                        }
                                    })
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                            />
                        </div>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 my-2'>
                            <label className=' fw-bold fs-6 mb-2'>Rounding Algorithm</label>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name='roundingAlgorithm'
                                    row
                                    onChange={(e) => {
                                        handleFormChange(e);
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                roundingAlgorithm: e.target.value
                                            }
                                        })
                                    }}
                                >
                                    {roundingAlgorithmOptions.map((option) => (
                                        <div
                                            className='col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12'
                                            key={option}
                                        >
                                            <FormControlLabel
                                                value={option}
                                                control={<Radio />}
                                                label={option}
                                            />
                                        </div>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 my-3'>
                            <label className=' fw-bold fs-6 mb-2'>Smallest Allowed Increment</label>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name='allowedIncrement'
                                    row
                                    onChange={(e) => {
                                        handleFormChange(e);
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                allowedIncrement: e.target.value
                                            }
                                        })
                                    }}
                                >
                                    {allowedIncrementOptions.map((option) => (
                                        <div
                                            className='col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12'
                                            key={option}
                                        >
                                            <FormControlLabel
                                                value={option}
                                                control={<Radio />}
                                                label={option}
                                            />
                                        </div>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div> */}



                    <Box sx={{ marginTop: '24px', }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            padding: '6px 0',
                        }} >
                            <Button
                                type='button'
                                variant='outlined'
                                sx={{
                                    color: '#000',
                                    backgroundColor: '#f2f2f2',
                                    '&:hover': {
                                        backgroundColor: '#f2f2f2',
                                    },
                                }}
                                onClick={() => history.goBack()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                sx={{
                                    marginLeft: '10px',
                                    backgroundColor: '#28a745',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#218838',
                                    },
                                }}
                                variant='outlined'
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </div>
            </form>
        </div>

    )
}

export default EditClientAuthorization