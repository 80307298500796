import { createSlice } from "@reduxjs/toolkit";
import { getAllAttendance } from "../action";

interface Attendance {
    clients: any[];
    attendanceType: string;
    company_code: any;
    serviceID: string;
    program: string;
    serviceCode: string;
    serviceDescription: string;
}

interface AttendanceState {
    attendance: Attendance[];
    isLoading: boolean;
}

const initialState: AttendanceState = {
    attendance: [],
    isLoading: false
}

export const AttendanceSlice = createSlice({
    name: 'attendanceInfo',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAllAttendance.fulfilled, (state, action: any) => {
                state.attendance = action.payload.data
                state.isLoading = false
            })
            .addCase(getAllAttendance.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getAllAttendance.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default AttendanceSlice.reducer;