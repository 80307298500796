import React, {FC, useEffect, useState} from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import './style.css'
import Avatar from '@mui/material/Avatar'
import {MoonLoader} from 'react-spinners'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import RoleModal from '../../pages/roles/RoleModal'
import {Button} from '@mui/material'
import {hasPermission} from '../../../_helper/hasPermission'
import {permissions} from '../../../_constants/permissions'
import {useSelector} from 'react-redux'
import dayjs from 'dayjs'
import OpenShiftModal from '../Modals/OpenShiftModal'
import CheckIcon from '@mui/icons-material/Check'
import styled from '@emotion/styled'
import { RootState } from '../../../setup'
import { SchedulesDynamicTableProps } from '../../pages/caregiver/CAREGIVER_TYPES'
const selectAuth = (state: RootState) => state.auth

const SchedulesDynamicTable: FC<SchedulesDynamicTableProps>  = ({
  columns,
  data,
  columnDataMapping,
  title,
  startDate,
  lastDate,
  moveDatesForward,
  moveDatesBackward,
  onClickCell,
  onDelete,
  onEdit,
  canAdd,
  canDelete,
  canEdit,
  invisiblePreferredText,
  onClickPublishSchedule,
  onClickCopySchedule,
  showWages,
  showFooter,
  stats,
  typeOfSchedules,
  disableCopyButton,
  changeDataSwitch,
  assignOpenShift,
  eligibleForOpenShifts,
  showInSchedule,
}) => {
  const rowsPerPageOptions = [5, 10, 25, 50]
  const [compData, setData] = useState(data)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedShift, setSelectedShift] = useState(false)
  const [hasSchedule, setHasSchedule] = useState(false)
  const [showOpenShiftModal, setShowOpenShiftModal] = useState(false)
  const [day, setDay] = useState('')
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const auth = useSelector(selectAuth)
  let noNumber = 0
  const userPermissions = auth.user.roleId
  const isCaregiver = auth?.user?.isCaregiver 

  useEffect(() => {
    setData([...data])
    setHasSchedule(false)
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page to 0 when rows per page changes
  }

  const handleDelete = (shift, row) => {
    setShowDeleteModal(true)
    setSelectedRow(row)
    setSelectedShift(shift)
  }

  const handleDiscardDelete = () => {
    setShowDeleteModal(false)
    setSelectedRow(null)
  }
  const handleConfirmDelete = () => {
    if (onDelete) {
      onDelete(selectedShift, selectedRow)
    }
    setShowDeleteModal(false)
    setSelectedRow(null)
    setSelectedShift(null)
  }

  const getDateToShow = (startDate, index) => {
    const numOfDays = index - 1
    let newDate = new Date(startDate)
    newDate.setDate(newDate.getDate() + numOfDays)
    const dateToReturn = newDate.getDate()
    if (dateToReturn < 10) {
      return `0${dateToReturn}`
    }
    return dateToReturn.toString()
  }

  const getTimeString = (numberOfMins = 0) => {
    let hours = numberOfMins / 60
    hours = parseInt(hours.toString())
    let mins = numberOfMins % 60
    mins = parseInt(mins.toString())
    return `${hours}h ${mins}m`
  }

  const addOpenShift = (day: string, index: number) => {
    let timeStart = new Date(startDate)
    let days = index - 1
    timeStart.setDate(timeStart.getDate() + days)
    let timeEnd = new Date(startDate)
    timeEnd.setDate(timeEnd.getDate() + days)
    timeStart.setHours(0)
    timeStart.setMinutes(0)
    timeStart.setSeconds(0)
    timeStart.setMilliseconds(0)
    timeEnd.setHours(2)
    timeEnd.setMinutes(0)
    timeEnd.setSeconds(0)
    timeEnd.setMilliseconds(0)
    const timeStartDate = dayjs(timeStart).toDate()
    const timeEndDate = dayjs(timeEnd).toDate()
    setStartTime(timeStartDate)
    setEndTime(timeEndDate)
    setDay(day)
    setShowOpenShiftModal(true)
  }

  const closeOpenShiftModel = () => {
    setShowOpenShiftModal(false)
  }

  const startSlice = page * rowsPerPage
  const endSlice = startSlice + rowsPerPage

  const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: 12,
      border: '1px solid #dadde9',
    },
  }))
  let isWholeDay = false
  return (
    <div className='table-responsive'>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center'>
          <MoonLoader color='#9db2fc' size={80} />
        </div>
      ) : (
        <>
          <div className='d-flex justify-content-between '>
            <div className='d-flex'>
              <div className='d-flex p-1'>
                <div
                  style={{
                    height: '20px',
                    width: '30px',
                    backgroundColor: '#b4f8c6',
                    border: '1px solid',
                    marginRight: '5px',
                  }}
                ></div>
                <label className='fs-8'>PREFERRED</label>
              </div>

              <div className='d-flex p-1'>
                <div
                  style={{
                    height: '20px',
                    width: '30px',
                    backgroundColor: '#fffbae',
                    border: '1px solid',
                    marginRight: '5px',
                  }}
                ></div>
                <label className='fs-8'>UNAVAILABLE</label>
              </div>

              <div className='d-flex p-1'>
                <div
                  style={{
                    height: '20px',
                    width: '30px',
                    backgroundColor: '#aaf0fe',
                    border: '1px solid',
                    marginRight: '5px',
                  }}
                ></div>
                <label className='fs-8'>PUBLISHED(PREFERRED)</label>
              </div>
              <div className='d-flex p-1'>
                <div
                  style={{
                    height: '20px',
                    width: '30px',
                    backgroundColor: '#ffabab',
                    border: '1px solid',
                    marginRight: '5px',
                  }}
                ></div>
                <label className='fs-8'>PUBLISHED (UNAVAILABLE)</label>
              </div>
            </div>
            <div className='d-flex'>
              {invisiblePreferredText &&
                  <Button
                    variant='contained'
                    color='primary'
                    type='button'
                    style={{marginRight: '0.5rem'}}
                    disabled={disableCopyButton || !hasSchedule}
                    onClick={() => onClickCopySchedule(startDate, lastDate)}
                  >
                    Copy Schedule
                  </Button>
                }
              {invisiblePreferredText && !isCaregiver && hasPermission(
                  userPermissions,
                  permissions.care_giver_schedules_schedules_publish
                ) && (
                <Button
                  variant='contained'
                  color='primary'
                  type='button'
                  style={{marginRight: '0.5rem'}}
                  onClick={() => onClickPublishSchedule(data)}
                >
                  Publish Schedule
                </Button>
              )}
            </div>
          </div>
          <Table className='table-container' stickyHeader>
            <TableHead className='schedules-table-cell'>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{
                      color: 'white',
                      backgroundColor: '#2f3347',
                      fontWeight: 'bold',
                      paddingBottom: '0%',
                      paddingLeft: '0%',
                      paddingRight: '0%',
                      paddingTop: '1%',
                    }}
                  >
                    {column !== 'WeeklyDatePicker' ? (
                      <>
                        <span className='schedules-table-weekly-date-picker-text schedules-table-header-date-text'>
                          {getDateToShow(startDate, index)}
                        </span>
                        <span className='schedules-table-weekly-date-picker-text schedules-table-date-text'>
                          {index === 1
                            ? 'SUN'
                            : index === 2
                            ? 'MON'
                            : index === 3
                            ? 'TUE'
                            : index === 4
                            ? 'WED'
                            : index === 5
                            ? 'THU'
                            : index === 6
                            ? 'FRI'
                            : index === 7
                            ? 'SAT'
                            : ''}
                        </span>
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: 'max-content',
                          paddingTop: '2rem',
                          padding: '1.5rem 1.5rem',
                        }}
                      >
                        <ArrowCircleLeftIcon
                          style={{cursor: 'pointer'}}
                          onClick={moveDatesBackward}
                        ></ArrowCircleLeftIcon>
                        <span
                          style={{
                            fontSize: '12px',
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                          }}
                        >
                          {startDate.toLocaleString('default', {month: 'short'}) +
                            ' ' +
                            startDate.getDate() +
                            ' - ' +
                            lastDate.toLocaleString('default', {month: 'short'}) +
                            ' ' +
                            lastDate.getDate() +
                            ', ' +
                            lastDate.getFullYear()}
                        </span>
                        <ArrowCircleRightIcon
                          style={{cursor: 'pointer'}}
                          onClick={moveDatesForward}
                        ></ArrowCircleRightIcon>
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {compData.length === 0 ? (
                <TableRow className='table-row-even'>
                  <TableCell className='table-cell-action' colSpan={columns.length + 1}>
                    <span className='text-gray-800 mb-1 schedules-table-no-data-found-text'>
                      No Data Found
                    </span>
                  </TableCell>
                </TableRow>
              ) : (
                compData.slice(startSlice, endSlice).map((row, rowIndex) => (
                  <TableRow
                    className={rowIndex % 2 === 0 ? 'table-row-even' : 'table-row-odd'}
                    key={rowIndex}
                  >
                    {rowIndex === 0 && typeOfSchedules !== 'caregiverSchedules' ? (
                      <>
                        <TableCell>
                          <h3>Open Shifts</h3>
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'sunday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>

                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules === 'allSchedules' && !isWholeDay && isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Sunday', 1)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'monday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>
                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules === 'allSchedules' && !isWholeDay &&  isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Monday', 2)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'tuesday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>
                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules ==='allSchedules' && !isWholeDay &&  isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Tuesday', 3)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'wednesday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>
                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules ==='allSchedules' && !isWholeDay &&  isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Wednesday', 4)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'thursday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>
                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules === 'allSchedules' && !isWholeDay &&  isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Thursday', 5)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'friday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>
                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules === 'allSchedules' && !isWholeDay &&  isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Friday', 6)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.map(
                            (eachRow) =>
                              eachRow._id === 'saturday' &&
                              eachRow.shifts.map((eachOpenShift) => (
                                <div className='schedules-table-open-shift-div'>
                                  {typeOfSchedules === 'mySchedule' &&
                                    eligibleForOpenShifts &&
                                    showInSchedule && (
                                      <div className='d-flex justify-content-end'>
                                        <CheckIcon
                                          onClick={() => assignOpenShift(eachOpenShift._id)}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  <span className='schedules-table-whole-day-text'>
                                    {eachOpenShift.timeStart.toString().toUpperCase() +
                                      ' ' +
                                      '-' +
                                      ' ' +
                                      eachOpenShift.timeEnd.toString().toUpperCase()}
                                    <br />
                                  </span>
                                  <span className='schedules-table-role-name'>
                                    ({eachOpenShift.role.toString().toUpperCase()})
                                  </span>
                                </div>
                              ))
                          )}
                          {typeOfSchedules === 'allSchedules' && !isWholeDay &&  isCaregiver !== true && (
                            <div className='d-flex justify-content-center'>
                              {
                                <AddCircleOutlineIcon
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => addOpenShift('Saturday', 7)}
                                ></AddCircleOutlineIcon>
                              }
                            </div>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      columns.map((column, colIndex) =>
                        colIndex === 0 ? (
                          <>
                            <div className='schedules-table-avatar-div'>
                              <Avatar>
                                {row.firstName[0].toUpperCase() + row.lastName[0].toUpperCase()}
                              </Avatar>
                              <span>
                                <span className='schedules-table-full-name-text'>
                                  {row.firstName.toUpperCase() + ' ' + row.lastName.toUpperCase()}
                                </span>
                                {showWages &&
                                  row.wages.map((eachWage) => (
                                    <>
                                      <span className='role-name-text'>
                                        {eachWage.roleName.toUpperCase()}: ${eachWage.wage}/hour
                                      </span>
                                    </>
                                  ))}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <TableCell
                              // sx={{padding: 0}}
                              key={colIndex}
                              className='table-cell schedules-table-cell'
                            >
                              {row[columnDataMapping[column]].length > 0 ? (
                                <div>
                                  {row[columnDataMapping[column]].map((eachShift, mapIndex) => {
                                    isWholeDay = false
                                    if (eachShift.isWholeDay && eachShift.isWholeDay === true) {
                                      isWholeDay = true
                                    }
                                    if (!hasSchedule) setHasSchedule(true)
                                    if (row.isPublished) {
                                      if (eachShift.isPreferred) {
                                        return (
                                          <>
                                            <HtmlTooltip
                                              title={
                                                <React.Fragment>
                                                  <Typography color='inherit'>
                                                    {eachShift?.applyTo?.address || ''}
                                                  </Typography>
                                                </React.Fragment>
                                              }
                                            >
                                              <div className='schedules-table-published-preferred-div'>
                                                {typeOfSchedules !== 'mySchedule' && (
                                                  <div className='d-flex justify-content-end'>
                                                    {canEdit && (
                                                      <EditOutlinedIcon
                                                        onClick={() =>
                                                          onEdit(eachShift, row, colIndex)
                                                        }
                                                      ></EditOutlinedIcon>
                                                    )}
                                                    {canDelete && (
                                                      <DeleteOutlineIcon
                                                        onClick={() => handleDelete(eachShift, row)}
                                                      ></DeleteOutlineIcon>
                                                    )}
                                                  </div>
                                                )}
                                                <span className='schedules-table-preferred-text'>
                                                  {invisiblePreferredText ? '' : 'PREFERRED'}
                                                  {/* PREFERRED */}
                                                </span>
                                                <span className='schedules-table-whole-day-text'>
                                                  {eachShift.isWholeDay
                                                    ? 'WHOLE DAY'
                                                    : eachShift.timeStart.toString().toUpperCase() +
                                                      ' ' +
                                                      '-' +
                                                      ' ' +
                                                      eachShift.timeEnd.toString().toUpperCase()}
                                                  <br />
                                                </span>
                                                <span className='schedules-table-role-name'>
                                                  ({eachShift.roleName.toString().toUpperCase()})
                                                </span>
                                              </div>
                                            </HtmlTooltip>
                                            {typeOfSchedules !== 'mySchedule' && (
                                              <div className='d-flex justify-content-center'>
                                                {canAdd &&
                                                  !isWholeDay &&
                                                  row.showInSchedule &&
                                                  mapIndex ===
                                                    row[columnDataMapping[column]].length - 1 && (
                                                    <AddCircleOutlineIcon
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        onClickCell(colIndex, rowIndex)
                                                      }
                                                    ></AddCircleOutlineIcon>
                                                  )}
                                              </div>
                                            )}
                                          </>
                                        )
                                      } else {
                                        return (
                                          <>
                                            <HtmlTooltip
                                              title={
                                                <React.Fragment>
                                                  <Typography color='inherit'>
                                                    {eachShift?.applyTo?.address || ''}
                                                  </Typography>
                                                </React.Fragment>
                                              }
                                            >
                                              <div className='schedules-table-published-unavailable-div'>
                                                {typeOfSchedules !== 'mySchedule' && (
                                                  <div className='d-flex justify-content-end'>
                                                    {canEdit && (
                                                      <EditOutlinedIcon
                                                        onClick={() =>
                                                          onEdit(eachShift, row, colIndex)
                                                        }
                                                      ></EditOutlinedIcon>
                                                    )}
                                                    {canDelete && (
                                                      <DeleteOutlineIcon
                                                        onClick={() => handleDelete(eachShift, row)}
                                                      ></DeleteOutlineIcon>
                                                    )}
                                                  </div>
                                                )}
                                                <span className='schedules-table-unavailable-text'>
                                                  {invisiblePreferredText ? '' : 'UNAVAILABLE'}
                                                  {/* UNAVAILABLE */}
                                                </span>
                                                <span className='schedules-table-whole-day-text'>
                                                  {eachShift.isWholeDay
                                                    ? 'WHOLE DAY'
                                                    : eachShift.timeStart.toString().toUpperCase() +
                                                      ' ' +
                                                      '-' +
                                                      ' ' +
                                                      eachShift.timeEnd.toString().toUpperCase()}
                                                  <br />
                                                </span>
                                                <span className='schedules-table-role-name'>
                                                  ({eachShift.roleName.toString().toUpperCase()})
                                                </span>
                                              </div>
                                            </HtmlTooltip>
                                            {typeOfSchedules !== 'mySchedule' && (
                                              <div className='d-flex justify-content-center'>
                                                {canAdd &&
                                                  row.showInSchedule &&
                                                  !isWholeDay &&
                                                  mapIndex ===
                                                    row[columnDataMapping[column]].length - 1 && (
                                                    <AddCircleOutlineIcon
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        onClickCell(colIndex, rowIndex)
                                                      }
                                                    ></AddCircleOutlineIcon>
                                                  )}
                                              </div>
                                            )}
                                          </>
                                        )
                                      }
                                    } else if (eachShift.isPreferred) {
                                      return (
                                        <div>
                                          {/* <div style={{overflow: 'hidden'}}>
                                        <div style={{float: 'right'}}>
                                          <div className='d-flex justify-content-center'>
                                            <AddCircleOutlineIcon
                                              style={{
                                                cursor: 'pointer',
                                                top: 0,
                                                right: 0,
                                              }}
                                              onClick={() => onClickCell(colIndex, rowIndex)}
                                            ></AddCircleOutlineIcon>
                                          </div>
                                        </div>
                                      </div> */}

                                          <div>
                                            <HtmlTooltip
                                              title={
                                                <React.Fragment>
                                                  <Typography color='inherit'>
                                                    {eachShift?.applyTo?.address || ''}
                                                  </Typography>
                                                </React.Fragment>
                                              }
                                            >
                                              <div className='schedules-table-preferred-div'>
                                                <div className='d-flex justify-content-end'>
                                                  {canEdit && (
                                                    <EditOutlinedIcon
                                                      onClick={() =>
                                                        onEdit(eachShift, row, colIndex)
                                                      }
                                                    ></EditOutlinedIcon>
                                                  )}
                                                  {canDelete && (
                                                    <DeleteOutlineIcon
                                                      onClick={() => handleDelete(eachShift, row)}
                                                    ></DeleteOutlineIcon>
                                                  )}
                                                </div>
                                                <span className='schedules-table-preferred-text'>
                                                  {invisiblePreferredText ? '' : 'PREFERRED'}
                                                  {/* PREFERRED */}
                                                </span>
                                                <span className='schedules-table-whole-day-text'>
                                                  {eachShift.isWholeDay
                                                    ? 'WHOLE DAY'
                                                    : eachShift.timeStart.toString().toUpperCase() +
                                                      ' ' +
                                                      '-' +
                                                      ' ' +
                                                      eachShift.timeEnd.toString().toUpperCase()}
                                                  <br />
                                                </span>
                                                <span className='schedules-table-role-name'>
                                                  ({eachShift.roleName.toString().toUpperCase()})
                                                </span>
                                              </div>
                                            </HtmlTooltip>
                                            <div className='d-flex justify-content-center'>
                                              {canAdd &&
                                                row.showInSchedule &&
                                                !isWholeDay &&
                                                mapIndex ===
                                                  row[columnDataMapping[column]].length - 1 && (
                                                  <AddCircleOutlineIcon
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={() => onClickCell(colIndex, rowIndex)}
                                                  ></AddCircleOutlineIcon>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      eachShift.isUnavailable &&
                                      eachShift.isApprovedByAdmin
                                    ) {
                                      return (
                                        <>
                                          <HtmlTooltip
                                            title={
                                              <React.Fragment>
                                                <Typography color='inherit'>
                                                  {eachShift?.applyTo?.address || ''}
                                                </Typography>
                                              </React.Fragment>
                                            }
                                          >
                                            <div className='schedules-table-published-unavailable-div'>
                                              {typeOfSchedules !== 'mySchedule' && (
                                                <div className='d-flex justify-content-end'>
                                                  {canEdit && (
                                                    <EditOutlinedIcon
                                                      onClick={() =>
                                                        onEdit(eachShift, row, colIndex)
                                                      }
                                                    ></EditOutlinedIcon>
                                                  )}
                                                  {canDelete && (
                                                    <DeleteOutlineIcon
                                                      onClick={() => handleDelete(eachShift, row)}
                                                    ></DeleteOutlineIcon>
                                                  )}
                                                </div>
                                              )}
                                              <span className='schedules-table-unavailable-text'>
                                                {invisiblePreferredText ? '' : 'UNAVAILABLE'}
                                                {/* UNAVAILABLE */}
                                              </span>
                                              <span className='schedules-table-whole-day-text'>
                                                {eachShift.isWholeDay
                                                  ? 'WHOLE DAY'
                                                  : eachShift.timeStart.toString().toUpperCase() +
                                                    ' ' +
                                                    '-' +
                                                    ' ' +
                                                    eachShift.timeEnd.toString().toUpperCase()}
                                                <br />
                                              </span>
                                              <span className='schedules-table-role-name'>
                                                ({eachShift.roleName.toString().toUpperCase()})
                                              </span>
                                            </div>
                                          </HtmlTooltip>
                                          <div className='d-flex justify-content-center'>
                                            {canAdd &&
                                              row.showInSchedule &&
                                              !isWholeDay &&
                                              mapIndex ===
                                                row[columnDataMapping[column]].length - 1 && (
                                                <AddCircleOutlineIcon
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => onClickCell(colIndex, rowIndex)}
                                                ></AddCircleOutlineIcon>
                                              )}
                                          </div>
                                        </>
                                      )
                                    } else {
                                      return (
                                        <>
                                          <HtmlTooltip
                                            title={
                                              <React.Fragment>
                                                <Typography color='inherit'>
                                                  {eachShift?.applyTo?.address || ''}
                                                </Typography>
                                              </React.Fragment>
                                            }
                                          >
                                            <div className='schedules-table-unavailable-div'>
                                              <div className='d-flex justify-content-end'>
                                                {canEdit && (
                                                  <EditOutlinedIcon
                                                    onClick={() => onEdit(eachShift, row, colIndex)}
                                                  ></EditOutlinedIcon>
                                                )}
                                                {canDelete && (
                                                  <DeleteOutlineIcon
                                                    onClick={() => handleDelete(eachShift, row)}
                                                  ></DeleteOutlineIcon>
                                                )}
                                              </div>
                                              <span className='schedules-table-unavailable-text'>
                                                {invisiblePreferredText ? '' : 'UNAVAILABLE'}
                                                {/* UNAVAILABLE */}
                                              </span>
                                              <span className='schedules-table-whole-day-text'>
                                                {eachShift.isWholeDay
                                                  ? 'WHOLE DAY'
                                                  : eachShift.timeStart.toString().toUpperCase() +
                                                    ' ' +
                                                    '-' +
                                                    ' ' +
                                                    eachShift.timeEnd.toString().toUpperCase()}
                                                <br />
                                              </span>
                                              <span className='schedules-table-role-name'>
                                                ({eachShift.roleName.toString().toUpperCase()})
                                              </span>
                                            </div>
                                          </HtmlTooltip>
                                          <div className='d-flex justify-content-center'>
                                            {canAdd &&
                                              row.showInSchedule &&
                                              !isWholeDay &&
                                              mapIndex ===
                                                row[columnDataMapping[column]].length - 1 && (
                                                <AddCircleOutlineIcon
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => onClickCell(colIndex, rowIndex)}
                                                ></AddCircleOutlineIcon>
                                              )}
                                          </div>
                                        </>
                                      )
                                    }
                                  })}
                                </div>
                              ) : (
                                ((typeOfSchedules === 'mySchedule' &&
                                  !row.isPublished &&
                                  row.showInSchedule) ||
                                  (typeOfSchedules === 'allSchedules' && row.showInSchedule)) &&
                                canAdd && (
                                  <div className='d-flex justify-content-center'>
                                    <AddCircleOutlineIcon
                                      style={{
                                        cursor: 'pointer',
                                        top: 0,
                                        right: 0,
                                      }}
                                      onClick={() => onClickCell(colIndex, rowIndex)}
                                    ></AddCircleOutlineIcon>
                                  </div>
                                )
                              )}
                            </TableCell>
                          </>
                        )
                      )
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>

            {showFooter && (
              <TableFooter className='schedules-table-cell'>
                <TableRow className='table-header-cell'>
                  <TableCell className='schedules-table-footer-col-1'>
                    <span className='schedules-table-footer-1'>
                      <span>WAGES</span>
                      <span>${stats?.totalWages?.toFixed(2) || noNumber.toFixed(2)}</span>
                    </span>
                    <span className='schedules-table-footer-1'>
                      <span>TIME</span>
                      <span>{getTimeString(stats.totalMins)}</span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.sundayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.sundayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.mondayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.mondayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.tuesdayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.tuesdayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.wednesdayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.wednesdayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.thursdayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.thursdayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.fridayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.fridayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='schedules-table-cell '>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        ${stats?.saturdayStats?.wages?.toFixed(2) || noNumber.toFixed(2)}
                      </span>
                    </span>
                    <span className='schedules-table-footer-cell-for-day'>
                      <span className='schedules-table-footer'>
                        {getTimeString(stats?.saturdayStats?.totalTimeInMins)}
                      </span>
                    </span>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </>
      )}

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={compData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='MuiTablePagination-toolbar'
      />
      <RoleModal
        title={title}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        handleDiscardDelete={handleDiscardDelete}
      />
      <OpenShiftModal
        title={'Open Shift'}
        showOpenShiftModal={showOpenShiftModal}
        onCancel={closeOpenShiftModel}
        day={day}
        startTime={startTime}
        endTime={endTime}
        startDate={startDate}
        endDate={lastDate}
        changeDataSwitch={changeDataSwitch}
      ></OpenShiftModal>
    </div>
  )
}

export default SchedulesDynamicTable
