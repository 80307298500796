import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface PayerInterface {
    _id: string;
    company_code: string;
    name: string;
    payerID: string;
    img: object;
    transmissionTo: string;
}

export const addPayer = createAsyncThunk(
    'payer/create',
    async (data: PayerInterface) => {
        try {
            const response = await axios.post('payer/create', data)
            return response.data;
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getPayer = createAsyncThunk(
    'payer/get',
    async ({ companyid, page, pageSize, sortBy, sortDir, searchTerm }: { companyid: string | undefined, page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm : string }) => {
        try {
            if (page && pageSize) {
                const response = await axios.get(`payer/get/${companyid}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data,
                }
            } else {
                const response = await axios.get(`payer/get/${companyid}`)
                return {
                    data: response.data,
                }
            }

        } catch (error) {
            console.log(error)
            return error
        }
    }
)
export const getOnePayer = createAsyncThunk(
    'payer/getOne/',
    async (company_code: string | undefined) => {
        try {
            const response = await axios.get('payer/getOne/' + company_code)
            return {
                data: response.data,
            }
        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const updatePayer = createAsyncThunk(
    'payer/update',
    async (data: PayerInterface) => {
        try {
            const response = await axios.put('payer/update', data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const deletePayer = createAsyncThunk(
    'payer/delete',
    async (data: string) => {
        try {
            const response = await axios.delete('payer/delete/' + data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
)