import { useEffect, useState } from 'react'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import FormWrapper from '../../../../reusable-components/Wrapper/Wrapper'
import { CAREGIVER_FORM_INPUTS } from '../../CAREGIVER_FORM_CONSTANTS'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

export default function NewCareGiver() {
  const [formData, setFormData] = useState({})
  const [rolesToShow, setRolesToShow] = useState([])
  const [uiElementsList, setUiElementsList] = useState([])
  const [error, setError] = useState([false])
  const [i, setI] = useState(0)
  const [clickCount, setClickCount] = useState(5)
  const [inputArr, setInputArr] = useState([
    {
      roleId: '',
      wage: '',
      wageUnit: '', 
    },
  ])

  const authUser = useSelector((state: { auth: any }) => state.auth)
  const company_code = authUser.user?.company_code

  const history = useHistory()

  useEffect(() => {
    axios
      .get('roleManagement/get')
      .then((res) => {
        res.data.map((obj) => {
          setRolesToShow((prevRoles) => [
            ...prevRoles,
            {
              label: obj.name,
              value: obj._id,
            },
          ])

          // setError((prevState) => [...prevState, false])
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])
  const onChangeRoleName = (value, index) => {
    
    // inputArr.map((item, itr) => {
    //   if (item.roleId == value && index !== 0 && itr !== index) {
    //     errorMsg = true
    //   }
    // })
    // if (errorMsg) {
    //   let newArr = [...error]
    //   newArr[index] = true
    //   setError([...newArr])
    //   setUiElementsList((prevState) => {
    //     return prevState.map((item) => {
    //       if (item.index == index && item.label == 'Search Role') {
    //         item.error = true
    //         item.helperText = 'Please select some other role'
    //       }
    //       return item
    //     })
    //   })
    // } else {
    //   let newArr = [...error]
    //   newArr[index] = false
    //   setError([...newArr])
    //   setUiElementsList((prevState) => {
    //     return prevState.map((item) => {
    //       if (item.index == index && item.label == 'Search Role') {
    //         item.error = false
    //         item.helperText = ''
    //       }
    //       return item
    //     })
    //   })
    // }
    let prevInputArr = inputArr
    prevInputArr[index]['roleId'] = value
    // inputArr[index]['roleId'] = value
    setInputArr(prevInputArr)
  }

  const onChangeRoleWage = (name, value, checked, type, index) => {
    let prevInputArr = inputArr
    prevInputArr[index]['wage'] = value
    // inputArr[index]['roleId'] = value
    setInputArr(prevInputArr)
    // inputArr[index]['wage'] = value
  }

  const onChangeRoleWageUnit = (value, index) => {
    let prevInputArr = inputArr
    prevInputArr[index]['wageUnit'] = value
    // inputArr[index]['roleId'] = value
    setInputArr(prevInputArr)
    // inputArr[index]['wageUnit'] = value
  }

  useEffect(() => {
    const tempElementsList = CAREGIVER_FORM_INPUTS(rolesToShow)
    setUiElementsList(tempElementsList)
    setUiElementsList((prevState) => [
      ...prevState,
      tempElementsList,
      {
        label: 'Search Role',
        index: 0,
        name: `${i}Role`,
        value: inputArr[0]['roleId'],
        onChange: onChangeRoleName,
        type: 'select',
        options: rolesToShow,
        required: true,
        rowNumber: 5,
      },
      {
        label: 'Unit',
        index: 0,
        name: `${i}wageUnit`,
        value: inputArr[0]['wageUnit'],
        onChange: onChangeRoleWageUnit,
        type: 'select',
        options: [
          {
            label: '/hour',
            value: 'hour',
          },
        ],
        required: true,
        rowNumber: 5,
      },
      {
        label: 'Wage',
        name: `${i}Wage`,
        index: 0,
        value: inputArr[0]['wage'],
        onChange: onChangeRoleWage,
        type: 'number',
        required: true,
        rowNumber: 5,
      },
    ])
  }, [rolesToShow])

  const handleSubmitForm = async (formData) => {
    let { email, firstName, lastName, password, phone, accessLevel } = formData
    axios
      .post('caregivers/create', {
        email,
        firstName,
        lastName,
        password,
        phone,
        access: {
          accessLevel,
        },
        company_code,
        roles: inputArr.filter((item) => item.roleId != ''),
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Caregiver added successfully')
          history.goBack()
        }
      })
      .catch((e) => {
       if(!e.response.data.success){
         toast.error(e?.response?.data?.message)
      } else {
        toast.error("Coudn't add caregiver")
      }
     })
  }
  useEffect(() => {
    if (i != 0) {
      const roleFieldToAdd = {
        index: i,
        label: 'Search Role',
        value: inputArr[i]['roleId'],
        name: `${i}Role`,
        type: 'select',
        options: rolesToShow,
        required: true,
        rowNumber: clickCount,
        onChange: onChangeRoleName,
        error: error[i],
        helperText: error[i] ? 'Please select a different role' : '',
      }
      const wageUnitFieldToAdd = {
        index: i,
        value: inputArr[i]['wageUnit'],
        label: `Unit`,
        name: `${i}wageUnit`,
        type: 'select',
        options: [
          {
            label: '/hour',
            value: 'hour',
          },
        ],
        required: true,
        rowNumber: clickCount,
        onChange: onChangeRoleWageUnit,
      }
      const wageFieldToAdd = {
        index: i,
        value: inputArr[i]['wage'],
        label: 'Wage',
        name: `${i}Wage`,
        type: 'number',
        required: true,
        rowNumber: clickCount,
        onChange: onChangeRoleWage,
      }
      const deleteButton = {
        index: i,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick:() => onRemoveRoleButtonClick(i),
        rowNumber: clickCount,
      }
      setUiElementsList((prevElements) => [
        ...prevElements,
        roleFieldToAdd,
        wageUnitFieldToAdd,
        wageFieldToAdd,
        deleteButton,
      ])
    }
  }, [i])
  const onAddAnotherRoleClick = async () => {
    setClickCount(clickCount + 1)
    let prevInputArr = inputArr
    prevInputArr.push({ roleId: '', wage: '', wageUnit: '' })
    setInputArr(prevInputArr)
    setI(i + 1)
  }
  const onRemoveRoleButtonClick = (index) => {
    setInputArr(prevInputArr => {
        const updatedArr = [...prevInputArr];
        updatedArr[index].roleId = '';
        return updatedArr;
    });
    setUiElementsList(prevState => {
        return prevState.filter(item => !item.index || (item.index && item.index !== index));
    });
};

  const buttons = [
    {
      label: 'Add Another Role',
      variant: 'outlined',
      type: 'button',
      onClick: onAddAnotherRoleClick,
    },
    { label: 'Cancel', variant: 'outlined', type: 'button' },
    { label: 'Save', variant: 'contained', type: 'submit' },
  ]
  return (
    <>
      <FormWrapper
        title='Add Caregiver'
        content={
          <DynamicForm
            fields={uiElementsList}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        }
      ></FormWrapper>
    </>
  )
}