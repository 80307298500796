import * as React from 'react'
import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Autocomplete, TextField } from '@mui/material';

import axios from 'axios'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Box, Typography} from '@mui/material'
import {toast, ToastContainer} from 'react-toastify'

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const selectAuth = (state) => state.auth

export default function AddBehaviorEntry({handleClose, callback, month = false}) {
  const auth = useSelector(selectAuth)
  const [clientData, setClientData] = React.useState([])
  const [selectedClient, setSelectedClient] = React.useState(null)
  const navigate = useHistory()
  const [date, setDate] = React.useState(new Date())
  const [error, setError] = React.useState('')

  React.useEffect(() => {
    ;(async () => {
      const companyid = auth.user?.company_code
      try {
        const response = await axios.get('/clients/get/' + companyid)
        setClientData(response.data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handleChange = ({target: {value}}) => {
    if (error) {
      setError('')
    }
    setSelectedClient(value)
  }

  const handleAddMedication = () => {
    if (!selectedClient) {
      setError('Client is required')
      return
    }

    if (dayjs(date).isAfter(dayjs())) {
      toast.error('The date should not be in the future')
      return
    }

    if (callback) {
      callback(selectedClient, date.toISOString(), clientData)
      return
    }
    navigate.push(`/behaviour/entry/${selectedClient}`, {date: date.toISOString()})
    handleClose()
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={true}>
      <DialogTitle sx={{m: 0, p: 2}} id='customized-dialog-title'>
        Select Client
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <FormControl style={{display: 'flex', width: '100%'}}>
          <Autocomplete
            id="client-autocomplete"
            options={clientData}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            value={clientData.find(client => client._id === selectedClient) || null}
            onChange={(event, newValue) => {
              setSelectedClient(newValue ? newValue._id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                variant="outlined"
                size="small"
              />
            )}
            size="small"
          />
        </FormControl>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={month ? ['year', 'month'] : ['year', 'month', 'day']}
              sx={{mt: 2}}
              label='Date'
              value={dayjs(date)}
              onChange={(newValue) => setDate(newValue)}
              slotProps={{textField: {size: 'small', style: {width: '350px'}}}}
            />
          </LocalizationProvider>
          {error && (
            <Typography variant='p' sx={{fontSize: '12px', color: 'red'}}>
              {error}
            </Typography>
          )}
        </Box>  
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleAddMedication}>
          Next
        </Button>
      </DialogActions>
      <ToastContainer />
    </BootstrapDialog>
  )
}
