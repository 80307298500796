import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from './Table';
import { getServiceAuth } from '../store/actions';
import axios from 'axios';
import { getProgram } from '../../../../admin/care/enrollment/store/actions';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { NEW_SERV_AUTH_SEARCH_DATA, SEARCH_SERVICE_AUTH_INPUT_CONSTANTS } from '../../../BILLING_CONSTANTS';
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action';
const selectAuth = (state) => state.auth;

const ServiceAuthorizationSearch = () => {
    const [searchValues, setSearchValues] = useState({})
    const [updatedServAuthOptions, setUpdatedServAuthOptions] = useState([]);
    const [showEditingForm, setShowEditingForm] = useState(true)
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [descriptionResult, serviceAuthResult, programResult, clientsResult] = await Promise.all([
                    dispatch(GetDescriptionCode(companyid)),
                    dispatch(getServiceAuth(companyid)),
                    dispatch(getProgram(companyid)),
                    (await axios.get('/clients/get/' + companyid)),
                ])
                const tempDes = descriptionResult?.payload.data;
                const seen = new Set();
                const tempAuths = serviceAuthResult.payload.data;
                const tempProgram = programResult.payload.data;

                let optionsClient = tempAuths.filter((data) => {
                    const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate;
                })
                optionsClient = optionsClient.map((obj) => {
                    let clientObj = clientsResult.data.find((clobj) => clobj._id === obj.clientID)
                    const updatedElement = {
                        value: clientObj._id,
                        label: clientObj.firstName + ' ' + clientObj.lastName
                    }
                    return updatedElement
                })
                const optionsProgram = tempProgram.map((data) => {
                    const updatedElement = {
                        value: data._id,
                        label: data.programName
                    };
                    return updatedElement;
                });
                const updatedServiceDescription = [
                    ...tempDes.map((des) => ({ label: des.serviceDescription, value: des._id }))
                ]

                const constantsWithUpdatedServAuth = SEARCH_SERVICE_AUTH_INPUT_CONSTANTS.map((field) => {
                    if (field.name === 'firstName') {
                        return {
                            ...field,
                            options: optionsClient,
                        };
                    } else if (field.name === 'programName') {
                        return {
                            ...field,
                            options: optionsProgram,
                        };
                    } else if (field.name === 'serviceDescription') {
                        return {
                            ...field,
                            options: updatedServiceDescription,
                        };
                    }
                    return field;
                });
                setUpdatedServAuthOptions(constantsWithUpdatedServAuth);
            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
    }, [])

    const handleSubmitForm = async (formData) => {
        setSearchValues(formData)
        console.log(formData)
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Search', variant: 'contained', type: 'submit' }
    ];
    return (
        <>
            {
                showEditingForm === false ? (
                    <Table searchValues={searchValues} />
                ) : (
                    <FormWrapper
                        title='Service Authorization Search'
                        content={
                            <DynamicForm
                                fields={updatedServAuthOptions}
                                data={NEW_SERV_AUTH_SEARCH_DATA}
                                onSubmit={handleSubmitForm}
                                buttons={buttons}
                            />
                        }
                    />

                )
            }
        </>
    )
}

export default ServiceAuthorizationSearch