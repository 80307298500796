export const CAREGIVER_FORM_INPUTS = (roles) => {
  
  return [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Phone',
      name: 'phone',
      type: 'number',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      required: true,
      rowNumber: 3,
    },
    {
      label: 'Access Level',
      name: 'accessLevel',
      type: 'select',
      options: [
        {label: 'Employee', value: 'Employee'},
        {label: 'Manager', value: 'Manager'},
        {label: 'General Manager', value: 'General Manager'},
      ],
      required: true,
      rowNumber: 3,
    },
    {
      sectionHeading: 'Roles & Wages',
      rowNumber: 4,
    },
  ]
}

export const EDIT_CAREGIVER_FORM_INPUTS = (roles) => {
  return [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Phone',
      name: 'phone',
      type: 'number',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Access Level',
      name: 'accessLevel',
      type: 'select',
      options: [
        {label: 'Employee', value: 'Employee'},
        {label: 'Manager', value: 'Manager'},
        {label: 'General Manager', value: 'General Manager'},
      ],
      required: true,
      rowNumber: 3,
    },

    {
      sectionHeading: 'Roles & Wages',
      rowNumber: 4,
    },
  ]
}

export const CAREGIVER_PROFILE_JOB_DETAILS_FORM_INPUTS = () => {
  return [
    {
      sectionHeading: 'Access',
      rowNumber: 0,
    },
    {
      label: 'Access Level',
      name: 'accessLevel',
      type: 'select',
      options: [
        {label: 'Employee', value: 'Employee'},
        {label: 'Manager', value: 'Manager'},
        {label: 'General Manager', value: 'General Manager'},
      ],
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Pin',
      name: 'pin',
      type: 'text',
      required: false,
      rowNumber: 1,
    },
    {
      label: 'StartDate',
      name: 'startDate',
      type: 'date',
      required: false,
      rowNumber: 2,
    },
    {
      label: 'Payroll ID',
      name: 'payrollId',
      type: 'number',
      required: false,
      rowNumber: 2,
    },
    {
      sectionHeading: 'Location Settings',
      rowNumber: 3,
    },
    {
      name: 'locationSettings',
      type: 'checkbox',
      required: true,
      rowNumber: 3,
      options: [
        {
          label: 'Show in Schedule',
          value: 'showInSchedule',
        },
        {
          label: 'Eligible for Open Shifts',
          value: 'eligibleForOpenShifts',
        },
      ],
    },
    {
      sectionHeading: 'Roles & Wages',
      rowNumber: 4,
    },
  ]
}

export const CAREGIVER_PROFILE_PERSONAL_INFORMATION_FORM_INPUTS = () => {
  return [
    {
      sectionHeading: 'Contact Information',
      rowNumber: 0,
    },
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Phone',
      name: 'phone',
      type: 'number',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'text',
      required: true,
      rowNumber: 2,
    },
    {
      sectionHeading: 'Emergency Contact',
      rowNumber: 3,
    },
    {
      label: 'Name',
      name: 'emergencyContactName',
      type: 'text',
      rowNumber: 4,
    },
    {
      label: 'Relationship',
      name: 'emergencyRelationship',
      type: 'text',
      rowNumber: 4,
    },
    {
      label: 'Phone ',
      name: 'emergencyPhone',
      type: 'number',
      rowNumber: 4,
    },

    {
      sectionHeading: 'Payroll Information',
      rowNumber: 5,
    },
    {
      label: 'Legal First Name',
      name: 'legalFirstName',
      type: 'text',
      required: true,
      rowNumber: 6,
    },
    {
      label: 'Legal Middle Name',
      name: 'legalMiddleName',
      type: 'text',
      required: true,
      rowNumber: 6,
    },
    {
      label: 'Legal Last Name',
      name: 'legalLastName',
      type: 'text',
      required: true,
      rowNumber: 6,
    },
    {
      label: 'Date of Birth',
      name: 'legalDateOfBirth',
      type: 'date',
      required: true,
      rowNumber: 7,
    },
    {
      label: 'Social Security Number',
      name: 'legalSocialSecurityNumber',
      type: 'number',
      required: true,
      rowNumber: 7,
    },

    {
      sectionHeading: 'Address Information',
      rowNumber: 8,
    },
    {
      label: 'Primary Phone',
      name: 'primaryPhone',
      type: 'number',
      required: true,
      rowNumber: 9,
    },
    {
      label: 'Secondary Phone',
      name: 'secondaryPhone',
      type: 'number',
      required: true,
      rowNumber: 9,
    },
    {
      label: 'Additional Phone',
      name: 'additionalPhone',
      type: 'number',
      required: true,
      rowNumber: 10,
    },
    {
      label: 'Country',
      name: 'country',
      type: 'text',
      required: true,
      rowNumber: 10,
    },
    {
      label: 'Steet 1',
      name: 'st1',
      type: 'text',
      required: true,
      rowNumber: 11,
    },
    {
      label: 'Steet 2',
      name: 'st2',
      type: 'text',
      required: true,
      rowNumber: 11,
    },
    {
      label: 'Fax Number',
      name: 'faxNumber',
      type: 'number',
      required: true,
      rowNumber: 12,
    },
    {
      label: 'City',
      name: 'city',
      type: 'text',
      required: true,
      rowNumber: 12,
    },
    {
      label: 'State',
      name: 'state',
      type: 'text',
      required: true,
      rowNumber: 13,
    },
    {
      label: 'Zip Code',
      name: 'zipCode',
      type: 'text',
      required: true,
      rowNumber: 13,
    },
    {
      label: 'Email',
      name: 'addressInfoEmail',
      type: 'text',
      required: true,
      rowNumber: 14,
    },
    {
      label: 'Web Address',
      name: 'webaddress',
      type: 'text',
      required: true,
      rowNumber: 14,
    },
  ]
}

export const CAREGIVER_PROFILE_DOCUMENTS_FORM_INPUTS = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      label: 'ExpiryDate',
      name: 'expiryDate',
      type: 'date',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'File',
      name: 'file',
      type: 'file',
      required: true,
      rowNumber: 3,
    },
  ]
}
