import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface BillingProvider {
    company_code: String | undefined;
    entityType: String;
    organizationName: String;
    firstName: String;
    lastName: String;
    middleName: String;
    identityInformation: {
        IDType1: String;
        IDNumber1: Number;
        IDType2: String;
        IDNumber2: Number;
        medicaidProviderNumber: Number;
        NPINumber: Number;
        providerCommercialNumber: Number;
        additionalProviderNumber: Number;
        demonstrationProjectIdentifier: String;
    },
    providerAddress: {
        st1: String;
        st2: String;
        city: String;
        state: String;
        country: String;
        phoneNumber: Number;
        zipCode: Number;
    },
    contactInformation: {
        name: String;
        email: String;
    },
    financialRouting: {
        accountNumber: Number;
        bankCode: Number;
    }
}

export const getBillingProvider = createAsyncThunk(
    'billingProvider/get/',
    async ({ companyid, page, pageSize, sortBy, sortDir, searchTerm  }: { companyid: string | undefined, page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm: string }) => {
        try {
            if (page && pageSize) {
                const response = await axios.get(`billingProvider/get/${companyid}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data
                }
            } else {
                const response = await axios.get('billingProvider/get/' + companyid)
                return {
                    data: response.data
                }
            }

        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneBillingProvider = createAsyncThunk(
    'billingProvider/getOne/',
    async (companyid: String | undefined) => {
        try {
            const response = await axios.get('billingProvider/getOne/' + companyid)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addBillingProvider = createAsyncThunk(
    'billingProvider/create',
    async (data: BillingProvider) => {
        try {
            const response = await axios.post('billingProvider/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteBillingProvider = createAsyncThunk(
    'billingProvider/delete/',
    async (data: String) => {
        try {
            const response = await axios.delete('billingProvider/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)


export const updateBillingProvider = createAsyncThunk(
    'billingProvider/update',
    async (data: BillingProvider) => {
        try {
            const response = await axios.put('billingProvider/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)