import {Fragment, useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {toast} from 'react-toastify'
import {MoonLoader} from 'react-spinners'
import DynamicAddModal from '../Modals/DynamicModal'
import {CAREGIVER_ADD_TIMESHEET_FORM_INPUTS} from '../../pages/caregiver/TIMESHEETS_MODAL_CONSTANTS'
import dayjs from 'dayjs'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import TableTitle from './TableTitle'
import './style.css'
const selectAuth = (state: RootState) => state.auth

function Row(props) {
  let {row, roles, index, handleFetchData, startDate, endDate} = props
  const [open, setOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [uiElements, setUIElements] = useState([])
  const [formData, setFormData] = useState({})
  const [scheduledHours, setScheduledHours] = useState('')
  const [scheduledVsActualHours, setScheduledVsActualHours] = useState('')
  const [numberOfTimeCards, setNumberOfTimeCards] = useState(0)
  const [actualHours, setActualHours] = useState('')
  const [breakHours, setBreakHours] = useState('')
  const [estimatedWages, setEstimatedWages] = useState('')

  const onClickCancel = () => {
    setShowModal(false)
  }

  const buttons = [
    {
      label: 'Cancel',
      variant: 'outlined',
      type: 'button',
      onClick: onClickCancel,
    },
    {
      label: 'Save',
      variant: 'contained',
      type: 'submit',
    },
  ]
  const onClickAddTimeCard = (employe) => {
    let rolesToShow = []
    employe.roles.map((eachRole) => {
      rolesToShow.push({
        label: eachRole?.role[0]?.name,
        value: eachRole.roleId,
      })
    })

    let prevData = formData
    prevData = {}
    let timeStart = new Date()
    let breakTimeStart = new Date()
    let timeEnd = new Date()
    let breakTimeEnd = new Date()
    timeStart.setHours(9)
    timeStart.setMinutes(0)
    timeStart.setSeconds(0)
    timeStart.setMilliseconds(0)
    timeEnd.setHours(18)
    timeEnd.setMinutes(0)
    timeEnd.setSeconds(0)
    timeEnd.setMilliseconds(0)

    breakTimeStart.setHours(13)
    breakTimeStart.setMinutes(0)
    breakTimeStart.setSeconds(0)
    breakTimeStart.setMilliseconds(0)
    breakTimeEnd.setHours(14)
    breakTimeEnd.setMinutes(0)
    breakTimeEnd.setSeconds(0)
    breakTimeEnd.setMilliseconds(0)

    prevData['shiftTimeStart'] = dayjs(timeStart).toDate()
    prevData['shiftTimeEnd'] = dayjs(timeEnd).toDate()
    prevData['breakTimeStart'] = dayjs(breakTimeStart).toDate()
    prevData['breakTimeEnd'] = dayjs(breakTimeEnd).toDate()
    prevData['shiftDate'] = new Date()
    prevData['userId'] = employe.user
    prevData['company_code'] = employe.company_code
    prevData['shiftDate'] = startDate
    setFormData(prevData)

    const elements = CAREGIVER_ADD_TIMESHEET_FORM_INPUTS(rolesToShow, startDate, endDate)
    setUIElements(elements)
    setShowModal(true)
  }

  const changeFetchData = () => {
    handleFetchData()
  }

  const handleSubmitForm = async (data) => {
    let shiftDate = new Date(data.shiftDate)
    let shiftStartTime = new Date(data.shiftTimeStart)
    shiftStartTime.setDate(shiftDate.getDate())
    let shiftEndTime = new Date(data.shiftTimeEnd)
    shiftEndTime.setDate(shiftDate.getDate())

    let breakStartTime = new Date(data.breakTimeStart)
    breakStartTime.setDate(shiftDate.getDate())
    let breakEndTime = new Date(data.breakTimeEnd)
    breakEndTime.setDate(shiftDate.getDate())
    await axios
      .post('timesheets/create', {
        userId: data.userId,
        company_code: data.company_code,
        date: data.shiftDate,
        startTime: shiftStartTime,
        endTime: shiftEndTime,
        roleId: data.roleId,
        breakSlots: [
          {
            startTime: breakStartTime,
            endTime: breakEndTime,
          },
        ],
      })
      .then((res) => {
        if (res.status === 201) {
          toast.success('Timesheet added succcessfully')
        }
        setShowModal(false)
        changeFetchData()
      })
      .catch((err) => {
        toast.error(err.response.data.error)
      })
  }

  const getRoleName = (list, employeIndex, sheetIndex, shiftIndexm, roleId) => {
    let name = ''
    if (list[employeIndex]['roleDetails'][0] != null) {
      let role = row?.roles.filter((eachRole) => eachRole.roleId === roleId)
      name = role[0]?.role[0]?.name
    }
    return `${name}`
  }

  const getWagesPerRole = (list, employeIndex, sheetIndex, shiftIndex, roleId) => {
    let wage = 0
    if (list[employeIndex]['roleDetails'][0] != null) {
      let role = row?.roles.filter((eachRole) => eachRole.roleId === roleId)
      wage = role[0]?.wage
    }
    return `$${wage}/hr`
  }

  const getWages = (list, employeIndex, sheetIndex, shiftIndex, shift, roleId) => {
    let wage = 0
    let totalWages = 0
    const timeSpent = shift?.numberOfMinutes
    const numberOfHours = Math.trunc(timeSpent / 60).toFixed(0)
    const numberOfMins = Math.trunc(timeSpent % 60).toFixed(0)
    if (list[employeIndex]['roleDetails'][0] != null) {
      let role = row?.roles.filter((eachRole) => eachRole.roleId === roleId)
      wage = role[0]?.wage
    }
    if (wage > 0) {
      let wagePerMin = wage / 60
      totalWages = totalWages + wage * parseInt(numberOfHours)
      totalWages = totalWages + wagePerMin * parseInt(numberOfMins)
    }
    return `$${totalWages.toFixed(2)}`
  }

  const getStartAndEndTime = (shift) => {
    const startTime = new Date(shift?.startTime)
    const endTime = new Date(shift?.endTime)
    let startTimeAm = 'am'
    let endTimeAm = 'am'
    let startingHours = startTime.getHours()
    let endingHours = endTime.getHours()
    if (startTime.getHours() >= 12) {
      startTimeAm = 'pm'
      startingHours = startTime.getHours() - 12
      if (startingHours === 0) startingHours = 12
    }
    if (endTime.getHours() >= 12) {
      endTimeAm = 'pm'
      endingHours = endTime.getHours() - 12
      if (endingHours === 0) endingHours = 12
    }
    let startingMinutes = startTime.getMinutes()
    let endingMinutes = endTime.getMinutes()

    let startingMinutesString = ''
    let endingMinutesString = ''
    if (startingMinutes < 10) {
      startingMinutesString = `0${startingMinutes}`
    } else {
      startingMinutesString = startingMinutes.toString()
    }
    if (endingMinutes < 10) {
      endingMinutesString = `0${endingMinutes}`
    } else {
      endingMinutesString = endingMinutes.toString()
    }

    return `${startingHours}:${startingMinutesString} ${startTimeAm} - ${endingHours}:${endingMinutesString} ${endTimeAm}`
  }

  const getScheduledTime = (shift) => {
    const timeSpent = shift?.scheduledNumberOfMinutes || 0
    const numberOfHours = Math.trunc(timeSpent / 60)
    const numberOfMins = Math.trunc(timeSpent % 60)
    return `${numberOfHours.toFixed(0)}h ${numberOfMins.toFixed(0)}m`
  }

  const getNumberOfHours = (shift) => {
    const timeSpent = shift?.numberOfMinutes
    const numberOfHours = Math.trunc(timeSpent / 60)
    const numberOfMins = Math.trunc(timeSpent % 60)
    return `${numberOfHours.toFixed(0)}h ${numberOfMins.toFixed(0)}m`
  }

  const getActualVsScheduled = (shift) => {
    const timeSpent = (shift?.numberOfMinutes || 0) - (shift?.scheduledNumberOfMinutes || 0)
    const numberOfHours = Math.trunc(timeSpent / 60)
    const numberOfMins = Math.trunc(timeSpent % 60)
    return `${numberOfHours.toFixed(0)}h ${numberOfMins.toFixed(0)}m`
  }

  const getDate = (shift) => {
    const date = new Date(shift?.startTime)
    return date.toDateString()
  }

  const getBreaks = (shift) => {
    const timeSpent = shift?.totalBreakTimeInMins
    const numberOfHours = Math.trunc(timeSpent / 60)
    const numberOfMins = Math.trunc(timeSpent % 60)
    return `${numberOfHours.toFixed(0)}h ${numberOfMins.toFixed(0)}m`
  }

  const noConflict = (shift) => {
    let conflict = false
    if (shift.breaks && shift.breaks.length > 0) {
      shift.breaks.map((eachBreak) => {
        if (eachBreak.startTime && !eachBreak.endTime) {
          conflict = true
        }
      })
    }
    return conflict
  }

  const prepareData = (row) => {
    let timeSpent = 0
    let numberOfTimeCard = 0
    let timeScheduled2 = 0
    let actualTime2 = 0
    let breakTime = 0
    let wages = 0
    row?.timeSheets[0]?.timeSheets.map((sheet, sheetIndex) => {
      sheet?.shifts?.map((shift, shiftIndex) => {
        let conflict = false
        if (shift.breaks && shift.breaks.length > 0) {
          shift.breaks.map((eachBreak) => {
            if (eachBreak.startTime && !eachBreak.endTime) {
              conflict = true
            }
          })
        }
        if (shift.startTime && shift.endTime && !conflict) {
          numberOfTimeCard++
          timeSpent = timeSpent + (shift.scheduledNumberOfMinutes || 0)
          timeScheduled2 = timeScheduled2 + (shift.scheduledNumberOfMinutes || 0)
          actualTime2 = actualTime2 + (shift.numberOfMinutes || 0)
          breakTime = breakTime + (shift.totalBreakTimeInMins || 0)
          wages =
            wages +
            (roles[index]['roleDetails'][sheetIndex][shiftIndex]['roleDetails'][0]['wage'] / 60) *
              shift.numberOfMinutes
        }
      })
    })
    let numberOfHours = Math.trunc(timeSpent / 60)
    let numberOfMins = Math.trunc(timeSpent % 60)
    let numberOfHours2 = Math.trunc((actualTime2 - timeScheduled2) / 60)
    let numberOfMins2 = Math.trunc((actualTime2 - timeScheduled2) % 60)
    let numberOfHours3 = Math.trunc(actualTime2 / 60)
    let numberOfMins3 = Math.trunc(actualTime2 % 60)
    let breakHours = Math.trunc(breakTime / 60)
    let breakMins = Math.trunc(breakTime % 60)

    setNumberOfTimeCards(numberOfTimeCard)
    setScheduledHours(`${numberOfHours}h ${numberOfMins}m`)
    setScheduledVsActualHours(`${numberOfHours2}h ${numberOfMins2}m`)
    setActualHours(`${numberOfHours3}h ${numberOfMins3}m`)
    setBreakHours(`${breakHours}h ${breakMins}m`)
    setEstimatedWages(`$${wages.toFixed(2).toString()}`)
  }

  useEffect(() => {
    prepareData(row)
  }, [row])

  return (
    <Fragment>
      <TableRow className={index % 2 === 0 ? 'table-row-even' : 'table-row-odd'}>
        <TableCell align='left' sx={{width: 1 / 20}}>
          <IconButton aria-label='expand row' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='left' sx={{width: 1 / 10}}>
          <span>{`${row.firstName.toUpperCase()} ${row.lastName.toUpperCase()}`}</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>--</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>--</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>{numberOfTimeCards}</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>{scheduledHours}</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>{scheduledVsActualHours}</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>{actualHours}</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>{breakHours}</span>
        </TableCell>
        <TableCell align='center' sx={{width: 1 / 10}}>
          <span>{estimatedWages}</span>
        </TableCell>
      </TableRow>
      <TableCell style={{padding: 0}} colSpan={12}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Table>
            <TableBody>
              {row?.timeSheets &&
                row.timeSheets.length > 0 &&
                row?.timeSheets[0].timeSheets &&
                row?.timeSheets[0].timeSheets.length > 0 &&
                row?.timeSheets[0]?.timeSheets.map((sheet, sheetIndex) =>
                  sheet?.shifts?.map(
                    (shift, shiftIndex) =>
                      !noConflict(shift) &&
                      shift.startTime &&
                      shift.endTime && (
                        <TableRow key={shift.date} sx={{backgroundColor: '#F5F5F5'}}>
                          <TableCell align='left' sx={{width: 1 / 20}}>
                            <Box sx={{padding: '16px'}}>
                              <Box sx={{padding: '2px'}}></Box>
                            </Box>
                          </TableCell>
                          <TableCell align='left' sx={{width: 1 / 7}}>
                            <span>{getDate(shift)}</span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>
                              {getRoleName(roles, index, sheetIndex, shiftIndex, shift?.roleId)}
                            </span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>
                              {getWagesPerRole(roles, index, sheetIndex, shiftIndex, shift?.roleId)}
                            </span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>{getStartAndEndTime(shift)}</span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>{getScheduledTime(shift)}</span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>{getActualVsScheduled(shift)}</span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>{getNumberOfHours(shift)}</span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>{getBreaks(shift)}</span>
                          </TableCell>
                          <TableCell align='center' sx={{width: 1 / 10}}>
                            <span>
                              {getWages(roles, index, sheetIndex, shiftIndex, shift, shift?.roleId)}
                            </span>
                          </TableCell>
                        </TableRow>
                      )
                  )
                )}
            </TableBody>
          </Table>

          {/* </TableRow> */}

          <TableRow>
            <TableCell align='left' onClick={() => onClickAddTimeCard(row)}>
              <span style={{fontSize: '13px', color: 'blue', fontWeight: 'bold'}}>
                +Add Time Card
              </span>
            </TableCell>
          </TableRow>
        </Collapse>
      </TableCell>
      <DynamicAddModal
        showAddScheduleModal={showModal}
        buttons={buttons}
        data={formData}
        formInputs={uiElements}
        handleSubmitForm={handleSubmitForm}
        title={'Add Timesheet'}
      ></DynamicAddModal>
    </Fragment>
  )
}

export default function TimeSheetsDynamicTable() {
  const auth = useSelector(selectAuth)
  const [allEmployesList, setAllEmployes] = useState([])
  const [rolesList, setRoles] = useState([])
  const [statsList, setStats] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [fetchData, setFetchData] = useState(1)
  let curr = new Date()
  const [startDate, setStartDate] = useState(new Date(curr.setDate(curr.getDate() - curr.getDay())))
  const [lastDate, setLastDate] = useState(
    new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
  )

  const handleFetchData = () => {
    let prevData = fetchData
    prevData = prevData + 1
    setFetchData(prevData)
  }

  useEffect(() => {
     axios
      .get(
        `/timesheets/getTimesheetsData/${auth.user.company_code}?startDate=${startDate}&endDate=${lastDate}`
      )
      .then((res) => {
        const {allEmployes, stats} = res.data
        setRoles(res.data.roles)
        setAllEmployes(allEmployes)
        setStats(stats)
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((e) => {
        toast.error(e)
      })
  }, [fetchData, startDate])

  const moveDatesForward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() + 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() + 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const moveDatesBackward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() - 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() - 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  return isLoading ? (
    <div className='d-flex justify-content-center'>
      <MoonLoader color='#9db2fc' size={80} />
    </div>
  ) : (
    <TableContainer component={Paper}>
      <div style={{margin: '2rem'}}>
        <TableTitle title='Company Timesheets'></TableTitle>
      </div>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell className='timesheets-table-header-cell' align='left' />
            <TableCell className='timesheets-table-header-cell' align='left' sx={{width: 1 / 7}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 'max-content',
                  paddingTop: '1rem',
                  padding: '1rem 0rem 1rem 0rem',
                }}
              >
                <ArrowCircleLeftIcon
                  style={{cursor: 'pointer'}}
                  onClick={moveDatesBackward}
                ></ArrowCircleLeftIcon>
                <span
                  style={{
                    fontSize: '12px',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                  }}
                >
                  {startDate.toLocaleString('default', {month: 'short'}) +
                    ' ' +
                    startDate.getDate() +
                    ' - ' +
                    lastDate.toLocaleString('default', {month: 'short'}) +
                    ' ' +
                    lastDate.getDate() +
                    ', ' +
                    lastDate.getFullYear()}
                </span>
                <ArrowCircleRightIcon
                  style={{cursor: 'pointer'}}
                  onClick={moveDatesForward}
                ></ArrowCircleRightIcon>
              </div>
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Role
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Wage
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Time Card
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Scheduled
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Scheduled Vs Actual
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Total Paid
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Breaks
            </TableCell>
            <TableCell className='timesheets-table-header-cell' align='center'>
              Estimated Wages
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEmployesList.map((row, index) => (
            <Row
              key={row.name}
              row={row}
              roles={rolesList}
              stats={statsList}
              index={index}
              handleFetchData={handleFetchData}
              startDate={startDate}
              endDate={lastDate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
