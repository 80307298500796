import React from 'react'
import {useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'
import {hasPermission} from '../../../../_helper/hasPermission'
import {permissions} from '../../../../_constants/permissions'

const selectAuth = (state) => state.auth

function AdminCareHeader() {
  const location = useLocation()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith('/admin/care/individual-intake/')) return 0
    if (location.pathname.startsWith('/admin/care/contact/')) return 1
    if (location.pathname.startsWith('/admin/care/enrollment/')) return 2
    if (location.pathname.startsWith('/admin/care/insurance/')) return 3
    if (location.pathname.startsWith('/admin/care/health/') || location.pathname.startsWith('/admin/care/allergy/') || location.pathname.startsWith('/admin/care/diagnosis/')) return 4
     if (location.pathname.startsWith('/admin/care/incident-report/')) return 5
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              onChange={() => {}}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              orientation='horizontal'
              aria-label='Admin Care Tabs'
              className='w-100'
              sx={{
                flexGrow: 1,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'space-between',

                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 120,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Tab
                label='Clients'
                sx={{flexGrow: 1}}
                component={Link}
                to='/admin/care/individual-intake/list'
              />
              <Tab
                label='Contact'
                sx={{flexGrow: 1}}
                component={Link}
                to='/admin/care/contact/list'
              />
              {hasPermission(userPermissions, permissions.admin_care_enrollment_view) && (
                <Tab
                  label='Enrollment'
                  sx={{flexGrow: 1}}
                  component={Link}
                  to='/admin/care/enrollment/by-program'
                />
              )}
              <Tab
                label='Insurance'
                sx={{flexGrow: 1}}
                component={Link}
                to='/admin/care/insurance/list'
              />
              <Tab
                label='Health Info'
                sx={{flexGrow: 1}}
                component={Link}
                to='/admin/care/health/list'
              />
              <Tab
                label='Incident Report'
                sx={{flexGrow: 1}}
                component={Link}
                to='/admin/care/incident-report/list'
              />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCareHeader
