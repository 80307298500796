import React, {useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ResetPassword} from './components/ResetPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ForgotPassword} from './components/ForgotPassword'
import MainLogo from '../../../assets/logo-files/PNGs - SVGs/1x/Asset 1.png'
import CreatePassword from './components/CreatePassword'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <Router>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-5'>
            <img alt='Logo' src={MainLogo} className='h-45px' />
            {/*<h1 style={{fontSize:"200%"}}>Easy Note Care</h1>*/}
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Switch>
              <Route path='/auth/create-password' component={CreatePassword} />
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/registration' component={Registration} />
              <Route path='/auth/forgot-password' component={ForgotPassword} />
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <p>Footer</p>
            {/* <a href='#' className='text-muted text-hover-primary px-2'>
            Help & Support
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Feedback
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Website
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Live Help
          </a> */}
          </div>
        </div>
        {/* end::Footer */}
      </div>
    </Router>
  )
}
