import { Form, Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addPayer } from '../store/actions'
import { toast } from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { NEW_PAYER_SOURCE, PAYER_FORM_INPUTS } from '../../BILLING_CONSTANTS'

const selectAuth = (state: { auth: any }) => state.auth;

const New = () => {
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmitForm = async (formData: any) => {
        const tempObj: any = {
            company_code: companyid,
            name: formData.name,
            payerID: formData.payerID,
            img: formData.img,
            transmissionTo: formData.transmissionTo
        }
        const response: any = await dispatch(addPayer(tempObj))
        if (response?.payload?.success) {
            toast.success(response?.payload?.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/billing/payers/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    };

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <FormWrapper
            title='Add Payer'
            content={
                <DynamicForm
                    fields={PAYER_FORM_INPUTS}
                    data={NEW_PAYER_SOURCE}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}
export default New        