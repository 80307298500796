import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { getServiceAuth, deleteServiceAuth, } from '../store/actions';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import { LIST_FOR_SERV_AUTH_COLUMNS, LIST_FOR_SERV_AUTH_COLUMNS_MAPPING, LIST_FOR_SERV_AUTH_SORTING_COLUMNS } from '../../../BILLING_TABLE_CONSTANTS';
import { useHistory } from 'react-router-dom';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
import { AppDispatch } from '../../../../../../setup/redux/Store';
const selectAuth = (state: RootState) => state.auth;

interface servAuthType {
    company_code: string | undefined;
    programID: string;
    IDType: string;
    fundingSourceID: string;
    authorizationNumber: string;
    accountingNumber: string;
    beginDate: string;
    endDate: string;
    servfirstName: string;
    servlastName: string;
    organizationAgency: string;
    serviceCoordinatorNumber: string;
    phoneNumber: string;
    extension: string;
    comments: string;
    clientID: string;
    status: string;
    unitMeasure: string;
    unitRate: number;
    claimType: string;
    primaryDiagnosis: string;
    rateHistory: string;
    rateHistoryValue: object[];
    procedureModifier: string[];
    diagnosisPointer: string[];
    procedureQualifier: string;
    unitOfMeasure: string;
    roundingAlgorithm: string;
    allowedIncrement: string;
    serviceID: string;
    totalBillable: number;
    totalAmount: number;
    priorAuth: string;
    dataCollectionMethod: string;
    attendanceType: string;
    remainingUnits: number;
    fundingSourceName: string;
    clientName: string;
    serviceCodeDesc: string;
}

const List = () => {
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deleteSA, setDeleteSA] = useState<string>('');
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'programName',
        sortDir: 'asc',
        searchTerm: ''
    })
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const companyid: string = auth.user?.company_code!;
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {

            try {
                let servAuthResult: any = await dispatch(getServiceAuth({companyid, page, pageSize, sortBy, sortDir, searchTerm: queryData.searchTerm}))
                const { data, currentPage, totalCounts } = servAuthResult.payload.data
                let servAuthsArr: servAuthType[] = data 
                let formattedData = servAuthsArr.map(item => ({
                  ...item,
                    beginDate: formatDate(item.beginDate),
                    endDate: formatDate(item.endDate)
                }));
                setFilteredData(formattedData);
                setQueryData((prevState) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    };
                });
            } catch (error) {
                console.log(error);
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [deleteSA, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      };
    const deleteServiceAuthFun = async (row) => {
        setDeleteSA(row._id)
        await dispatch(deleteServiceAuth(row._id));
    }

    const onUpdate = (row) => {
        history.push(`/billing/professionalclaim/service-authorization/list/${row._id}`)
    }
    const onClickAddButton = () => {
        history.push('/billing/professionalclaim/service-authorization/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Service Auth List'></TableTitle>
                <TableWrapper
                    searchField={
                    <TableSearchBar
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        setQueryData={setQueryData}
                        setSearchTerm={setSearchTerm}
                        margin="normal"
                        inputAdornmentPosition="start"
                      />
                    }
                    tableButtons={
                        <>
                            {!!hasPermission(userPermissions, permissions.billing_professionalClaim_serviceAuthorization_create) &&
                                <AddButton
                                    onClick={onClickAddButton}
                                />}
                            {/* {!!hasPermission(userPermissions, permissions.billing_professionalClaim_serviceAuthorization_search) && <TableButton
                                variant="contained"
                                color="primary"
                                style={{ float: 'right', marginRight: '5px' }}
                                onClick={() => history.push(`/billing/professionalclaim/service-authorization/search`)}
                                title="Advance Search"
                            />} */}
                        </>
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_FOR_SERV_AUTH_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.billing_professionalClaim_serviceAuthorization_update)}
                            canDelete={hasPermission(userPermissions, permissions.billing_professionalClaim_serviceAuthorization_delete)}
                            columnDataMapping={LIST_FOR_SERV_AUTH_COLUMNS_MAPPING}
                            sortingColumns={LIST_FOR_SERV_AUTH_SORTING_COLUMNS}
                            onUpdate={onUpdate}
                            onDelete={deleteServiceAuthFun}
                            title='Are you sure you want to delete the Program'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />



            </div>
        </div>
    )
}

export default List