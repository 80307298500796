import { useEffect, useState } from 'react'
import * as Yup from "yup"
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import AdminCareHeader from '../AdminCareHeader'
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu'
import { DIAGNOSTIC_LIST_COLUMNS_DATA_MAPPING, DIAGNOSTIC_LIST_COLUMNS, DIAGNOSTIC_LIST__SORTING_COLUMNS } from '../care_table_constants'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import TableButton from '../../../../reusable-components/tables/TableButton'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
import { toast } from 'react-toastify'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../reusable-components/Buttons/AddButton'
const selectAuth = (state) => state.auth;

const registrationSchema = Yup.object().shape({
    diagnosisCodeType: Yup.string()
        .required("Diagnosis Code Type is Required"),
    diagnosisName: Yup.string()
        .required("Diagnosis Code is Required"),
    description: Yup.string()
        .required("Diagnosis Description is Required")
})

const DiagnosticsList = () => {
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [diagnosis, setDiagnosis] = useState([])
    const [client, setclient] = useState([])
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: '_id',
        sortDir: 'desc',
        searchTerm: ''
    })

    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const parameters = useParams()
    
    useEffect(() => {
        const fetchData = async (page, pageSize, sortBy, sortDir) => {
            const id = parameters.clientID
            if (id) {
                const [ClientsData, diagnosticsInfo] = await Promise.all([
                    axios.get('/clients/getOne/' + id),
                    axios.get(`/health/getDiagnosis?clientID=${id}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`),
                ])
                const { data, currentPage, totalCounts} = diagnosticsInfo.data
                const clientData = ClientsData.data.client
                setFilteredData(data)
                setclient(clientData)
                setDiagnosis(data)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            }else {
                history.push('/admin/care/health/list')
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

    const onUpdate = (row) => {
        history.push(`/admin/care/diagnosis/list/${client.length > 0 ? client._id : parameters.clientID}/${row._id}`)
    }

    const onDelete = async (row) => {
        try {
            let tempobj = {
                clientID: client.length > 0 ? client._id : parameters.clientID,
                deleteID: row._id
            }
            const response = await axios.put("/health/deleteDiagnosis", tempobj)
            if (response.status === 200) {
                toast.success('Diagnosis Deleted Successfully')
                setFilteredData(prevState =>
                    prevState.filter(entry => entry._id !== row._id)
                )
            }
        } catch (err) {
            console.log(err)
            toast.error('Error Deleting Diagnosis')
        }

    }
    const onClickAddButton = () => {
        history.push({
            pathname: `/admin/care/diagnosis/new/${parameters.clientID}`
        })
    }

    return (
        <div>
            <AdminCareHeader />
            <HealthProfileMenu />
            <div className='card my-10'>
                <div className='card-body'>
                    <div className='mt-5'>
                        <TableTitle title='Diagnosis List'></TableTitle>
                        <TableWrapper
                            searchField={
                                <TableSearchBar
                                    label='Search'
                                    variant='outlined'
                                    size='small'
                                    value={searchTerm}
                                    setQueryData={setQueryData}
                                    setSearchTerm={setSearchTerm}
                                    margin='normal'
                                    inputAdornmentPosition='start'
                                />
                            }
                            tableButtons={
                                !!hasPermission(userPermissions, permissions.admin_care_diagnosis_create) &&
                                <AddButton
                                    onClick={onClickAddButton}
                                />
                            }
                            dynamicTable={
                                <DynamicTable
                                    columns={DIAGNOSTIC_LIST_COLUMNS}
                                    data={filteredData}
                                    canUpdate={hasPermission(userPermissions, permissions.admin_care_diagnosis_update)}
                                    canDelete={hasPermission(userPermissions, permissions.admin_care_diagnosis_delete)}
                                    columnDataMapping={DIAGNOSTIC_LIST_COLUMNS_DATA_MAPPING}
                                    sortingColumns={DIAGNOSTIC_LIST__SORTING_COLUMNS}
                                    onUpdate={onUpdate}
                                    onDelete={onDelete}
                                    title='Are you sure you want to delete this contact?'
                                    setQueryData={setQueryData}
                                    queryData={queryData}
                                ></DynamicTable>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiagnosticsList