import { createSlice } from "@reduxjs/toolkit";
import { getProgram } from "../actions";

interface Program {
    _id: string;
    company_code: string | undefined;
    programName: String;
    programType: String;
    programCode: String;
    primaryContacts: {
        name: String;
        primaryPhone: String;
        secondaryPhone: String
    }
    secondaryContacts: {
        secName: String;
        secPrimaryPhone: String;
        secSecondaryPhone: String
    }
    capacity: Number;
    siteName: String;
    programID: Number;
    individualsAdded: String;
    programCostCenterNumber: Number;
    createDate: String;
    providerCode: String;
    providerName: String;
}

interface ProgramState {
    program: Program[] | any;
    isLoading: boolean
}

const initialState: ProgramState = {
    program: [],
    isLoading: false
}

export const programInitialSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getProgram.fulfilled, (state, action: any) => {
            state.program = action.payload.data
            state.isLoading = false
        })
            .addCase(getProgram.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getProgram.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default programInitialSlice.reducer