import { Redirect, Route, Switch } from 'react-router-dom'
import NewIndividual from '../../../../../pages/admin/care/individual-intake/new/New'
import IndividualList from '../../../../../pages/admin/care/individual-intake/listIndividual/List'
import EditIndividualForm from '../../../../../pages/admin/care/individual-intake/listIndividual/Edit'
import React from 'react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import SearchData from '../../../../../pages/admin/care/individual-intake/searchFields/SearchData'
import SearchFields from '../../../../../pages/admin/care/individual-intake/searchFields/SearchFields'

const IndividualIntakeIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.CARE.CLIENTS.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.CLIENTS.CREATE');
    const canRead = hasPermission(userPermissions,'ADMIN.CARE.CLIENTS.VIEW');
    return (
        <Switch>
            <Route path='/admin/care/individual-intake/list/:id' component={EditIndividualForm} />
            <Route path='/admin/care/individual-intake/list' component={IndividualList} />
            <Route path='/admin/care/individual-intake/new'>
                <NewIndividual />
            </Route>
            <Route path='/admin/care/individual-intake/search'>
                <SearchFields />
            </Route>
            <Route path='/error/404' component={Error404} />
            <Redirect from='/admin/care' exact={true} to='/admin/care/individual-intake/list' />
            <Redirect from='/admin/care/individual-intake' exact={true} to='/admin/care/individual-intake/list' />
        </Switch>
    )
}

export default IndividualIntakeIndexRoutes
