import {useEffect, useState} from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import { Tabs, Tab, useTheme, useMediaQuery } from '@mui/material';

const HealthProfileMenu = () => {
  const [client, setclient] = useState()
  const paramters = useParams()
  const history = useHistory()
  const location = useLocation()

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith(`/admin/care/health`)) return 0
    if (location.pathname.startsWith(`/admin/care/allergy`)) return 1
    if (location.pathname.startsWith(`/admin/care/diagnosis`)) return 2
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    if (paramters.clientID) {
      setclient(paramters.clientID)
    } else {
      history.push('/admin/care/health/list')
    }
  }, [])
  return (
    <div className='card'>
      <div className='card-body pb-0 pt-3'>
        <div className='d-flex overflow-auto h-60px'>
          <Tabs
            value={value}
            variant='scrollable'
            scrollButtons={isSmallScreen ? 'auto' : 'on'}
            aria-label='Health Profile Tabs'
            sx={{
              flexGrow: 1,
              '.MuiTab-root': {
                textTransform: 'none',
                minWidth: 150,
                fontSize: '1rem',
                fontWeight: 'bold',
              },
              '.Mui-selected': {
                color: theme.palette.primary.main,
              },
              '.MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <Tab label='Health Data' component={Link} to={`/admin/care/health/list/${client}`} />
            <Tab
              label='Allergy Profile'
              component={Link}
              to={`/admin/care/allergy/list/${client}`}
            />
            <Tab label='Diagnosis' component={Link} to={`/admin/care/diagnosis/list/${client}`} />
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default HealthProfileMenu
