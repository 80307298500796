import { createSlice } from "@reduxjs/toolkit";
import { getPayer } from "../actions";

interface PayerInterface {
    _id: string;
    company_code: string;
    name: string;
    payerID: string;
    img: object;
    transmissionTo: string;
}

interface PayerState {
    payers: PayerInterface[] | any;
    isLoading: boolean;
}

const initialState: PayerState = {
    payers: [],
    isLoading: false
}

export const payerInitialSlice = createSlice({
    name: 'payers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPayer.fulfilled, (state, action) => {
                state.payers = action.payload;
                state.isLoading = false
            })
            .addCase(getPayer.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPayer.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default payerInitialSlice.reducer;