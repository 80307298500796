import React, {useState, useEffect} from 'react'
import {
  Button,
  TextField,
  Box,
  FormControl,
  Typography,
  InputLabel,
  FormControlLabel,
  Checkbox,
  CardMedia,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Autocomplete,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import dayjs from 'dayjs'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {useHistory} from 'react-router-dom'
import GetICDValues from './GetICDValues'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {toast} from 'react-toastify'
import ClearIcon from '@mui/icons-material/Clear'
import AddressForm from '../Adress/AddressForm'
import PhoneNumber from './PhoneNumber'
import NumberField from './NumberField'

const DynamicForm = ({fields, data, onSubmit, buttons, typographySx, disabled}: any) => {
  const [formData, setFormData] = useState(data)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [filePreview, setFilePreview] = useState('')
  const history = useHistory()
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />

  useEffect(() => {
    const initialOptions = fields.reduce((acc, field) => {
      if (field.type === 'multiSelect' && data[field.name]) {
        acc[field.name] = data[field.name]
      }
      return acc
    }, {})
    setSelectedOptions(initialOptions)
  }, [data, fields])

  useEffect(() => {
    setFormData(data)
  }, [data])

  const handleInputChange = (
    name: string,
    value: boolean | string | number | Date | File,
    checked: boolean | string,
    type: string | undefined
  ) => {
    setFormData((prevData: any) => {
      if (type === 'checkbox') {
        let updatedValue
        if (prevData[name] === undefined) {
          updatedValue = [value]
        } else {
          updatedValue = prevData[name].includes(value)
            ? prevData[name].filter((item: string | number | Date) => item !== value)
            : [...prevData[name], value]
        }

        return {
          ...prevData,
          [name]: updatedValue,
        }
      } else if (type === 'checkboxBoolean') {
        return {
          ...prevData,
          [name]: checked,
        }
      } else {
        return {
          ...prevData,
          [name]: value,
        }
      }
    })
  }
  const handleImageChange = (event: any) => {
    const Image = event.target.files[0]
    if (Image) {
      const imageUrl = URL.createObjectURL(Image)
      setSelectedImage(imageUrl)
      handleInputChange('img', Image, '', 'image')
    }
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]

    if (file) {
      const allowedMimeTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ]

      if (allowedMimeTypes.includes(file.type)) {
        setSelectedFile(file)
        handleInputChange('file', file, '', 'file')
      } else {
        toast.error('Invalid file type. Please select a valid file.')
        event.target.value = ''
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        if (typeof e.target.result === 'string') {
          setFilePreview(e.target.result)
        } else {
          // Handle the case where result is an ArrayBuffer, if needed.
          setFilePreview('')
        }
      }
      reader.readAsDataURL(file)
    } else {
      setFilePreview('')
    }
  }

  // const handleDeleteFile = () => {
  //   setSelectedFile(null)
  //   const file = formData.file
  //   axios
  //     .delete('/deleteFile/' + file)
  //     .then((response) => {
  //       console.log(response.data.message)
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         file: null,
  //       }))
  //     })
  //     .catch((error) => {
  //       console.error(error)
  //       // Handle errors here
  //     })
  //   setFilePreview('')
  // }
  const handleDeleteImage = () => {
    setSelectedImage('')
  }

  // const checkValidation = () => {
  //   return new Promise((resolve, reject) => {
  //     let validData = true
  //     for (let i = 0; i < fields.length; i++) {
  //       if (fields[i].validation && formData[fields[i].name]) {
  //         if (!fields[i].validation.pattern.test(formData[fields[i].name])) {
  //           validData = false
  //           break
  //         }
  //       }
  //     }
  //     if (validData) {
  //       resolve(validData)
  //     } else {
  //       reject(new Error('Invalid values being submitted'))
  //     }
  //   })
  // }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      // const validationBool = await checkValidation()
      onSubmit(formData)
    } catch (err) {
      toast.error(err.message)
    }
  }
  const groupedFields = fields?.reduce((acc: {[x: string]: any[]}, field: {rowNumber: any}) => {
    const {rowNumber} = field
    if (!acc[rowNumber]) {
      acc[rowNumber] = []
    }
    acc[rowNumber].push(field)
    return acc
  }, {})

  const handleAutocompleteChange = (name: string, newValue: string | number | Date) => {
    handleInputChange(name, newValue, true, 'autocomplete')
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {Object.keys(groupedFields).map((rowNumber, index) => {
          const fieldsInRow = groupedFields[rowNumber]
          const sectionHeadingField = fieldsInRow.find(
            (field: {sectionHeading: any}) => field.sectionHeading
          )
          const addressInfo = fieldsInRow.filter(
            (field: {addressInfo: boolean}) => field?.addressInfo
          )
          const mailingInfo = fieldsInRow.filter(
            (field: {mailingInfo: boolean}) => field?.mailingInfo
          )
          return (
            <div key={index}>
              {sectionHeadingField && (
                <Typography
                  sx={
                    typographySx
                      ? typographySx
                      : {
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginBottom: '0.5rem',
                          marginLeft: '1rem',
                        }
                  }
                  color='primary'
                >
                  {sectionHeadingField.sectionHeading}
                </Typography>
              )}
              {(addressInfo.length > 0 || mailingInfo.length > 0) && (
                <AddressForm
                  addressInfo={addressInfo && addressInfo[0]}
                  mailingInfo={mailingInfo && mailingInfo[0]}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}

              <div
                className='row g-3'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                }}
              >
                {fieldsInRow.map((field: any, index: React.Key) => {
                  if (field.sectionHeading) {
                    return null
                  }
                  if (
                    field.type === 'text' ||
                    field.type === 'email' ||
                    field.type === 'password'
                  ) {
                    return (
                      <div
                        key={field.name}
                        className={
                          field?.className
                            ? field.className
                            : `col-12 col-sm-6 col-md-6 
                        ${fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'}
                         ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                         ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                         ${
                           fieldsInRow.length === 4 &&
                           (field.label === 'Comment' || field.label === 'Dosage')
                             ? 'col-lg-4 col-md-4 col-sm-3'
                             : 'col-lg-3 col-md-3 col-sm-3'
                         }
                         ${fieldsInRow.length === 5 && 'col-lg-3 col-md-3 col-sm-3'}`
                        }
                      >
                        <TextField
                          sx={field.sx || {}}
                          defaultValue={field.defaultValue ? field.defaultValue : undefined}
                          label={field.label}
                          variant='outlined'
                          type={field.type}
                          size='small'
                          name={field.name}
                          value={field.value ? field.value : formData[field.name] || ''}
                          disabled={field?.disabled || disabled}
                          onChange={(e) => {
                            if (field.onChange) {
                              field.onChange(
                                e.target.name,
                                e.target.value,
                                '',
                                e.target.type,
                                field.index
                              )
                            }
                            handleInputChange(e.target.name, e.target.value, '', e.target.type)
                          }}
                          required={field.required || false}
                          className='textField'
                          error={Boolean(
                            field.validation &&
                              formData[field.name] &&
                              !field.validation.pattern.test(formData[field.name])
                          )}
                          helperText={
                            field.validation &&
                            formData[field.name] &&
                            !field.validation.pattern.test(formData[field.name])
                              ? field.validation.message
                              : ''
                          }
                        />
                      </div>
                    )
                  } else if (field.type === 'phoneNumber') {
                    return (
                      <div
                        key={field.name}
                        className={
                          field?.className
                            ? field.className
                            : `col-12 col-sm-6 col-md-6 
                        ${fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'}
                         ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                         ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}`
                        }
                      >
                        <PhoneNumber
                          formData={formData}
                          handleInputChange={handleInputChange}
                          field={field}
                          disabled={disabled}
                        />
                      </div>
                    )
                  } else if (field.type === 'number') {
                    return (
                      <div
                        key={field.name}
                        className={`col-12 col-sm-6 col-md-6 
                          ${fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'}
                           ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                           ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                           ${fieldsInRow.length === 4 && 'col-lg-2 col-md-2 col-sm-2'}
                           ${
                             fieldsInRow.length === 5 &&
                             field.label === 'Wage' &&
                             'col-lg-3 col-md-3 col-sm-2'
                           }`}
                      >
                        <TextField
                          defaultValue={field.defaultValue ? field.defaultValue : undefined}
                          label={field.label}
                          variant='outlined'
                          type={field.type}
                          size='small'
                          name={field.name}
                          value={field.value ? field.value : formData[field.name] || ''}
                          disabled={disabled}
                          onChange={(e) => {
                            if (field.onChange) {
                              field.onChange(
                                e.target.name,
                                e.target.value,
                                '',
                                e.target.type,
                                field.index
                              )
                            }
                            handleInputChange(e.target.name, e.target.value, '', e.target.type)
                          }}
                          required={field.required || false}
                          className='textField'
                          error={Boolean(
                            field.validation &&
                              formData[field.name] &&
                              !field.validation.pattern.test(formData[field.name])
                          )}
                          helperText={
                            field.validation &&
                            formData[field.name] &&
                            !field.validation.pattern.test(formData[field.name])
                              ? field.validation.message
                              : ''
                          }
                        />
                      </div>
                    )
                  }
                  if (field.type === 'numberOnly') {
                    return (
                      <div
                        key={field.name}
                        className={`col-12 col-sm-6 col-md-6 
                          ${fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'}
                           ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                           ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}`}
                      >
                        <NumberField
                          formData={formData}
                          handleInputChange={handleInputChange}
                          field={field}
                          disabled={disabled}
                        />
                      </div>
                    )
                  } else if (field.type === 'textarea') {
                    return (
                      <div
                        key={field.name}
                        className={
                          field?.className
                            ? field.className
                            : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
                        }
                      >
                        <TextareaAutosize
                          key={field.name}
                          value={field?.value ? field.value : formData[field.name] || ''}
                          onChange={(e) => {
                            if (field.onChange) {
                              field.onChange(
                                e.target.name,
                                e.target.value,
                                '',
                                e.target.type,
                                field.index
                              )
                            }
                            handleInputChange(e.target.name, e.target.value, '', e.target.type)
                          }}
                          name={field.name}
                          aria-label={field.label}
                          placeholder={field.label}
                          disabled={field?.disabled || disabled}
                          minRows={field.minRows || 6}
                          maxRows={field.maxRows || 6}
                          className={
                            field?.textAreaClassName ? field.textAreaClassName : 'TextareaAutosize'
                          }
                          style={{resize: 'none'}}
                        />
                      </div>
                    )
                  } else if (field.type === 'date') {
                    return (
                      <div
                        key={field.name}
                        className={
                          field?.className
                            ? field.className
                            : `col-12 col-sm-6 col-md-6 
                    ${fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'}
                    ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                    ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                    ${fieldsInRow.length === 4 && 'col-lg-3 col-md-3 col-sm-2'}
                    ${
                      fieldsInRow.length === 5 &&
                      field.label === 'Effective Date' &&
                      'col-lg-2 col-md-2 col-sm-2'
                    }`
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label={field.label}
                            value={dayjs(formData[field.name]) || ''}
                            format='MM-DD-YYYY'
                            onChange={(newValue: any) => {
                              const year = newValue.$y.toString().padStart(2, '0')
                              const month = (newValue.$M + 1).toString().padStart(2, '0')
                              const day = newValue.$D.toString().padStart(2, '0')
                              const newDate = `${year}-${month}-${day}`
                              field.onChange
                                ? field.onChange(newDate, field.index)
                                : handleInputChange(field.name, newDate, '', 'date')
                            }}
                            slotProps={{
                              textField: {size: 'small', error: false, style: {width: '100%'}},
                            }}
                            disabled={disabled}
                            shouldDisableDate={(date: any) => {
                              if (field?.minDate && field?.maxDate) {
                                let minDate = new Date(
                                  field?.minDate.getFullYear(),
                                  field?.minDate.getMonth(),
                                  field?.minDate.getDate()
                                )
                                let maxDate = new Date(
                                  field?.maxDate.getFullYear(),
                                  field?.maxDate.getMonth(),
                                  field?.maxDate.getDate()
                                )
                                return date < minDate || date > maxDate
                              }
                              return false
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )
                  } else if (field.type === 'multiSelect') {
                    return (
                      <div
                        key={field.name}
                        className={`col-12 col-sm-6 col-md-6 ${
                          fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'
                        }`}
                      >
                        <Autocomplete
                          multiple
                          id={field.name}
                          options={field.options}
                          size='small'
                          disableCloseOnSelect
                          forcePopupIcon={true}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderOption={(props, option, {selected}) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          style={{maxWidth: '100%'}}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={selectedOptions.length === 0}
                              label={field.label}
                              placeholder={field.label}
                            />
                          )}
                          value={formData[field.name]}
                          onChange={(event, newValue) => {
                            const selectedValues: any = newValue.map((option) => ({
                              label: option.label,
                              value: option.value,
                            }))
                            setSelectedOptions(selectedValues)
                            handleAutocompleteChange(field.name, selectedValues)
                          }}
                        />
                      </div>
                    )
                  } else if (field.type === 'select') {
                    return (
                      <div
                        key={field.name}
                        className={
                          field?.className
                            ? field.className
                            : `col-12 col-sm-6 col-md-6  ${
                                fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'
                              }
                    ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                    ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                    ${
                      fieldsInRow.length === 4 &&
                      (field.label === 'Search Role' || field.label === 'Unit') &&
                      'col-lg-4 col-md-4 col-sm-4'
                    }
                    ${
                      fieldsInRow.length === 5 &&
                      (field.label === 'Search Role' || field.label === 'Unit') &&
                      'col-xl-3 col-lg-3 col-md-3 col-sm-3'
                    }`
                        }
                      >
                        <Autocomplete
                          disablePortal
                          id={field.name}
                          size='small'
                          options={field.options || []}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          forcePopupIcon={true}
                          disabled={disabled || field?.disabled}
                          renderInput={(params) => (
                            <TextField
                              sx={field.sx || {}}
                              error={field.error ? field.error : undefined}
                              helperText={field.helperText ? field.helperText : undefined}
                              required={field.required}
                              {...params}
                              label={field.label}
                            />
                          )}
                          value={
                            field.value
                              ? field.value
                              : formData[field.name] !== undefined
                              ? field.options.find(
                                  (option: {value: string}) => option.value === formData[field.name]
                                )?.label || ''
                              : ''
                          }
                          onChange={(event, newValue) => {
                            const selectedValue = newValue ? newValue.value : ''
                            handleAutocompleteChange(field.name, selectedValue)
                            if (field.onChange) {
                              field.onChange(selectedValue, field.index)
                            }
                          }}
                        />
                      </div>
                    )
                  } else if (field.type === 'radio') {
                    return (
                      <div key={field.name} className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <FormControl
                          key={field.name}
                          component='fieldset'
                          // className={classes.radioGroup}
                        >
                          <InputLabel shrink>{field.label}</InputLabel>
                          <RadioGroup
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value, true, e.target.type)
                            }
                          >
                            {field.options.map((option: any) => (
                              <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio required={field.required || false} />}
                                label={option.label}
                                className='radioLabel'
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )
                  } else if (field.type === 'checkbox') {
                    return (
                      <div key={field.name} className='col-12 col-md-4 col-lg-4 col-xl-4'>
                        <FormControl
                          key={field.name}
                          component='fieldset'
                          sx={{display: 'flex', flexDirection: 'row'}}
                        >
                          <InputLabel shrink>{field.label}</InputLabel>
                          {field.options.map((option: any) => (
                            <FormControlLabel
                              key={option.value}
                              label={option.label}
                              labelPlacement='end'
                              control={
                                <Checkbox
                                  name={field.name}
                                  value={option.value}
                                  checked={formData[field.name]?.includes(option.value) || false}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value,
                                      e.target.checked,
                                      field.type
                                    )
                                  }
                                />
                              }
                            />
                          ))}
                        </FormControl>
                      </div>
                    )
                  } else if (field.type === 'checkboxBoolean') {
                    return (
                      // <div key={field.name} className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div key={field.name} className='col-12 col-md-4 col-lg-4 col-xl-4'>
                        <FormControl
                          disabled={field?.disabled || false}
                          key={field.name}
                          component='fieldset'
                          sx={{display: 'flex', flexDirection: 'row'}}
                        >
                          <InputLabel shrink>{field.label}</InputLabel>
                          {field.options.map((option: any) => (
                            <FormControlLabel
                              key={option.value}
                              label={option.label}
                              labelPlacement='end'
                              control={
                                <Checkbox
                                  name={field.name}
                                  value={option.value}
                                  checked={formData[field.name] || false}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value,
                                      e.target.checked,
                                      field.type
                                    )
                                  }
                                />
                              }
                            />
                          ))}
                        </FormControl>
                      </div>
                    )
                  } else if (field.type === 'asyncSelectICD') {
                    return (
                      <div key={field.name} className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <GetICDValues
                          field={field}
                          handleInputChange={handleInputChange}
                          initialValue={formData}
                        ></GetICDValues>
                      </div>
                    )
                  } else if (field.type === 'image') {
                    return (
                      <div key={field.name} className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <input
                          type='file'
                          accept='image/*'
                          id='image-input'
                          className='textField'
                          onChange={handleImageChange}
                        />

                        {selectedImage && (
                          <div
                            style={{
                              position: 'relative',
                              display: 'flex',
                              margin: '10px',
                            }}
                          >
                            <CardMedia
                              component='img'
                              alt='Selected'
                              height='120px'
                              width='100px'
                              image={selectedImage}
                            />
                            <ClearIcon
                              style={{
                                position: 'absolute',
                                top: '5px',
                                left: '5px',
                                backgroundColor: 'lightgray',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '5px',
                              }}
                              onClick={handleDeleteImage}
                            />
                          </div>
                        )}
                      </div>
                    )
                  } else if (field.type === 'file') {
                    return (
                      <div key={field.name} className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <div>
                          <input
                            type='file'
                            accept='.pdf, application/pdf, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv '
                            id='file-input'
                            onChange={(event) => handleFileChange(event)}
                          />
                          {selectedFile && (
                            <div
                              style={{
                                position: 'relative',
                                display: 'flex',
                                margin: '10px',
                              }}
                            >
                              {selectedFile.type.startsWith('image/') ? (
                                <img src={filePreview} alt='Selected File' />
                              ) : (
                                <iframe
                                  src={filePreview}
                                  title='Selected File'
                                  width='200'
                                  height='180'
                                />
                              )}
                              <ClearIcon
                                style={{
                                  position: 'absolute',
                                  top: '5px',
                                  left: '5px',
                                  backgroundColor: 'lightgray',
                                  color: 'white',
                                  border: 'none',
                                  cursor: 'pointer',
                                  padding: '5px',
                                }}
                                onClick={(file) => {
                                  console.log(selectedFile)
                                  //  setSelectedFile(null);
                                  //     const file = formData.file
                                  //     axios.delete('/deleteFile/' + file)
                                  //     .then(response => {
                                  //         console.log(response.data.message);
                                  //          setFormData((prevFormData) => ({
                                  //     ...prevFormData,
                                  //     file: null,
                                  //   }))
                                  //     })
                                  //     .catch(error => {
                                  //         console.error(error);
                                  //         // Handle errors here
                                  //     });
                                  //     setFilePreview(null);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  } else if (field.type === 'time') {
                    return (
                      <div
                        key={field.name}
                        className={
                          field?.className
                            ? field.className
                            : `col-12 col-sm-6 col-md-6 
                      ${fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'}
                      ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                      ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                      ${fieldsInRow.length === 4 && 'col-lg-3 col-md-3 col-sm-2'}
                      ${
                        fieldsInRow.length === 5 &&
                        field.label === 'Time' &&
                        'col-lg-3 col-md-3 col-sm-2'
                      }`
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label={field.label}
                            minutesStep={field.minutesStep}
                            value={formData[field.name] ? dayjs(formData[field.name]) : ''}
                            onChange={(e) => {
                              field.onChange
                                ? field.onChange(
                                    field.name,
                                    dayjs(e).toDate(),
                                    '',
                                    field.type,
                                    field.index
                                  )
                                : handleInputChange(field.name, dayjs(e).toDate(), '', field.type)
                            }}
                            slotProps={{
                              textField: {
                                size: 'small',
                                disabled: true,
                                style: {width: '100%'},
                              },
                            }}
                            sx={{
                              '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#000000',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )
                  } else if (field.type === 'button') {
                    return (
                      <div
                        key={field.name}
                        className={`col-12 col-sm-6 col-md-6  ${
                          fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'
                        }
                        ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                        ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                        ${fieldsInRow.length === 4 && 'col-lg-1 col-md-1 col-sm-2'}
                        ${fieldsInRow.length === 5 && 'col-lg-1 col-md-1 col-sm-1'}`}
                      >
                        <Button
                          key={index}
                          type={field.type}
                          sx={
                            field.label === 'Delete'
                              ? {justifyContent: 'flex-end'}
                              : {padding: '4px', margin: '4px'}
                          }
                          variant={field.variant}
                          className={field.type === 'submit' ? 'submitButton' : 'cancelButton'}
                          onClick={
                            field.label === 'Cancel'
                              ? field.onClick
                                ? () => field.onClick(field.index)
                                : () => history.goBack()
                              : field.onClick
                          }
                        >
                          {field.label}
                        </Button>
                      </div>
                    )
                  } else if (field.type === 'textLabel') {
                    return (
                      <div
                        key={field.name}
                        className={`col-12 col-sm-6 col-md-6  ${
                          fieldsInRow.length === 1 && 'col-lg-12 col-md-12 col-sm-12'
                        }
                        ${fieldsInRow.length === 2 && 'col-lg-6 col-md-6 col-sm-6'}
                        ${fieldsInRow.length === 3 && 'col-lg-4 col-md-4 col-sm-4'}
                        ${fieldsInRow.length === 4 && 'col-lg-2 col-md-2 col-sm-2'}
                        ${fieldsInRow.length === 5 && 'col-lg-1 col-md-1 col-sm-1'}`}
                      >
                        <span className='h5'>{`${field.label} : `}</span>
                        <span style={{marginLeft: '10px'}}>{field.value}</span>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </div>
          )
        })}
        <Box className='root'>
          <Box className='buttonContainer'>
            {buttons.map((button: any, index: React.Key | null | undefined) =>
              button.disabled === true ? (
                <div></div>
              ) : (
                <Button
                  key={index}
                  type={button.type}
                  sx={{padding: '4px', margin: '4px'}}
                  variant={button.variant}
                  className={button.type === 'submit' ? 'submitButton' : 'cancelButton'}
                  onClick={
                    button.label === 'Cancel'
                      ? button.onClick
                        ? () => button.onClick(button.index)
                        : () => history.goBack()
                      : button.onClick
                      ? () => button.onClick(button.index)
                      : () => {}
                  }
                  disabled={button.disabled}
                >
                  {button.label}
                </Button>
              )
            )}
          </Box>
        </Box>
      </form>
    </>
  )
}

export default DynamicForm
