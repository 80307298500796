import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react';
import { getOneAttendanceType, updateAttendanceType } from '../store/action';
import { GetDescriptionCode } from '../../description-code/store/action';
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { getLeaveRule } from '../../leave-rule/store/action';
import * as validator from '../../../../../reusable-components/Validation/Validations'
import {
    TextField,  Button, Autocomplete, Box, FormControlLabel, Checkbox, RadioGroup, Radio, FormControl, FormLabel
} from '@mui/material'
import TableTitle from '../../../../../reusable-components/tables/TableTitle';

const selectAuth = (state) => state.auth;


const registrationSchema = Yup.object({
    name: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Name is required'),
    time: Yup.string()
        .oneOf(['Yes', 'No'])
        .required('Time is required'),
    duration: Yup.string().when('time', {
        is: 'Yes',
        then: Yup.string().when('bundleRules', {
            is: (bundleRules) => !bundleRules,
            then: Yup.string().required('Duration is Required')
        })
    }),
    dataRangeGroup: Yup.boolean(),
    directBilling: Yup.boolean(),
    totalUnits: Yup.string().when('dataRangeGroup', {
        is: true,
        then: Yup.string().required('Total units are required'),
        otherwise: Yup.string()
    }),
    billingDate: Yup.string().when('dataRangeGroup', {
        is: true,
        then: Yup.string().required('Billing date is required'),
        otherwise: Yup.string()
    }),
    options: Yup.array().of(
        Yup.object().shape({
            optionName: Yup.string().required("Option Name required"),
            optionCode: Yup.string()
                .required("Option Code is required"),
            billingUnit: Yup.string()
                .when('directBilling', {
                    is: false,
                    then: Yup.string().required("Billing Unit is required"),
                    otherwise: Yup.string()
                        .notRequired(),
                }),
            billable: Yup.bool()
                .required("Billable is required"),

        })
    )
})


const EditAttendanceType = () => {
    const [showDuration, setShowDuration] = useState(false)
    const [descriptionCode, setDescriptionCode] = useState([])
    const [selectedAttendanceType, setSelectedAttendanceType] = useState({})
    const [billing, setBilling] = useState(false)
    const [ispData, setIspData] = useState(false)
    const [bundles, setBundles] = useState(false)
    const [leaveRules, setLeaveRules] = useState(false)
    const [dataRangeGrouping, setDataRangeGrouping] = useState(false)
    const [procedure, setProcedure] = useState(false)
    const [desCode, setDesCode] = useState(false)
    const [leaveRule, setLeaveRule] = useState([])

    const auth = useSelector(selectAuth);
    const dispatch = useDispatch()
    const companyid = auth.user?.company_code
    const [formData, setFormData] = useState({
        company_code: companyid,
        name: '',
        time: '',
        duration: '',
        bundleRules: '',
        directBilling: '',
        bundling: '',
        dataRangeGroup: '',
        leaveRules: '',
        targetService: '',
        totalUnits: '',
        ISPBillable: '',
        ispProgram: '',
        ISPData: '',
        billingDate: '',
        options: [],
        bundlingForm: [],
    })
    const history = useHistory();
    const parameters = useParams();

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                try {
                    const [
                        // DescriptionCodes, 
                        AttendanceType,
                        //  LRResult
                        ] = await Promise.all([
                        // dispatch(GetDescriptionCode({companyid})),
                        dispatch(getOneAttendanceType(parameters.id)),
                        // dispatch(getLeaveRule({companyid}))
                    ])
                    // let tempObj = DescriptionCodes.payload.data
                    // let tempLR = LRResult.payload.data;
                    // const updatedDes = [
                    //     ...tempObj.map((des) => ({ label: des.serviceDescription, value: des._id }))
                    // ]
                    // const updatedLR = [
                    //     ...tempLR.map((LR) => ({ label: LR.name, value: LR._id }))
                    // ]
                    let tempAT = AttendanceType.payload.data.data;
                    // setLeaveRule(updatedLR)
                    // setDescriptionCode(updatedDes)
                    setSelectedAttendanceType(tempAT)
                    setFormData(tempAT)
                }
                catch (err) {
                    console.log(err)
                    // history.push("/admin/billing/attendance-type/list")
                }
            } else {
                // history.push("/admin/billing/attendance-type/list")
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        if (selectedAttendanceType) {
            if (selectedAttendanceType.bundleRules === 'true' || selectedAttendanceType.directBilling === 'true' ||
                selectedAttendanceType.dataRangeGroup === 'true' || selectedAttendanceType.leaveRules === 'true' ||
                selectedAttendanceType.ISPData === 'true') {

                if (selectedAttendanceType.bundleRules === 'true') {
                    setBundles(true)
                } else {
                    setBundles(false)
                }
                if (selectedAttendanceType.directBilling === 'true') {
                    setDataRangeGrouping(true)
                } else {
                    setDataRangeGrouping(false)
                }
                if (selectedAttendanceType.dataRangeGroup === 'true') {
                    setBilling(true)
                } else {
                    setBilling(false)
                }
                if (selectedAttendanceType.leaveRules === 'true') {
                    setLeaveRules(true)
                } else {
                    setLeaveRules(false)
                }
                if (selectedAttendanceType.ISPData === 'true') {
                    setIspData(true)
                } else {
                    setIspData(false)
                }
            } else {
                setBundles(true)
                setDataRangeGrouping(true)
                setBilling(true)
                setLeaveRules(true)
                setIspData(true)
            }
        }

        if (selectedAttendanceType.bundling === 'Daily Bundling') {
            if (selectedAttendanceType.targetService === 'Description Code') {
                setDesCode(true)
            }
            if (selectedAttendanceType.targetService === 'Procedure Modifier') {
                setProcedure(true)
            }
        }
        if (selectedAttendanceType.time === "No") {
            setShowDuration(false)
        }

    }, [selectedAttendanceType])


    const handleInputChange = (event, index, field) => {
        const { value } = event.target;
        const updatedFormData = { ...formData };
        updatedFormData.bundlingForm[index][field] = value;
        setFormData(updatedFormData);
    };

    const handleRemoveItem = (index) => {
        const updatedFormData = { ...formData };
        updatedFormData.bundlingForm.splice(index, 1);
        setFormData(updatedFormData);
    };

    const handleAddItem = () => {
        setFormData((prevData) => ({
            ...prevData,
            bundlingForm: [
                ...prevData.bundlingForm,
                {
                    minRange: '',
                    maxRange: '',
                    billableUnit: '',
                    bundlingCode: '',
                    serviceDescription: '',
                },
            ],
        }));
    };

    const handleBundleFormChange = (event, index, field, modifierIndex) => {
        const { value } = event.target;
        setFormData((prevData) => {
            const newBundlingForm = [...prevData.bundlingForm];

            if (field === 'procedureModifier') {
                // Handle Procedure Modifier as an array
                newBundlingForm[index][field] = [...(newBundlingForm[index][field] || [])];
                newBundlingForm[index][field][modifierIndex] = value;
            } else {
                // Handle other fields as strings
                newBundlingForm[index] = {
                    ...newBundlingForm[index],
                    [field]: value,
                };
            }
            return {
                ...prevData,
                bundlingForm: newBundlingForm,
            };
        });
    };

    const handleRemoveBundleForm = (index) => {
        setFormData((prevData) => {
            const newBundlingForm = [...prevData.bundlingForm];
            newBundlingForm.splice(index, 1);
            return {
                ...prevData,
                bundlingForm: newBundlingForm,
            };
        });
    };

    const handleAddBundleForm = () => {
        setFormData((prevData) => {
            return {
                ...prevData,
                bundlingForm: [
                    ...prevData.bundlingForm,
                    {
                        minRange: '',
                        maxRange: '',
                        billableUnit: '',
                        bundlingCode: '',
                        procedureModifier: ['', '', '', '']
                    },
                ],
            };
        });
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleRadioClickYes = (e) => {
        const { checked } = e.target;
        if (checked) {
            setShowDuration(true);
            setBilling(false);
            setBundles(false);
            setDataRangeGrouping(false);
            setLeaveRules(false);
            setIspData(false);
            setFormData((prevData) => ({
                ...prevData,
                directBilling: '',
                bundleRules: '',
                dataRangeGroup: '',
                leaveRules: '',
                ISPData: '',
                ISPBillable: '',
                totalUnits: '',
                bundling: '',
                duration: '',
                billingDate: '',
                ispProgram: '',
                bundlingForm: [],
                options: [],
            }));
        }
    };
    const handleRadioClickNo = (e) => {
        const { checked } = e.target;
        if (checked) {
            setShowDuration(false);
            setBilling(false);
            setBundles(false);
            setDataRangeGrouping(false);
            setLeaveRules(false);
            setIspData(false);
            setFormData((prevData) => ({
                ...prevData,
                bundleRules: '',
                dataRangeGroup: '',
                ISPData: '',
                ISPBillable: '',
                duration: '',
                bundling: '',
                billingDate: '',
                totalUnits: '',
                ispProgram: '',
                bundlingForm: [],
                options: [],
            }));
        }
    };
    const handleAddOption = () => {
        const newOption = {
            optionName: '',
            optionCode: '',
            billingUnit: '',
            leaveRule: '',
            IspDataCount: '',
            billable: false,
        };

        setFormData((prevData) => ({
            ...prevData,
            options: [...prevData.options, newOption],
        }));
    };

    const handleRemoveOption = (index) => {
        setFormData((prevData) => {
            const updatedOptions = [...prevData.options];
            updatedOptions.splice(index, 1);
            return {
                ...prevData,
                options: updatedOptions,
            };
        });
    };

    const handleSubmit = async (values) => {
        const validations = [
            { field: 'name', validation: validator.NAME_VALIDATION },
        ]
        for (const { field, validation } of validations) {
            if (values[field] !== undefined && values[field] !== null && values[field] !== '') {
                if (!validation.pattern.test(values[field])) {
                    toast.error('Form Validation Failed');
                    return;
                }
            }
        }
        if(values.options.length > 0) {
             if (values.options.length < 2) {
            toast.error(`At least two Attendance options are required`)
            return
            }
        }
        const response = await dispatch(updateAttendanceType(values))
        if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push("/admin/billing/attendance-type/list")
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    return (
        <>
            {
                Object.keys(selectedAttendanceType).length === 0 ? <div className='d-flex justify-content-center'><MoonLoader color="#9db2fc" size={80} /></div>
                    :
                    <div className="card">
                          <form className='card-body' onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(formData);
                            }}>
                                <TableTitle title='Edit Attendance Type' ></TableTitle>
                                <div className='row mt-5'>
                                    <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                            <TextField
                                                type="text"
                                                name="name"
                                                size='small'
                                                className='textField'
                                                value={formData.name || ''}
                                                onChange={handleFieldChange}
                                                variant="outlined"
                                                label="Type Name"
                                                autoComplete="off"
                                                error={Boolean(formData.name && !validator.NAME_VALIDATION.pattern.test(formData.name))}
                                                helperText={formData.name && !validator.NAME_VALIDATION.pattern.test(formData.name) ? validator.NAME_VALIDATION.message : ''}
                                            />
                                        </Box>
                                    </Box>
                                    {/* <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                            <label className="fw-bold fs-6">Use Time In/Out</label>
                                            <RadioGroup
                                                name="time"
                                                value={formData.time}

                                                onChange={(e) => {
                                                    const value = e.target.value
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        time: value,
                                                    }));
                                                }}>
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio disabled={true} onClick={handleRadioClickYes} />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio disabled={true} onClick={handleRadioClickNo} />}
                                                    label="No"
                                                />
                                            </RadioGroup>

                                        </Box>
                                    </Box> */}
                                    {/* {showDuration ? (
                                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                            <Box className='fieldContainer'>
                                                <label className="fw-bold fs-6">Use Duration</label>
                                                <RadioGroup
                                                    name='duration'
                                                    value={formData.duration}
                                                    onChange={handleFieldChange}
                                                >
                                                    <FormControlLabel
                                                        value='For the entire day'
                                                        control={<Radio />}
                                                        label='For the entire day'
                                                    />
                                                    <FormControlLabel
                                                        value='For each session'
                                                        control={<Radio />}
                                                        label='For each session'
                                                    />
                                                </RadioGroup>
                                            </Box>
                                        </Box>
                                    ) : null} */}
                                </div>

                                <div className='row mt-5'>
                                    <div>
                                        <h3 className='mb-3 text-primary display-7'>Advanced</h3>
                                        <Box display='flex' flexDirection='row' sx={{ marginBottom: '0px' }} className='fieldRow'>
                                            <Box className='fieldContainer'>
                                                {/* {
                                                    formData.time === 'No' && (
                                                        <div>
                                                            {dataRangeGrouping ? */}

                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            name="directBilling"
                                                                            // checked={formData.directBilling}
                                                                            disabled
                                                                            checked={true}
                                                                            onChange={(e) => {
                                                                                const { checked } = e.target;
                                                                                const options = formData.options;

                                                                                if (checked) {
                                                                                    const updatedOptions = options.map((option) => ({
                                                                                        ...option,
                                                                                        billingUnit: "",
                                                                                        IspDataCount: '',
                                                                                    }));
                                                                                    setFormData((prevData) => {
                                                                                        return {
                                                                                            ...prevData,
                                                                                            totalUnits: '',
                                                                                            ispProgram: '',
                                                                                            ISPBillable: '',
                                                                                            billingDate: '',
                                                                                            bundleRules: '',
                                                                                            options: updatedOptions
                                                                                        }
                                                                                    })
                                                                                    setBilling(false)
                                                                                    setLeaveRules(false)
                                                                                    setBundles(false)
                                                                                    setIspData(false)
                                                                                } else {
                                                                                    setBilling(true)
                                                                                    setLeaveRules(true)
                                                                                    setBundles(true)
                                                                                    setIspData(true)
                                                                                }
                                                                                setFormData((prevData) => {
                                                                                    return {
                                                                                        ...prevData,
                                                                                        directBilling: checked || "",
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Use Direct Billing Units from Attendance"
                                                                />
                                                                {/* : null}
                                                        </div>
                                                    )
                                                } */}

                                            </Box>
                                        </Box>

                                        {/* {
                                            bundles ? (
                                                <Box display='flex' flexDirection='row' sx={{ marginBottom: '0px' }} className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.bundleRules}
                                                                    onChange={(e) => {
                                                                        const { checked } = e.target;
                                                                        setFormData((prevData) => {
                                                                            if (checked) {
                                                                                setBilling(false)
                                                                                setDataRangeGrouping(false)
                                                                                setLeaveRules(false)
                                                                                if (formData.time === "Yes") {
                                                                                    setShowDuration(false)
                                                                                };

                                                                                // Handle checked state
                                                                                // You can set other values as needed
                                                                                return {
                                                                                    ...prevData,
                                                                                    bundleRules: checked,
                                                                                };
                                                                            } else {
                                                                                setBilling(true)
                                                                                setLeaveRules(true)
                                                                                setIspData(true)
                                                                                setDataRangeGrouping(true)
                                                                                setBundles(true)
                                                                                if (formData.time === "Yes") {
                                                                                    setShowDuration(true)
                                                                                };
                                                                                // Reset relevant fields when the checkbox is unchecked
                                                                                return {
                                                                                    ...prevData,
                                                                                    bundleRules: '',
                                                                                    billingDate: '',
                                                                                    bundling: '',
                                                                                    targetService: '',
                                                                                    bundlingForm: [],
                                                                                    ISPData: '',
                                                                                    duration: '',
                                                                                };
                                                                            }
                                                                        });
                                                                        if (formData.time === "Yes") {
                                                                            setShowDuration(!showDuration)
                                                                        };
                                                                    }}
                                                                />
                                                            }
                                                            label="Bundle Rules"
                                                        />
                                                        {
                                                            formData.bundleRules && (
                                                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-5'>
                                                                    <RadioGroup
                                                                        name='bundling'
                                                                        value={formData.bundling}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value
                                                                            setFormData((prevData) => ({
                                                                                ...prevData,
                                                                                bundling: value,
                                                                            }));
                                                                        }}
                                                                    >
                                                                        <FormControlLabel
                                                                            value='Continuous Bundling'
                                                                            control={<Radio onChange={(e) => {
                                                                                const { checked } = e.target
                                                                                setFormData((prevData) => {
                                                                                    if (checked) {
                                                                                        setIspData(true)
                                                                                        setDesCode(false)
                                                                                        setProcedure(false)
                                                                                        if (formData.time === "Yes") {
                                                                                            setShowDuration(true)
                                                                                        };
                                                                                        return {
                                                                                            ...prevData,
                                                                                            billingDate: '',
                                                                                            bundlingForm: [],
                                                                                            targetService: '',
                                                                                            ISPBillable: '',
                                                                                            ispProgram: '',
                                                                                            ISPData: '',
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }} />}
                                                                            label='Continuous Bundling'
                                                                        />
                                                                        {formData.time === 'Yes' ? (
                                                                            <FormControlLabel
                                                                                value='Daily Bundling'
                                                                                control={
                                                                                    <Radio
                                                                                        onClick={(e) => {
                                                                                            const { checked } = e.target
                                                                                            if (checked) {
                                                                                                setIspData(false)
                                                                                                setShowDuration(false)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label='Daily Bundling'
                                                                            />
                                                                        ) : (
                                                                            <FormControlLabel
                                                                                value='Monthly Bundling'
                                                                                control={
                                                                                    <Radio
                                                                                        onChange={(e) => {
                                                                                            const { checked } = e.target
                                                                                            setFormData((prevData) => {
                                                                                                if (checked) {
                                                                                                    setIspData(true)
                                                                                                    return {
                                                                                                        ...prevData,
                                                                                                        ispProgram: '',
                                                                                                        ISPBillable: '',
                                                                                                        ISPData: '',
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label='Monthly Bundling'
                                                                            />
                                                                        )}
                                                                    </RadioGroup>
                                                                </div>
                                                            )
                                                        }
                                                    </Box>
                                                </Box>
                                            ) : null
                                        }

                                        {formData.time === 'Yes' && formData.bundling === 'Daily Bundling' && formData.bundlingForm.length > 0 ? (
                                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                                <Box className='fieldContainer'>
                                                    <FormLabel className='fw-bold fs-6 mb-2 '>Target Service Matching Criteria</FormLabel>
                                                    <RadioGroup
                                                        name='targetService'
                                                        sx={{ marginLeft: '10px' }}
                                                        value={formData.targetService}
                                                        onChange={(e) => {
                                                            const value = e.target.value
                                                            setFormData((prevData) => {
                                                                return {
                                                                    ...prevData,
                                                                    targetService: value
                                                                }
                                                            })

                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value='Description Code'
                                                            control={<Radio
                                                                onChange={(e) => {
                                                                    const { checked } = e.target
                                                                    setFormData((prevData) => {
                                                                        if (checked) {
                                                                            setProcedure(false)
                                                                            setDesCode(true)
                                                                            return {
                                                                                ...prevData,
                                                                                bundlingForm: [
                                                                                    {
                                                                                        ...prevData.bundlingForm[0],
                                                                                        procedureModifier: [],
                                                                                    },
                                                                                ]
                                                                            }
                                                                        }

                                                                    })
                                                                }}
                                                            />}
                                                            label='Description Code'
                                                            disabled={!formData.bundlingForm}
                                                        />
                                                        <FormControlLabel
                                                            value='Procedure Modifier'
                                                            control={<Radio
                                                                onChange={(e) => {
                                                                    const { checked } = e.target
                                                                    setFormData((prevData) => {
                                                                        if (checked) {
                                                                            setProcedure(true)
                                                                            setDesCode(false)
                                                                            return {
                                                                                ...prevData,
                                                                                bundlingForm: [
                                                                                    {
                                                                                        ...prevData.bundlingForm[0],
                                                                                        serviceDescription: '',
                                                                                    },
                                                                                ]
                                                                            }
                                                                        }

                                                                    })
                                                                }}
                                                            />}
                                                            label='Procedure Modifier'
                                                        />
                                                    </RadioGroup>
                                                </Box>
                                            </Box>
                                        ) : null}

                                        {
                                            formData.time === "No" && formData.bundling === "Monthly Bundling" && formData.bundlingForm ? (
                                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        {formData.bundlingForm.map((item, index) => (
                                                            <div key={index}>
                                                                <div className='row'>
                                                                    <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Min. Range'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.minRange}
                                                                            onChange={(e) => handleInputChange(e, index, 'minRange')}
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Max. Range'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.maxRange}
                                                                            onChange={(e) => handleInputChange(e, index, 'maxRange')}
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Billable Unit'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.billableUnit}
                                                                            onChange={(e) => handleInputChange(e, index, 'billableUnit')}
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Billing Code'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.bundlingCode}
                                                                            onChange={(e) => handleInputChange(e, index, 'bundlingCode')}
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mt-5'>
                                                                        <Autocomplete
                                                                            value={item.serviceDescription}
                                                                            onChange={(e, newValue) => handleInputChange({ target: { value: newValue ? newValue.label : undefined } }, index, 'serviceDescription')}
                                                                            options={descriptionCode}
                                                                            renderInput={(params) => <TextField {...params} size='small' label="- Select Description Code -" />}
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 mt-5'>
                                                                        <Button
                                                                            variant='contained'
                                                                            sx={{
                                                                                backgroundColor: '#D9214E', borderColor: '#D9214E', '&:hover': {
                                                                                    backgroundColor: '#C21C47',
                                                                                },
                                                                            }}
                                                                            size='small'
                                                                            onClick={() => handleRemoveItem(index)}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <div className='d-flex justify-content-start mb-3'>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    backgroundColor: '#50CD89', borderColor: '#50CD89', '&:hover': {
                                                                        backgroundColor: '#409877',
                                                                    },
                                                                }}
                                                                size='small'
                                                                onClick={handleAddItem}
                                                            >
                                                                Add Bundling
                                                            </Button>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            ) : formData.time === "Yes" && formData.bundling === "Daily Bundling" && formData.bundlingForm ? (
                                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        {formData.bundlingForm.map((item, index) => (
                                                            <div key={index}>
                                                                <div className='row'>
                                                                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Min. Range'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.minRange}
                                                                            onChange={(e) =>
                                                                                handleBundleFormChange(e, index, 'minRange')
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Max. Range'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.maxRange}
                                                                            onChange={(e) =>
                                                                                handleBundleFormChange(e, index, 'maxRange')
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Billable Unit'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.billableUnit}
                                                                            onChange={(e) =>
                                                                                handleBundleFormChange(e, index, 'billableUnit')
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                                                        <TextField
                                                                            label='Billing Code'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            margin='normal'
                                                                            value={item.bundlingCode}
                                                                            onChange={(e) =>
                                                                                handleBundleFormChange(e, index, 'bundlingCode')
                                                                            }
                                                                        />
                                                                    </div>

                                                                    {procedure === true ? (
                                                                        <div className='row mt-4'>
                                                                            {Array.from({ length: 4 }).map((_, modifierIndex) => (
                                                                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6' key={modifierIndex}>
                                                                                    <TextField
                                                                                        label={`Procedure Modifier ${modifierIndex + 1}`}
                                                                                        variant='outlined'
                                                                                        size='small'
                                                                                        fullWidth
                                                                                        margin='normal'
                                                                                        value={(item.procedureModifier || [])[modifierIndex] || ''}
                                                                                        onChange={(e) => handleBundleFormChange(e, index, 'procedureModifier', modifierIndex)}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : desCode === true ? (
                                                                        <div className='row'>
                                                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-6'>
                                                                                <Autocomplete
                                                                                    value={item.serviceDescription}
                                                                                    onChange={(e, newValue) => handleInputChange({ target: { value: newValue ? newValue.label : undefined } }, index, 'serviceDescription')}
                                                                                    options={descriptionCode}
                                                                                    renderInput={(params) => <TextField {...params} size='small' label="- Select Description Code -" />}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                    }
                                                                    <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 my-2'>
                                                                        <Button
                                                                            variant='contained'
                                                                            sx={{
                                                                                backgroundColor: '#D9214E', borderColor: '#D9214E', '&:hover': {
                                                                                    backgroundColor: '#C21C47',
                                                                                },
                                                                            }}
                                                                            size='small'
                                                                            onClick={() => handleRemoveBundleForm(index)}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='d-flex justify-content-start mb-3'>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    backgroundColor: '#50CD89', borderColor: '#50CD89', '&:hover': {
                                                                        backgroundColor: '#409877',
                                                                    },
                                                                }}
                                                                size='small'
                                                                onClick={handleAddBundleForm}
                                                            >
                                                                Add Bundling
                                                            </Button>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            ) : null
                                        }
                                        {
                                            billing === true ?
                                                <Box display='flex' flexDirection='row' sx={{ marginBottom: '0px' }} className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="dataRangeGroup"
                                                                    checked={formData.dataRangeGroup}
                                                                    onChange={(e) => {
                                                                        const { checked } = e.target;
                                                                        setFormData((prevData) => {
                                                                            if (!checked) {
                                                                                // Reset relevant fields when the checkbox is unchecked
                                                                                setBilling(true)
                                                                                setLeaveRules(true)
                                                                                setBundles(true)
                                                                                setIspData(true)
                                                                                setDataRangeGrouping(true)
                                                                                return {
                                                                                    ...prevData,
                                                                                    dataRangeGroup: '',
                                                                                    totalUnits: '',
                                                                                    billingDate: '',
                                                                                };
                                                                            } else if (checked) {
                                                                                setBundles(false)
                                                                                setIspData(false)
                                                                                setLeaveRules(false)
                                                                                setDataRangeGrouping(false)
                                                                                setFormData((prevData) => {
                                                                                    return {
                                                                                        ...prevData,
                                                                                        dataRangeGroup: checked,
                                                                                        bundlingForm: [],
                                                                                        bundling: '',
                                                                                        bundleRules: !checked || ''
                                                                                    }
                                                                                })
                                                                                // Handle checked state
                                                                                // You can set other values as needed
                                                                                return {
                                                                                    ...prevData,
                                                                                    directBilling: '',
                                                                                    dataRangeGroup: checked || '',
                                                                                };
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Use Data Range Grouping"
                                                        />
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            formData.time === "No" && leaveRules ? (
                                                <Box display='flex' flexDirection='row' sx={{ marginBottom: '0px' }} className='fieldRow'>
                                                    <Box className='fieldContainer' >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="leaveRules"
                                                                    checked={formData.leaveRules}
                                                                    onChange={(e) => {
                                                                        const { checked } = e.target;
                                                                        const options = formData.options;
                                                                        const updatedOptions = options.map((option) => ({
                                                                            ...option,
                                                                            leaveRule: "", billingUnit: ''
                                                                        }));
                                                                        if (checked) {
                                                                            setBundles(false)
                                                                            setBilling(false)
                                                                            setDataRangeGrouping(false)
                                                                            setIspData(false)
                                                                            setFormData({
                                                                                ...formData,
                                                                                options: updatedOptions,
                                                                            });
                                                                        } else {
                                                                            setBundles(true)
                                                                            setBilling(true)
                                                                            setDataRangeGrouping(true)
                                                                            setIspData(true)
                                                                            setFormData({
                                                                                ...formData, options: updatedOptions,
                                                                            })
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            leaveRules: checked || "",
                                                                            options: updatedOptions
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Use Leave Rules"
                                                        />
                                                    </Box>
                                                </Box>
                                            ) : null
                                        }
                                        {
                                            formData.bundleRules === true ? (
                                                ispData ? (
                                                    <Box display='flex' flexDirection='row' sx={{ marginBottom: '0px' }} className='fieldRow'>
                                                        <Box className='fieldContainer'>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="ISPData"
                                                                        checked={formData.ISPData}
                                                                        onChange={(e) => {
                                                                            const { checked } = e.target;
                                                                            const options = formData.options;

                                                                            if (!checked) {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    ispProgram: "",
                                                                                    ISPBillable: "",
                                                                                    options: options.map((option) => ({
                                                                                        ...option,
                                                                                        IspDataCount: "", billingUnit: ''
                                                                                    })),
                                                                                });
                                                                            } else if (checked) {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    ISPData: checked || "",
                                                                                    ispProgram: "",
                                                                                    options: options.map((option) => ({
                                                                                        ...option,
                                                                                        IspDataCount: "", billingUnit: ''
                                                                                    })),
                                                                                });
                                                                            }

                                                                        }}
                                                                    />
                                                                }
                                                                label="Use ISP Data as Supporting Document"
                                                            />
                                                        </Box>
                                                    </Box>
                                                ) : null
                                            ) : (
                                                ispData ? (
                                                    <Box display='flex' flexDirection='row' sx={{ marginBottom: '0px' }} className='fieldRow'>
                                                        <Box className='fieldContainer'>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="ISPData"
                                                                        checked={formData.ISPData}
                                                                        onChange={(e) => {
                                                                            const { checked } = e.target;
                                                                            const options = formData.options;
                                                                            if (!checked) {
                                                                                setBundles(true)
                                                                                setBilling(true)
                                                                                setDataRangeGrouping(true)
                                                                                setLeaveRules(true)
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    ispProgram: "",
                                                                                    ISPData: "",
                                                                                    ISPBillable: "",
                                                                                    options: options.map((option) => ({
                                                                                        ...option,
                                                                                        IspDataCount: "", billingUnit: ''
                                                                                    })),
                                                                                });
                                                                            } else {
                                                                                setBilling(false)
                                                                                setDataRangeGrouping(false)
                                                                                setLeaveRules(false)
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    ISPData: checked || "",
                                                                                    ispProgram: "",
                                                                                    ISPBillable: "",
                                                                                    options: options.map((option) => ({
                                                                                        ...option,
                                                                                        IspDataCount: "", billingUnit: ''
                                                                                    })),
                                                                                });
                                                                            }

                                                                        }}
                                                                    />
                                                                }
                                                                label="Use ISP Data as Supporting Document"
                                                            />
                                                        </Box>
                                                    </Box>
                                                ) : null
                                            )
                                        }

                                        {
                                            formData.dataRangeGroup ? (
                                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup name="totalUnits" value={formData.totalUnits} sx={{ marginLeft: '10px' }} onChange={handleFieldChange}>
                                                                <FormControlLabel
                                                                    value="Calculate total units by adding units of each day"
                                                                    control={<Radio />}
                                                                    label="Calculate total units by adding units of each day"
                                                                />
                                                                <FormControlLabel
                                                                    value="Calculate total units by adding durations of each day"
                                                                    control={<Radio />}
                                                                    label="Calculate total units by adding durations of each day"
                                                                />

                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            ) : null
                                        }
                                        {
                                            formData.dataRangeGroup || formData.bundling === "Monthly Bundling" ? (
                                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend" className='fw-bold fs-6 mb-2'>Billing Data Service Date</FormLabel>
                                                            <RadioGroup name="billingDate" value={formData.billingDate} sx={{ marginLeft: '10px' }} onChange={handleFieldChange}>
                                                                {formData.bundling === "Monthly Bundling" ? (
                                                                    <>
                                                                        <FormControlLabel
                                                                            value="Use Attendance Search Start Date and End Date as Billing Data Service Date From and Service Date To"
                                                                            control={<Radio />}
                                                                            label="Use Attendance Search Start Date and End Date as Billing Data Service Date From and Service Date To"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Use Only Attendance Search Start Date as Billing Data Service Date"
                                                                            control={<Radio />}
                                                                            label="Use Only Attendance Search Start Date as Billing Data Service Date"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Use Only Attendance Search End Date as Billing Data Service Date"
                                                                            control={<Radio />}
                                                                            label="Use Only Attendance Search End Date as Billing Data Service Date"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FormControlLabel
                                                                            value="Use Attendance Search Start Date and End Date as Billing Data Service Date From and Service Date To"
                                                                            control={<Radio />}
                                                                            label="Use Attendance Search Start Date and End Date as Billing Data Service Date From and Service Date To"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Use First Billable Attendance Service Date and Last Billable Attendance Service Date as Billing Data Service Date From and Service Date To"
                                                                            control={<Radio />}
                                                                            label="Use First Billable Attendance Service Date and Last Billable Attendance Service Date as Billing Data Service Date From and Service Date To"
                                                                        />
                                                                    </>
                                                                )}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            ) : null
                                        }


                                        {
                                            formData.ISPData ? (
                                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                                    <Box className='fieldContainer'>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend" className='fw-bold fs-6 mb-2'>Billing Data Service Date</FormLabel>
                                                            <RadioGroup name="ispProgram" value={formData.ispProgram} sx={{ marginLeft: '10px' }} onChange={handleFieldChange}>
                                                                <FormControlLabel
                                                                    value="Same Program only (Use ISP Programs under the Same Program as the Service Authorization)"
                                                                    control={<Radio />}
                                                                    label="Same Program only (Use ISP Programs under the Same Program as the Service Authorization)"
                                                                />
                                                                <FormControlLabel
                                                                    value="Any Program (Use ISP Programs under any Program)"
                                                                    control={<Radio />}
                                                                    label="Any Program (Use ISP Programs under any Program)"
                                                                />
                                                                <FormControlLabel
                                                                    value="Supporting Document (Use the ISP Program added as Supporting Document in the Service Authorization)"
                                                                    control={<Radio />}
                                                                    label="Supporting Document (Use the ISP Program added as Supporting Document in the Service Authorization)"
                                                                />
                                                            </RadioGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="ISPBillable"
                                                                        checked={formData.ISPBillable}
                                                                        onChange={(e) => {
                                                                            const { checked } = e.target;
                                                                            setFormData({ ...formData, ISPBillable: checked || "" });
                                                                        }}
                                                                    />
                                                                }
                                                                label="ISP Data criteria - Billable only"
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            ) : null
                                        } */}
                                    </div>
                                </div>
                                <div className="row">
                                     <Box display='flex' flexDirection='row' className='fieldRow'>
                                    <Box className='fieldContainer'>
                                        <div className='row'>
                                            <h3 className='mb-3 text-primary display-7 mt-3'>Options</h3>
                                            <div style={{ backgroundColor: "BlanchedAlmond", display: 'flex', maxWidth: 'fit-content' }} className='my-3 mx-5'>
                                                <p className='mt-3'>Once an Attendance Option is used the Option Name can no longer be changed.</p>
                                            </div>
                                            {/* {
                                                formData.leaveRules ?
                                                    (
                                                        <div>
                                                            {formData.options.map((option, index) => (
                                                                <Box key={index} marginBottom={1} marginTop={1}>
                                                                    <div className="row">
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.optionName}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].optionName = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Option Name'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.optionCode}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].optionCode = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Option Code'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.billingUnit}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].billingUnit = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Billing Unit'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <Autocomplete
                                                                                // value={formData.options[index].leaveRule}
                                                                                onChange={(e, newValue) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].leaveRule = newValue;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                options={leaveRule}
                                                                                renderInput={(params) => <TextField {...params} size='small' label="- Select Leave Rules -" />}
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={option.billable}
                                                                                        onChange={(e) => {
                                                                                            const updatedOptions = [...formData.options];
                                                                                            updatedOptions[index].billable = e.target.checked;
                                                                                            setFormData((prevData) => ({
                                                                                                ...prevData,
                                                                                                options: updatedOptions,
                                                                                            }));
                                                                                        }}
                                                                                        size='small'
                                                                                    />
                                                                                }
                                                                                label="Billable"
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 mt-2'>
                                                                            <Button
                                                                                variant='contained'
                                                                                size='small'
                                                                                sx={{
                                                                                    backgroundColor: '#D9214E', borderColor: '#D9214E', '&:hover': {
                                                                                        backgroundColor: '#C21C47',
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleRemoveOption(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            ))}
                                                            <div className='d-flex justify-content-start '>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    sx={{
                                                                        backgroundColor: '#50CD89', borderColor: '#50CD89', '&:hover': {
                                                                            backgroundColor: '#409877',
                                                                        },
                                                                    }}
                                                                    onClick={handleAddOption}
                                                                >
                                                                    Add Attendance
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )
                                                    : formData.ISPData ? (
                                                        <div>
                                                            {formData.options.map((option, index) => (
                                                                <Box key={index} marginBottom={1} marginTop={1}>
                                                                    <div className="row">
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.optionName}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].optionName = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Option Name'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.optionCode}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].optionCode = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Option Code'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        {formData.time === "No" && (
                                                                            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                                <TextField
                                                                                    value={option.billingUnit}
                                                                                    onChange={(e) => {
                                                                                        const updatedOptions = [...formData.options];
                                                                                        updatedOptions[index].billingUnit = e.target.value;
                                                                                        setFormData((prevData) => ({
                                                                                            ...prevData,
                                                                                            options: updatedOptions,
                                                                                        }));
                                                                                    }}
                                                                                    placeholder='Billing Unit'
                                                                                    variant='outlined'
                                                                                    size='small'
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.IspDataCount}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].IspDataCount = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Isp Data Count'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={option.billable}
                                                                                        onChange={(e) => {
                                                                                            const updatedOptions = [...formData.options];
                                                                                            updatedOptions[index].billable = e.target.checked;
                                                                                            setFormData((prevData) => ({
                                                                                                ...prevData,
                                                                                                options: updatedOptions,
                                                                                            }));
                                                                                        }}
                                                                                        size='small'
                                                                                    />
                                                                                }
                                                                                label="Billable"
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 mt-1'>
                                                                            <Button
                                                                                variant='contained'
                                                                                size='small'
                                                                                sx={{
                                                                                    backgroundColor: '#D9214E', borderColor: '#D9214E', '&:hover': {
                                                                                        backgroundColor: '#C21C47',
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleRemoveOption(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            ))}
                                                            <div className='d-flex justify-content-start mt-2'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    sx={{
                                                                        backgroundColor: '#50CD89', borderColor: '#50CD89', '&:hover': {
                                                                            backgroundColor: '#409877',
                                                                        },
                                                                    }}
                                                                    onClick={handleAddOption}
                                                                >
                                                                    Add Attendance
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : formData.bundleRules ? (
                                                        <div>
                                                            {formData.options.map((option, index) => (
                                                                <Box key={index} marginBottom={1} marginTop={1}>
                                                                    <div className="row">
                                                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.optionName}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].optionName = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Option Name'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <TextField
                                                                                value={option.optionCode}
                                                                                onChange={(e) => {
                                                                                    const updatedOptions = [...formData.options];
                                                                                    updatedOptions[index].optionCode = e.target.value;
                                                                                    setFormData((prevData) => ({
                                                                                        ...prevData,
                                                                                        options: updatedOptions,
                                                                                    }));
                                                                                }}
                                                                                placeholder='Option Code'
                                                                                variant='outlined'
                                                                                size='small'
                                                                            />
                                                                        </div>
                                                                        {formData.time === "No" && (
                                                                            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                                <TextField
                                                                                    value={option.billingUnit}
                                                                                    onChange={(e) => {
                                                                                        const updatedOptions = [...formData.options];
                                                                                        updatedOptions[index].billingUnit = e.target.value;
                                                                                        setFormData((prevData) => ({
                                                                                            ...prevData,
                                                                                            options: updatedOptions,
                                                                                        }));
                                                                                    }}
                                                                                    placeholder='Billing Unit'
                                                                                    variant='outlined'
                                                                                    size='small'
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={option.billable}
                                                                                        onChange={(e) => {
                                                                                            const updatedOptions = [...formData.options];
                                                                                            updatedOptions[index].billable = e.target.checked;
                                                                                            setFormData((prevData) => ({
                                                                                                ...prevData,
                                                                                                options: updatedOptions,
                                                                                            }));
                                                                                        }}
                                                                                        size='small'
                                                                                    />
                                                                                }
                                                                                label="Billable"
                                                                            />
                                                                        </div>
                                                                        <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 mt-3'>
                                                                            <Button
                                                                                variant='contained'
                                                                                size='small'
                                                                                sx={{
                                                                                    backgroundColor: '#D9214E', borderColor: '#D9214E', '&:hover': {
                                                                                        backgroundColor: '#C21C47',
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleRemoveOption(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            ))}
                                                            <div className='d-flex justify-content-start mt-2 mx-3'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    sx={{
                                                                        backgroundColor: '#50CD89', borderColor: '#50CD89', '&:hover': {
                                                                            backgroundColor: '#409877',
                                                                        },
                                                                    }}
                                                                    onClick={handleAddOption}
                                                                >
                                                                    Add Attendance
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : */}
                                                        <div>
                                                            {formData.options && formData.options.length > 0 ? (
                                                                formData.options.map((option, index) => (
                                                                    <Box key={index} marginBottom={1} marginTop={1}>
                                                                        <div className="row">
                                                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mb-1'>
                                                                                <TextField
                                                                                    value={option.optionName}
                                                                                    onChange={(e) => {
                                                                                        const updatedOptions = [...formData.options];
                                                                                        updatedOptions[index].optionName = e.target.value;
                                                                                        setFormData({
                                                                                            ...formData,
                                                                                            options: updatedOptions,
                                                                                        });
                                                                                    }}
                                                                                    fullWidth
                                                                                    placeholder='Option Name'
                                                                                    variant='outlined'
                                                                                    size='small'
                                                                                />
                                                                            </div>
                                                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mb-1'>
                                                                                <TextField
                                                                                    value={option.optionCode}
                                                                                    onChange={(e) => {
                                                                                        const updatedOptions = [...formData.options];
                                                                                        updatedOptions[index].optionCode = e.target.value;
                                                                                        setFormData({
                                                                                            ...formData,
                                                                                            options: updatedOptions,
                                                                                        });
                                                                                    }}
                                                                                    fullWidth
                                                                                    placeholder='Option Code'
                                                                                    variant='outlined'
                                                                                    size='small'
                                                                                />
                                                                            </div>
                                                                            {/* {formData.time === "No" ? (
                                                                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                                    <TextField
                                                                                        value={option.billingUnit}
                                                                                        onChange={(e) => {
                                                                                            const updatedOptions = [...formData.options];
                                                                                            updatedOptions[index].billingUnit = e.target.value;
                                                                                            setFormData({
                                                                                                ...formData,
                                                                                                options: updatedOptions,
                                                                                            });
                                                                                        }}
                                                                                        placeholder='Billing Unit'
                                                                                        variant='outlined'
                                                                                        size='small'
                                                                                    />
                                                                                </div>
                                                                            ) : null} */}
                                                                            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-1'>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={option.billable}
                                                                                            onChange={(e) => {
                                                                                                const updatedOptions = [...formData.options];
                                                                                                updatedOptions[index].billable = e.target.checked;
                                                                                                setFormData({
                                                                                                    ...formData,
                                                                                                    options: updatedOptions,
                                                                                                });
                                                                                            }}
                                                                                            size='small'
                                                                                        />
                                                                                    }
                                                                                    label="Billable"
                                                                                />
                                                                            </div>
                                                                            <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 mt-3'>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    size='small'
                                                                                    sx={{
                                                                                        backgroundColor: '#D9214E', borderColor: '#D9214E', '&:hover': {
                                                                                            backgroundColor: '#C21C47',
                                                                                        },
                                                                                    }}
                                                                                    onClick={() => handleRemoveOption(index)}
                                                                                >
                                                                                    Remove
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </Box>
                                                                ))
                                                            ) : null}
                                                            <div className='d-flex justify-content-start mt-2 mx-3'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    sx={{
                                                                        backgroundColor: '#50CD89', borderColor: '#50CD89', '&:hover': {
                                                                            backgroundColor: '#409877',
                                                                        },
                                                                    }}
                                                                    onClick={handleAddOption}
                                                                >
                                                                    Add Attendance
                                                                </Button>
                                                            </div>
                                                        </div>
                                            {/* } */}
                                        </div>
                                    </Box>
                                </Box>
                                </div>
                               

                                <Box style={{ marginTop: '24px', }}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        borderBottomLeftRadius: '4px',
                                        borderBottomRightRadius: '4px',
                                        padding: '10px 0',
                                    }}>

                                        <Button
                                            type='button'
                                            sx={{ padding: '4px', margin: '4px' }}
                                            variant='contained'
                                            style={{
                                                color: '#000',
                                                backgroundColor: '#fff',
                                                '&:hover': {
                                                    backgroundColor: '#f2f2f2',
                                                }
                                            }}
                                            onClick={() => history.push("/admin/billing/attendance-type/list")}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type='submit'
                                            sx={{ padding: '4px', margin: '4px' }}
                                            variant='contained'
                                            style={{
                                                marginLeft: '6px',
                                                backgroundColor: '#28a745',
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: '#218838',
                                                },
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </form >
                    </div>
            }
        </>
    )
}

export default EditAttendanceType