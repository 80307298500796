import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'


let API_URL
console.log('auths', process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
  API_URL = process.env.REACT_APP_DEV_API_URL + '/api'
} else {
  API_URL = process.env.REACT_APP_API_URL + '/api'
}
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const RESET_PASSWORD_URL = `${API_URL}/resetPassword`
export const UPDATE_PASSWORD_URL = `${API_URL}/user/updatePassword`
export const CREATE_PASSWORD_URL = `${API_URL}/auth/create-password`
export const TOKEN_VALIDATION_URL = `${API_URL}/auth/token`
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.get(LOGIN_URL, {
    params: {
      email: email,
      password: password,
    },
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function resetPassword(email: string, password: string, token: string, isInvitedUser: string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    email,
    password,
    token,
    ...(isInvitedUser && { isInvitedUser: true })
  })
}

export async function updatePassword(currentPassword: string, newPassword: string, confirmPassword: string, userId : string) {
  if (newPassword !== confirmPassword) {
    return Promise.reject(new Error("New password and confirm password do not match"));
  }

  try {
    const response = await axios.post<{ message?: string, success?: boolean }>(UPDATE_PASSWORD_URL, {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_new_password: confirmPassword,
      userId : userId
    });
    if (response.data && response.data.success === false) {
      if (response.data.message === "Incorrect current password") {
        throw new Error("Incorrect current password");
      } else {
        throw new Error("Failed to change password. Please try again.");
      }
    }

    return response;
  } catch (error) {
    throw error;
  }
}

export async function tokenValidation(token) {
    try{
      const response = await axios.post(TOKEN_VALIDATION_URL, {
        token
      })
      return response
    } catch(err) {
      console.log(err)
    }
 
}

export async function createPassword(token, newPassword, confirmPassword) { 
  if (newPassword !== confirmPassword) {
    return Promise.reject(new Error("New password and confirm password do not match"));
  }

  try {
    const response = await axios.post<{ message?: string, success?: boolean }>(CREATE_PASSWORD_URL, {
      token,
      newPassword,
    });
    if (response.data && response.data.success === false) {
      if (response.data.message === "Incorrect current password") {
        throw new Error("Incorrect current password");
      } else {
        throw new Error("Failed to change password. Please try again.");
      }
    }

    return response;
  } catch (error) {
    throw error;
  }
}


// export function updatePassword(email: string,password: string, token: string,isInvitedUser:string) {
//   return axios.post<{ result: boolean }>(UPDATE_PASSWORD_URL, {
//     email: 
//   })
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
