import React, {useEffect, useState} from 'react'
import {Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField} from '@mui/material'
import {State, City} from 'country-state-city'
import '../../reusable-components/styles/style.css'
import * as validator from '../Validation/Validations'
import {Country} from './ADDRESS_CONSTANTS'

const AddressForm = ({formData, setFormData, mailingInfo, addressInfo}) => {
  const [showMailingAddr, setShowMailingAddr] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedMailingState, setSelectedMailingState] = useState(null)
  const [selectedMailingCountry, setSelectedMailingCountry] = useState(null)
  const [states, setStates] = useState([])
  const [city, setCity] = useState([])
  const [mailingCity, setMailingCity] = useState([])
  const [mailingStates, setMailingStates] = useState([])

  useEffect(() => {
    setSelectedCountry({ label: formData?.addrInfo?.country, value: formData?.addrInfo?.country })
    setSelectedMailingCountry({ label: formData?.mailingInfo?.country, value: formData?.mailingInfo?.country })
    setSelectedState({label: formData?.addrInfo?.state, value: formData?.addrInfo?.state})
    setSelectedMailingState({label: formData?.mailingInfo?.state, value: formData?.mailingInfo?.state})
    if (
      formData?.mailingInfo?.st1 === formData?.addrInfo?.st1 &&
      formData?.mailingInfo?.st2 === formData?.addrInfo?.st2 &&
      formData?.mailingInfo?.city === formData?.addrInfo?.city &&
      formData?.mailingInfo?.country === formData?.addrInfo?.country &&
      formData?.mailingInfo?.zipCode === formData?.addrInfo?.zipCode &&
      formData?.mailingInfo?.state === formData?.addrInfo?.state &&
      formData?.mailingInfo?.primaryPhone === formData?.addrInfo?.primaryPhone &&
      formData?.mailingInfo?.secondaryPhone === formData?.addrInfo?.secondaryPhone &&
      formData?.mailingInfo?.additionalPhone === formData?.addrInfo?.additionalPhone
  ) {
      setShowMailingAddr(false)
  } else {
      setShowMailingAddr(true)
  }
}, [formData?.addrInfo?.st1, formData?.addrInfo?.st2, formData?.addrInfo?.city, formData?.addrInfo?.country,
    formData?.addrInfo?.zipCode, formData?.addrInfo?.state, formData?.addrInfo?.primaryPhone,
    formData?.addrInfo?.secondaryPhone, formData?.addrInfo?.additionalPhone,
    formData?.mailingInfo?.st1, formData?.mailingInfo?.st2, formData?.mailingInfo?.city, formData?.mailingInfo?.country,
    formData?.mailingInfo?.zipCode, formData?.addrInfo?.state, formData?.mailingInfo?.primaryPhone,
    formData?.mailingInfo?.secondaryPhone, formData?.mailingInfo?.additionalPhone])

  useEffect(() => {
    const fetchData = async () => {
        try{
            const country = selectedCountry?.label === 'United States' ? "US" : ''
             const stateOptions = country ? State.getStatesOfCountry(country).map((state) =>  ({
                    label: state.name,
                    value: state.isoCode,
                  })) : [];
                  const state = stateOptions?.filter((option)=> option.label === selectedState?.label)
            const cityOptions = selectedState ? City.getCitiesOfState(country, state[0]?.value).map((city) => (
                {
                        label: city.name,
                        value: city.name,
                      })) : [];

                      setStates(stateOptions)
                      setCity(cityOptions)
        } catch(err){
            console.log(err)
        }
    }
    fetchData()
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    const fetchData = async () => {
        try{
            const country = selectedMailingCountry?.label === 'United States' ? "US" : ''
            const mailingStateOptions = country ? State.getStatesOfCountry(country).map((state) =>  ({
                    label: state.name,
                    value: state.isoCode,
            })) : [];
            const state = mailingStateOptions?.filter((option)=> option?.label === selectedMailingState?.label)

            const mailingCityOptions = selectedMailingState ? City.getCitiesOfState(country, state[0]?.value).map((city) => (
                        {
                                label: city.name,
                                value: city.name,
                              })) : [];
                      setMailingStates(mailingStateOptions)
                      setMailingCity(mailingCityOptions)
        } catch(err){
            console.log(err)
        }
    }
    fetchData()
  }, [selectedMailingCountry, selectedMailingState]);
  return (
    <Box>
      {addressInfo?.addressInfo === true && (
        <>
          <Box display='flex' flexDirection='row' className='fieldRow'>
            <Box className='fieldContainer'>
              <TextField
                required={true}
                type='text'
                name='st1'
                size='small'
                className='textField'
                value={formData?.addrInfo?.st1 || ''}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    addrInfo: {
                      ...prevState.addrInfo,
                      st1: e.target.value,
                    },
                  }))
                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          st1: e.target.value,
                        },
                      }
                    })
                  }
                }}
                variant='outlined'
                label='Street 1'
                autoComplete='off'
                error={Boolean(
                  formData?.addrInfo?.st1 &&
                    !validator.STREET_VALIDATION.pattern.test(formData?.addrInfo?.st1)
                )}
                helperText={
                  formData?.addrInfo?.st1 &&
                  !validator.STREET_VALIDATION.pattern.test(formData?.addrInfo?.st1)
                    ? validator.STREET_VALIDATION.message
                    : ''
                }
              />
            </Box>
            <Box className='fieldContainer'>
              <TextField
                type='text'
                name='st2'
                size='small'
                className='textField'
                value={formData?.addrInfo?.st2 || ''}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    addrInfo: {
                      ...prevState.addrInfo,
                      st2: e.target.value,
                    },
                  }))
                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          st2: e.target.value,
                        },
                      }
                    })
                  }
                }}
                variant='outlined'
                label='Street 2'
                autoComplete='off'
                error={Boolean(
                  formData?.addrInfo?.st2 &&
                    !validator.STREET_VALIDATION.pattern.test(formData?.addrInfo?.st2)
                )}
                helperText={
                  formData?.addrInfo?.st2 &&
                  !validator.STREET_VALIDATION.pattern.test(formData?.addrInfo?.st2)
                    ? validator.STREET_VALIDATION.message
                    : ''
                }
              />
            </Box>
          </Box>

          <Box display='flex' flexDirection='row' className='fieldRow'>
            <Box className='fieldContainer'>
              <Autocomplete
                disabled={!formData?.addrInfo?.st1}
                options={Country || []}
                size='small'
                className='textField'
                value={formData?.addrInfo?.country || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, newValue) => {
                  const SelectedValue = newValue ? newValue : ''
                  setSelectedCountry(SelectedValue ? SelectedValue.value : '')
                  setFormData((prevState) => ({
                    ...prevState,
                    addrInfo: {
                      ...prevState.addrInfo,
                      country: SelectedValue ? SelectedValue.label : '',
                    },
                  }))
                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          country: SelectedValue ? SelectedValue.label : '',
                        },
                      }
                    })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='- Select Country -'
                    required={true}
                  />
                )}
              />
            </Box>
            <Box className='fieldContainer'>
              <Autocomplete
                disabled={!formData?.addrInfo?.country}
                options={states}
                value={formData?.addrInfo?.state || ''}
                size='small'
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, newValue) => {
                  const SelectedValue = newValue ? newValue : ''
                  setSelectedState(SelectedValue ? SelectedValue.value : '')
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      addrInfo: {
                        ...prevState.addrInfo,
                        state: SelectedValue ? SelectedValue.label : '',
                      },
                    }
                  })

                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          state: SelectedValue ? SelectedValue.label : '',
                        },
                      }
                    })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='- Select State -'
                    required={true}
                  />
                )}
              />
            </Box>
          </Box>

          <Box display='flex' flexDirection='row' className='fieldRow'>
            <Box className='fieldContainer'>
              <Autocomplete
                disabled={!formData?.addrInfo?.state}
                options={city}
                value={formData?.addrInfo?.city || ''}
                size='small'
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, newValue) => {
                  const SelectedValue = newValue ? newValue : ''
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      addrInfo: {
                        ...prevState?.addrInfo,
                        city: SelectedValue ? SelectedValue.value : '',
                      },
                    }
                  })
                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          city: SelectedValue ? SelectedValue.value : '',
                        },
                      }
                    })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='- Select City -'
                    required={true}
                  />
                )}
              />
            </Box>
            <Box className='fieldContainer'>
              <TextField
                required={true}
                disabled={!formData?.addrInfo?.city}
                type='text'
                name='zipCode'
                size='small'
                className='textField'
                fullWidth
                value={formData?.addrInfo?.zipCode || ''}
                onChange={(e) => {
                  const {value} = e.target
                  let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 5)
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      addrInfo: {
                        ...prevState.addrInfo,
                        zipCode: formattedValue,
                      },
                    }
                  })
                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          zipCode: formattedValue,
                        },
                      }
                    })
                  }
                }}
                variant='outlined'
                label='Zip Code'
                autoComplete='off'
                error={Boolean(
                  formData?.addrInfo?.zipCode &&
                    !validator.ZIP_CODE_VALIDATION.pattern.test(formData?.addrInfo?.zipCode)
                )}
                helperText={
                  formData?.addrInfo?.zipCode &&
                  !validator.ZIP_CODE_VALIDATION.pattern.test(formData?.addrInfo?.zipCode)
                    ? validator.ZIP_CODE_VALIDATION.message
                    : ''
                }
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' className='fieldRow'>
            <Box className='fieldContainer'>
              <TextField
                type='text'
                name='primaryPhone'
                size='small'
                className='textField'
                fullWidth
                value={formData?.addrInfo?.primaryPhone || ''}
                onChange={(e) => {
                  const {value} = e.target
                  let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      addrInfo: {
                        ...prevState.addrInfo,
                        primaryPhone: formattedValue,
                      },
                    }
                  })
                  if (!showMailingAddr) {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          primaryPhone: formattedValue,
                        },
                      }
                    })
                  }
                }}
                variant='outlined'
                label='Primary Phone'
                autoComplete='off'
                error={Boolean(
                  formData?.addrInfo?.primaryPhone &&
                    !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                      formData?.addrInfo?.primaryPhone
                    )
                )}
                helperText={
                  formData?.addrInfo?.primaryPhone &&
                  !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData?.addrInfo?.primaryPhone)
                    ? validator.PHONE_NUMBER_VALIDATION.message
                    : ''
                }
              />
            </Box>
          </Box>
        </>
      )}

      {mailingInfo && (
        <Box display='flex' flexDirection='row' className='fieldRow'>
          <Box className='fieldContainer'>
            <label className='fw-bold fs-6'>Is Mailing Address the same as Residential?</label>
            <RadioGroup
              value={showMailingAddr ? 'no' : 'yes'}
              onChange={() => {
                const newShowMailingAddr = !showMailingAddr
                setShowMailingAddr(newShowMailingAddr)

                if (newShowMailingAddr) {
                  setFormData((prevData) => ({
                    ...prevData,
                    mailingInfo: {
                      st1: '',
                      st2: '',
                      primaryPhone: '',
                      zipCode: '',
                      state: '',
                      city: '',
                      country: '',
                    },
                  }))
                } else {
                  setFormData((prevData) => ({
                    ...prevData,
                    mailingInfo: {
                      st1: prevData?.addrInfo?.st1 || '',
                      st2: prevData?.addrInfo?.st2 || '',
                      primaryPhone: prevData?.addrInfo?.primaryPhone || '',
                      zipCode: prevData?.addrInfo?.zipCode || '',
                      state: prevData?.addrInfo?.state || '',
                      city: prevData?.addrInfo?.city || '',
                      country: prevData?.addrInfo?.country || '',
                    },
                  }))
                }
              }}
              row
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' labelPlacement='end' />
              <FormControlLabel value='no' control={<Radio />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Box>
        </Box>
      )}

      {mailingInfo && showMailingAddr ? (
        <>
          {mailingInfo?.mailingInfo === true && (
            <>
              <Box display='flex' flexDirection='row' className='fieldRow'>
                <Box className='fieldContainer'>
                  <TextField
                    required={true}
                    type='text'
                    name='st1'
                    size='small'
                    className='textField'
                    value={formData?.mailingInfo?.st1 || ''}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          st1: e.target.value,
                        },
                      }))
                    }}
                    variant='outlined'
                    label='Street 1'
                    autoComplete='off'
                    error={Boolean(
                      formData?.mailingInfo?.st1 &&
                        !validator.STREET_VALIDATION.pattern.test(formData?.mailingInfo?.st1)
                    )}
                    helperText={
                      formData?.mailingInfo?.st1 &&
                      !validator.STREET_VALIDATION.pattern.test(formData?.mailingInfo?.st1)
                        ? validator.STREET_VALIDATION.message
                        : ''
                    }
                  />
                </Box>
                <Box className='fieldContainer'>
                  <TextField
                    type='text'
                    name='st2'
                    size='small'
                    className='textField'
                    value={formData?.mailingInfo?.st2 || ''}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          st2: e.target.value,
                        },
                      }))
                    }}
                    variant='outlined'
                    label='Street 2'
                    autoComplete='off'
                    error={Boolean(
                      formData?.mailingInfo?.st2 &&
                        !validator.STREET_VALIDATION.pattern.test(formData?.mailingInfo?.st2)
                    )}
                    helperText={
                      formData?.mailingInfo?.st2 &&
                      !validator.STREET_VALIDATION.pattern.test(formData?.mailingInfo?.st2)
                        ? validator.STREET_VALIDATION.message
                        : ''
                    }
                  />
                </Box>
              </Box>
              <Box display='flex' flexDirection='row' className='fieldRow'>
                <Box className='fieldContainer'>
                  <Autocomplete
                    disabled={!formData?.mailingInfo?.st1}
                    options={Country}
                    size='small'
                    className='textField'
                    value={formData?.mailingInfo?.country || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, newValue) => {
                      const SelectedValue = newValue ? newValue : ''
                      setSelectedMailingCountry(SelectedValue ? SelectedValue?.value : '')
                      setFormData((prevState) => ({
                        ...prevState,
                        mailingInfo: {
                          ...prevState.mailingInfo,
                          country: SelectedValue ? SelectedValue?.label : '',
                        },
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='- Select Country -'
                        required={true}
                      />
                    )}
                  />
                </Box>
                <Box className='fieldContainer'>
                  <Autocomplete
                    disabled={!formData?.mailingInfo?.country}
                    options={mailingStates}
                    value={formData?.mailingInfo?.state || ''}
                    size='small'
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, newValue) => {
                      const SelectedValue = newValue ? newValue : ''
                      setSelectedMailingState(SelectedValue)
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          mailingInfo: {
                            ...prevState?.mailingInfo,
                            state: SelectedValue ? SelectedValue.label : '',
                          },
                        }
                      })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='- Select State -' />
                    )}
                  />
                </Box>
              </Box>
              <Box display='flex' flexDirection='row' className='fieldRow'>
                <Box className='fieldContainer'>
                  <Autocomplete
                    disabled={!formData?.mailingInfo?.state}
                    options={mailingCity}
                    value={formData?.mailingInfo?.city || ''}
                    size='small'
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, newValue) => {
                      const SelectedValue = newValue ? newValue : ''
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          mailingInfo: {
                            ...prevState?.mailingInfo,
                            city: SelectedValue ? SelectedValue.value : '',
                          },
                        }
                      })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='- Select City -' />
                    )}
                  />
                </Box>
                <Box className='fieldContainer'>
                  <Box className='fieldContainer'>
                    <TextField
                      required={true}
                      disabled={!formData?.mailingInfo?.city}
                      type='text'
                      name='zipCode'
                      size='small'
                      className='textField'
                      fullWidth
                      value={formData?.mailingInfo?.zipCode || ''}
                      onChange={(e) => {
                        const {value} = e.target
                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 5)
                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            mailingInfo: {
                              ...prevState.mailingInfo,
                              zipCode: formattedValue,
                            },
                          }
                        })
                      }}
                      variant='outlined'
                      label='Zip Code'
                      autoComplete='off'
                      error={Boolean(
                        formData?.mailingInfo?.zipCode &&
                          !validator.ZIP_CODE_VALIDATION.pattern.test(
                            formData?.mailingInfo?.zipCode
                          )
                      )}
                      helperText={
                        formData?.mailingInfo?.zipCode &&
                        !validator.ZIP_CODE_VALIDATION.pattern.test(formData?.mailingInfo?.zipCode)
                          ? validator.ZIP_CODE_VALIDATION.message
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box display='flex' flexDirection='row' className='fieldRow'>
                <Box className='fieldContainer'>
                  <TextField
                    type='text'
                    name='primaryPhone'
                    size='small'
                    className='textField'
                    fullWidth
                    value={formData?.mailingInfo?.primaryPhone || ''}
                    onChange={(e) => {
                      const {value} = e.target
                      let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          mailingInfo: {
                            ...prevState.mailingInfo,
                            primaryPhone: formattedValue,
                          },
                        }
                      })
                    }}
                    variant='outlined'
                    label='Primary Phone'
                    autoComplete='off'
                    error={Boolean(
                      formData?.mailingInfo?.primaryPhone &&
                        !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                          formData.mailingInfo.primaryPhone
                        )
                    )}
                    helperText={
                      formData?.mailingInfo?.primaryPhone &&
                      !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                        formData?.mailingInfo?.primaryPhone
                      )
                        ? validator.PHONE_NUMBER_VALIDATION.message
                        : ''
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : null}
    </Box>
  )
}

export default AddressForm
