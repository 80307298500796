import { useState, useEffect } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { getOneProgram, updateProgram } from '../../../care/enrollment/store/actions';
import { toast } from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { getSites } from '../../site/store/action';
import { NEW_PROGRAM_INPUTS } from '../../GENERAL_CONSTANTS';
import { useHistory, useParams } from 'react-router-dom';
const selectAuth = (state) => state.auth;

const EditProgram = () => {
    const [site, setSite] = useState([])
    const [data, setData] = useState({})
    const dispatch = useDispatch()
    const history = useHistory()
    const parameters = useParams();
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                const [siteData, ProgramData] = await Promise.all([
                    dispatch(getSites({companyid})),
                    dispatch(getOneProgram(parameters.id)),
                ])
                let programResult = ProgramData.payload?.data
                const { data } = siteData?.payload;
                const updatedSiteOptions = [
                    ...data.map(site => ({ label: site.siteName, value: site._id }))
                ];
                const constantsWithUpdatedProgram = NEW_PROGRAM_INPUTS.map((field) => {
                    if (field.name === 'siteName') {
                        return {
                            ...field,
                            options: updatedSiteOptions,
                        };
                    }
                    return field;
                })
                setSite(constantsWithUpdatedProgram)
                const temp = {
                    _id: programResult._id,
                    company_code: programResult.company_code,
                    programName: programResult.programName,
                    programID: programResult.programID,
                    programType: programResult.programType,
                    programCode: programResult.programCode,
                    name: programResult.primaryContact.name,
                    primaryPhone: programResult.primaryContact.primaryPhone,
                    secondaryPhone: programResult.primaryContact.secondaryPhone,
                    secName: programResult.secondaryContact?.secName,
                    secPrimaryPhone: programResult.secondaryContact?.secPrimaryPhone,
                    secSecondaryPhone: programResult.secondaryContact?.secSecondaryPhone,
                    programCostCenterNumber: programResult.programCostCenterNumber,
                    capacity: programResult.capacity,
                    siteName: programResult.siteName,
                    createDate: programResult.createDate,
                }
                setData(temp)

            } else {
                history.push('/admin/general/programs/listProgram')
            }
        };
        fetchData();
    }, [])

    const handleSubmitForm = async (formData) => {
        const tempObj = {
            _id: data._id,
            company_code: formData.company_code,
            programName: formData.programName,
            programID: Number(formData.programID),
            programType: formData.programType,
            programCode: formData.programCode,
            primaryContact: {
                name: formData.name,
                primaryPhone: Number(formData.primaryPhone),
                secondaryPhone: Number(formData.secondaryPhone),
            },
            secondaryContact: {
                secName: formData.secName,
                secPrimaryPhone: Number(formData.secPrimaryPhone),
                secSecondaryPhone: Number(formData.secSecondaryPhone),
            },
            programCostCenterNumber: Number(formData.programCostCenterNumber),
            capacity: Number(formData.capacity),
            siteName: formData.siteName,
            createDate: formData.createDate,
        }
        const response = await dispatch(updateProgram(tempObj))
        if(response.payload.status === 400 && response.payload.success === false) {
            toast.error(response.payload.message)
        } else if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/general/programs/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];
    return (
        <div>
            <FormWrapper
                title='Edit Program'
                content={
                    <DynamicForm
                        fields={site}
                        data={data}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}

                    />
                }
            />
        </div>
    )
}

export default EditProgram