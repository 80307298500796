import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface ServiceAuth {
    company_code: string | undefined;
    IDType: string;
    fundingSourceID: string;
    programID: string;
    authorizationNumber: string;
    accountingNumber: string;
    beginDate: string;
    endDate: string;
    rateHistory: string;
    rateHistoryValue: Array<Object>;
    servfirstName: string;
    servlastName: string;
    organizationAgency: string;
    serviceCoordinatorNumber: string;
    phoneNumber: string;
    extension: string;
    comments: string;
    clientID: string;
    status: string;
    unitMeasure: string;
    unitRate: number;
    claimType: string;
    primaryDiagnosis: string;
    procedureModifier: string[];
    diagnosisPointer: string[];
    procedureQualifier: string;
    unitOfMeasure: string;
    roundingAlgorithm: string;
    allowedIncrement: string;
    serviceID: string;
    totalBillable: number;
    totalAmount: number;
    priorAuth: string;
    dataCollectionMethod: string;
    attendanceType: string;
    remainingUnits: number;
}

export const getServiceAuth = createAsyncThunk(
    'sau/getAll/',
    async ({ companyid, page, pageSize, sortBy, sortDir, searchTerm }: { companyid?: string | undefined, page?: number, pageSize?: number, sortBy?: string, sortDir?: string, searchTerm?: string  }) => {
        try {
            if(page && pageSize){
                const response = await axios.get(`sau/getAll/${companyid}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data,
                }
            } else {
                const response = await axios.get(`sau/getAll/${companyid}`)
                return {
                    data: response.data,
                }}
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getSearchServiceAuth = createAsyncThunk(
    'sau/search',
    async (searchTerm) => {
        try {
            const response = await axios.get(`sau/search?searchTerm=${searchTerm}`)
            return {
                data: response.data  
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneServiceAuth = createAsyncThunk(
    'sau/get/',
    async (companyid: string | undefined) => {
        try {
            const response = await axios.get('sau/get/' + companyid)
            return {
                data: response.data,
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)
export const getOneSA = createAsyncThunk(
    'sau/getOne/',
    async (companyid: string | undefined) => {
        try {
            const response = await axios.get('sau/getOne/' + companyid)
            return {
                data: response.data,
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addServiceAuth = createAsyncThunk(
    'sau/create/',
    async (data: ServiceAuth) => {
        try {
            const response = await axios.post('sau/create', data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const deleteServiceAuth = createAsyncThunk(
    'sau/delete',
    async (data: string) => {
        try {
            const response = await axios.delete('sau/delete/' + data);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
)

export const updateServiceAuth = createAsyncThunk(
    'sau/update',
    async (data: ServiceAuth) => {
        try {
            console.log(data)
            const response = await axios.put('sau/update', data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
)