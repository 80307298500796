import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LIST_FOR_SERV_AUTH_PROGRAM_COLUMNS, LIST_FOR_SERV_AUTH_PROGRAM_COLUMNS_MAPPING, LIST_FOR_SERV_AUTH_PROGRAM_SORTING_COLUMNS } from '../../../BILLING_TABLE_CONSTANTS'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { useHistory } from 'react-router-dom'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'
import { getProgram } from '../../../../admin/care/enrollment/store/actions'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'
const selectAuth = (state: { auth: any }) => state.auth;

const ProgramsList = () => {
    const [programs, setPrograms] = useState<any[]>([])
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm : ''
    });
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const history = useHistory()
    const companyid = auth.user?.company_code
    const dispatch = useDispatch()

   useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const result: any = await dispatch(getProgram({ companyid, page: page, pageSize: pageSize, sortBy: sortBy, sortDir: sortDir, searchTerm : queryData.searchTerm }));
                const { data, currentPage, totalCounts } = result.payload.data;
                setPrograms(data);
                setFilteredData(data);
                setQueryData((prevState) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                });
            } catch (err) {
                console.log(err);
            }
        };
    
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);

    const onView = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/billing/professionalclaim/service-authorization/new/${_id}`
        })
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Select Your Program'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                       <BackButton/>
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_FOR_SERV_AUTH_PROGRAM_COLUMNS}
                            data={filteredData}
                            canDelete={false}
                            columnDataMapping={LIST_FOR_SERV_AUTH_PROGRAM_COLUMNS_MAPPING}
                            sortingColumns={LIST_FOR_SERV_AUTH_PROGRAM_SORTING_COLUMNS}
                            canView={hasPermission(userPermissions, permissions.billing_professionalClaim_serviceAuthorization_view)}
                            onView={onView}
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default ProgramsList