import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface Attendance {
    clients: Object;
    attendanceType: string;
    company_code: any;
    serviceID: string;
    program: string;
    status: string;
}

interface getAttendanceInterface {
    attendanceType: string;
    program: string;
    serviceID: string;
    company_code: string;
    startDate: string;
    endDate: string;
    status: string;
}

export const getAttendance = createAsyncThunk(
    'attendance/get',
    async (obj: getAttendanceInterface) => {
        try {
            const response = await axios.get('attendance/get', { params: obj })
            return { data: response.data }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getAllAttendance = createAsyncThunk(
    'attendance/getAll/',
    async (company_code: string) => {
        try {
            const response = await axios.get('attendance/getAll/' + company_code)
            return { data: response.data }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addAttendance = createAsyncThunk(
    'attendance/create',
    async (data: Attendance) => {
        try {
            const response = await axios.post('attendance/create', data)
            return response.data
        } catch (err) {
            console.log(err)
            return err
        }
    }
)