import axios from 'axios'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import TableWrapper from '../../reusable-components/tables/TableWrapper'
import {Button, Typography} from '@mui/material'
import PermissionSectionContainer from '../roles/PermissionSectionContainer'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import { RootState } from '../../../setup'
const selectAuth = (state: RootState) => state.auth

export default function NotificationManager() {
  const auth = useSelector(selectAuth)
  const [notificationData, setNotificationData] = useState({})
  const permissionRef = useRef({})
  const [title, setTitle] = useState('Notification Manager')
  const history = useHistory()
  let [roleIds, setRoleIds] = useState([])

  const companyid = auth?.user?.company_code

  const fetchData = async () => {
    let axiosArray = []
    let newPromise = axios({
      method: 'get',
      url: `/notificationManager/${companyid}`,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'get',
      url: `roleManagement/get?namesOnly=true`,
    })
    axiosArray.push(newPromise)

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          let newData = []
          const notificationResponse = responses[0]?.data
          const rolesResponse = responses[1]?.data
          notificationResponse?.notificationsData.map((eachNotification) => {
            rolesResponse?.map((eachRole) => {
              let filter = eachNotification?.roleIds.filter(
                (element) => element?._id == eachRole._id
              )
              newData[eachNotification?.notificationType] = {
                ...newData[eachNotification?.notificationType],
                [eachRole?.name]: filter && filter?.length > 0,
              }
            })
          })
          permissionRef.current = newData
          setNotificationData(newData)
          setRoleIds(rolesResponse)
        })
      )
      .catch((err) => toast.error('Something went wrong'))
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleSave = async (event) => {
    event.preventDefault()
    let notificationDataToPost = []
    Object.keys(permissionRef.current).map((elem) => {
      let roleIdsList = []
      Object.keys(permissionRef.current[elem]).map((element) => {
        if (permissionRef.current[elem][element] == true) {
          let filter = roleIds.filter((eachRole) => eachRole.name == element)
          roleIdsList.push(filter[0]?._id)
        }
      })
      notificationDataToPost.push({
        notificationType: elem,
        roleIds: roleIdsList,
      })
    })

    await axios
      .post('/notificationManager', {
        company_code: companyid,
        notificationsData: notificationDataToPost,
      })
      .then((res) => {
        toast.success('Notification manager updated successfully')
        history.goBack()
      })
      .catch((err) => toast.error('Something went wrong'))
  }
  const handleCancel = () => {
    history.goBack()
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <form onSubmit={handleSave}>
          <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
            {title}
          </Typography>
          <TableWrapper
            searchField={<></>}
            tableButtons={
              <>
                <Button variant='outlined' color='primary' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant='contained' sx={{margin: '3px'}} color='primary' type='submit'>
                  Save
                </Button>
              </>
            }
            dynamicTable={
              <>
                <PermissionSectionContainer
                  allPermissions={notificationData}
                  permissionRef={permissionRef}
                />
              </>
            }
          />
        </form>
      </div>
    </div>
  )
}
