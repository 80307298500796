import { useState } from 'react';
import SearchData from './SearchData';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { CLIENT_SEARCH, NEW_CLIENT_SEARCH } from '../../CARE_CONSTANTS';


const SearchFields = () => {
    const [showForm, setShowForm] = useState(true)
    const [formData, setFormData] = useState({})

    const handleSubmitForm = async (formData) => {
        let keys = Object.keys(formData)
        let obj = formData
        for (let i = 0; i < keys.length; i++) {
            if (obj[keys[i]] !== '' || obj[keys[i]] !== 'Select Status'
                || obj[keys[i]] !== 'Select Individual ID Type') {
                setFormData(obj)
                setShowForm(false)
            }
        }
        
        console.log(formData)
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Search', variant: 'contained', type: 'submit' }
    ];
    return (
        <FormWrapper
            title='Search Clients'
            content={
                showForm ?
                    <DynamicForm
                        fields={CLIENT_SEARCH}
                        data={NEW_CLIENT_SEARCH}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                    :
                    <SearchData formData={formData} setShowForm={setShowForm} />
            }
        />
    )
}

export default SearchFields