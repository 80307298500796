import { COUNTRIES } from "../../../reusable-components/Adress/COUNTRIES";
import { STATES } from "../../../reusable-components/Adress/STATES";

export const SERVICE_PROVIDER = [
    {
        label: "Service Provider Code",
        name: "serviceProviderCode",
        type: "text",
        required: true,
        rowNumber: 1
    },
    {
        label: "Service Provider Type",
        name: "serviceProviderType",
        type: "select",
        options: [
            { label: "Person", value: "Person" },
            { label: "Organization", value: "Organization" },
        ],
        required: false,
        rowNumber: 1
    },
    {
        label: "Service Provider Name",
        name: "serviceProviderName",
        type: "text",
        required: true,
        rowNumber: 2
    },
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 2
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 3
    },
    {
        label: "Business Name",
        name: "businessName",
        type: "text",
        required: false,
        rowNumber: 3
    },
    {
        label: "Owner Organization ID",
        name: "ownerOrgID",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "E-mail",
        name: "email",
        type: "email",
        required: false,
        rowNumber: 4
    },
    {
        label: "",
        name: "authNumber",
        type: "checkbox",
        options: [
            { label: "Prior Auth Number Required for Authorization", value: 'true' },
        ],
        required: false,
        rowNumber: 5
    },
    {
        sectionHeading: "Physical Address",
        rowNumber: 6
    },
    {
        label: "Street 1",
        name: "st1",
        type: "text",
        required: false,
        rowNumber: 6
    },
    {
        label: "Street 2",
        name: "st2",
        type: "text",
        required: false,
        rowNumber: 6
    },
    {
        label: "City",
        name: "city",
        type: "text",
        required: false,
        rowNumber: 7
    },
    {
        label: "State",
        name: "state",
        type: "select",
        options: STATES,
        required: false,
        rowNumber: 7
    },
    {
        label: "Zip Code",
        name: "zipCode",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "Country",
        name: "country",
        type: "select",
        options: COUNTRIES,
        required: false,
        rowNumber: 7
    },
    {
        sectionHeading: "Mailing Address",
        rowNumber: 8
    },
    {
        label: "Street 1",
        name: "mailingSt1",
        type: "text",
        required: false,
        rowNumber: 8
    },
    {
        label: "Street 2",
        name: "mailingSt2",
        type: "text",
        required: false,
        rowNumber: 8
    },
    {
        label: "City",
        name: "mailingCity",
        type: "text",
        required: false,
        rowNumber: 9
    },
    {
        label: "State",
        name: "mailingState",
        type: "select",
        options: STATES,
        required: false,
        rowNumber: 9
    },
    {
        label: "Zip Code",
        name: "mailingZipCode",
        type: "number",
        required: false,
        rowNumber: 9
    },
    {
        label: "Country",
        name: "mailingCountry",
        type: "select",
        options: COUNTRIES,
        required: false,
        rowNumber: 9
    },
    {
        sectionHeading: "Identifier(s)",
        rowNumber: 10
    },
    {
        label: "Taxonomy Code",
        name: "taxonomyCode",
        type: "text",
        required: false,
        rowNumber: 10
    },
    {
        label: "Taxonomy Code Description",
        name: "taxonomyCodeDescription",
        type: "text",
        required: false,
        rowNumber: 10
    },
]

export const SERVICE_PROVIDER_DATA = {
    company_code: '',
    serviceProviderCode: '',
    serviceProviderType: '',
    serviceProviderName: '',
    firstName: '',
    lastName: '',
    businessName: '',
    ownerOrgID: '',
    email: '',
    authNumber: '',
    country: '',
    st1: '',
    st2: '',
    city: '',
    state: '',
    zipCode: '',
    mailingCountry: '',
    mailingSt1: '',
    mailingSt2: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    taxonomyCode: '',
    taxonomyCodeDescription: '',
}