// import * as routes from './allRoutes'
import AttendanceTypeIndexRoutes from './pages/admin/billing/attendance-type/AttendanceTypeIndexRoutes'
import AuthPageRoutes from './modules/auth/AuthPageRoutes'
import React from 'react'
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'
import LeaveRuleIndexRoutes from './pages/admin/billing/leave-rule/LeaveRuleIndexRoutes'
import DescriptionCodeIndexRoutes from './pages/admin/billing/description-code/DescriptionCodeIndexRoutes'
import FundingSourceIndexRoutes from './pages/admin/billing/funding-source/FundingSourceIndexRoutes'
import PayersSettingIndexRoutes from './pages/admin/billing/payers-setting/PayersSettingIndex'
import ContactIndexRoutes from './pages/admin/care/contact/ContactIndexRoutes'
import EnrollmentIndexRoutes from './pages/admin/care/enrollment/EnrollmentIndexRoutes'
import IndividualIntakeIndexRoutes from './pages/admin/care/individual-intake/IndividualIntakeIndexRoutes'
import InsuranceIndexRoutes from './pages/admin/care/insurance/InsuranceIndexRoutes'
import SubMenuIndexRoutes from './pages/admin/care/subMenuSection/SubMenuIndexRoutes'
import TLogsIndexRoutes from './pages/admin/care/clientlogs/ClientLogsIndex'
import ChangePasswordIndexRoutes from './pages/admin/general/change-password/ChangePasswordIndexRoutes'
import ProgramIndexRoutes from './pages/admin/general/programs/programIndexRoutes'
import SharedContactIndexRoutes from './pages/admin/general/shared-contact/SharedContactIndexRoutes'
import SiteIndexRoutes from './pages/admin/general/site/SiteIndexRoutes'
import UserIndexRoutes from './pages/admin/general/user/UserIndexRoutes'
import InstitutionIndexRoutes from './pages/admin/personalfinance/institution/InstitutionIndexRoutes'
import MerchantIndexRoutes from './pages/admin/personalfinance/merchant/MerchantIndexRoutes'
import TypesAndCategoriesIndexRoutes from './pages/admin/personalfinance/types-and-categories/TypesAndCategoriesIndex'
import ProviderIndexRoutes from './pages/admin/service-directory/service-provider/ProviderIndexRoutes'
import AttendanceIndexRoutes from './pages/billing/attendance/attendance/AttendanceIndexRoutes'
import AgencyBasedReportsIndexRoutes from './pages/billing/professionalclaim/agency-based-reports/AgencyBasedReportsIndexRoutes'
import BillingConversionIndexRoutes from './pages/billing/professionalclaim/billing-conversion/BillingConversionIndexRoutes'
import BillingDataIndexRoutes from './pages/billing/professionalclaim/billing-data/BillingDataIndex'
import ClaimTemplateIndexRoutes from './pages/billing/professionalclaim/claim-template/ClaimTemplateIndexRoutes'
import ProfessionalClaimExcelUploadIndexRoutes from './pages/billing/professionalclaim/excel-upload/ExcelUploadIndexRoutes'
import ProfessionalClaimIndexRoutes from './pages/billing/professionalclaim/professional-claim/ProfessionalClaimIndexRoutes'
import ServiceAuthorizationIndexRoutes from './pages/billing/professionalclaim/service-authorization/ServiceAuthorizationIndex'
import ServiceAuthorizationIndex from '../pages/billing/professionalclaim/service-authorization/ServiceAuthorizationIndex'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { allRoutes } from './allRoutesHandler'
import PermittedRoute from './PermittedRoute'
import AttendanceTypeIndex from '../pages/admin/billing/attendance-type/AttendanceTypeIndex'
import DescriptionCodeIndex from '../pages/admin/billing/description-code/DescriptionCodeIndex'
import FundingSourceIndex from '../pages/admin/billing/funding-source/FundingSourceIndex'
import LeaveRuleIndex from '../pages/admin/billing/leave-rule/LeaveRuleIndex'
import PayersSettingIndex from '../pages/admin/billing/payers-setting/PayersSettingIndex'
import ContactIndex from '../pages/admin/care/contact/ContactIndex'
import EnrollmentIndex from '../pages/admin/care/enrollment/EnrollmentIndex'
import IndividualIntakeIndex from '../pages/admin/care/individual-intake/IndividualIntakeIndex'
import InsuranceIndex from '../pages/admin/care/insurance/InsuranceIndex'
import SubMenuIndex from '../pages/admin/care/subMenuSection/SubMenuIndex'
import ClientLogsIndex from '../pages/admin/care/clientLog/ClientLogsIndex'
import ChangePasswordIndex from '../pages/admin/general/change-password/ChangePasswordIndex'
import ProgramIndex from '../pages/admin/general/programs/programIndex'
import SharedContactIndex from '../pages/admin/general/shared-contact/SharedContactIndex'
import SiteIndex from '../pages/admin/general/site/SiteIndex'
import UserIndex from '../pages/admin/general/user/UserIndex'
import InstitutionIndex from '../pages/admin/personalfinance/institution/InstitutionIndex'
import MerchantIndex from '../pages/admin/personalfinance/merchant/MerchantIndex'
import TypesAndCategoriesIndex from '../pages/admin/personalfinance/types-and-categories/TypesAndCategoriesIndex'
import ProviderIndex from '../pages/admin/service-directory/service-provider/ProviderIndex'
import AttendanceIndex from '../pages/billing/attendance/attendance/AttendanceIndex'
import AgencyBasedReportsIndex from '../pages/billing/professionalclaim/agency-based-reports/AgencyBasedReportsIndex'
import BillingConversionIndex from '../pages/billing/professionalclaim/billing-conversion/BillingConversionIndex'
import BillingDataIndex from '../pages/billing/professionalclaim/billing-data/BillingDataIndex'
import ClaimTemplateIndex from '../pages/billing/professionalclaim/claim-template/ClaimTemplateIndex'
import ProfessionalClaimExcelUploadIndex from '../pages/billing/professionalclaim/excel-upload/ExcelUploadIndex'
import ProfessionalClaimIndex from '../pages/billing/professionalclaim/professional-claim/ProfessionalClaimIndex'
import MarsHeader from '../pages/mars/MarsDataHeader'
import MarsIndexRouter from './pages/mars/MarsIndexRoutes'
import BehaviourRoutes from './pages/behaviour'
import { ChangePassword } from '../modules/auth/components/ChangePassword'
import BillingProviderIndexRoutes from './pages/admin/billing/billing-provider/BillingProviderIndexRoutes'
import BillingProviderIndex from '../pages/admin/billing/billing-provider/BillingProviderIndex'
import NotesRoutes from './pages/notes/NotesRoutes'
import NotesEntriesRoutes from './pages/notes/notesEntries/NotesEntriesRoutes'
import UsersRoute from './pages/users/UsersRoute'
import RolesRoutes from './pages/roles/RolesRoutes'
import SchedulesIndexRoute from './pages/caregiver/schedules/SchedulesIndexRoute'
import CaregiverIndexRoute from './pages/caregiver/caregiver/CaregiverIndexRoute'
import TimeSheetIndexRoute from './pages/caregiver/timesheet/TimeSheetIndexRoute'

const AllRoutes: React.FC = () => {
    // const permissionsAuth = (state: any) => state?.roles
    // const permissions = useSelector(permissionsAuth).permissions
    return (
        <Switch>
            <Route path='/updatePassword' component={ChangePassword} />

            <Route path='/error/'>
                <ErrorsPage />
            </Route>

            <Route path='/admin/billing/attendance-type/'>
                <AttendanceTypeIndex></AttendanceTypeIndex>
                <AttendanceTypeIndexRoutes></AttendanceTypeIndexRoutes>
            </Route>

            <Route path='/admin/billing/description-code/'>
                <DescriptionCodeIndex></DescriptionCodeIndex>
                <DescriptionCodeIndexRoutes></DescriptionCodeIndexRoutes>
            </Route>

            <Route path='/admin/billing/funding-source/'>
                <FundingSourceIndex></FundingSourceIndex>
                <FundingSourceIndexRoutes></FundingSourceIndexRoutes>
            </Route>

            <Route path='/admin/billing/leave-rule/'>
                <LeaveRuleIndex></LeaveRuleIndex>
                <LeaveRuleIndexRoutes></LeaveRuleIndexRoutes>
            </Route>

            <Route path='/admin/billing/payers/'>
                <PayersSettingIndex></PayersSettingIndex>
                <PayersSettingIndexRoutes></PayersSettingIndexRoutes>
            </Route>

            <Route path='/admin/care/contact/'>
                <ContactIndex></ContactIndex>
                <ContactIndexRoutes></ContactIndexRoutes>
            </Route>

            <Route path='/admin/care/enrollment/'>
                <EnrollmentIndex></EnrollmentIndex>
                <EnrollmentIndexRoutes></EnrollmentIndexRoutes>
            </Route>

            <Route path='/admin/care/individual-intake/'>
                <IndividualIntakeIndex></IndividualIntakeIndex>
                <IndividualIntakeIndexRoutes></IndividualIntakeIndexRoutes>
            </Route>

            <Route path='/admin/care/insurance/'>
                <InsuranceIndex></InsuranceIndex>
                <InsuranceIndexRoutes></InsuranceIndexRoutes>
            </Route>

            <Route path='/admin/care/sub/'>
                <SubMenuIndex></SubMenuIndex>
                <SubMenuIndexRoutes></SubMenuIndexRoutes>
            </Route>

            <Route path='/admin/care/incident-report/'>
                <ClientLogsIndex></ClientLogsIndex>
                <TLogsIndexRoutes></TLogsIndexRoutes>
            </Route>

            <Route path='/admin/general/change-password/'>
                <ChangePasswordIndex></ChangePasswordIndex>
                <ChangePasswordIndexRoutes></ChangePasswordIndexRoutes>
            </Route>

            <Route path='/admin/general/programs/'>
                <ProgramIndex></ProgramIndex>
                <ProgramIndexRoutes></ProgramIndexRoutes>
            </Route>

            <Route path='/admin/general/shared-contact/'>
                <SharedContactIndex></SharedContactIndex>
                <SharedContactIndexRoutes></SharedContactIndexRoutes>
            </Route>

            <Route path='/admin/general/site/'>
                <SiteIndex></SiteIndex>
                <SiteIndexRoutes></SiteIndexRoutes>
            </Route>

            <Route path='/admin/general/user/'>
                <UserIndex></UserIndex>
                <UserIndexRoutes></UserIndexRoutes>
            </Route>

            <Route path='/admin/personalfinance/institution/'>
                <InstitutionIndex></InstitutionIndex>
                <InstitutionIndexRoutes></InstitutionIndexRoutes>
            </Route>

            <Route path='/admin/personalfinance/merchant/'>
                <MerchantIndex></MerchantIndex>
                <MerchantIndexRoutes></MerchantIndexRoutes>
            </Route>

            <Route path='/admin/personalfinance/types-and-categories/'>
                <TypesAndCategoriesIndex></TypesAndCategoriesIndex>
                <TypesAndCategoriesIndexRoutes></TypesAndCategoriesIndexRoutes>
            </Route>

            <Route path='/admin/service-directory/service-provider/'>
                <ProviderIndex></ProviderIndex>
                <ProviderIndexRoutes></ProviderIndexRoutes>
            </Route>

            <Route path='/billing/attendance/'>
                <AttendanceIndex></AttendanceIndex>
                <AttendanceIndexRoutes></AttendanceIndexRoutes>
            </Route>

            <Route path='/professionalclaim/agency-based-reports/'>
                <AgencyBasedReportsIndex></AgencyBasedReportsIndex>
                <AgencyBasedReportsIndexRoutes></AgencyBasedReportsIndexRoutes>
            </Route>

            <Route path='/professionalclaim/billing-conversion/'>
                <BillingConversionIndex></BillingConversionIndex>
                <BillingConversionIndexRoutes></BillingConversionIndexRoutes>
            </Route>

            <Route path='/professionalclaim/billing-data/'>
                <BillingDataIndex></BillingDataIndex>
                <BillingDataIndexRoutes></BillingDataIndexRoutes>
            </Route>

            <Route path='/professionalclaim/claim-template/'>
                <ClaimTemplateIndex></ClaimTemplateIndex>
                <ClaimTemplateIndexRoutes></ClaimTemplateIndexRoutes>
            </Route>

            <Route path='/professionalclaim/excel-upload/'>
                <ProfessionalClaimExcelUploadIndex></ProfessionalClaimExcelUploadIndex>
                <ProfessionalClaimExcelUploadIndexRoutes></ProfessionalClaimExcelUploadIndexRoutes>
            </Route>

            <Route path='/billing/professionalclaim/service-authorization/'>
                <ServiceAuthorizationIndex></ServiceAuthorizationIndex>
                <ServiceAuthorizationIndexRoutes></ServiceAuthorizationIndexRoutes>
            </Route>

            <Route path='/professionalclaim/professional-claim/'>
                <ProfessionalClaimIndex></ProfessionalClaimIndex>
                <ProfessionalClaimIndexRoutes></ProfessionalClaimIndexRoutes>
            </Route>

            <Route path='/mars/'>
                <MarsHeader></MarsHeader>
                <MarsIndexRouter></MarsIndexRouter>
            </Route>
            <Route path='/behaviour/'>
                <BehaviourRoutes/>
            </Route>
            <Route path='/caregivers/notes'>
                <NotesRoutes/>
            </Route>
            <Route path='/caregivers/notesEntries/:id'>
                <NotesEntriesRoutes/>
            </Route>

            <Route path='/admin/billing/billing-provider/'>
                <BillingProviderIndex></BillingProviderIndex>
                <BillingProviderIndexRoutes></BillingProviderIndexRoutes>
            </Route>

            <Route path='/users'>
                <UsersRoute/>
            </Route>

            <Route path='/roles'>
                <RolesRoutes/>
            </Route>
            <Route path='/caregivers/schedules'>
                <SchedulesIndexRoute/>
            </Route>
            <Route path='/caregivers/sheets'>
                <TimeSheetIndexRoute/>
            </Route>

            <Route path='/caregivers'>
                <CaregiverIndexRoute/>
            </Route>
            {allRoutes.map((route, index) => (
                <PermittedRoute
                    path={route.path}
                    component={route.component}
                    componentHeader={route.componentHeader}
                    permission={route.permission}
                    key={index}
                />
            ))}
            <Route path='/dashboard' component={DashboardWrapper} />
            <Route path='/auth/' component={AuthPageRoutes} />
            <Redirect exact from='/' to='/dashboard' />
            <Redirect to='error/404' />
            {/* <Logout></Logout> */}
        </Switch>
    )
}

export default AllRoutes
