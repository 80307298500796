import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addProgram } from '../../../care/enrollment/store/actions'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { NEW_PROGRAM_DATA, NEW_PROGRAM_INPUTS } from '../../GENERAL_CONSTANTS'
import { getSites } from '../../site/store/action'
import { toast } from 'react-toastify'
import { CurrentDate } from '../../../../../../_helper/currentDate'

const selectAuth = (state) => state.auth

const NewProgram = () => {
    const [site, setSite] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth)
    const companyid = auth.user?.company_code

    useEffect(() => {
        const fetchData = async () => {
            const result =  await dispatch(getSites({companyid}))
            const { data } = result.payload;
            const updatedSiteOptions = [
                ...data.map(site => ({ label: site.siteName, value: site._id }))
            ];
            const constantsWithUpdatedSite = NEW_PROGRAM_INPUTS.map((field) => {
                if (field.name === 'siteName') {
                    return {
                        ...field,
                        options: updatedSiteOptions,
                    };
                }
                return field;
            })
            setSite(constantsWithUpdatedSite)
        };
        fetchData();
    }, [])

    const handleSubmitForm = async (formData) => {
        const tempObj = {
            company_code: companyid,
            programName: formData.programName,
            programID: Number(formData.programID),
            programType: formData.programType,
            programCode: formData.programCode,
            primaryContact: {
                name: formData.name,
                primaryPhone: Number(formData.primaryPhone),
                secondaryPhone: Number(formData.secondaryPhone),
            },
            secondaryContact: {
                secName: formData.secName,
                secPrimaryPhone: Number(formData.secPrimaryPhone),
                secSecondaryPhone: Number(formData.secSecondaryPhone),
            },
            programCostCenterNumber: Number(formData.programCostCenterNumber),
            capacity: Number(formData.capacity),
            siteName: formData.siteName,
            createDate: CurrentDate(),
            individualsAdded: [],
        }
        const response = await dispatch(addProgram(tempObj))
        if(response.payload.status === 400 && response.payload.success === false) {
            toast.error(response.payload.message)
        } else if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/general/programs/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <FormWrapper
            title='Add New Program'
            content={
                <DynamicForm
                    fields={site}
                    data={NEW_PROGRAM_DATA}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default NewProgram
