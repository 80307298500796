import { Route, Switch } from 'react-router-dom'
import ClientLogList from '../../../../../pages/admin/care/clientLog/new/List'
import ListOfClientLogs from '../../../../../pages/admin/care/clientLog/list/List'
import SearchClientLogFields from '../../../../../pages/admin/care/clientLog/search/SearchClientLogFields'
import React from 'react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import ListOfClientLogsEdit from '../../../../../pages/admin/care/clientLog/list/Edit'

const ClientLogsIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canSearch = hasPermission(userPermissions, 'ADMIN.CARE.CLIENT_LOGS.SEARCH');
    const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.CLIENT_LOGS.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.CARE.CLIENT_LOGS.VIEW');

    return (
        <Switch>
            <RouteWithPermission path='/admin/care/incident-report/new' component={ClientLogList} hasPerm={canCreate} />
            <RouteWithPermission path='/admin/care/incident-report/list/:id' component={ListOfClientLogsEdit} hasPerm={canRead} />
            <RouteWithPermission path='/admin/care/incident-report/list' component={ListOfClientLogs} hasPerm={canRead} />
            <RouteWithPermission path='/admin/care/incident-report/search' component={SearchClientLogFields} hasPerm={canSearch} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default ClientLogsIndexRoutes
