
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { updateServiceProvider } from '../store/action';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { SERVICE_PROVIDER } from '../../SERVICE_DIRECTORY_CONSTANTS';
const selectAuth = (state) => state.auth;

const registrationSchema = Yup.object({
    serviceProviderCode: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Service Provider Code is required'),
    serviceProviderName: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Service Provider Name is required'),
    firstName: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Last Name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),

})

const SPEdit = ({ selectedSP, setShowEditingForm }) => {
    const [formData, setFormData] = useState({})
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code

    useEffect(() => {

        const booleanValue = selectedSP.authNumber;
        const stringArray = [booleanValue.toString()];
        console.log(stringArray); // Outputs: ["true"]

        const tempObj = {
            _id: selectedSP._id,
            company_code: companyid,
            serviceProviderCode: selectedSP.serviceProviderCode,
            serviceProviderType: selectedSP.serviceProviderType,
            serviceProviderName: selectedSP.serviceProviderName,
            firstName: selectedSP.firstName,
            lastName: selectedSP.lastName,
            businessName: selectedSP.businessName,
            ownerOrgID: selectedSP.ownerOrgID,
            email: selectedSP.email,
            authNumber: selectedSP.booleanValue,
            st1: selectedSP.physicalAddr.st1,
            st2: selectedSP.physicalAddr.st2,
            city: selectedSP.physicalAddr.city,
            state: selectedSP.physicalAddr.state,
            zipCode: selectedSP.physicalAddr.zipCode,
            country: selectedSP.physicalAddr.country,
            mailingCountry: selectedSP.mailingInfo.mailingCountry,
            mailingSt1: selectedSP.mailingInfo.mailingSt1,
            mailingSt2: selectedSP.mailingInfo.mailingSt2,
            mailingCity: selectedSP.mailingInfo.mailingCity,
            mailingState: selectedSP.mailingInfo.mailingState,
            mailingZipCode: selectedSP.mailingInfo.mailingZipCode,
            taxonomyCode: selectedSP.taxonomyCode,
            taxonomyCodeDescription: selectedSP.taxonomyCodeDescription,
        }
        setFormData(tempObj)
    }, [])


    const handleSubmitForm = async (formData) => {
        let tempAuth = false
        if (formData.authNumber.length > 0) {
            tempAuth = true
        }
        const tempObj = {
            company_code: companyid,
            serviceProviderCode: formData.serviceProviderCode,
            serviceProviderType: formData.serviceProviderType,
            serviceProviderName: formData.serviceProviderName,
            firstName: formData.firstName,
            lastName: formData.lastName,
            businessName: formData.businessName,
            ownerOrgID: formData.ownerOrgID,
            email: formData.email,
            authNumber: tempAuth,
            physicalAddr: {
                st1: formData.st1,
                st2: formData.st2,
                city: formData.city,
                state: formData.state,
                zipCode: formData.zipCode,
                country: formData.country
            },
            mailingInfo: {
                mailingCountry: formData.mailingCountry,
                mailingSt1: formData.mailingSt1,
                mailingSt2: formData.mailingSt2,
                mailingCity: formData.mailingCity,
                mailingState: formData.mailingState,
                mailingZipCode: formData.mailingZipCode
            },
            taxonomyCode: formData.taxonomyCode,
            taxonomyCodeDescription: formData.taxonomyCodeDescription,
        }
        console.log(tempObj)
        dispatch(updateServiceProvider(tempObj))
        // history.push('/admin/service-directory/service-provider/list')
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <FormWrapper
            title='Edit Service Provider'
            content={
                <DynamicForm
                    fields={SERVICE_PROVIDER}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default SPEdit