import React from 'react'
import { Route, Switch } from 'react-router-dom'
import InsuranceClients from '../../../../../pages/admin/care/insurance/list/InsuranceClients'
import MainInsuranceForm from '../../../../../pages/admin/care/insurance/Main Insurance Form/New'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const InsuranceIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.VIEW');
    return (
        <Switch>
            <Route path='/admin/care/insurance/list' component={InsuranceClients} />
            <Route path='/error/404' component={Error404} />
            <Route path='/admin/care/insurance/:id' component={MainInsuranceForm} />
        </Switch>
    )
}

export default InsuranceIndexRoutes
