
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { addServiceProvider } from '../store/action';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { SERVICE_PROVIDER, SERVICE_PROVIDER_DATA } from '../../SERVICE_DIRECTORY_CONSTANTS';
const selectAuth = (state) => state.auth;

const registrationSchema = Yup.object({
    serviceProviderCode: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Service Provider Code is required'),
    serviceProviderName: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Service Provider Name is required'),
    firstName: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(3, 'Minimum 3 letters required')
        .max(50, 'Maximum 50 letters')
        .required('Last Name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),

})

const ServiceProvider = () => {
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code
    const history = useHistory()

    const handleSubmitForm = async (formData) => {
        let tempAuth = false
        if (formData.authNumber.length > 0) {
            tempAuth = true
        }
        const tempObj = {
            company_code: companyid,
            serviceProviderCode: formData.serviceProviderCode,
            serviceProviderType: formData.serviceProviderType,
            serviceProviderName: formData.serviceProviderName,
            firstName: formData.firstName,
            lastName: formData.lastName,
            businessName: formData.businessName,
            ownerOrgID: formData.ownerOrgID,
            email: formData.email,
            authNumber: tempAuth,
            physicalAddr: {
                st1: formData.st1,
                st2: formData.st2,
                city: formData.city,
                state: formData.state,
                zipCode: formData.zipCode,
                country: formData.country
            },
            mailingInfo: {
                mailingCountry: formData.mailingCountry,
                mailingSt1: formData.mailingSt1,
                mailingSt2: formData.mailingSt2,
                mailingCity: formData.mailingCity,
                mailingState: formData.mailingState,
                mailingZipCode: formData.mailingZipCode
            },
            taxonomyCode: formData.taxonomyCode,
            taxonomyCodeDescription: formData.taxonomyCodeDescription,
        }
        dispatch(addServiceProvider(tempObj))
        // history.push('/admin/service-directory/service-provider/list')
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];
    return (
        <FormWrapper
            title='Service Provider(New)'
            content={
                <DynamicForm
                    fields={SERVICE_PROVIDER}
                    data={SERVICE_PROVIDER_DATA}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default ServiceProvider