import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import axios from 'axios';
import { addAttendance, getAttendance } from '../../store/action';
import ExportXLSX from '../../../../../../_helper/exportCSVHelper/ExportToXlsx';
import { CurrentDate } from '../../../../../../_helper/currentDate';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, TextField, TextareaAutosize } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AppDispatch } from '../../../../../../setup/redux/Store';

const selectAuth = (state: { auth: any }) => state.auth;
const selectAtt = (state: { attendanceType: any }) => state.attendanceType;
const selectDesc = (state: { descriptionCode: any }) => state.descriptionCode;
const selectservAuths = (state: { serviceAuths: any }) => state.serviceAuths;
const selectprogramAuth = (state: { program: any }) => state.program;

// const registrationSchema = Yup.object().shape({
//     timeIn: Yup.string(),
//     timeOut: Yup.string()
//         .test('timeOut', 'Time out must be greater than time in', function (value) {
//             const { timeIn } = this.parent;
//             if (!value || !timeIn) return true;
//             const timeInDate = new Date(`1970-01-01T${timeIn}:00Z`);
//             const timeOutDate = new Date(`1970-01-01T${value}:00Z`);
//             return timeOutDate > timeInDate;
//         })
// });
// interface selectOptionType {
//     label: string,
//     value: string
// }
// interface attType {
//     client: selectOptionType,
//     startDate: string,
//     attendanceType: selectOptionType,
//     serviceDescription: selectOptionType,
//     programNames: selectOptionType,
//     status: selectOptionType,
//     company_code: undefined | string;
// }

interface serviceAuthType {
    clientName: string;
    clientID: string;
    status: string;
    programName: string;
    serviceID: string;
    _id: string;
    beginDate: string;
    endDate: string;
}

interface serviceDesc {
    _id: string;
    serviceDescription: string;
    serviceID: string;
    attendanceType: string;
    serviceCode: string;
}

interface Attendance {
    attendanceOption: string;
    approved: boolean;
    billingGenerated: boolean;
    durationHours: number;
    durationMinutes: number;
    billableSlot: boolean;
    clientID: string;
    clientName: string;
    comments: string;
    date: string;
    timeIn: string;
    timeOut: string;
    totalUnits: number;
    totalRemainingUnits: number;
}

interface AttendanceMain {
    [key: string]: {
        [key: string]: Attendance;
    };
}

const NewTable = ({ searchValues, setShowEditingForm, showEditingForm }: { searchValues: any; setShowEditingForm: (value: boolean) => void; showEditingForm: boolean }) => {
    const [attendanceType, setAttendanceType] = useState<{
        _id: string; name: string; attendanceType: string;
        options: {
            billable: boolean; optionName: string; optionCode: string
        }[]
    }[]>([])
    const [serviceDescription, setServiceDescription] = useState<serviceDesc[]>([])
    const [selectedServDesc, setSelectedServDesc] = useState(searchValues.serviceDescription);
    const [selectedAtt, setSelectedAtt] = useState({
        attendanceOption: "",
        clientID: "",
        clientName: "",
        comments: "",
        program: "",
        programName: "",
        date: "",
        serviceDescription: "",
        serviceID: "",
        timeIn: "",
        timeOut: "",
        approved: false,
        billingGenerated: false,
        durationHours: 0,
        durationMinutes: 0,
        totalUnits: 0,
        totalRemainingUnits: 0,
        billableSlot: false,
    })
    const [selectedProgram, setSelectedProgram] = useState<{ programName: string }>({
        programName: ''
    })
    const [timeIn, setTimeIn] = useState('00:00');
    const [timeOut, setTimeOut] = useState('00:00');
    const [servAuths, setServAuths] = useState<serviceAuthType[]>([]);
    const [attendanceMain, setAttendanceMain] = useState<AttendanceMain>({});
    const [attendanceMainData, setAttendanceMainData] = useState<any[]>([]);
    const [attendanceTypeOptions, setAttendanceTypeOptions] = useState<
        {
            _id: string; name: string; attendanceType: string; options: { billable: boolean; optionName: string; optionCode: string; }[];
        }
    >()
    const [timeInOut, setTimeInOut] = useState<{ label: string; value: string }[]>([])
    const [billable, setBillable] = useState<string[]>([]);
    const [timeInError, setTimeInError] = useState<boolean>(false);
    const [timeOutError, setTimeOutError] = useState<boolean>(false);
    const [servBool, setSevBool] = useState(false);
    const [date, setDate] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [headers, setHeaders] = useState<any[]>([]);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch<AppDispatch>()
    const companyid = useSelector(selectAuth).user?.company_code;
    const attendanceTypeSelector = useSelector(selectAtt).attendanceType;
    const descCodeSelector = useSelector(selectDesc).descriptionCode;
    const servAuthTypeSelector = useSelector(selectservAuths).serviceAuths;
    const progTypeSelector = useSelector(selectprogramAuth).program;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let obj = {
                    attendanceType: searchValues.attendanceType,
                    program: searchValues.programNames,
                    serviceID: selectedServDesc, //selectedservice is causing issues. we will need to update it so that clients listing has an entry of serviceID as well
                    company_code: companyid,
                    startDate: searchValues.startDate,
                    endDate: searchValues.startDate,
                    status: searchValues.status
                }

                let [attendanceResult, clientsResult]: any = await Promise.all([
                    dispatch(getAttendance(obj)),
                    (await axios.get('/clients/get/' + companyid)).data
                ])
                const dateArray = await UpdateDateArray(searchValues.startDate, searchValues.startDate);

                let tempAttendanceResult = attendanceResult.payload.data || []
                let filteredServiceAuths: serviceAuthType[] = await FilterServiceAuthorizations(servAuthTypeSelector)

                const tempServiceAuths = filteredServiceAuths.filter(serviceAuth => {
                    const beginDate = Date.parse(serviceAuth.beginDate);
                    const endDate = Date.parse(serviceAuth.endDate);
                    const rangeStartDate = Date.parse(searchValues.startDate);
                    const rangeEndDate = Date.parse(searchValues.startDate);
                    return (beginDate <= rangeEndDate) && (endDate >= rangeStartDate);
                });

                const tempObj1: any = {};

                tempServiceAuths.forEach((serviceAuth) => {
                    const clientID = serviceAuth.clientID
                    tempObj1[clientID] = {};
                    let clientNameStr = clientsResult.find((obj: { _id: string; }) => obj._id === clientID)
                    serviceAuth.clientName = clientNameStr.firstName + ' ' + clientNameStr.lastName!
                    // check if tempAttendanceResult has data for this name
                    if (Object.keys(tempAttendanceResult).length > 0 && tempAttendanceResult[clientID]) {
                        dateArray.forEach((date: string) => {
                            if (tempAttendanceResult[clientID][date] !== undefined) {
                                tempObj1[clientID][date] = {
                                    attendanceOption: tempAttendanceResult[clientID][date].attendanceOption,
                                    clientName: clientNameStr.firstName + " " + clientNameStr.lastName,
                                    clientID: tempAttendanceResult[clientID][date].clientID,
                                    comments: tempAttendanceResult[clientID][date].comments,
                                    timeIn: tempAttendanceResult[clientID][date].timeIn,
                                    timeOut: tempAttendanceResult[clientID][date].timeOut,
                                    approved: tempAttendanceResult[clientID][date].approved,
                                    billingGenerated: tempAttendanceResult[clientID][date].billingGenerated,
                                    durationMinutes: tempAttendanceResult[clientID][date].durationMinutes,
                                    durationHours: tempAttendanceResult[clientID][date].durationHours,
                                    totalUnits: tempAttendanceResult[clientID][date].totalUnits,
                                    totalRemainingUnits: tempAttendanceResult[clientID][date].totalRemainingUnits,
                                    billableSlot: tempAttendanceResult[clientID][date].billableSlot,
                                    serviceID: tempAttendanceResult[clientID][date].serviceID,
                                    attendanceDate: tempAttendanceResult[clientID][date].attendanceDate
                                };
                            } else {
                                tempObj1[clientID][date] = {
                                    attendanceOption: "",
                                    clientName: clientNameStr.firstName + " " + clientNameStr.lastName,
                                    clientID: serviceAuth.clientID,
                                    comments: "",
                                    timeIn: "00:00",
                                    timeOut: "00:00",
                                    approved: false,
                                    billingGenerated: false,
                                    serviceID: selectedServDesc,
                                    attendanceDate: date,
                                    durationHours: 0,
                                    durationMinutes: 0,
                                    billableSlot: false,
                                    totalUnits: 0,
                                    totalRemainingUnits: 0
                                };
                            }
                        });
                    } else {
                        // if tempAttendanceResult is empty or does not have data for this name,
                        // set default values for all dates
                        dateArray.forEach((date: string) => {
                            tempObj1[clientID][date] = {
                                attendanceOption: "",
                                clientName: clientNameStr.firstName + " " + clientNameStr.lastName,
                                clientID: serviceAuth.clientID,
                                comments: "",
                                timeIn: "00:00",
                                timeOut: "00:00",
                                approved: false,
                                billingGenerated: false,
                                serviceID: selectedServDesc,
                                attendanceDate: date,
                                durationHours: 0,
                                durationMinutes: 0,
                                billableSlot: false,
                                totalUnits: 0,
                                totalRemainingUnits: 0
                            };
                        });
                    }
                });
                let tempBillables = await BillableFilter(attendanceTypeSelector)
                let tempAttOptions: any = attendanceTypeSelector.find((data: { _id: string; }) => data._id === searchValues.attendanceType)!

                setSelectedProgram(progTypeSelector.find((obj: { _id: string; }) => obj._id === searchValues.programNames))
                setAttendanceTypeOptions(tempAttOptions)
                setServAuths(tempServiceAuths);
                setBillable(tempBillables)
                setServiceDescription(descCodeSelector)
                setAttendanceType(attendanceTypeSelector)
                setAttendanceMain(tempObj1);
                setDate(dateArray)
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
        generateTimeOptions()
    }, [servBool])

    useEffect(() => {
        const tempArr = Object.values(attendanceMain).flatMap(Object.values);

        const uniqueAttendance = tempArr.reduce((result, record) => {
            const { clientName, attendanceOption, clientID } = record;
            if (attendanceOption !== "") {
                const existingRecord = result.find((item: { [x: string]: any; }) => item["clientID"] === clientID);

                if (existingRecord) {
                    if (existingRecord.hasOwnProperty(attendanceOption)) {
                        existingRecord[attendanceOption]++;
                    } else {
                        existingRecord[attendanceOption] = 1;
                    }
                } else {
                    const newRecord = {
                        "Client Name": clientName, // Update key name to "Client Name"
                        clientID,
                        [attendanceOption]: 1,
                    };
                    result.push(newRecord);
                }
            }
            return result;
        }, []);

        const attendanceTypeOptionsBill = attendanceType
            .find((data: {
                _id: string; name: any;
            }) => data._id === searchValues.attendanceType)
            ?.options.map((option: { billable: any; optionName: any; optionCode: any; }) => ({
                label: option.billable
                    ? `${option.optionName} (billable)`
                    : `${option.optionName} (non-billable)`,
                key: option.optionCode,
                width: 15,
            })) ?? [];

        attendanceTypeOptionsBill.unshift({ label: "Client Name", key: "Client Name", width: 20 });

        // Add missing keys with a value of 0 to transformedData
        attendanceTypeOptionsBill.forEach((option: { key: any; }) => {
            const { key } = option;
            uniqueAttendance.forEach((item: { [x: string]: number; hasOwnProperty: any; }) => {
                if (!item.hasOwnProperty(key)) {
                    item[key] = 0;
                }
            });
        });

        setHeaders(attendanceTypeOptionsBill);
        setAttendanceMainData(uniqueAttendance);
    }, [attendanceMain])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const UpdateDateArray = (startDate: string, endDate: string) => {
        // Use a variable to store the start date
        let date = new Date(`${startDate}T00:00:00`);

        // Use a while loop instead of creating an array
        let result = [];
        while (date <= new Date(`${endDate}T00:00:00`)) {
            // Use template literals instead of concatenation
            result.push(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`);
            // Use setDate to increment the date by one day
            date.setDate(date.getDate() + 1);
        }
        return result
    };

    const FilterServiceAuthorizations = (servAuthTypeSelector: any[]) => {
        // Use a variable to store the search values
        const { programNames, status, client } = searchValues;

        // Use a for loop instead of filter and map
        let result = [];
        for (let serviceAuth of servAuthTypeSelector) {
            // Use destructuring to get the properties
            const { serviceID, programID, clientID } = serviceAuth;
            // Use strict equality comparison and logical operators
            if (
                serviceID === selectedServDesc &&
                programID === programNames &&
                status === status &&
                (!client || clientID === client)
            ) {
                // Use spread syntax to create a new object with the clientName property
                let updatedElement = { ...serviceAuth, clientName: "" };
                // Push the updated element to the result array
                result.push(updatedElement);
            }
        }
        return result
    }

    const BillableFilter = (attendanceTypeSelector: any[]) => {
        const attendanceType = searchValues.attendanceType;
        // Use a for loop instead of filter and map
        let result = [];
        for (let data of attendanceTypeSelector) {
            // Use strict equality comparison
            if (data._id === attendanceType) {
                // Use a for-of loop instead of filter and map
                for (let ato of data.options) {
                    // Use a single condition instead of two
                    if (ato.billable) {
                        // Push the option code to the result array
                        result.push(ato.optionCode);
                    }
                }
            }
        }
        return result
    }

    const generateTimeOptions = () => {
        const timeOptions = [];
        let time = new Date();
        time.setHours(0, 0, 0, 0);

        for (let i = 0; i < 1440; i++) {
            const hours = time.getHours();
            const formattedHour = (hours % 12 === 0) ? 12 : hours % 12;
            const formattedMinute = time.getMinutes().toString().padStart(2, '0');
            const period = hours === 12 ? 'PM' : (hours < 12 ? 'AM' : 'PM');

            const label = `${formattedHour}:${formattedMinute} ${period}`;
            const value = `${formattedHour}:${formattedMinute}`;

            timeOptions.push({ label, value });

            time.setMinutes(time.getMinutes() + 5);
        }
        setTimeInOut(timeOptions);
    };


    const generatePDF = () => {
        setLoadingPDF(true)
        let attendanceOptions = attendanceTypeOptions

        let serviceDescriptionName = serviceDescription.find(sd => sd._id === selectedServDesc)

        const parameters = {
            fileName: "New-Attendance-Report-" + CurrentDate(),
            descriptionCode: serviceDescriptionName?.serviceDescription + '-' + serviceDescriptionName?.serviceCode,
            attendanceOptions,
            date: CurrentDate(),
            attendanceType: attendanceTypeSelector.find((obj: { _id: string; }) => obj._id === searchValues.attendanceType)["name"],
            // program: progTypeSelector.find((obj: { _id: string; }) => obj._id === searchValues.programNames)["progTypeSelector"],
            program: selectedProgram.programName,
            attendanceData: Object.values(attendanceMain).flatMap(Object.values),
            headers: [searchValues.startDate],
        };

        axios.post("pdf/generateReport", parameters, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "New-Attendance-Report-" + CurrentDate() + '.pdf');
                document.body.appendChild(link);
                link.click();
                setLoadingPDF(false)
            })
            .catch(error => {
                console.log(error);
                setLoadingPDF(false)
            });
    }

    const submitAttendance = async () => {
        let filteredAttendance = Object.keys(attendanceMain).reduce((acc: any, key: any) => {
            let obj = attendanceMain[key];
            let filteredObj = Object.keys(obj).reduce((acc2: any, key2: any) => {
                let innerObj = obj[key2];
                if (innerObj.attendanceOption === "-") {
                    acc2[key2] = innerObj;
                } else if (innerObj.attendanceOption !== "") {
                    acc2[key2] = innerObj;
                }
                return acc2;
            }, {});
            if (Object.keys(filteredObj).length > 0) {
                acc[key] = filteredObj;
            }
            return acc;
        }, {});
        if (Object.keys(filteredAttendance).length > 0) {
            let tempObj: {
                clients: object;
                attendanceType: string;
                company_code: any;
                serviceID: string;
                program: string;
                status: string;
                startDate: string;
                endDate: string;
            } = {
                clients: filteredAttendance,
                attendanceType: searchValues.attendanceType,
                company_code: companyid,
                serviceID: selectedServDesc,
                program: searchValues.programNames,
                status: searchValues.status,
                startDate: searchValues.startDate,
                endDate: searchValues.startDate,
            }
            const response: any = await dispatch(addAttendance(tempObj));
            if (response?.payload.success) {
                toast.success(response?.payload?.message || 'success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }

    const submitApproval = async () => {
        let filteredAttendance = Object.keys(attendanceMain).reduce((acc: any, key: any) => {
            let obj = attendanceMain[key];
            let filteredObj = Object.keys(obj).reduce((acc2: any, key2: any) => {
                let innerObj = obj[key2];
                if (innerObj.attendanceOption === "-") {
                    acc2[key2] = innerObj;
                } else if (innerObj.attendanceOption !== "") {
                    if (innerObj.timeOut !== "00:00") {
                        innerObj.approved = true
                        acc2[key2] = innerObj;
                    } else if (!billable.includes(innerObj.attendanceOption)) {
                        innerObj.approved = true
                        acc2[key2] = innerObj;
                    }
                }
                return acc2;
            }, {});
            if (Object.keys(filteredObj).length > 0) {
                acc[key] = filteredObj;
            }
            return acc;
        }, {});
        if (Object.keys(filteredAttendance).length > 0) {
            let tempObj: {
                clients: object;
                attendanceType: string;
                company_code: any;
                serviceID: string;
                program: string;
                status: string;
                startDate: string;
                endDate: string;
            } = {
                clients: filteredAttendance,
                attendanceType: searchValues.attendanceType,
                company_code: companyid,
                serviceID: selectedServDesc,
                program: searchValues.programNames,
                status: searchValues.status,
                startDate: searchValues.startDate,
                endDate: searchValues.startDate
            }
            const response: any = await dispatch(addAttendance(tempObj));
            if (response?.payload.success) {
                toast.success(response?.payload?.message || 'success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                setAttendanceMain((prevState: any) => {
                    let newState = { ...prevState }
                    Object.keys(newState).forEach((clientID) => {
                        Object.keys(newState[clientID]).forEach((date) => {
                            if (!newState[clientID][date].approved) {
                                if (newState[clientID][date].timeOut !== "00:00" && newState[clientID][date].attendanceOption !== "") {
                                    newState[clientID][date].approved = true;
                                }
                            }
                        });
                    });
                    return newState
                })
            } else {
                toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }

    const generateBilling = async () => {
        let filteredAttendance = Object.keys(attendanceMain).reduce((acc: any, key: any) => {
            let obj = attendanceMain[key];
            let filteredObj = Object.keys(obj).reduce((acc2: any, key2: any) => {
                let innerObj = obj[key2];
                if (innerObj.approved) {
                    if (billable.includes(innerObj.attendanceOption)) {
                        let tempTimeIn = innerObj.timeIn.slice(0, 5)
                        let tempTimeOut = innerObj.timeOut.slice(0, 5)
                        const time1 = new Date(`2000-01-01T${tempTimeIn}`);
                        const time2 = new Date(`2000-01-01T${tempTimeOut}`);

                        const diffMilliseconds: number = time2.getTime() - time1.getTime();
                        const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
                        const hours = Math.floor(diffMinutes / 60);
                        const minutes = diffMinutes % 60;
                        const totalMinutes = hours * 60 + minutes;
                        innerObj.durationHours = hours;
                        innerObj.durationMinutes = totalMinutes;
                        innerObj.billableSlot = true;
                    } else {
                        innerObj.durationHours = 0;
                        innerObj.durationMinutes = 0;
                        innerObj.billableSlot = false;
                    }
                    innerObj.billingGenerated = true;
                    acc2[key2] = innerObj;
                }
                return acc2;
            }, {});
            if (Object.keys(filteredObj).length > 0) {
                acc[key] = filteredObj;
            }
            return acc;
        }, {});

        if (Object.keys(filteredAttendance).length > 0) {
            let tempObj: {
                clients: object;
                attendanceType: string;
                company_code: any;
                serviceID: string;
                program: string;
                status: string;
                startDate: string;
                endDate: string;
            } = {
                clients: filteredAttendance,
                attendanceType: searchValues.attendanceType,
                company_code: companyid,
                serviceID: selectedServDesc,
                program: searchValues.programNames,
                status: searchValues.status,
                startDate: searchValues.startDate,
                endDate: searchValues.startDate
            }
            const response: any = await dispatch(addAttendance(tempObj));
            if (response?.payload.success) {
                toast.success(response?.payload?.message || 'success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                setAttendanceMain((prevState: any) => {
                    let newState = { ...prevState }
                    Object.keys(newState).forEach((clientID) => {
                        Object.keys(newState[clientID]).forEach((date) => {
                            if (!newState[clientID][date].billingGenerated) {
                                newState[clientID][date].billingGenerated = true;
                            }
                        });
                    });
                    return newState
                })
            } else {
                toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }
    const handleSubmit = (values) => {
        try {
            let clientID = values["clientID"]
            let tempDate = values["date"]
            setAttendanceMain(prevState => {
                let newState = { ...prevState }
                newState[clientID as keyof object][tempDate as keyof object] = {
                    ...newState[clientID as keyof object][tempDate as keyof object], // spread existing values
                    attendanceOption: values.attendanceOption,
                    timeIn: values.timeIn,
                    timeOut: values.timeOut,
                    comments: values.comments,
                }
                return newState;
            })

            handleClose()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='fv-row mb-7 fv-plugins-icon-container p-5 card' >
            <div className='card mb-5 col-mb-10' id='kt_content'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                    <div id='kt_content_container' className='container-xxl'>
                        <div className='d-flex justify-content-start mb-4'>
                            <button className='btn btn-primary btn-active-secondary btn-sm'
                                onClick={() => setShowEditingForm(true)} disabled={loading}
                            >
                                Back
                            </button>
                            <button className="btn btn-primary btn-sm mx-2"
                                onClick={submitAttendance} disabled={loading}
                            >
                                Submit
                            </button>
                            <button className="btn btn-success btn-sm"
                                disabled={loading} onClick={submitApproval}
                            >
                                Approve
                            </button>
                            <button className="btn btn-danger btn-sm mx-2"
                                disabled={loading} onClick={generateBilling}
                            >
                                Generate Billing Data
                            </button>
                        </div>
                        {
                            loading ? (
                                <div className="overlay d-flex justify-content-center">
                                    <MoonLoader
                                        color="#9db2fc"
                                        size={80}
                                        loading={loading}
                                    />
                                </div>
                            ) :
                                <>
                                    <div className="alert alert-success">
                                        <h6>Attendance Options</h6>
                                        <div className="row">
                                            {
                                                attendanceTypeOptions?.options.map((option: any) => (
                                                    <div className='col-sm-4' key={option.optionCode} style={{ marginBottom: "-10px" }}>
                                                        <p>{option.optionName} - {option.optionCode} {option.billable === true ? "(billable)" : "(non-billable)"}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                            <label className=' fw-bold fs-6 mb-2'>Select Description (Code)</label>
                                            <select
                                                className='form-select form-select-lg mb-3'
                                                autoComplete='off'
                                                placeholder='Enter State here...'
                                                name='serviceDescription'
                                                defaultValue={selectedServDesc}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setSelectedServDesc(e.target.value)
                                                    setLoading(true)
                                                    setSevBool(!servBool)
                                                }}
                                            >
                                                {/* <option value="selectAll">Select All</option> */}
                                                {
                                                    serviceDescription.map((data) => (
                                                        data.attendanceType === searchValues.attendanceType ?
                                                            <option value={data._id} key={data._id} >{data.serviceDescription}</option>
                                                            : null
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3">
                                                <label className='fw-bold fs-6 mb-2'>Time In</label>
                                                <TimePicker
                                                    ampm={true}
                                                    className="mui-time-picker"
                                                    value={dayjs(timeIn, 'HH:mm')}
                                                    onChange={(e) => {
                                                        setTimeInError(false);
                                                        const min = e?.minute().toString().padStart(2, '0');
                                                        const hour = e?.hour().toString().padStart(2, '0');
                                                        const newDate = `${hour}:${min}`;
                                                        setTimeIn(newDate);

                                                        setAttendanceMain((prevState) => {
                                                            let newState = { ...prevState };
                                                
                                                            Object.keys(newState).forEach((clientID) => {
                                                                Object.keys(newState[clientID]).forEach((date) => {
                                                                    if (!newState[clientID][date].approved) {
                                                                        // Format e to "HH:mm" string for timeIn and timeOut
                                                                        if (newState[clientID][date].timeIn === "00:00" && newState[clientID][date].timeOut === "00:00") {
                                                                            newState[clientID][date].timeIn = newDate; // Use newDate
                                                                        } else if (newState[clientID][date].attendanceOption === "") {
                                                                            newState[clientID][date].timeOut = newDate; // Use newDate
                                                                        }
                                                                    }
                                                                });
                                                            });
                                                            return newState;
                                                        });
                                                    }}
                                                />
                                                {timeInError === true ? <p className='text-danger'>* Time In is required</p> : null}
                                            </div>
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3">
                                                <label className='fw-bold fs-6 mb-2'>Time Out</label>
                                                <TimePicker
                                                    ampm={true}
                                                    className="mui-time-picker"
                                                    value={dayjs(timeOut, 'HH:mm')}
                                                    onChange={(e) => {
                                                        const min = e?.minute().toString().padStart(2, '0');
                                                        const hour = e?.hour().toString().padStart(2, '0');
                                                        const newDate = `${hour}:${min}`
                                                        setTimeOut(newDate);
                                                        setTimeOutError(false);
                                                        setTimeInError(false);

                                                         // Update the attendanceMain state
                                                        setAttendanceMain((prevState) => {
                                                            let newState = { ...prevState };
                                                            
                                                            // Iterate over each client and date
                                                            Object.keys(newState).forEach((clientID) => {
                                                                Object.keys(newState[clientID]).forEach((date) => {
                                                                    // If attendance is not approved, proceed with time updates
                                                                    if (!newState[clientID][date].approved) {
                                                                        
                                                                        // If timeOut is "00:00", assign the new timeOut value
                                                                        if (newState[clientID][date].timeOut === "00:00") {
                                                                            newState[clientID][date].timeOut = newDate; // Use the formatted time string

                                                                        // If the attendance option is empty, also update timeOut
                                                                        } else if (newState[clientID][date].attendanceOption === "") {
                                                                            newState[clientID][date].timeOut = newDate; // Use the formatted time string
                                                                        }
                                                                    }
                                                                });
                                                            });
                                                            return newState; // Return the updated state
                                                        });
                                                    }}
                                                />
                                                {timeOutError === true ?
                                                    <p className='text-danger'>* Time Out is required</p> :
                                                    (new Date(`1970-01-01T${timeOut}:00Z`) < new Date(`1970-01-01T${timeIn}:00Z`)) ?
                                                        <p className='text-danger'>* Time Out must be less than Time In</p> :
                                                        null
                                                }
                                            </div>
                                        </LocalizationProvider>

                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3">
                                            <label className=' fw-bold fs-6 mb-2'>Mark all clients as: </label>
                                            <select className="form-select form-select lg mb-3"
                                                onChange={(e: { target: { value: string } }) => {
                                                    setAttendanceMain(prevState => {
                                                        const newState = { ...prevState };
                                                        Object.keys(newState).forEach((clientID: any) => {
                                                            Object.keys(newState[clientID]).forEach((date) => {
                                                                if (!newState[clientID][date].approved) newState[clientID][date].attendanceOption = e.target.value;
                                                            });
                                                        });
                                                        return newState;
                                                    });
                                                }}>
                                                <option value="-">- Code for all clients -</option>
                                                {
                                                    attendanceTypeOptions?.options.map((option: { optionCode: string; }, index: React.Key) => (
                                                        <option value={option.optionCode} key={index}>{option.optionCode}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3">
                                            <div className="mt-10">
                                                <div className="d-flex">
                                                    <div className="d-flex w-50">
                                                        <div style={{ height: "20px", width: "30px", backgroundColor: "#ffabab", border: "1px solid", marginRight: "5px" }}></div>
                                                        <label className='fs-8'>Attendance Option Missing</label>
                                                    </div>
                                                    <div className="d-flex w-50">
                                                        <div style={{ height: "20px", width: "30px", backgroundColor: "#ffecad", border: "1px solid", marginRight: "5px" }}></div>
                                                        <label className='fs-8'>Time Out Missing</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="d-flex w-50">
                                                        <div style={{ height: "20px", width: "30px", backgroundColor: "#fffbae", border: "1px solid", marginRight: "5px" }}></div>
                                                        <label className='fs-8'>Approval Pending</label>
                                                    </div>
                                                    <div className="d-flex w-50">
                                                        <div style={{ height: "20px", width: "30px", backgroundColor: "#b4f8c6", border: "1px solid", marginRight: "5px" }}></div>
                                                        <label className="fs-8">Pending Billing Generation</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div style={{ height: "20px", width: "30px", backgroundColor: "#aaf0fe", border: "1px solid", marginRight: "5px" }}></div>
                                                    <label className="fs-8">Billing Generated</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='table-responsive' style={{ padding: "0rem 0rem" }}>
                                        <table
                                            className='table table-light table-rounded border border-2 fs-6 gy-5 mt-5'
                                            id='kt_individual_table'
                                        >
                                            <thead>
                                                <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th className="px-3 min-w-150px" style={{ cursor: "pointer" }} >
                                                        Client Name
                                                    </th>
                                                    {
                                                        date.map((data, index) => {
                                                            return (
                                                                <th className="px-3" style={{ cursor: "pointer", minWidth: "120px" }} key={index} >
                                                                    <div className="d-flex justify-content-center">
                                                                        {data}
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mt-3'>
                                                                        <select
                                                                            className='form-select-arrow-only attendanceCode'
                                                                            autoComplete='off'
                                                                            onChange={(e: { target: { value: string; name: string } }) => {
                                                                                setAttendanceMain(prevState => {
                                                                                    const newState = { ...prevState };
                                                                                    Object.keys(prevState).forEach(clientID => {
                                                                                        if (!newState[clientID][data].approved) {
                                                                                            if (newState[clientID as keyof object]) {
                                                                                                newState[clientID as keyof object][data].attendanceOption = e.target.value;
                                                                                            }
                                                                                        }
                                                                                    });
                                                                                    return newState;
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value=''>-</option>
                                                                            {
                                                                                attendanceTypeOptions?.options.map((option: { optionCode: string; }, index: React.Key) => (
                                                                                    <option value={option.optionCode} key={index}>{option.optionCode}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    servAuths.length > 0 ?
                                                        servAuths.map((serv, index) => (
                                                            <tr key={index} >
                                                                <td className='px-3' >
                                                                    <div style={{ paddingBottom: "5px" }} >
                                                                        {serv.clientName}
                                                                    </div>
                                                                    <select
                                                                        className='form-select-arrow-only attendanceCode'
                                                                        onChange={(e: { target: { value: string } }) => {
                                                                            let clientID = serv.clientID
                                                                            setAttendanceMain(prevState => {
                                                                                const newState = { ...prevState };
                                                                                const clientName = newState[clientID as keyof object];
                                                                                Object.keys(clientName).forEach(date => {
                                                                                    if (!clientName[date].approved) {
                                                                                        clientName[date].attendanceOption = e.target.value;
                                                                                    }
                                                                                });
                                                                                return newState;
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value={'-'}>-</option>
                                                                        {
                                                                            attendanceTypeOptions?.options.map((option: { optionCode: string; }, index: React.Key) => (
                                                                                <option value={option.optionCode} key={index}>{option.optionCode}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                                {
                                                                    date.map((data, index) => {
                                                                        let clientID = serv.clientID
                                                                        return (
                                                                            <td key={index}
                                                                                className={
                                                                                    clientID + ' selectattendancecode tableAtt td-' + data + " "
                                                                                    + (attendanceMain && attendanceMain[clientID] && attendanceMain[clientID][data] && attendanceMain[clientID][data].approved === true && attendanceMain[clientID][data].billingGenerated === true ? "billingGenerated" :
                                                                                        attendanceMain && attendanceMain[clientID] && attendanceMain[clientID][data] && attendanceMain[clientID][data].attendanceOption !== ""
                                                                                            && attendanceMain[clientID][data].approved === true ? "billingPending" :
                                                                                            attendanceMain && attendanceMain[clientID] && attendanceMain[clientID][data] && attendanceMain[clientID][data].attendanceOption !== ""
                                                                                                && attendanceMain[clientID][data].approved === false && attendanceMain[clientID][data].timeOut !== "00:00" ? "approvalMissing" :
                                                                                                attendanceMain && attendanceMain[clientID] && attendanceMain[clientID][data] && attendanceMain[clientID][data].attendanceOption !== ""
                                                                                                    && attendanceMain[clientID][data].approved === false && attendanceMain[clientID][data].timeOut === "00:00" ? "timeOutMissing" :
                                                                                                    "noneSelected"
                                                                                    )
                                                                                }>
                                                                                <select
                                                                                    className={'form-select form-select-sm selectattendancecode attendanceCode select-' + data}
                                                                                    autoComplete='off'
                                                                                    defaultValue={attendanceMain[clientID][data].attendanceOption}
                                                                                    name={clientID}
                                                                                    disabled={attendanceMain[clientID][data].approved}
                                                                                    onChange={(e: { target: { value: string; name: string } }) => {
                                                                                        setAttendanceMain((prevState) => {
                                                                                            // Create a shallow copy of the previous state object
                                                                                            const newState = { ...prevState };

                                                                                            // Check if the clientID exists in the state object
                                                                                            if (clientID in newState) {
                                                                                                // Update the desired property of the clientID object
                                                                                                newState[clientID as keyof object][data].attendanceOption = e.target.value;
                                                                                                if (timeIn) {
                                                                                                    newState[clientID as keyof object][data].timeIn = timeIn;
                                                                                                }
                                                                                                if (timeOut) {
                                                                                                    newState[clientID as keyof object][data].timeOut = timeOut;
                                                                                                }
                                                                                            } else {
                                                                                                // Handle the error, e.g. by logging a message or returning the prevState
                                                                                                console.error(`clientID ${clientID} does not exist in attendanceMain state`);
                                                                                                return prevState;
                                                                                            }
                                                                                            // Return the updated newState
                                                                                            return newState;
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <option value=''>-</option>
                                                                                    {
                                                                                        attendanceTypeOptions?.options.map((option: { optionCode: string; }, index: React.Key) => (
                                                                                            <option value={option.optionCode} key={index}>{option.optionCode}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                <div className="d-flex justify-content-center">
                                                                                    <p className="attPTag" onClick={() => {
                                                                                        let tempVar: any = JSON.stringify(attendanceMain[clientID as keyof object][data])
                                                                                        tempVar = JSON.parse(tempVar)
                                                                                        tempVar.serviceID = selectedServDesc
                                                                                        if (!tempVar.timeIn) {
                                                                                            tempVar.timeIn = timeIn
                                                                                        }
                                                                                        if (!tempVar.timeIn) {
                                                                                            tempVar.timeOut = timeOut
                                                                                        }
                                                                                        tempVar.date = data
                                                                                        tempVar.program = searchValues.programNames
                                                                                        tempVar.programName = selectedProgram.programName
                                                                                        let tempServD: serviceDesc | undefined = serviceDescription.find(servD => servD._id === selectedServDesc)
                                                                                        if (tempServD) {
                                                                                            tempVar.serviceDescription = tempServD.serviceCode + '-' + tempServD.serviceDescription
                                                                                            setSelectedAtt(tempVar)
                                                                                            handleOpen()
                                                                                        }
                                                                                    }}>{attendanceMain[clientID as keyof object][data].attendanceOption}</p>
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )) :
                                                        <tr>
                                                            <td align="center" colSpan={12}><span className='text-gray-600 fs-2 fw-bolder'>No Service Auths Found</span></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-2">
                                            {<ExportXLSX headerProp={headers} data={attendanceMainData} title={"Export to XLSX"} filename={"Attendance-Summary-" + CurrentDate()} />}
                                        </div>
                                        {
                                            loadingPDF ?
                                                <div className="overlay d-flex justify-content-center">
                                                    <MoonLoader
                                                        color="#9db2fc"
                                                        size={80}
                                                        loading={loading}
                                                    />
                                                </div>
                                                :
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-success" onClick={generatePDF} disabled={attendanceMainData.length < 1}>
                                                        Generate PDF
                                                        <i className="far fa-file-alt mx-2"></i>
                                                    </button>
                                                </div>
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle variant='h5' fontWeight='bold'  alignSelf='center'>Attendance Data</DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-center pb-3">
                        {selectedAtt.approved === true ? <label className='fw-bold fs-5 text-primary my-5'>(This attendance has been approved)</label> : null}

                    </div>
                    <form className='card-body' onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(selectedAtt);
                    }}>
                        <div className="row px-9 mt-2 pb-7">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                <label className='fw-bold fs-6 mb-2'>Client Name:</label>
                                <label className='fs-6 mb-2'>&emsp;{selectedAtt.clientName}</label>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                <label className='fw-bold fs-6 mb-2'>Service Description:</label>
                                <label className='fs-6 mb-2'>&emsp;{selectedAtt.serviceDescription === 'selectAll' ? "All Service Descriptions Are Selected" : selectedAtt.serviceDescription}</label>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                <label className='fw-bold fs-6 mb-2'>Program:</label>
                                <label className='fs-6 mb-2'>&emsp;{selectedAtt.programName}</label>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                <label className='fw-bold fs-6 mb-2'>Attendance Options:</label>
                                <Autocomplete
                                    options={attendanceTypeOptions?.options || []}
                                    getOptionLabel={(option) => option.optionCode}
                                    value={attendanceTypeOptions?.options.find((option) => option.optionCode === selectedAtt.attendanceOption) || null}
                                    onChange={(event, newValue) => {
                                        setSelectedAtt({
                                            ...selectedAtt,
                                            attendanceOption: newValue?.optionCode || '',
                                        });
                                    }}
                                    disableCloseOnSelect
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="attendanceOption"
                                            label="Attendance Option"
                                            placeholder="Select Attendance Option"
                                            disabled={selectedAtt.approved}
                                        />
                                    )}
                                />
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                                    <TimePicker
                                        value={dayjs(selectedAtt.timeIn, 'HH:mm')}
                                        onChange={(e) => {
                                            const min = e?.minute().toString().padStart(2, '0');
                                            const hour = e?.hour().toString().padStart(2, '0');
                                            const newDate = `${hour}:${min}`
                                            console.log(selectedAtt.timeIn)
                                            if (e && e.toDate) {
                                                setSelectedAtt({
                                                    ...selectedAtt,
                                                    timeIn: newDate,
                                                })
                                            }
                                        }}
                                        ampm
                                        label='Time In'
                                        slotProps={{ textField: { size: 'small', style: { width: '100%' }, placeholder:"Select Time In", disabled: selectedAtt.approved } }}
                                        openTo="hours"
                                        views={['hours', 'minutes']}
                                        format="hh:mm A"
                                        components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                        }}
                                    />
                                </div>
                            </LocalizationProvider>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={dayjs(selectedAtt.timeOut, 'HH:mm')}
                                        onChange={(e) => {
                                            const min = e?.minute().toString().padStart(2, '0');
                                            const hour = e?.hour().toString().padStart(2, '0');
                                            const newDate = `${hour}:${min}`
                                            if (e && e.toDate) {
                                                setSelectedAtt({
                                                    ...selectedAtt,
                                                    timeOut: newDate,
                                                })
                                            }
                                        }}
                                        slotProps={{ textField: { size: 'small', style: { width: '100%' }, placeholder: 'Select Time Out', disabled: selectedAtt.approved } } }
                                        ampm={true}
                                        openTo="hours"
                                        label='Time Out'
                                        views={['hours', 'minutes']}
                                        format="hh:mm A"
                                        components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                <TextareaAutosize
                                    key='comments'
                                    value={selectedAtt.comments}
                                    onChange={(e: { target: { value: string } }) => {
                                        if (e) {
                                            setSelectedAtt({
                                                ...selectedAtt,
                                                comments: e.target.value
                                            })
                                        }
                                    }}
                                    name='comments'
                                    className='TextareaAutosize'
                                    aria-label='comments'
                                    placeholder='Comments'
                                    minRows={4}
                                    disabled={selectedAtt.approved}
                                />
                            </div>

                            <div className="d-flex justify-content-end">
                                <Button variant="contained" color="primary" style={{ marginRight: '3px' }} type='submit' disabled={selectedAtt.approved} >
                                    Update
                                </Button>

                                <Button variant="contained" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default NewTable