import {useEffect, useState, SetStateAction} from 'react'
import axios from 'axios'
import '../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import {RootState} from '../../../../../setup'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import TableExportToXlsxButton from '../../../../reusable-components/tables/TableExportToXlsxButton'
import TableButton from '../../../../reusable-components/tables/TableButton'
import {
  INDIVIDUAL_MEDICATION_COLUMNS,
  INDIVIDUAL_MEDICATION_DISCONTINUED_COLUMNS,
  INDIVIDUAL_MEDICATION_DATA_MAPPING,
  INDIVIDUAL_MEDICATION_SORTING_COLUMNS,
  INDIVIDUAL_MEDICATION_DISCONTINUED_DATA_MAPPING,
} from '../../mars_table_contants'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import PatientModal from './component/patient-select-modal'
import {Button} from '@mui/material'
import {useSelector} from 'react-redux'
import { MoonLoader } from 'react-spinners';
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
const selectAuth = (state) => state.auth

const IndividualMedicationList = ({tab}) => {
  const history = useHistory('')
  const [individualMedicationListing, setIndividualMedicationListing] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [deletedID, setDeletedID] = useState('')
  const [showPatientModal, setShowPatientModal] = useState(false)
  const [showPatientModalPdf, setShowPatientModalPdf] = useState(false)
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [pdfData, setData] = useState([])
  const [responseData,setResponseData]=useState('');
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: '_id',
    sortDir:'desc',
    searchTerm: ''
  })
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const auth = useSelector(selectAuth)
  const userPermissions = auth?.user?.roleId;
  const companyid = auth?.user?.company_code;
  const userId = auth?.user?._id
  useEffect(() => {
    const fetchData = async (page, pageSize, sortBy, sortDir) => {
      try {
        const [responseForTable, responseForDowndown] = await Promise.all([
          await axios.get(
          `/individualMedications?company_code=${companyid}&status=${tab}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`
        ),
          await axios.get(
            `/individualMedications?company_code=${companyid}&status=${tab}`
        )
        ])
        const {data: responseData, currentPage, totalCounts} = responseForTable.data
          setQueryData((prevState) => ({
            ...prevState,
            currentPage,
            totalCounts,
          }))
          setResponseData(responseForDowndown.data);
          setIndividualMedicationListing(responseData)
          setFilteredData(responseData)
      } catch (error) {
        console.error('Error fetching medications:', error)
      }
    }

    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)

    return () => {
      setIndividualMedicationListing([])
      setFilteredData([])
    }
  }, [tab, deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

  const handleGeneratePDF = async (pdfData) => {
    // Check if necessary data is available
    if (pdfData.clientId && pdfData.month && pdfData.year) {
      console.log("PDF Generating...");
      setLoadingPDF(true);
      try {
        // Call the generatePDF function
        const response = await axios.post('/pdf/mars-report', {
          fileName: 'Mars-Report-',
          clientId: pdfData.clientId,
          month: pdfData.month,
          year: pdfData.year,
          userId : userId
        }, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',  pdfData.clientName + '-Mars_Report-' + months[pdfData.month - 1] + '.pdf');
        document.body.appendChild(link);
        link.click();
        setLoadingPDF(false);
      } catch (err) {
        console.log(err);
        setLoadingPDF(false);
      }
    } else {
      // Notify the user that required data is missing
      console.log('Please select a patient and enter month and year.');
    }
  };

  const onView = (row) => {
    history.push(`/mars/individual-medication/view`, {data: row})
  }
  const onDelete = async (record) => {
    try {
      const response = await axios.put(`/individualMedications/` + record?._id, {
        status: 'DISCONTINUED',
        discontinuedOn: new Date(),
      })
      if (response.status === 200) {
        toast.success('Medication discontinued successfully!')
        setDeletedID(record?._id)
      }
    } catch (error) {
      console.error('Error discontinuing data:', error)
      toast.error('Error discontinuing Medication')
    }
  }
  return (
    <div className='card'>
      {showPatientModal ? <PatientModal button='AddMedication' data={responseData} handleClose={() => setShowPatientModal(false)} /> : null}
      <div className='card-body'>
        <TableTitle
          title={tab === 'ACTIVE' ? 'Individual Medication List' : 'Discontinued Medication List'}
        ></TableTitle>
        <TableWrapper
          searchField={
            <>
            {
             (tab === "ACTIVE" && hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_search)) &&
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm||""}
                  setQueryData={setQueryData}
                  setSearchTerm={setSearchTerm}
                  margin='normal'
                  inputAdornmentPosition='start'
                />
            }
            {
              (tab === 'DISCONTINUED' && hasPermission(userPermissions, permissions.mars_individual_medication_discontinued_medication_search)) &&
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm||""}
                  setQueryData={setQueryData}
                  setSearchTerm={setSearchTerm}
                  margin='normal'
                  inputAdornmentPosition='start'
                />
            }
            </>
          }
          tableButtons={
            tab === 'ACTIVE' && (
              <>
                {
                  hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_create) &&
                    <TableButton
                      variant='contained'
                      color='primary'
                      sx={{float: 'right', marginTop: '1.7rem'}}
                      onClick={() => history.push(`/mars/individual-medication/new`)}
                      title='Add Medications'
                    />
                }
                {
                  hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_create_entry) &&
                    <TableButton
                      variant='contained'
                      color='primary'
                      sx={{float: 'right', marginTop: '1.7rem'}}
                      onClick={() => setShowPatientModal((prev) => !prev)}
                      title='ADD Medication Entry'
                    />
                }
              </>
            )
          }
          dynamicTable={
            <>
              <DynamicTable
                columns={
                  tab === 'ACTIVE'
                    ? INDIVIDUAL_MEDICATION_COLUMNS
                    : INDIVIDUAL_MEDICATION_DISCONTINUED_COLUMNS
                }
                data={filteredData}
                canView={
                  tab === 'ACTIVE'
                    ? hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_view)
                    : hasPermission(userPermissions, permissions.mars_individual_medication_discontinued_medication_view)  }
                onView={onView}
                canDelete={tab === 'ACTIVE' && hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_delete)}
                onDelete={onDelete||""}
                columnDataMapping={
                  tab === 'ACTIVE'
                    ? INDIVIDUAL_MEDICATION_DATA_MAPPING
                    : INDIVIDUAL_MEDICATION_DISCONTINUED_DATA_MAPPING
                }
                sortingColumns={INDIVIDUAL_MEDICATION_SORTING_COLUMNS}
                title={'Are you sure you want to discontinue those medication?'}
                setQueryData={setQueryData||""}
                queryData={queryData}
              />
              {showPatientModalPdf ? (
                <PatientModal
                  button='AddPdf'
                  handleGeneratePDF={handleGeneratePDF}
                  data={responseData}  setData={setData}
                  handleClose={() => setShowPatientModalPdf(false)}
                />
              ) : null}
              {loadingPDF ? (
                <div className='overlay mx-8'>
                  <MoonLoader color='#9db2fc' size={30} loading={loadingPDF} />
                </div>
              ) : (
                <>
                {
                 (tab === 'DISCONTINUED' && hasPermission(userPermissions, permissions.mars_individual_medication_discontinued_medication_generate_pdf)) &&
                    <Button
                      variant='contained'
                      color='primary'
                      sx={{backgroundColor: '#57b857', color: 'white'}}
                      onClick={() => setShowPatientModalPdf((prev) => !prev)}
                    >
                      Generate PDF
                    </Button>
                }
                {
                 (tab === 'ACTIVE' && hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_create_entry)) &&
                    <Button
                      variant='contained'
                      color='primary'
                      sx={{backgroundColor: '#57b857', color: 'white'}}
                      onClick={() => setShowPatientModalPdf((prev) => !prev)}
                    >
                      Generate PDF
                    </Button>
                }
                </>
              )}
            </>
          }
        />
      </div>
    </div>
  )
}

export default IndividualMedicationList
