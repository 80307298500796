import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import {
    TextField, Button, Box,Typography
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import ClearIcon from '@mui/icons-material/Clear';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import PdfViewer from '../../../../chat_system/components/PdfViewer'

const MainInsuranceForm = () => {
    const [insuranceData, setInsuranceData] = useState({})
    const [individual, setIndividual] = useState({})
    const [clientFileName, setClientFileName] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [filePreview, setFilePreview] = useState(null);
    const [file, setFile] = useState(null);
    const parameter = useParams()
    const history = useHistory()
    const [formData, setFormData] = useState({
        medicareNumber: "",
        medicareDate: "",
        medPlanDId: "",
        medPlanDName: "",
        medPlanDIssuer: "",
        medPlanDRxBin: "",
        medPlanDRxPcn: "",
        medPlanDRxGrp: "",
        otherBenefits: "",
        insuranceCompany: "",
        insuranceGroup: "",
        insurancePolicyNumber: "",
        insurancePolicyHolder: "",
        file: ""
    });
    useEffect(() => {
        if (parameter.id) {
            axios.get('/clients/getOne/' + parameter.id).then((res) => {
                const tempData = res?.data?.client
                setInsuranceData(tempData.insuranceInfo)
                setIndividual(tempData)
                setClientFileName(tempData.firstName + ' ' + tempData.lastName + '_Form')
            })
        }

    }, [history, parameter.id])

    useEffect(() => {
        const fetchFile = async () => {
            const regex = /uploads[\/\\]insurance_documents[\/\\]/g;
            const fileName = insuranceData.file && insuranceData.file.replace(regex, '');
            if (fileName) {
                try {
                    const response = await axios.get(`clients/getDocument/${fileName}`, { responseType: 'blob' });
                    const mimeType = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: mimeType });
                    const objectURL = URL.createObjectURL(blob);
                    setFile(objectURL);
                } catch (error) {
                    console.error('Error fetching file:', error);
                }
            }
        };
        fetchFile();
    }, [insuranceData]);

    useEffect(() => {
        const tempObj = {
            medicareNumber: insuranceData.medicareNumber,
            medicareDate: insuranceData.medicareDate,
            medPlanDId: insuranceData.medPlanDId,
            medPlanDName: insuranceData.medPlanDName,
            medPlanDIssuer: insuranceData.medPlanDIssuer,
            medPlanDRxBin: insuranceData.medPlanDRxBin,
            medPlanDRxPcn: insuranceData.medPlanDRxPcn,
            medPlanDRxGrp: insuranceData.medPlanDRxGrp,
            otherBenefits: insuranceData.otherBenefits,
            insuranceCompany: insuranceData.insuranceCompany,
            insuranceGroup: insuranceData.insuranceGroup,
            insurancePolicyNumber: insuranceData.insurancePolicyNumber,
            insurancePolicyHolder: insuranceData.insurancePolicyHolder,
            file: insuranceData.file
        }
        setFormData(tempObj)
    }, [insuranceData, parameter.id])

    const handleFileChange = (event) => {
        const file = event.target.files[0]

        if (formData.file) {
            toast.error('You cannot upload more than one file.');
            event.target.value = null; 
            return;
        }

        if (file) {
            const allowedMimeTypes = [
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/msword',
                'image/jpeg', 
                'image/png',  
                'image/gif',
                'image/webp',  
                'image/heic',  
                'image/heif', 
                'image/bmp'     
            ];
            if (allowedMimeTypes.includes(file.type)) {
                setSelectedFile(file)
                setFormData((data) => {
                    return {
                        ...formData,
                        file: file
                    }
                })
            } else {
                toast.error('Invalid file type. Please select a valid file.');
                event.target.value = null;
            }
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFilePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFilePreview(null);
        }
    }

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDeleteFile = async () => {
        try {
            const regex = /uploads[\/\\]insurance_documents[\/\\]/g;
            const fileName = insuranceData.file.replace(regex, '');
           const response = await axios.delete(`/clients/deleteFile/${fileName}`)
           if(response.status === 200) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    file: ''
                }
            })
            setFile(null)
            setFilePreview(null)
           }
        } catch(err) {
         console.error('Error in handleDeleteFile', err)   
        }
    }       
    

    const handleSubmit = async (formData) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        const newFormData = new FormData()
        newFormData.append('medicareNumber', formData['medicareNumber'])
        newFormData.append('medicareDate', formData['medicareDate'])
        newFormData.append('medPlanDId', formData['medPlanDId'])
        newFormData.append('medPlanDName', formData['medPlanDName'])
        newFormData.append('medPlanDIssuer', formData['medPlanDIssuer'])
        newFormData.append('medPlanDRxBin', formData['medPlanDRxBin'])
        newFormData.append('medPlanDRxPcn', formData['medPlanDRxPcn'])
        newFormData.append('medPlanDRxGrp', formData['medPlanDRxGrp'])
        newFormData.append('otherBenefits', formData['otherBenefits'])
        newFormData.append('insuranceCompany', formData['insuranceCompany'])
        newFormData.append('insuranceGroup', formData['insuranceGroup'])
        newFormData.append('insurancePolicyNumber', formData['insurancePolicyNumber'])
        newFormData.append('insurancePolicyHolder', formData['insurancePolicyHolder'])
        newFormData.append('file', formData['file']);
        if (insuranceData.file) newFormData.append('oldFile', insuranceData.file)
        try {
            if (formData.medicareDate <= currentDateFormatted) {
                await axios.patch("clients/updateInsurance/" + parameter.id, newFormData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(res => {
                        setFormData(res.data.insuranceInfo)
                        if (res.status == 201) {
                            toast.success('Insurance Updated successfully')
                            history.push('/admin/care/insurance/list')
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error('Failed to upload document')
                    })
            } else {
                toast.error('Medicare Date should not be later than today')
            }

        }
        catch (err) {
            console.log(err)
        }
    };

    return (
        <div>
            {/* <div className="mx-6">
                        <SubMenuSection individual={individual} />
                    </div> */}
            <FormWrapper
                title={`Insurance Data Of: ${individual.firstName + ' ' + individual.lastName}`}
                content={
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(formData);
                    }}>
                        <Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="number"
                                        name="medicareNumber"
                                        size='small'
                                        className='textField'
                                        value={formData?.medicareNumber || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Medicare Number"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(formData?.medicareDate)}
                                            format="YYYY-MM-DD"
                                            onChange={(newValue) => {
                                                const year = newValue.$y.toString().padStart(2, '0');
                                                const month = (newValue.$M + 1).toString().padStart(2, '0');
                                                const day = newValue.$D.toString().padStart(2, '0');
                                                const newDate = `${year}-${month}-${day}`;
                                                setFormData({ ...formData, medicareDate: newDate });
                                            }}
                                            className='textField'
                                            slotProps={{ textField: { size: 'small', label: 'Insurance Expiry', error: false } }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="number"
                                        name="medPlanDId"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDId || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Med Plan D ID"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDName"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDName || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Med Plan D Name"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDIssuer"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDIssuer || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Med Plan D Issuer"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDRxBin"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDRxBin || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Med Plan D RxBIN"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDRxPcn"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDRxPcn || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Med Plan D RxPCN"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDRxGrp"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDRxGrp || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Med Plan D RxGrp"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                    fullWidth
                                    label='Other Benefits'
                                    name='otherBenefits'
                                    placeholder='Enter Other Benefits'
                                    variant='outlined'
                                    multiline
                                    rows={3}
                                    value={formData?.otherBenefits || ''}
                                    onChange={handleFieldChange}
                                  />
                                    
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insuranceCompany"
                                        size='small'
                                        className='textField'
                                        value={formData?.insuranceCompany || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Insurance Company"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insuranceGroup"
                                        size='small'
                                        className='textField'
                                        value={formData?.insuranceGroup || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Insurance Group"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="number"
                                        name="insurancePolicyNumber"
                                        size='small'
                                        className='textField'
                                        value={formData?.insurancePolicyNumber || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Insurance Policy Number"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insurancePolicyHolder"
                                        size='small'
                                        className='textField'
                                        value={formData?.insurancePolicyHolder || ''}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        label="Insurance Policy Holder"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="file"
                                        accept=".pdf, application/pdf, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv ,.doc, application/msword, .jpg, .jpeg, .png, .gif, .webp, .heic,.heif, .bmp"
                                        id="file-input"
                                        sx={{ maxWidth: 965 }}
                                        name='file'
                                        onChange={handleFileChange}
                                    />
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    Allowed file types: PDF,Word (.docx), CSV,.jpg, .jpeg, .png, .gif.
                                  </Typography>
                                    {selectedFile || file ? (
                                        <div style={{
                                            position: 'relative',
                                            display: 'flex',
                                            margin: '10px',
                                        }}>
                                            {file && (
                                                <div style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                }}>
                                                    <PdfViewer name={clientFileName} filePath={file}  mimeType={selectedFile?.type}/>
                                                    <ClearIcon
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-3px',
                                                            right: '-2px',
                                                            color: 'black',
                                                            border: 'none',
                                                            borderRadius: '2px',
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                        }}
                                                        onClick={() => {
                                                            handleDeleteFile();
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    file: ''
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {selectedFile && (
                                                <div style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    margin: '10px',
                                                }}>
                                                    <PdfViewer name={clientFileName} filePath={URL.createObjectURL(selectedFile)}  mimeType={selectedFile?.type}/>
                                                    <ClearIcon
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-3px',
                                                            right: '-2px',
                                                            color: 'black',
                                                            border: 'none',
                                                            borderRadius: '2px',
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                        }}
                                                        onClick={() => {
                                                            setFilePreview(null);
                                                            setSelectedFile(null);
                                                            setFormData({
                                                                ...formData,
                                                                file: ''
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : null}


                                </Box>
                            </Box>
                            <Box style={{ marginTop: '24px', }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    borderBottomLeftRadius: '4px',
                                    borderBottomRightRadius: '4px',
                                    padding: '10px 0',
                                }}>

                                    <Button
                                        type='button'
                                        sx={{ padding: '4px', margin: '4px' }}
                                        variant='contained'
                                        style={{
                                            color: '#000',
                                            backgroundColor: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#f2f2f2',
                                            }
                                        }}
                                        onClick={() => history.push('/admin/care/insurance/list')}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type='submit'
                                        sx={{ padding: '4px', margin: '4px' }}
                                        variant='contained'
                                        style={{
                                            marginLeft: '6px',
                                            backgroundColor: '#28a745',
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#218838',
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                    </form>
                }
            />

        </div>
    )
}

export default MainInsuranceForm