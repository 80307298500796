import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOneFundingSource, updateFundingSource } from '../store/actions'
import { getPayer } from '../../payers-setting/store/actions'
import { toast } from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { FUNDING_SOURCE_FORM_INPUTS } from '../../BILLING_CONSTANTS'
import { useHistory, useParams } from 'react-router-dom'

const selectAuth = (state) => state?.auth;

const EditFundingSource = () => {
    const [updatedFundingSource, setUpdatedFundingSource] = useState([])
    const [formData, setFormData] = useState({})
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;
    const parameters = useParams();
    const history = useHistory()


    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                const [resultPayer, resultFS] = await Promise.all([
                    dispatch(getPayer({companyid})),
                    dispatch(getOneFundingSource(parameters.id)),
                ])
                const tempObj = resultPayer.payload.data.data;
                const tempFS = resultFS?.payload?.data?.data
                const updatedPayerOptions = tempObj.map((payer) => ({ label: payer.name, value: payer._id }))
                const constantsWithUpdatedPayer = FUNDING_SOURCE_FORM_INPUTS.map((field) => {
                    if (field.name === 'payer') {
                        return {
                            ...field,
                            options: updatedPayerOptions,
                        };
                    }
                    return field;
                });
                setUpdatedFundingSource(constantsWithUpdatedPayer)
                setFormData(tempFS)
            } else {
                history.push('/admin/billing/funding-source/list')
            }

        }
        fetchData()
    }, [parameters.id])

    const handleSubmitForm = async (value) => {
        const tempObj = {
            _id: formData._id,
            name: value.name,
            fundingProvider: Number(value.fundingProvider),
            vendorID: Number(value.vendorID),
            addrInfo: {
                st1: value?.addrInfo?.st1,
                st2: value?.addrInfo?.st2,
                city: value?.addrInfo?.city,
                state: value?.addrInfo?.state,
                country: value?.addrInfo?.country,
                zipCode: Number(value?.addrInfo?.zipCode),
                primaryPhone: Number(value?.addrInfo?.primaryPhone),
            },
            title: value.title,
            firstName: value.firstName,
            lastName: value.lastName,
            payer: value.payer,
            payerType: value.payerType,
            email: value.email,
        }
        const response = await dispatch(updateFundingSource(tempObj))
        if(response?.payload?.status === 400 && response?.payload?.success === false) {
            toast.error(response?.payload?.message)
        } else if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/billing/funding-source/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <FormWrapper
            title="Edit Funding Source"
            content={
                <DynamicForm
                    fields={updatedFundingSource}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        ></FormWrapper>
    )
}

export default EditFundingSource