import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOnePayer, getPayer, updatePayer } from '../store/actions'
import { toast } from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { PAYER_FORM_INPUTS } from '../../BILLING_CONSTANTS'
import { useHistory, useParams } from 'react-router-dom'


const EditPayer = () => {
    const [formData, setFormData] = useState({})
    const dispatch = useDispatch()
    const selectAuth = (state) => state?.auth;
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;
    const parameters = useParams();
    const history = useHistory();
    useEffect(() => {

        const fetchData = async () => {
            if (parameters.id) {
                const result = await dispatch(getOnePayer(parameters.id))
                let tempPayer = result.payload?.data?.data
                setFormData(tempPayer)
            }
        }
        fetchData()
    }, [])

    const handleSubmitForm = async (value) => {
        const tempObj = {
            _id: formData._id,
            company_code: companyid,
            name: value.name,
            payerID: value.payerID,
            transmissionTo: value.transmissionTo
        }
        const response = await dispatch(updatePayer(tempObj))
        if (response?.payload?.success) {
            toast.success(response?.payload?.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/billing/payers/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    };

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];
    return (
        <FormWrapper
            title="Edit Payer"
            content={
                <DynamicForm
                    fields={PAYER_FORM_INPUTS}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        ></FormWrapper>
    )
}

export default EditPayer