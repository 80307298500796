import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'

function GeneralHeader() {
  const location = useLocation()

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith('/admin/general/shared-contact')) return 0
    if (location.pathname.startsWith('/admin/general/site')) return 1
    if (location.pathname.startsWith('/admin/general/programs')) return 2
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              aria-label='General Admin Tabs'
              sx={{
                flexGrow: 1,
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Tab
                label='Shared Contact'
                component={Link}
                to='/admin/general/shared-contact/list'
              />
              <Tab label='Site' component={Link} to='/admin/general/site/list' />
              <Tab label='Programs' component={Link} to='/admin/general/programs/list' />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralHeader
