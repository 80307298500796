export const NOTES_LISTING_COLUMNS = [
  'Name',
  'Client Name',
  'Program Name',
  'Service Description',
  'Start Date',
  'End Date',
  'Status',
]
export const NOTES_SORTING_COLUMNS = {
  'Name': 'noteName',
  'Client Name': 'clientName',
  'Program Name': 'programName',
  'Service Description':'serviceName',
  'Start Date': 'startDate',
  'End Date': 'endDate',
  'Status': 'isApproved',
}
export const NOTES_COLUMNS_DATA_MAPPING = {
  'Name': 'noteName',
  'Client Name': 'clientName',
  'Program Name': 'programName',
  'Service Description':'serviceName',
  'Start Date': 'startDate',
  'End Date': 'endDate',
  'Status': 'isApproved',
}

export const NOTES_ENTRIES_COLUMNS = [
  'Creation Date',
  'Begin Time',
  'End Time',
  'Location',
  'Created By',
]
export const NOTES_ENTRIES_SORTING_COLUMNS = {
  'Creation Date': 'creationDateFormatted',
  'Begin Time': 'beginTimeFormatted',
  'End Time': 'endTimeFormatted',
  'Location':'locationAddress',
  'Created By': 'createdBy',
}
export const NOTES_ENTRIES_COLUMNS_DATA_MAPPING = {
  'Creation Date': 'creationDateFormatted',
  'Begin Time': 'beginTimeFormatted',
  'End Time': 'endTimeFormatted',
  'Location':'locationAddress',
  'Created By': 'createdBy',
}
