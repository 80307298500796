import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useSelector} from 'react-redux'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {ADD_NEW_SCORING_METHOD, ADD_NOTES_FORM_INPUTS} from '../../ADD_NOTES_FORM_CONSTANTS'
import {Box, Button, Typography} from '@mui/material'
import DynamicAddModal from '../../../../reusable-components/Modals/DynamicModal'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {GetDescriptionCode} from '../../../admin/billing/description-code/store/action'
import {RootState} from '../../../../../setup'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {AppDispatch} from '../../../../../setup/redux/Store'
export default function NewNote() {
  const history = useHistory()
  const [formElements, setFormElements] = useState([])
  const [showAddScoringMethod, setShowAddScoringMethod] = useState(false)
  const [newScoringMethodAdded, setNewScoringMethodAdded] = useState(0)
  const [formData, setFormData] = useState({
    programId: '',
    clientId: '',
    noteName: '',
    startDate: new Date(),
    endDate: new Date(),
    targetCompletionDate: new Date(),
    location: '',
    scoringMethod: '',
    longTermObjectives: '',
    goals: '',
    reasonForNotes: '',
    criteriaForCompletion: '',
    materialsRequired: '',
    serviceId: '',
  })
  const dispatch = useDispatch<AppDispatch>()
  const selectAuth = (state: RootState) => state.auth
  const auth = useSelector(selectAuth)
  const companyid: string = auth.user?.company_code
  const [uiElements, setUIElements] = useState([])
  const [indexForScoringMethod, setIndexForScoringMethod] = useState(0)
  const [indexForTask, setIndexForTask] = useState(0)
  const [clickCount, setClickCount] = useState(3)
  const [taskClickCount, setTaskClickCount] = useState(19)
  const [inputArr, setInputArr] = useState([
    {
      levelName: '',
      levelAcronym: '',
      // isReportable: false,
    },
  ])
  const [inputArrForTask, setInputArrForTask] = useState([
    {
      taskName: '',
      taskDescription: '',
    },
  ])
  const authUser = useSelector((state: {auth: any}) => state.auth)
  const company_code = authUser.user?.company_code
  const userPermissions = authUser.user.roleId

  const onClickAddTask = () => {
    setTaskClickCount(taskClickCount + 1)
    let prevInputArr = inputArrForTask
    prevInputArr.push({
      taskName: '',
      taskDescription: '',
    })
    setInputArrForTask(prevInputArr)
    setIndexForTask(indexForTask + 1)
  }
  const onRemoveTaskButtonClick = (index) => {
    let prevInputArr = inputArrForTask
    prevInputArr[index].taskName = ''
    // setInputArr(prevInputArr)
    setFormElements((prevState) => {
      return prevState.filter((item) => {
        return !item.index || (item.index && item.index != index)
      })
    })
  }
  const buttons = [
    {label: 'Add Task', variant: 'outlined', type: 'button', onClick: onClickAddTask},
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  const onAddAnotherLevelClick = async () => {
    setClickCount(clickCount + 1)
    let prevInputArr = inputArr
    prevInputArr.push({
      levelName: '',
      levelAcronym: '',
      //  isReportable: false
    })
    setInputArr(prevInputArr)
    setIndexForScoringMethod(indexForScoringMethod + 1)
  }

  const onRemoveAnotherLevelButtonClick = (index) => {
    let prevInputArr = inputArr
    prevInputArr[index].levelName = ''
    // setInputArr(prevInputArr)
    setUIElements((prevState) => {
      return prevState.filter((item) => {
        return !item.index || (item.index && item.index != index)
      })
    })
  }

  const onChangeLevelName = (name, value, checked, type, index) => {
    let prevInputArr = inputArr
    prevInputArr[index]['levelName'] = value
    setInputArr(prevInputArr)
  }

  const onChangeTaskName = (name, value, checked, type, index) => {
    let prevInputArr = inputArrForTask
    prevInputArr[index]['taskName'] = value
    setInputArrForTask(prevInputArr)
  }

  const onChangeTaskDescription = (name, value, checked, type, index) => {
    let prevInputArr = inputArrForTask
    prevInputArr[index]['taskDescription'] = value
    setInputArrForTask(prevInputArr)
  }

  const onChangeLevelAcronym = (name, value, checked, type, index) => {
    let prevInputArr = inputArr
    prevInputArr[index]['levelAcronym'] = value
    setInputArr(prevInputArr)
  }

  // use effect to fetch all data from backend
  useEffect(() => {
    fetchData()
  }, [])

  // use effect to add new scoring method row when user is creating a new scoring method
  useEffect(() => {
    addNewScoringMethodRow()
  }, [indexForScoringMethod])

  // use effect to add new row for task when user clicks add task button
  useEffect(() => {
    addNewRowForTask()
  }, [indexForTask])

  // use effect to add newly created scoring method in list of exisiting scoring methods
  useEffect(() => {
    addNewScoringMethodInList()
  }, [newScoringMethodAdded])

  const fetchData = () => {
    let programList = []
    let scoringMethodList = []
    let serviceDescriptionList = []
    let axiosArray = []
    let newPromise = axios({
      method: 'get',
      url: `/programs/get/${company_code}`,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'get',
      url: `/scoringMethod/getAll`,
    })
    axiosArray.push(newPromise)

    axiosArray.push(
      new Promise(async (resolve, reject) => {
        try {
          const result = await dispatch(GetDescriptionCode({companyid}))
          resolve(result.payload)
        } catch (err) {
          reject(err)
        }
      })
    )

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          responses[0].data.map((eachProgram) => {
            programList.push({
              label: eachProgram.programName,
              value: eachProgram._id,
            })
          })

          responses[1].data.map((eachScoringMethod) => {
            scoringMethodList.push({
              label: eachScoringMethod.methodName,
              value: eachScoringMethod._id,
            })
          })
          responses[2].data.map((service) => {
            serviceDescriptionList.push({
              label: service.serviceDescription,
              value: service._id,
            })
          })
        })
      )
      .catch((err) => toast.error('Something went wrong'))

    let formUI = ADD_NOTES_FORM_INPUTS(programList, scoringMethodList, serviceDescriptionList)
    setFormElements((prevState) => [
      ...formUI,
      {
        label: 'Name',
        index: 0,
        name: `${indexForScoringMethod}taskName`,
        value: inputArrForTask[0]['taskName'],
        onChange: onChangeTaskName,
        type: 'text',
        required: true,
        rowNumber: 13,
      },
      {
        label: 'Description',
        index: 0,
        name: `${indexForScoringMethod}taskDescription`,
        value: inputArrForTask[0]['taskDescription'],
        onChange: onChangeTaskDescription,
        type: 'text',
        required: true,
        rowNumber: 13,
      },
    ])

    let scoringMethodUI = ADD_NEW_SCORING_METHOD()
    setUIElements((prevState) => [
      ...scoringMethodUI,
      {
        label: 'Level Name',
        index: 0,
        name: `${indexForScoringMethod}levelName`,
        value: inputArr[0]['levelName'],
        onChange: onChangeLevelName,
        type: 'text',
        required: true,
        rowNumber: 3,
      },
      {
        label: 'Level Acronym',
        index: 0,
        name: `${indexForScoringMethod}levelAcronym`,
        value: inputArr[0]['levelAcronym'],
        onChange: onChangeLevelAcronym,
        type: 'text',
        required: true,
        rowNumber: 3,
      },
    ])
    // {
    //   index: 0,
    //   name: `${i}isReportable`,
    //   value: inputArr[0]['isReportable'],
    //   // onChange: onChangeRoleName,
    //   type: 'checkboxBoolean',
    //   options: [{label: 'Is Reportable?', value: false}],
    //   required: true,
    //   rowNumber: 3,
    // },)
    // )
  }

  const addNewScoringMethodInList = () => {
    let scoringMethodList = []
    if (newScoringMethodAdded != 0) {
      axios.get(`/scoringMethod/getAll`).then(({data}) => {
        data.map((eachScoringMethod) => {
          scoringMethodList.push({
            label: eachScoringMethod.methodName,
            value: eachScoringMethod._id,
          })
        })
      })
      let prevFormElement = formElements
      prevFormElement[9]['options'] = scoringMethodList
      setFormElements(prevFormElement)
    }
  }

  const addNewScoringMethodRow = () => {
    if (indexForScoringMethod != 0) {
      const levelNameFieldToAdd = {
        index: indexForScoringMethod,
        rowNumber: clickCount,
        // error: error[i],
        // helperText: error[i] ? 'Please select a different role' : '',
        label: 'Level Name',
        name: `${indexForScoringMethod}levelName`,
        value: inputArr[indexForScoringMethod]['levelName'],
        onChange: onChangeLevelName,
        type: 'text',
        required: true,
      }
      const levelAcronymFieldToAdd = {
        index: indexForScoringMethod,
        rowNumber: clickCount,
        label: 'Level Acronym',
        name: `${indexForScoringMethod}levelAcronym`,
        value: inputArr[indexForScoringMethod]['levelAcronym'],
        onChange: onChangeLevelAcronym,
        type: 'text',
        required: true,
      }
      // const isReportableFieldToAdd = {
      //   index: i,
      //   rowNumber: clickCount,
      //   name: `${i}isReportable`,
      //   value: inputArr[i]['isReportable'],
      //   // onChange: onChangeRoleName,
      //   type: 'checkboxBoolean',
      //   options: [{label: 'Is Reportable?', value: false}],
      //   required: true,
      // }
      const deleteButton = {
        index: indexForScoringMethod,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick: () => onRemoveAnotherLevelButtonClick(indexForScoringMethod),
        rowNumber: clickCount,
      }
      setUIElements((prevElements) => [
        ...prevElements,
        levelNameFieldToAdd,
        levelAcronymFieldToAdd,
        // isReportableFieldToAdd,
        deleteButton,
      ])
    }
  }

  const addNewRowForTask = () => {
    if (indexForTask != 0) {
      const taskNameFieldToAdd = {
        index: indexForTask,
        rowNumber: taskClickCount,
        label: 'Name',
        name: `${indexForTask}taskName`,
        value: inputArrForTask[indexForTask]['taskName'],
        onChange: onChangeTaskName,
        type: 'text',
        required: true,
      }
      const taskDescriptionFieldToAdd = {
        index: indexForTask,
        rowNumber: taskClickCount,
        label: 'Description',
        name: `${indexForTask}taskDescription`,
        value: inputArrForTask[indexForTask]['taskDescription'],
        onChange: onChangeTaskDescription,
        type: 'text',
        required: true,
      }
      const deleteButton = {
        index: indexForTask,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick: () => onRemoveTaskButtonClick(indexForTask),
        rowNumber: taskClickCount,
      }
      setFormElements((prevElements) => [
        ...prevElements,
        taskNameFieldToAdd,
        taskDescriptionFieldToAdd,
        deleteButton,
      ])
    }
  }

  const modalButtons = [
    {
      label: 'Add Level',
      variant: 'outlined',
      type: 'button',
      onClick: onAddAnotherLevelClick,
    },
    {label: 'Save', variant: 'contained', type: 'submit'},
    {
      label: 'Cancel',
      variant: 'outlined',
      type: 'button',
      onClick: () => setShowAddScoringMethod(false),
    },
  ]

  const handleSubmitForm = async (formData) => {
    let notesTasks = []
    inputArrForTask.map((eachElem) => {
      if (eachElem.taskName !== '') {
        notesTasks.push(eachElem)
      }
    })

    const currentDate = new Date()
    currentDate.setUTCHours(0)
    currentDate.setUTCMinutes(0)
    currentDate.setUTCSeconds(0)
    currentDate.setUTCMilliseconds(0)
    // Create the formatted date string
    if (
      new Date(formData.startDate) >= currentDate &&
      new Date(formData.endDate) >= new Date(formData.startDate)
    ) {
      await axios
        .post('/notes/create', {
          company_code: company_code,
          userId: authUser.user._id,
          programId: formData['programId'],
          clientId: formData['clientId'],
          noteName: formData['noteName'],
          startDate: formData['startDate'],
          endDate: formData['endDate'],
          targetCompletionDate: formData['targetCompletionDate'],
          location: {
            address: formData['location']?.address,
            lat: '31.5245054',
            lng: '74.3033196',
            typeOfLocation: 'client',
            idOfDoc: '65ae6e8b682092bf5446fd8b',
          },
          longTermObjectives: formData['longTermObjectives'],
          goals: formData['goals'],
          reasonForNotes: formData['reasonForNotes'],
          criteriaForCompletion: formData['criteriaForCompletion'],
          materialsRequired: formData['materialsRequired'],
          scoringMethodId: formData['scoringMethod'],
          notesTasks: notesTasks,
          serviceId: formData['serviceId'],
        })
        .then((res) => {
          const {message, success} = res?.data
          if (!success) {
           return toast.error(message)
          } 
          toast.success(message)
          history.push('/caregivers/notes')
        })
        .catch((e) => {
          toast.error(e.response.data.error ? e.response.data.error : `Couldn't Add Note`)
        })
    } else {
      if (new Date(formData.startDate) < currentDate) {
        toast.error('Start Date should not be earlier than today')
      } else if (new Date(formData.endDate) < new Date(formData.startDate)) {
        toast.error('End Date should not be earlier than Start Date')
      }
    }
  }

  const handleSubmitAddScoringMethod = async (formData) => {
    let scoringLevels = []
    inputArr.map((eachElem) => {
      if (eachElem.levelName !== '') {
        scoringLevels.push(eachElem)
      }
    })

    await axios
      .post('/scoringMethod/create', {
        userId: authUser.user._id,
        methodName: formData['scoringMethodName'],
        numberOfLevels: scoringLevels.length,
        scoringLevels: scoringLevels,
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Scoring method added successfully')
          setNewScoringMethodAdded(newScoringMethodAdded + 1)
          setShowAddScoringMethod(false)
        }
      })
      .catch((e) => {
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't Add Scoring Method`)
        setShowAddScoringMethod(false)
      })
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1.5rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }}
          >
            <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
              Add Note
            </Typography>
            {/* {hasPermission(userPermissions, permissions.care_giver_notes_scoring_method_create) && ( */}
              <Button
                variant='contained'
                color='primary'
                type='button'
                style={{marginRight: '0.7rem'}}
                onClick={() => {
                  // setUIElements(ADD_NEW_SCORING_METHOD())
                  // setUIElements((prevState) => [
                  //     ...prevState,
                  //     {
                  //       label: 'Search Role',
                  //       index: 0,
                  //       name: `${i}Role`,
                  //       value: inputArr[0]['roleId'],
                  //       onChange: onChangeRoleName,
                  //       type: 'select',
                  //       options: rolesToShow,
                  //       required: true,
                  //       rowNumber: 5,
                  //     },
                  //     {
                  //       label: 'Unit',
                  //       index: 0,
                  //       name: `${i}wageUnit`,
                  //       value: inputArr[0]['wageUnit'],
                  //       onChange: onChangeRoleWageUnit,
                  //       type: 'select',
                  //       options: [
                  //         {
                  //           label: '/hour',
                  //           value: 'hour',
                  //         },
                  //       ],
                  //       required: true,
                  //       rowNumber: 5,
                  //     },
                  //     {
                  //       label: 'Wage',
                  //       name: `${i}Wage`,
                  //       index: 0,
                  //       value: inputArr[0]['wage'],
                  //       onChange: onChangeRoleWage,
                  //       type: 'number',
                  //       required: true,
                  //       rowNumber: 5,
                  //     },
                  //   ])
                  setShowAddScoringMethod(true)
                }}
              >
                Add Scoring Method
              </Button>
            {/* )} */}
          </Box>
          <DynamicForm
            fields={formElements}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
          <DynamicAddModal
            title={'Add New Scoring Method'}
            showAddScheduleModal={showAddScoringMethod}
            formInputs={uiElements}
            handleSubmitForm={handleSubmitAddScoringMethod}
            buttons={modalButtons}
            data={inputArr}
          ></DynamicAddModal>
        </div>
      </div>
    </>
  )
}
