import axios from "axios"
import { useSelector } from 'react-redux'
import FormWrapper from "../../../../../reusable-components/Wrapper/Wrapper"
import DynamicForm from "../../../../../reusable-components/forms/DynamicForm"
import { TYPE_CONSTANT_INPUT, TYPE_DATA } from "../../GENERAL_CONSTANTS"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
const selectAuth = (state) => state.auth;


const NewTypeForm = () => {
    const auth = useSelector(selectAuth);
    const history = useHistory()
    const companyid = auth.user?.company_code


    const handleSubmitForm = async (formData) => {
        const tempObj = {
            company_code: companyid,
            name: formData.name
        }
      const response = await axios.post("/sharedtype/create", tempObj)
      if(response?.data?.status === 400 && response?.data?.success === false) {
        toast.error(response?.data?.message)
        } else if (response.data && response.data.success) {
        toast.success(response?.data?.message || 'Success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
        history.push("/admin/general/shared-contact/type/list");
    } else {
        toast.error(response?.data?.message || 'Error', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }
};
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Create', variant: 'contained', type: 'submit' }
    ];
    return (
        <FormWrapper
            title='Shared Contact Type'
            content={
                <DynamicForm
                    fields={TYPE_CONSTANT_INPUT}
                    data={TYPE_DATA}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default NewTypeForm