import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import TableTitle from '../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../reusable-components/tables/TableWrapper'
import TableSearchBar from '../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../reusable-components/tables/DynamicTable'
import TableButton from '../../../reusable-components/tables/TableButton'
import {NOTES_COLUMNS_DATA_MAPPING, NOTES_LISTING_COLUMNS, NOTES_SORTING_COLUMNS } from '../NOTES_TABLE_CONSTANTS'
import {toast} from 'react-toastify'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import { QueryData } from '../../../reusable-components/tables/DynamicTableTypes'
import { RootState } from '../../../../setup'
const selectAuth = (state: RootState) => state.auth;

export default function Notes() {
  const authUser = useSelector((state: {auth: any}) => state.auth)
  const history = useHistory()
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [queryData, setQueryData] = useState<QueryData>({
      pageNumber: 1,
      pageSize: 5,
      currentPage: 1,
      totalCounts: 1,
      sortBy: 'clientName',
      sortDir: 'asc',
      searchTerm: '',
  })
  const auth = useSelector(selectAuth);
  const userPermissions = auth.user.roleId;

  useEffect(() => {
    const companyid = authUser.user?.company_code
    const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
      const response = await axios.get(`/notes/getAll?company_code=${companyid}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`)
      const { data, currentPage, totalCounts } = response.data;
        setFilteredData(data)
        setQueryData((prevState) => {
            return {
                ...prevState,
                currentPage,
                totalCounts,
            };
        });
    }
    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
  }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir])

  const onClickAddButton = () => {
    history.push('/caregivers/notes/new')
  }

  const onUpdate = (row) => {
    history.push(`/caregivers/notes/edit/${row['_id']}`)
  }

  const onView = (row) => {
    history.push(`/caregivers/notesEntries/${row['_id']}`)
  }

  const onAdd = (row) => {
    if (row.isApproved == 'UNAPPROVED') {
      toast.error('Can not add entry to unapproved note')
    } else {
      history.push(`/caregivers/notesEntries/new/${row['_id']}`)
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <TableTitle title='Notes'></TableTitle>
        <TableWrapper 
          searchField={
            hasPermission(userPermissions, permissions.care_giver_notes_note_search) &&
            <TableSearchBar
              label='Search'
              variant='outlined'
              size='small'
              value={searchTerm}
              setQueryData={setQueryData}
              setSearchTerm={setSearchTerm}
              margin='normal'
              inputAdornmentPosition='start'
            ></TableSearchBar>
          }
          tableButtons={
            hasPermission(userPermissions, permissions.care_giver_notes_note_create) &&
            <TableButton
              variant={'contained'}
              color={'primary'}
              title='Add Note'
              onClick={onClickAddButton}
            />
          }
          dynamicTable={
            <DynamicTable
              htmlColumns={[]}
              columns={NOTES_LISTING_COLUMNS}
              data={filteredData}
              // canView={true}
              canAdd={hasPermission(userPermissions, permissions.care_giver_notes_note_create_entry)}
              onAdd={onAdd}
              canUpdate={hasPermission(userPermissions, permissions.care_giver_notes_note_update)}
              onUpdate={onUpdate}
              sortingColumns={NOTES_SORTING_COLUMNS}
              canView={hasPermission(userPermissions, permissions.care_giver_notes_note_view)}
              onView={onView}
              setQueryData={setQueryData}
              queryData={queryData}
              columnDataMapping={NOTES_COLUMNS_DATA_MAPPING}
            />
          }
        />
      </div>
    </div>
  )
}
