import React, {useEffect, useState} from 'react'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import {
  CAREGIVER_PROFILE_JOB_DETAILS_FORM_INPUTS,
  CAREGIVER_PROFILE_PERSONAL_INFORMATION_FORM_INPUTS,
} from '../../../CAREGIVER_FORM_CONSTANTS'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import axios from 'axios'
import {toast} from 'react-toastify'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
const selectAuth = (state: RootState) => state.auth

export default function PersonalInformation(props) {
  const [formData, setFormData] = useState({})
  const [uiElementsList, setUiElementsList] = useState([])
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId;

  useEffect(() => {
    const tempElementsList = CAREGIVER_PROFILE_PERSONAL_INFORMATION_FORM_INPUTS()
    setUiElementsList(tempElementsList)
  }, [])
  useEffect(() => {
    axios.get(`caregivers/getById/${props.id}`).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        firstName: res.data?.careGiver?.firstName,
        lastName: res.data?.careGiver?.lastName,
        phone: res.data?.careGiver?.phone,
        email: res.data?.careGiver?.email,
        emergencyContactName: res.data?.careGiver?.emergencyContact?.name
          ? res.data?.careGiver?.emergencyContact?.name
          : '',
        emergencyRelationship: res.data?.careGiver?.emergencyContact?.relationship
          ? res.data?.careGiver?.emergencyContact?.relationship
          : '',
        emergencyPhone: res.data?.careGiver?.emergencyContact?.phoneNum
          ? res.data?.careGiver?.emergencyContact?.phoneNum
          : '',
        legalFirstName: res.data?.careGiver?.payrollInfo?.legalFirstName
          ? res.data?.careGiver?.payrollInfo?.legalFirstName
          : '',
        legalMiddleName: res.data?.careGiver?.payrollInfo?.legalMiddleName
          ? res.data?.careGiver?.payrollInfo?.legalMiddleName
          : '',
        legalLastName: res.data?.careGiver?.payrollInfo?.legalLastName
          ? res.data?.careGiver?.payrollInfo?.legalLastName
          : '',
        legalDateOfBirth: res.data?.careGiver?.payrollInfo?.dateOfBirth
          ? res.data?.careGiver?.payrollInfo?.dateOfBirth
          : '',
        legalSocialSecurityNumber: res.data?.careGiver?.payrollInfo?.socialSecurityNumber
          ? res.data?.careGiver?.payrollInfo?.socialSecurityNumber
          : '',
        primaryPhone: res.data?.careGiver?.addressInfo?.primaryPhone
          ? res.data?.careGiver?.addressInfo?.primaryPhone
          : '',
        secondaryPhone: res.data?.careGiver?.addressInfo?.secondaryPhone
          ? res.data?.careGiver?.addressInfo?.secondaryPhone
          : '',
        additionalPhone: res.data?.careGiver?.addressInfo?.additionalPhone
          ? res.data?.careGiver?.addressInfo?.additionalPhone
          : '',
        country: res.data?.careGiver?.addressInfo?.country
          ? res.data?.careGiver?.addressInfo?.country
          : '',
        st1: res.data?.careGiver?.addressInfo?.st1 ? res.data?.careGiver?.addressInfo?.st1 : '',
        st2: res.data?.careGiver?.addressInfo?.st2 ? res.data?.careGiver?.addressInfo?.st2 : '',
        faxNumber: res.data?.careGiver?.addressInfo?.faxNumber
          ? res.data?.careGiver?.addressInfo?.faxNumber
          : '',
        city: res.data?.careGiver?.addressInfo?.city ? res.data?.careGiver?.addressInfo?.city : '',
        state: res.data?.careGiver?.addressInfo?.state
          ? res.data?.careGiver?.addressInfo?.state
          : '',
        zipCode: res.data?.careGiver?.addressInfo?.zipCode
          ? res.data?.careGiver?.addressInfo?.zipCode
          : '',
        addressInfoEmail: res.data?.careGiver?.addressInfo?.email
          ? res.data?.careGiver?.addressInfo?.email
          : '',
        webaddress: res.data?.careGiver?.addressInfo?.webaddress
          ? res.data?.careGiver?.addressInfo?.webaddress
          : '',
      }))
    })
  }, [])
  const handleSubmitForm = (formData) => {
    let emergencyContact = {
      name: formData['emergencyContactName'],
      relationship: formData['emergencyRelationship'],
      phoneNum: formData['emergencyPhone'],
    }

    let payrollInfo = {
      legalFirstName: formData['legalFirstName'],
      legalMiddleName: formData['legalMiddleName'],
      legalLastName: formData['legalLastName'],
      dateOfBirth: formData['legalDateOfBirth'],
      socialSecurityNumber: formData['legalSocialSecurityNumber'],
    }
    let addressInfo = {
      primaryPhone: formData['primaryPhone'],
      secondaryPhone: formData['secondaryPhone'],
      additionalPhone: formData['additionalPhone'],
      country: formData['country'],
      st1: formData['st1'],
      st2: formData['st2'],
      faxNumber: formData['faxNumber'],
      city: formData['city'],
      state: formData['state'],
      zipCode: formData['zipCode'],
      email: formData['email'],
      webaddress: formData['webaddress'],
    }
    let body = {
      firstName: formData['firstName'],
      lastName: formData['lastName'],
      phone: formData['phone'],
      email: formData['email'],
      emergencyContact: emergencyContact,
      payrollInfo: payrollInfo,
      addressInfo: addressInfo,
    }
    axios
      .patch('caregivers/updateProfile/' + props.id, body)
      .then((res) => {
        if (res.status == 201) {
          toast.success('Caregiver updated successfully')
        }
      })
      .catch((e) => toast.error("Couldn't update caregiver"))
  }
  const buttons = [
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit', disabled: !hasPermission(userPermissions, permissions.care_giver_profile_personalInformation_update)},
  ]

  return (
    <>
      <FormWrapper
        title='Personal Information'
        content={
          <DynamicForm
            fields={uiElementsList}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        }
      ></FormWrapper>
    </>
  )
}
