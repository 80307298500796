export const columns = ['Name', 'Dates', 'Time', 'Role', 'TotalHours']
export const columnDataMapping = {
  Name: 'name',
  Dates: 'dates',
  Time: 'timeFormat',
  Role: 'role',
  TotalHours: 'totalHours',
}
export const columnSorting = {
  Name: 'name',
  Dates: 'dates',
  Time: 'timeFormat',
  Role: 'role',
  TotalHours: 'totalHours',
}
