import React, {useEffect, useRef, useState} from 'react'
import io from 'socket.io-client'
import { ToastContainer,} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Popper,
  Button
} from '@mui/material'
import avatar from './avatar2.png'
import {makeStyles} from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import SendIcon from './send.png'
import axios from 'axios'
import {useSelector} from 'react-redux'
import Logo from '../chat_system/logo.png'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import EditNoteIcon from '@mui/icons-material/EditNote'
import dayjs from 'dayjs'
import DescriptionDialog from './components/DescriptionModal'
import SearchModal from './components/SearchModal'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import PdfViewer from './components/PdfViewer'
import './index.css'
import {GroupServices} from './services'
import NewMessage from './components/NewMessage'
import { RootState } from '../../../setup';


const useStyles = makeStyles((theme) => ({
  logo: {
    width: '70px',
    height: '60px',
    marginLeft: '1rem',
  },
  avatar: {
    flexShrink: 0,
    marginRight: '0.5rem',
  },

  paper1: {
    backgroundColor: '#f5f8fa !important',
    height: '622px',
    overflowY: 'auto',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0) !important',
    '& .MuiPaper-root': {
      backgroundColor: '#f5f8fa',
      boxShadow:
        '0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)',
    },
  },
  listItem: {
    padding: 0,
  },
  contactName: {
    marginLeft: '1rem',
    marginTop: '5px',
  },
  paper: {
    height: '612px',
    borderTopRightRadius: '33px',
    borderTopLeftRadius: '33px',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0) !important',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  chatMessages: {
    overflowY: 'auto',
    padding: '1rem',
    display: 'grid',
    scrollBehavior: 'smooth',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    
    marginBottom: '0.5rem', 
  },
  iAmTheReciever:{
    alignItems:"flex-start",
  },
  iAmTheSender:{
    alignItems:"end",
  },
  contactAvatar: {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    marginRight: '0.5rem', 
  },
  receivedTail: {
    position: 'relative',
    borderRadius: '7.5px 7.5px 7.5px ',
    backgroundColor: '#F0F0F0', 
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    padding: '10px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-7px',
      left: '-14px', 
      width: '20px',
      height: '20px',
      backgroundColor: '#F0F0F0', 
      clipPath: 'polygon(325% 0, 0 50%, 100% 100%)', 
      boxShadow: 'rgba(0, 0, 0, -1.84) 0px 1px 4px',
    },
  },

  sentTail: {
    position: 'relative',
    borderRadius: '7.5px 7.5px 7.5px 7.5px',
    backgroundColor: '#0391FD',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    padding: '10px',
    color: 'white',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0px',
      right: '-13px',
      width: '20px',
      height: '20px',
      backgroundColor: '#0391FD',
      clipPath: 'polygon(0 0, 86% 0%, 0 100%)',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', 
    },
  },
  messageContentTo: {
    marginBottom: '8px',
    marginLeft:"8px",
    padding: '0.2rem 0.5rem',
    backgroundColor: '#F0F0F0',
    boxShadow: 'rgb(0 0 0 / 0%) 0px 1px 4px',
    maxWidth:"500px",
    height:"auto",
    display: 'inline-block',
    wordWrap: 'break-word', 
    whiteSpace: 'normal',
    overflow: 'hidden', 
  },
  messageBox:{
    display:"flex",
    flexDirection: "column",
    alignItems: "flexEnd",
  },
  messageContent: {
    padding: '0.7rem 0.5rem',
    marginBottom: '8px',
    minWidth:"50px",
    backgroundColor: "#0391FD", 
    color: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    position: 'relative',
    maxWidth: "500px",
    wordWrap: 'break-word', 
    whiteSpace: 'normal',
    overflow: 'hidden', 
  },
  contactMessage: {
    marginBottom: '0.25rem', 
    alignSelf: 'flex-start',
    fontSize:"0.9rem",
    paddingRight:"5px",
  },
  userMessage: {
    marginLeft: 'auto',
    marginBottom: '0.25rem',
    fontSize:"0.9rem",
    paddingRight:"5px",
  },
  senderName: {
    fontWeight: '600',
    fontSize: '0.8rem', 
    marginBottom: '0.2rem',
  },
  receiverMessageContent: {
    color: 'black',
  },
  timestamp: {
    fontSize: '0.8rem',
    color: '#3f4254',
    alignSelf: 'flex-end',
    whiteSpace:"nowrap",
  },
  
  inputField: {
     maxHeight: '150px',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '13px',
    overflow: 'hidden',
    outline: 'none',
    border: 'none',
    padding: '10px 20px',
    resize: 'none', 
    lineHeight: '1.5',
  },
  sendButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    borderRadius: '50%',
    marginLeft: '0.5rem',
  },
  stickyHeader: {
    backgroundColor: '#fff',
    padding: '0.5rem',
    borderBottom: '2px solid ghostwhite',
    borderTopRightRadius: '26px',
    borderTopLeftRadius: '26px',
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  contactInfoInternal: {
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    borderBottomRightRadius: '15px',
    padding: '4px',
  },
  textareaContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    alignItems: 'flex-start', 
    backgroundColor: 'white',
    borderBottomRightRadius: '15px',
    borderBottomLeftRadius: '15px',
    padding: '10px',
    overflow: 'hidden', 
  },
  accordionRoot: {
    boxShadow: 'none',
    '&:before': {
      content: 'none',
    },
    margin: 0,
  },
  accordionSummary: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    '& .MuiAccordionSummary-expandIcon': {
      color: '#333',
    },
    '& .Mui-expanded ': {
      marginBottom: 0,
      borderBottom: 'none',
    },
    '& .MuiButtonBase-root': {
      maxHeight: '3rem',
    },
  },
  accordionDetails: {
    display: 'block',
    padding: 0,
  },
}))
const Chat = () => {
  const auth = useSelector((state: RootState) => state.auth)
  const user = auth.user
  const creatorId = user._id;
  const creatorFullName = `${user.firstName} ${user.lastName}`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [contacts, setContacts] = useState([])
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [expanded, setExpanded] = useState('')
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [groupList, setGroupList] = useState([])
  const [socket, setSocket] = useState(null)
  const chatContainerRef = useRef(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const companyid : string= auth.user?.company_code
  const [openDescriptionModal, setOpenDescriptionModal] = useState('')
  const [searchModalAction, setSearchModalAction] = useState('')
  const [groupId, setGroupId] = useState(null)
  const textareaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
const [hasMore, setHasMore] = useState(false);
const messagesPerPage = 100;
const [isLoadingMessages, setIsLoadingMessages] = useState(true);
const [isContactsLoaded, setIsContactsLoaded] = useState(false);
const [isGroupsLoaded, setIsGroupsLoaded] = useState(false);
const [isContactsLoading, setIsContactsLoading] = useState(false);
const [isGroupsLoading, setIsGroupsLoading] = useState(false);
const [isMessagesLoading, setIsMessagesLoading] = useState(false);

useEffect(() => {
  if (page === 1 && chatContainerRef.current) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
}, [messages])

useEffect(() => {
  if (chatContainerRef.current && shouldScrollToBottom) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    setShouldScrollToBottom(false);
  }
}, [shouldScrollToBottom]); 
const loadMoreMessages = async () => {
  if (chatContainerRef.current) {
    const chatContainer = chatContainerRef.current;
    const previousScrollHeight = chatContainer.scrollHeight;
    const currentScrollTop = chatContainer.scrollTop;

    setIsLoadingMessages(true);

    const newPage = page + 1;
    setPage(newPage);
    await fetchMessages(newPage);

    const newScrollHeight = chatContainer.scrollHeight;
    chatContainer.scrollTop = newScrollHeight - previousScrollHeight + currentScrollTop;

    setIsLoadingMessages(false);
  }
};
  const handleScroll = () => {
    if (
      chatContainerRef.current &&
      chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop ===
        chatContainerRef.current.clientHeight
    ) {
      setShouldScrollToBottom(true)
    } else {
      setShouldScrollToBottom(false)
    }
  }

  const MAX_MESSAGE_LENGTH = 500;
  const [tooltipText, setTooltipText] = useState('');
  const handleMessageChange = (e) => {
    const limitedMessage = e.target.value.substring(0, MAX_MESSAGE_LENGTH);
    setMessage(limitedMessage);

    const remainingChars = MAX_MESSAGE_LENGTH - limitedMessage.length;

    
    if (remainingChars <= 20 && remainingChars > 0) {
      setTooltipText(`${remainingChars} characters remaining out of ${MAX_MESSAGE_LENGTH}`);
    } else if (remainingChars < 1) {
      setTooltipText('Message cannot exceed 500 characters');
    } else {
      setTooltipText(''); 
    }
    
    if (textareaRef.current) {
      const maxHeight = 100; 
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, maxHeight)}px`;
    }
  };
  const fetchMessages = async (currentPage = page) => {
    let url;
    if (selectedContact) {
      url = `messages/${selectedContact?.userId}?page=${currentPage}&limit=${messagesPerPage}`;
    } else if (selectedGroup) {
      url = `groupMessages/${selectedGroup?._id}?page=${currentPage}&limit=${messagesPerPage}`;
    }

    try {
      setIsLoadingMessages(true);
      const { data } = await axios.get(url);
      if (data.length < messagesPerPage) {
        setHasMore(false);
      }
      if (currentPage === 1) {
        setMessages(data);
      } else {
        setMessages(prevMessages => [...data, ...prevMessages]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setIsLoadingMessages(false);
    }
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (panel === 'contacts' && !isContactsLoaded) {
      setIsContactsLoading(true); 
      axios.get('messages/userChatListing')
        .then(({ data }) => {
          if (data?.length) {
            setContacts(data); 
            setIsContactsLoaded(true); 
          }
        })
        .finally(() => {
          setIsContactsLoading(false);
        });
    } else if (panel === 'groupChat' && !isGroupsLoaded) {
      setIsGroupsLoading(true);
      axios.get('groups/list')
        .then(({ data }) => {
          if (data?.length) {
            setGroupList(data);
            setIsGroupsLoaded(true);
          }
        })
        .finally(() => {
          setIsGroupsLoading(false);
        });
    }
    setExpanded(isExpanded ? panel : false);
  };
  
  useEffect(() => {
    let socketInstance = null
    if (process.env.NODE_ENV === 'development') {
      socketInstance = io(`${process.env.REACT_APP_DEV_API_URL}`, {
        auth: {token: auth.accessToken},
        path: '/api/chats',
        query:{
          providerCode: localStorage.getItem('providerCode')
        }
      })
    } else {
      socketInstance = io(`${process.env.REACT_APP_API_URL}`, {
        auth: {token: auth.accessToken},
        path: '/api/chats',
        query:{
          providerCode: localStorage.getItem('providerCode')
        }
      })
    }

    setSocket(() => socketInstance)
    return () => {
      // Clean up the socket connection when the component unmounts
      if (socketInstance) {
        socketInstance.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on('NEW_MESSAGE_RECIEVED', (data) => {
        console.log({messages, data})
        setMessages((prevMessages) => [...prevMessages, data.message])
      })
      socket.on('REFRESH_CONVERSATION_LIST', () => {
        axios.get('messages/userChatListing').then(({data}) => {
          if (data?.length) {
            setContacts(data)
          }
        })
      })
    }
  }, [socket])

  useEffect(() => {
    axios.get('user/users', {
      params: {
        companyid: companyid,
      }
    }).then(({data}) => {
      setUsers(data)
    })
  }, [])

  useEffect(() => {
    if (inputValue.trim() === '') {
      setFilteredUsers(users)
    } else {
      const filtered = users.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
        return fullName.includes(inputValue.toLowerCase())
      })
      setFilteredUsers(filtered)
    }
  }, [inputValue, users])
  useEffect(() => {
    if (selectedContact || selectedGroup) {
      setMessages([]);
      setPage(1);
      setHasMore(true);
      fetchMessages(1);
    }
  }, [selectedContact, selectedGroup]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    
  if (isLoading || (message.trim() === '' && (!selectedContact || !selectedGroup))) {
    return;
  }
    setIsLoading(true); 
    try{
      if (selectedContact) {
        const response = await axios.post('/messages', {
          text: message,
          senderId: user._id,
          receiverId: selectedContact.userId,
        })
        if (!response?.data?._id) {
          console.log('Something went wrong', response)
          return
        }
  
        setMessage('')
        const newMessageFormat = {
          id: response.data._id,
          text: response.data.text,
          filePath: response.data.filePath,
          isAttachment: response.data.isAttachment,
          senderId: response.data.senderId,
          receiverId: response.data.receiverId,
          createdAt: response.data.createdAt,
        }
        setMessages([...messages, {...newMessageFormat}])
        if (socket) {
          socket.emit('SEND_NEW_MESSAGE', {to: selectedContact.userId, message: newMessageFormat})
          socket.emit('REFRESH_CONVERSATION_LIST', selectedContact.userId)
        }
        axios.get('messages/userChatListing').then(({data}) => {
          if (data?.length) {
            setContacts(data)
          }
        })
        setShouldScrollToBottom(true);
      } else if (message.trim() !== '' && selectedGroup) {
        const response = await axios.post('/groupMessages', {
          text: message,
          groupId: selectedGroup._id,
          senderId: creatorId,
        })
        if (!response?.data?._id) {
          console.log('Something went wrong', response)
          setIsLoading(false);
          return
        }
        const groupMessageFormat = {
          id: response.data._id,
          text: response.data.text,
          filePath: response.data.filePath,
          isAttachment: response.data.isAttachment,
          senderId: response.data.senderId,
          groupId: response.data.groupId,
          timestamp: response.data.createdAt,
        }
        setMessage('')
        setMessages([
          ...messages,
          {
            ...groupMessageFormat,
          },
        ])
        axios.get('groups/list').then(({data}) => {
          if (data?.length) {
            setGroupList(data)
          }
        })
        console.log({groupMessageFormat})
        socket.emit('GROUP_MESSAGE', {
          groupId: groupMessageFormat.groupId,
          message: groupMessageFormat,
        })
        setShouldScrollToBottom(true);
      }
    }
     catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); 
    } finally {
      setIsLoading(false); 
    }
  }

  const handleContactClick = (contact) => {
    setIsMessagesLoading(true);
    setSelectedContact(contact);
    setSelectedGroup(null);
  
    const updatedContacts = contacts.map((c) => {
      if (c.userId=== contact.userId) {
        return { ...c, unreadCount: 0 };
      } else {
        return c;
      }
    });
    setContacts(updatedContacts);
  };

  const handleGroupClick = (group) => {
    console.log(group, selectedContact)
    if (selectedGroup) socket.emit('LEAVE_GROUP', selectedGroup._id)
    setSelectedGroup(group)
    socket.emit('JOIN_GROUP', group._id)
    setSelectedContact(null)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e)
    }
  }

  const classes = useStyles()

  const handleFileInput = async (event: any) => {
    try {
      if (event.target.files) {
        // const file = event.target.files[0]
        // const fileName = file?.name.toLowerCase()

        const newFormData = new FormData()
        newFormData.append('file', event.target.files[0])
        let response = null
        if (selectedContact) {
          newFormData.append('receiverId', selectedContact.userId)
          newFormData.append('senderId', user._id)
          response = await GroupServices.postAttactmentMessageContact(newFormData)
          const newMessageFormat = {
            id: response.data._id,
            text: response.data.text,
            filePath: response.data.filePath,
            isAttachment: response.data.isAttachment,
            senderId: response.data.senderId,
            receiverId: response.data.receiverId,
            createdAt: response.data.createdAt,
          }
          socket.emit('SEND_NEW_MESSAGE', {to: selectedContact.userId, message: newMessageFormat})
        } else {
          newFormData.append('groupId', selectedGroup._id)
          response = await GroupServices.postAttactmentMessageGroup(newFormData)

          socket.emit('GROUP_MESSAGE', {groupId: response.data.groupId, message: response.data})
          console.log('response group', response)
        }
        const {data} = response
        const dummyMessages = [...messages]
        dummyMessages.push(data)

        setMessages(dummyMessages)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleImageDownload = (filePath) => {
    const link = document.createElement('a')
    link.href = filePath // Optional: Set the downloaded file's name
    link.target = '_blank'
    link.click()
    document.body.removeChild(link)
  }
  return (
    <Container>
      {openDescriptionModal.length ? (
        <DescriptionDialog
          setGroupId={setGroupId}
          groupId={groupId}
          type={openDescriptionModal}
          open={true}
          setOpen={setOpenDescriptionModal}
          setGroupList={setGroupList}
          creatorId={creatorId} 
          creatorName={creatorFullName}
        />
      ) : null}
      {searchModalAction.length ? (
        <SearchModal
          open={true}
          setResponse={searchModalAction === 'group' ? handleGroupClick : handleContactClick}
          type={searchModalAction}
          data={searchModalAction === 'group' ? groupList : contacts}
          setOpen={setSearchModalAction}
        />
      ) : null}
      {isMenuOpen ? <NewMessage 
        users={users} 
        open={isMenuOpen} 
        setOpen={setIsMenuOpen} 
        setMessage={setMessage} 
        messages={messages}
        setMessages={setMessages}
        socket={socket}
        setContacts={setContacts}
        setSelectedContact={setSelectedContact}
        /> 
        : null
      }
      <Grid container>
        <Grid
          item
          xs={3}
          sx={{
            borderRadius: '4px',
            backgroundColor: 'white',
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            zIndex:1,
          }}
        >
          <Paper elevation={3} square className={classes.paper1} sx={{backgroundColor: '#ffffff !important'}}>
            <Accordion
              expanded={expanded === 'contacts'}
              onChange={handleAccordionChange('contacts')}
              sx={{
                backgroundColor: '#ffffff !important',
                '&.Mui-expanded': {
                  margin: '0px',
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <>
                    <div>
                      <IconButton onClick={(e) => setSearchModalAction('contact')}
                         disabled={isContactsLoading}
                        >
                        <SearchIcon />
                      </IconButton>
                      <IconButton onClick={(e) => setIsMenuOpen(true)} disabled={isContactsLoading}>
                        <AddIcon />
                      </IconButton>
                    </div>
                    <IconButton
                      style={{
                        transform: expanded === 'contacts' ? 'rotate(180deg)' : 'none',
                      }}
                      disabled={isContactsLoading}
                    >
                       <ArrowDropDownIcon />
                    </IconButton>
                  </>
                }
                aria-controls='contacts-content'
                id='contacts-header'
                sx={{
                  '&.Mui-expanded': {
                    margin: '0px',
                  },
                }}
              >
                <Typography variant='h6'>Contacts</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: '34vh',
                  padding: '2px',
                  overflow: 'hidden',
                }}
              >
                 {isContactsLoading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                  </Box>
                ) : (
                <List
                  sx={{
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                    position: 'relative',
                    border: '0.01rem solid ghostwhite',
                    borderRadius: '30px',
                  }}
                >
                  {contacts.map((contact, index) => (
                    <ListItem
                      key={index}
                      selected={contact === selectedContact}
                      onClick={() => handleContactClick(contact)}
                      sx={{
                        height: '3.4rem',
                        '&.Mui-selected': {
                          backgroundColor: 'rgb(117 171 225 / 27%)',
                          borderRadius: '30px',
                          marginTop: '3px',
                        },
                        cursor: 'pointer',
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={contact.userName}
                          src={contact.profileImg || avatar}
                          style={{height: '2rem', width: '2rem'}}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={contact.userName}
                        secondary={
                          contact?.lastMessage?.text?.length > 20
                            ? `${contact?.lastMessage?.text.substring(0, 20)}...`
                            : contact?.lastMessage?.text
                        }
                      />
                      {contact?.unreadCount > 0 && !(contact?._id !== selectedContact?._id) && (
                        <div
                          style={{
                            backgroundColor: 'rgb(120 149 195)',
                            color: 'white',
                            borderRadius: '50%',
                            width: '16px',
                            height: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '10px',
                            marginTop: '20px',
                          }}
                        >
                          {contact?.unreadCount}
                        </div>
                      )}
                    </ListItem>
                  ))}
                </List>
                  )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'groupChat'}
              onChange={handleAccordionChange('groupChat')}
              sx={{
                backgroundColor: '#ffffff !important',
                '&.Mui-expanded': {
                  margin: '0px',
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <div>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        setSearchModalAction('group')
                      }}
                      disabled={isGroupsLoading}
                    >
                      <SearchIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        setOpenDescriptionModal('add')
                      }}
                      disabled={isGroupsLoading}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      style={{
                        transform: expanded === 'groupChat' ? 'rotate(180deg)' : 'none',
                      }}
                      disabled={isGroupsLoading}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </div>
                }
                aria-controls='contacts-content'
                id='contacts-header'
                sx={{
                  '&.Mui-expanded': {
                    margin: '0px',
                  },
                }}
              >
                <Typography variant='h6'>Group Chat</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  height: '275px',
                  padding: '2px',
                }}
              >
                 {isGroupsLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </Box>
              ) : (
                <List
                  sx={{
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                    position: 'relative',
                    border: '0.01rem solid ghostwhite',
                    borderRadius: '30px',
                  }}
                >
                  {groupList.map((group, index) => {
                    return (
                      <ListItem
                        key={index}
                        selected={group === selectedGroup}
                        onClick={() => handleGroupClick(group)}
                        sx={{
                          height: '3.4rem',
                          '&.Mui-selected': {
                            backgroundColor: 'rgb(117 171 225 / 27%)',
                            borderRadius: '30px',
                          },
                          cursor: 'pointer',
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={group.name}
                            src={group.image || avatar}
                            style={{height: '2rem', width: '2rem'}}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={group.name} />
                        {!group?.unreadCount && selectedGroup?._id !== group?._id && (
                          <div
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              borderRadius: '50%',
                              width: '8px',
                              height: '8px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '10px',
                              marginTop: '20px',
                            }}
                          >
                            {group?.unreadCount}
                          </div>
                        )}
                      </ListItem>
                    )
                  })}
                </List>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper  className={classes.paper}>
            {selectedContact || selectedGroup ? (
              <>
                <Box className={classes.stickyHeader}>
                  <Box className={classes.contactInfo}>
                    <Box className={classes.contactInfoInternal}>
                      <Avatar
                        alt={selectedContact ? selectedContact.userName : selectedGroup?.name}
                        src={selectedContact ? selectedContact.profileImg : selectedGroup?.image}
                        className={classes.contactAvatar}
                      />
                      <h5 className={classes.contactName}>
                        {selectedContact ? selectedContact.userName : selectedGroup?.name}
                      </h5>
                    </Box>
                    <EditNoteIcon
                      style={{fontSize: 30, cursor: 'pointer'}}
                      onClick={() => {
                        setGroupId(selectedGroup._id)
                        setOpenDescriptionModal('edit')
                      }}
                    />
                  </Box>
                </Box>

                <Box
                ref={chatContainerRef}
                onScroll={handleScroll}
                className={classes.chatMessages}
              >
                {hasMore && (
                  <Button onClick={loadMoreMessages} fullWidth disabled={isLoadingMessages}>
                    {isLoadingMessages ? <CircularProgress size={24} /> : 'Load More Messages'}
                  </Button>
                )}
                  {messages.map((msg, index) => {
                    let amITheSender = false
                    if(selectedGroup){
                      amITheSender = user._id === msg.senderId?._id
                    }else{
                      amITheSender = user._id === msg.senderId
                    }
                    const sender = users.find((user) => user._id === msg.senderId);
                    return (
                      <Box key={index} className={`${classes.messageContainer} ${amITheSender?classes.iAmTheSender:classes.iAmTheReciever}`}>
                        <Box className='d-flex' style={{alignItems:"baseline"}}>
                          {!amITheSender?
                            <>
                             {selectedContact &&
                            (selectedContact && amITheSender ? (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  style={{width: '2rem', height: '2rem'}}
                                  alt={selectedContact.userName}
                                  src={selectedContact.profileImg}
                                />
                              </Box>
                            ) : (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  alt='User Avatar'
                                  style={{width: '2rem', height: '2rem'}}
                                  src={avatar}
                                />
                              </Box>
                            ))}
                          {/* this part is when someone sent us the message*/}
                          {selectedGroup &&
                            (msg?.senderId === user?._id ? (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  style={{width: '2rem', height: '2rem'}}
                                  alt={msg.senderId.firstName}
                                  src={msg.senderId.profileImg}
                                />
                              </Box>
                            ) : (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  alt='User Avatar'
                                  style={{width: '2rem', height: '2rem',}}
                                  src={avatar}
                                />
                              </Box>
                            ))}
                            </>:null
                          }
                         <Box
                          className={`${amITheSender ? `${classes.messageContent} ${classes.sentTail}` : `${classes.messageContentTo} ${classes.receivedTail}`}`}
                        >
                           {!amITheSender && (
                          <Typography
                            variant='caption'
                            style={{ fontWeight: '600', lineHeight: '0', fontFamily: 'inherit' }}
                          >
                            {selectedGroup ? `${msg.senderId?.firstName} ${msg.senderId?.lastName}`: `${sender?.firstName} ${sender?.lastName}`}
                          </Typography>
                            )}
                          <Box
                            className={`${
                              amITheSender ? classes.contactMessage : classes.userMessage
                            } ${classes.messageBox}`}
                          >
                            <ShowMessage
                              msg={msg}
                              handleImageDownload={handleImageDownload}
                            />
                            <Typography variant='caption' className={classes.timestamp}>
                              {dayjs(msg.createdAt).format("MM/DD LT")}
                            </Typography>
                          </Box>
                        </Box>
                          {amITheSender?
                            <>
                             {/* {selectedContact &&
                            (selectedContact && amITheSender ? (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  style={{width: '2rem', height: '2rem',marginLeft:"0.5rem"}}
                                  alt={selectedContact.userName}
                                  src={selectedContact.profileImg}
                                />
                              </Box>
                            ) : (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  alt='User Avatar'
                                  style={{width: '2rem', height: '2rem',marginLeft:"0.5rem"}}
                                  src={avatar}
                                />
                              </Box>
                            ))} */}
                          {/* {selectedGroup &&
                            (msg?.senderId?._id === user?._id ? (

                              <>
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  style={{width: '2rem', height: '2rem',marginLeft:"0.5rem"}}
                                  alt={msg.senderId.firstName}
                                  src={msg.senderId.profileImg}
                                />
                              </Box>
                              </>
                            ) : (
                              <Box className={classes.contactAvatar}>
                                <Avatar
                                  alt='User Avatar'
                                  style={{width: '2rem', height: '2rem',marginLeft:"0.5rem"}}
                                  src={avatar}
                                />
                              </Box>

                            ))} */}
                            </>:null
                          }
                        </Box>
                        {/* <Typography variant='caption' className={classes.timestamp}>
                        {dayjs(msg.createdAt).format('h:mm A')}
                        </Typography> */}
                      </Box>

                    )
                  })
}
                </Box>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '60vh',
                }}
              >
                <img src={Logo} alt='Trumemos Logo' style={{maxWidth: '50vh'}} />
                
              </div>
            )}
          </Paper>
          <div>
          <ToastContainer />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container style={{display:"flex", justifyContent:"center",
             alignItems:"center",
            }}>
              {(selectedContact || selectedGroup) && (
                <>
                  <Grid item xs={11}>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={5}
                      ref={textareaRef}
                      placeholder='Type your message'
                      value={message}
                      onChange={handleMessageChange}
                      onKeyDown={handleKeyDown}
                      className={classes.inputField}
                      disabled={isLoading}
                      style={{
                        width: '100%',
                        flex: 1,
                        marginRight: 'auto',
                        borderBottomRightRadius: '13px',
                        marginTop: '4px',
                        overflow: 'auto',
                        outline: 'none',
                        border: 'none',
                        padding: '5px 20px',
                      }}
                      onFocus={(event) => setAnchorEl(event.currentTarget)}
                      onBlur={() => setAnchorEl(null)} 
                    />
                    {/* Code of popup chat area */}
                   {tooltipText && (
                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top-start">
                      <Paper style={{
                       padding: '5px 10px',
                       backgroundColor: '#333',
                       color: '#fff',
                       borderRadius: '4px', 
                       boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                       fontSize: '12px',
                       maxWidth: '300px',
                      }}>
                        {tooltipText}
                      </Paper>
                    </Popper>
                  )}
                  </Grid>
                  <input
                    type='file'
                    onChange={handleFileInput}
                    id='myFileInput'
                    accept='.jpg, .jpeg, .png, .pdf'
                    style={{display: 'none'}}
                  />
                  <Grid item xs={1}>
                    <Box style={{display: 'flex', paddingRight: '5px'}}>
                      <IconButton
                        color='primary'
                        onClick={() => {
                          document.getElementById('myFileInput').click()
                        }}
                      >
                        <AttachFileIcon />
                      </IconButton>
                      <IconButton type='submit' className={classes.sendButton}  disabled={isLoading}>
                      {isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <img
                        style={{ height: '1.6rem', width: '1.6rem' }}
                        src={SendIcon}
                        alt='Send'
                      />
                    )}
                      </IconButton>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Chat

const ShowMessage = ({msg,handleImageDownload}) =>{

  if(msg?.isAttachment){
    if(msg.filePath.endsWith('.pdf')){
      return <PdfViewer
      name={msg.filePath ? msg.filePath.split(/[\\\/]/).pop() : ''}
      filePath={`${
        process.env.REACT_APP_DEV_API_URL.split('/api')[0]
      }/${msg.filePath}`}
    />
    }else{
     return <div role="button" tabIndex={0} onClick={() =>
      handleImageDownload(
        `${process.env.REACT_APP_DEV_API_URL.split('/api')[0]}/${
          msg.filePath
        }`
      )
    }>
      <img
        style={{width: '500px', height: '200px', cursor: 'pointer'}}
      alt={msg.filePath}
        src={`${process.env.REACT_APP_DEV_API_URL.split('/api')[0]}/${
          msg.filePath
        }`}
      />
      <br />
    </div>
    }
  }
 return <Box className='mx-1' sx={{paddingRight:"10px"}}>{msg.text}</Box>
}