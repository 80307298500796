import Modal from '@mui/material/Modal'
import { Typography, Box} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {
  Button,
  TextField,
  Autocomplete,
} from '@mui/material'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {LocalizationProvider,} from '@mui/x-date-pickers'
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import { RootState } from '../../../setup'

const selectAuth = (state: RootState) => state.auth
const OpenShiftModal = ({
  title,
  showOpenShiftModal,
  onCancel,
  day,
  startTime,
  endTime,
  startDate,
  endDate,
  changeDataSwitch,
}) => {
  const [rolesToShow, setRolesToShow] = useState([])
  const [error, setError] = useState(false)
  const [shiftStartTime, setShiftStartTime] = useState<Date | undefined>(undefined)
  const [shiftEndTime, setShiftEndTime] = useState<Date | undefined>(undefined)
  const [roleId, setRoleId] = useState()
  const [dataSwitch, setDataSwitch] = useState(false)
  const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      flex: 1,
      marginRight: '8px',
    },
  }))
  useEffect(() => {
    setShiftStartTime(startTime)
    setShiftEndTime(endTime)
  }, [rolesToShow])

  const createShift = async () => {
    if (!roleId) {
      setError(true)
      return
    }
    axios
      .post('/openshifts/create', {
        day: day.toLowerCase(),
        timeStart: shiftStartTime,
        timeEnd: shiftEndTime,
        isPreferred: true,
        roleId,
        company_code: auth?.user?.company_code,
        timeStartTimeStamp: shiftStartTime,
        timeEndTimeStamp: shiftEndTime,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        if (res.status === 201) {
          toast.success('Open Shift added successfully')
          onCancel()
          changeDataSwitch()
          setRoleId(undefined)
        }
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't add schedule`)
      })
  }

  const handleRoleChange = async (role) => {
    setRoleId(role.value)
  }

  const [buttons, setButtons] = useState([
    {
      label: 'CREATE SHIFT',
      variant: 'contained',
      type: 'button',
      onClick: () => createShift(),
    },
  ])

  const auth = useSelector(selectAuth)

  useEffect(() => {
    axios
      .get(`/roleManagement/get`)
      .then(async (res1) => {
        if (res1.status === 200) {
          let roles = []
          res1.data.map((eachRole) => {
            roles.push({
              label: eachRole.name,
              value: eachRole._id,
            })
          })
          setRolesToShow(roles)
        }
      })
      .catch((e) => console.error(e))
  }, [showOpenShiftModal])

  const style = {
    position: 'absolute' as 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFFFFF',
    boxShadow: 24,
    p: 4,
    outline: 0,
  }

  const classes = useStyles()

  return (
    <Modal
      sx={{position: 'absolute', top: '15rem'}}
      onClose={onCancel}
      open={showOpenShiftModal}
      aria-labelledby='add-modal-title'
      aria-describedby='add-modal-description'
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='h3'
            sx={{paddingTop: '2rem', paddingBottom: '1.5rem'}}
            fontSize={20}
            fontWeight={'bold'}
          >
            {title}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '0.5rem',
              marginLeft: '1rem',
            }}
            color='primary'
          >
            {day}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1.5rem',
          }}
        >
          {/* roles drop down */}
          <div key='showRoleBar' className={classes.fieldContainer}>
            <Autocomplete
              disablePortal
              size='medium'
              options={rolesToShow || []}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              sx={{maxWidth: 965}}
              forcePopupIcon={true}
              renderInput={(params) => (
                <TextField
                  error={error}
                  helperText={'Please select a role'}
                  required={true}
                  {...params}
                  label={'Role'}
                />
              )}
              onChange={(event, newValue) => {
                setError(false)
                handleRoleChange(newValue)
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1.5rem',
          }}
        >
          <div key='timeStart' className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker']}>
                <DemoItem label='Start Time'>
                  <TimePicker
                    minutesStep={5}
                    value={dayjs(startTime)}
                    onChange={(e) => {
                      const time = dayjs(e).toDate()
                      setShiftStartTime(time)
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        disabled: true,
                        style: {width: '100%'},
                      },
                    }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div key='timeEnd' className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker']}>
                <DemoItem label='End Time'>
                  <TimePicker
                    minutesStep={5}
                    value={dayjs(endTime)}
                    onChange={(e) => {
                      const time = dayjs(e).toDate()
                      setShiftEndTime(time)
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        disabled: true,
                        style: {width: '100%'},
                      },
                    }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {buttons.map((button: any, index: React.Key | null | undefined) => (
            <Button
              key={index}
              type={button.type}
              sx={{padding: '4px', margin: '4px'}}
              style={{height: '5rem', width: '14rem'}}
              variant={button.variant}
              onClick={() => createShift()}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </Box>
    </Modal>
  )
}
export default OpenShiftModal
