import Roles from '../pages/roles/Roles'
import RolesListing from '../pages/roles/RolesListing'
import TestingPage from '../pages/testingpage/Testing'
import BehaviorIndex from '../pages/admin/care/behavior/BehaviorIndex'

/*Billing Module Routes*/
import ProfessionalClaim from '../pages/billing/professionalclaim/ProfessionalClaim'
import AgencyBasedReportsIndex from '../pages/billing/professionalclaim/agency-based-reports/AgencyBasedReportsIndex'
import BillingConversionIndex from '../pages/billing/professionalclaim/billing-conversion/BillingConversionIndex'
import ClaimTemplateIndex from '../pages/billing/professionalclaim/claim-template/ClaimTemplateIndex'
import ExcelUploadIndex from '../pages/billing/professionalclaim/excel-upload/ExcelUploadIndex'
import BillingDataIndex from '../pages/billing/professionalclaim/billing-data/BillingDataIndex'

import CareAdmin from '../pages/admin/care/AdminCare'
import ContactHeader from '../pages/admin/care/contact/ContactIndex'
import IndividualIntakeHeader from '../pages/admin/care/individual-intake/IndividualIntakeIndex'
import SubMenuIndex from '../pages/admin/care/subMenuSection/SubMenuIndex'
import ListHealth from '../pages/admin/care/HealthProfile/List'
import EditHealth from '../pages/admin/care/HealthProfile/Edit'
import ListAllergy from '../pages/admin/care/AllergyProfile/List'
import NewAllergy from '../pages/admin/care/AllergyProfile/New'
import ListDiagnosis from '../pages/admin/care/Diagnostics List/List'
import EditDiagnosis from '../pages/admin/care/Diagnostics List/Edit'
import NewDiagnosis from '../pages/admin/care/Diagnostics List/New'

import GeneralAdmin from '../pages/admin/general/General'
/*Admin Module Routes*/
import BillingAdmin from '../pages/admin/billing/AdminBilling'

/*Service Directory Modules Routes*/

import AddUser from '../pages/users/AddUser'
import Users from '../pages/users/Users'
import Clients from '../pages/clients/clients'
import ClientDetails from '../pages/clients/ClientDetails'

// import ReusableTable from '../reusable-components/table'
import EditUser from '../pages/users/EditUser'

import NewCareGiver from '../pages/caregiver/General/Add/NewCareGiver'
import EditCareGiver from '../pages/caregiver/General/Edit/EditCareGiver'

import {CaregiverProfilePersonalInformation} from '../pages/caregiver/General/Profiles/PersonalInformation/CareGiverProfilePersonalInformation'
import {CaregiverProfileDocuments} from '../pages/caregiver/General/Profiles/Documents/CaregiverDocuments'
import AddCaregiverProfileDocuments from '../pages/caregiver/General/Profiles/Documents/AddCaregiverProfileDocuments'
import {CaregiversList} from '../pages/caregiver/General/List/CaregiversList'
import {CaregiverProfileJobDetails} from '../pages/caregiver/General/Profiles/JobDetails/CaregiverProfileJobDetails'
import EditAllergy from '../pages/admin/care/AllergyProfile/Edit'
import CaregiverSchedules from '../pages/caregiver/Schedules/TeamSchedules/CaregiverSchedules'
import MySchedules from '../pages/caregiver/Schedules/MySchdeules/MySchedules'
import AllSchedules from '../pages/caregiver/Schedules/AllSchedules/AllSchedules'
import Chat from '../pages/chat_system/chat'
import CaregiverTimesheets from '../pages/caregiver/TimeSheets/CaregiverTimeSheets/CaregiverTimesheets'
import TimesheetsSummaries from '../pages/caregiver/TimeSheets/TimeSheetsSummaries/TimesheetsSummaries'
import CompanyTimesheet from '../pages/caregiver/TimeSheets/CompanyTimesheets/CompanyTimesheet'
import TimeOffs from '../pages/caregiver/Schedules/TimeOffs/TimeOffs'
import Notes from '../pages/caregiver/Notes/Notes'
import ScoringMethods from '../pages/caregiver/ScoringMethods/ScoringMethods'
import NewNote from '../pages/caregiver/Notes/Add/NewNote'
import NewNoteEntries from '../pages/caregiver/NotesEntries/Add/NewNoteEntries'
import NoteEntries from '../pages/caregiver/NotesEntries/NoteEntries'
import EditNoteEntry from '../pages/caregiver/NotesEntries/Edit/EditNoteEntry'
import EditNote from '../pages/caregiver/Notes/Edit/EditNote'
import UploadFiles from '../pages/importClients'
import NotificationManager from '../pages/notification-manager/NotificationManager'

export const allRoutes = [
  {path: '/import/data', component: UploadFiles, permission: 'USER_MANAGEMENT.ROLES.VIEW'},
  // {path: '/reusable-component', component: ReusableTable, permission: true},
  {path: '/testingpage', component: TestingPage, permission: true},
  {path: '/admin/billing', component: BillingAdmin, permission: true},
  {path: '/chat', component: Chat, permission: true},
  {path: '/notification-manager', component: NotificationManager, permission: true},
  // {
  //     path: '/admin/billing/attendance-type/list/:id',
  //     exact: true,
  //     component: EditAT,
  //     permission: 'ADMIN.BILLING.ATTENDANCE_TYPE.UPDATE',
  // },
  // { path: '/admin/billing/attendance-type/list', exact: true, component: ListAT, permission: 'ADMIN.BILLING.ATTENDANCE_TYPE.VIEW' },
  // { path: '/admin/billing/carrier', component: CarrierIndex, permission: true },
  // {
  //     path: '/admin/billing/cms-1500-print-calibration',
  //     component: CMS1500PrintCalibrationIndex,
  //     permission: 'ADMIN.BILLING.CARRIER.VIEW',
  // },
  // { path: '/admin/billing/cost-center-type', component: CostCenterTypeIndex, permission: 'ADMIN.BILLING.COST_CENTER_TYPE.VIEW' },

  // {
  //     path: '/admin/billing/custom-attendance-pdf-configuration',
  //     component: CustomAttendancePDFConfigurationIndex,
  //     permission: true,
  // },
  {
    path: '/admin/care/individual-intake',
    component: IndividualIntakeHeader,
    permission: 'ADMIN.CARE.CLIENTS.VIEW',
  },
  {path: '/admin/care/contact', component: ContactHeader, permission: 'ADMIN.CARE.CONTACT.VIEW'},

  {
    path: '/admin/care/health/list/:clientID',
    component: EditHealth,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.HEALTH_DATA.UPDATE',
  },
  {
    path: '/admin/care/health/list',
    component: ListHealth,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.VIEW',
  },
  {
    path: '/admin/care/allergy/list/:clientID/:id',
    component: EditAllergy,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.UPDATE',
  },
  {
    path: '/admin/care/allergy/list/:clientID',
    component: ListAllergy,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.VIEW',
  },
  {
    path: '/admin/care/allergy/new/:clientID',
    component: NewAllergy,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.CREATE',
  },
  {
    path: '/admin/care/diagnosis/list/:clientID/:id',
    component: EditDiagnosis,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.UPDATE',
  },
  {
    path: '/admin/care/diagnosis/list/:clientID',
    component: ListDiagnosis,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.VIEW',
  },
  {
    path: '/admin/care/diagnosis/new/:clientID',
    component: NewDiagnosis,
    permission: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.CREATE',
  },

  // { path: '/admin/care/client-logs', component: ClientLogsHeader, permission: true },

  // {
  //     path: '/admin/billing/professional-template-group',
  //     component: ProfessionalTemplateGroupIndex,
  //     permission: true,
  // },
  // { path: '/admin/billing/taxonomy-code', component: TaxonomyCodeIndex, permission: true },
  // {
  //     path: '/admin/billing/unit-calculation-rule',
  //     component: UnitCalculationRuleIndex,
  //     permission: true,
  // },
  {path: '/admin/care', component: CareAdmin, permission: 'ADMIN.CARE.VIEW'},
  {path: '/admin/care/behavior', component: BehaviorIndex, permission: true},

  // {
  //     path: '/admin/care/isp-program-scoring-method',
  //     component: ISPProgramScoringMethodIndex,
  //     permission: true,
  // },
  {path: '/admin/care/sub', component: SubMenuIndex, permission: true},
  {
    path: '/admin/care',
    exact: true,
    to: '/care/individual-intake/list',
    permission: 'ADMIN.CARE.VIEW',
  }, 

  {path: '/admin/general', exact: true, component: GeneralAdmin, permission: true},
  
  {path: '/billing/professionalclaim', component: ProfessionalClaim, permission: true},
  {
    path: '/professionalclaim/agency-based-reports',
    component: AgencyBasedReportsIndex,
    permission: true,
  },
  {path: '/professionalclaim/billing-data', component: BillingDataIndex, permission: true},
  {
    path: '/professionalclaim/billing-conversion',
    component: BillingConversionIndex,
    permission: true,
  },
  {path: '/professionalclaim/claim-template', component: ClaimTemplateIndex, permission: true},
  {path: '/professionalclaim/excel-upload', component: ExcelUploadIndex, permission: true},

  {path: '/clients', component: Clients, permission: true},
  {path: '/clientdetails', component: ClientDetails, permission: true},
  {
    path: '/caregivers/scoringMethods',
    component: ScoringMethods,
    permission: true,
  }
]
export const allroutesHandler = () => {
}
