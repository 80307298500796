import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface DescriptionCode {
    _id: String;
    company_code: String | undefined;
    accountNumber: String;
    serviceDescription: String;
    serviceCode: String;
    unitMeasure: String;
    rateHistory: String;
    rateHistoryValue: Array<object>;
    unitRate: String;
    useService: String;
    revenueCode: String;
    rateCode: String;
    rateValue: String;
    locatorCode: String;
    useLocator: String;
    claimType: String;
    procedureModifier: String;
    primaryDiagnosis: String;
    diagnosisPointer: String;
    electronicUnit: String;
    procedureQualifier: String;
    addService: String;
    validationTotalBillable: String;
    unitOfMeasure: String;
    roundingAlgorithem: String;
    allowedIncreament: String
}

export const GetDescriptionCode = createAsyncThunk(
    'des/get/',
    async ({ companyid, page, pageSize, sortBy, sortDir, searchTerm }: { companyid?: string, page?: number, pageSize?: number, sortBy?: string, sortDir?: string, searchTerm?: string  }) => {
        try {
            if (page && pageSize) {
                const response = await axios.get(`des/get/${companyid}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data
                }
            } else {
                const response = await axios.get(`des/get/${companyid}`)
                return {
                    data: response.data
                }
            }

        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneDescriptionCode = createAsyncThunk(
    'des/getOne/',
    async (descId: string | undefined) => {
        try {
            const response = await axios.get('des/getOne/' + descId)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addDescriptionCode = createAsyncThunk(
    'des/create',
    async (data: DescriptionCode) => {
        try {
            const response = await axios.post('des/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteDescriptionCode = createAsyncThunk(
    'des/delete/',
    async (data: String) => {
        try {
            const response = await axios.delete('des/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const updateDescriptionCode = createAsyncThunk(
    'des/update',
    async (data: DescriptionCode) => {
        try {
            const response = await axios.put('des/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)