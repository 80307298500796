// _ means that its a sub section
// for example userManagement_roles means that  roles is a subsection of userManagement
// menu bar and main section permissions constants
export const permissions = {
    userManagement: 'USER_MANAGEMENT.VIEW',
    userManagement_userManagement: 'USER_MANAGEMENT.USER.VIEW',
    admin: 'ADMIN.VIEW',
    admin_general: 'ADMIN.ADMIN_GENERAL.VIEW',
    admin_care: 'ADMIN.CARE.VIEW',
    admin_personalFinance: 'ADMIN.PERSONAL_FINANCE.VIEW',
    admin_billing: 'ADMIN.ADMIN_BILLING.VIEW',
    admin_dataDrivenOutcome: 'ADMIN.DATA_DRIVEN_OUTCOME.VIEW',
    admin_serviceDirectory: 'ADMIN.SERVICE_DIRECTORY.VIEW',
    agency: 'AGENCY.VIEW',
    agency_agencyWideDocument: 'AGENCY.AGENCY_WIDE_DOCUMENT.VIEW',
    billing: 'BILLING.VIEW',
    billing_attendance: 'BILLING.ATTENDANCE.VIEW',
    billing_professionalClaim: 'BILLING.PROFESSIONAL_CLAIM.VIEW',
    //USER__MANAGEMENT
    userManagement_roles: 'USER_MANAGEMENT.ROLES.VIEW',
    userManagement_roles_create: 'USER_MANAGEMENT.ROLES.CREATE',
    userManagement_roles_update: 'USER_MANAGEMENT.ROLES.UPDATE',
    userManagement_roles_delete: 'USER_MANAGEMENT.ROLES.DELETE',
    /// USER__MANAGEMENT_____USER
    user_management_user_view: 'USER_MANAGEMENT.USER.VIEW',
    user_management_user_create: 'USER_MANAGEMENT.USER.CREATE',
    user_management_user_update: 'USER_MANAGEMENT.USER.UPDATE',
    user_management_user_delete: 'USER_MANAGEMENT.USER.DELETE',
    //    ADMIN______BILLING
    // FUNDING__SOURCE
    admin_billing_fundingSource_view: 'ADMIN.ADMIN_BILLING.FUNDING_SOURCE.VIEW',
    admin_billing_fundingSource_create: 'ADMIN.ADMIN_BILLING.FUNDING_SOURCE.CREATE',
    admin_billing_fundingSource_update: 'ADMIN.ADMIN_BILLING.FUNDING_SOURCE.UPDATE',
    admin_billing_fundingSource_delete: 'ADMIN.ADMIN_BILLING.FUNDING_SOURCE.DELETE',
    //  PAYER
    admin_billing_payer_view: 'ADMIN.ADMIN_BILLING.PAYERS_LIST.VIEW',
    admin_billing_payer_create: 'ADMIN.ADMIN_BILLING.PAYERS_LIST.CREATE',
    admin_billing_payer_update: 'ADMIN.ADMIN_BILLING.PAYERS_LIST.UPDATE',
    admin_billing_payer_delete: 'ADMIN.ADMIN_BILLING.PAYERS_LIST.DELETE',
    //  BILLING__PROVIDER
    admin_billing_billingProvider_view: 'ADMIN.ADMIN_BILLING.BILLING_PROVIDER.VIEW',
    admin_billing_billingProvider_create: 'ADMIN.ADMIN_BILLING.BILLING_PROVIDER.CREATE',
    admin_billing_billingProvider_update: 'ADMIN.ADMIN_BILLING.BILLING_PROVIDER.UPDATE',
    admin_billing_billingProvider_delete: 'ADMIN.ADMIN_BILLING.BILLING_PROVIDER.DELETE',
    //  LEAVE RULES
    admin_billing_leaveRule_create: 'ADMIN.ADMIN_BILLING.LEAVE_RULE.CREATE',
    admin_billing_leaveRule_update: 'ADMIN.ADMIN_BILLING.LEAVE_RULE.UPDATE',
    admin_billing_leaveRule_delete: 'ADMIN.ADMIN_BILLING.LEAVE_RULE.DELETE',
    //  DESCRIPTION__CODE
    admin_billing_descriptionCode_view: 'ADMIN.ADMIN_BILLING.DESCRIPTION_CODE.VIEW',
    admin_billing_descriptionCode_create: 'ADMIN.ADMIN_BILLING.DESCRIPTION_CODE.CREATE',
    admin_billing_descriptionCode_update: 'ADMIN.ADMIN_BILLING.DESCRIPTION_CODE.UPDATE',
    admin_billing_descriptionCode_delete: 'ADMIN.ADMIN_BILLING.DESCRIPTION_CODE.DELETE',
    //  ATTENDANCE__TYPE
    admin_billing_attendanceType_view: 'ADMIN.ADMIN_BILLING.ATTENDANCE_TYPE.VIEW',
    admin_billing_attendanceType_create: 'ADMIN.ADMIN_BILLING.ATTENDANCE_TYPE.CREATE',
    admin_billing_attendanceType_update: 'ADMIN.ADMIN_BILLING.ATTENDANCE_TYPE.UPDATE',
    admin_billing_attendanceType_delete: 'ADMIN.ADMIN_BILLING.ATTENDANCE_TYPE.DELETE',
    //  ADMIN____CARE
    // CARE 
    admin_care_create : 'ADMIN.CARE.CLIENTS.CREATE',
    admin_care_update : 'ADMIN.CARE.CLIENTS.UPDATE',
    admin_care_delete : 'ADMIN.CARE.CLIENTS.DELETE',
    admin_care_search : 'ADMIN.CARE.CLIENTS.SEARCH',
    admin_care_view : 'ADMIN.CARE.CLIENTS.VIEW',
    admin_care_export : 'ADMIN.CARE.CLIENTS.EXPORT',
    //  CLIENT__LOGS
    admin_care_clientLog_create: 'ADMIN.CARE.CLIENT_LOGS.CREATE',
    admin_care_clientLog_view: 'ADMIN.CARE.CLIENT_LOGS.VIEW',
    admin_care_clientLog_update: 'ADMIN.CARE.CLIENT_LOGS.UPDATE',
    admin_care_clientLog_delete: 'ADMIN.CARE.CLIENT_LOGS.DELETE',
    admin_care_clientLog_search: 'ADMIN.CARE.CLIENT_LOGS.SEARCH',
    // HEALTH____PROFILE
    admin_care_healthProfile_view: 'ADMIN.CARE.HEALTH_PROFILE.VIEW',
    // HEALTH___DATA
    admin_care_healthData_view: 'ADMIN.CARE.HEALTH_PROFILE.HEALTH_DATA.VIEW',
    admin_care_healthData_update: 'ADMIN.CARE.HEALTH_PROFILE.HEALTH_DATA.UPDATE',
    // ALLERGY__PROFILE
    admin_care_allergy_view: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.VIEW',
    admin_care_allergy_update: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.UPDATE',
    admin_care_allergy_create: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.CREATE',
    admin_care_allergy_delete: 'ADMIN.CARE.HEALTH_PROFILE.ALLERGY_PROFILE.DELETE',
    // DIAGNOSIS
    admin_care_diagnosis_view: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.VIEW',
    admin_care_diagnosis_update: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.UPDATE',
    admin_care_diagnosis_create: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.CREATE',
    admin_care_diagnosis_delete: 'ADMIN.CARE.HEALTH_PROFILE.DIAGNOSIS.DELETE',

    //   ADMIN___INSURANCE
    admin_care_insurance_view: 'ADMIN.CARE.INSURANCE.VIEW',
    admin_care_insurance_update: 'ADMIN.CARE.INSURANCE.UPDATE',
    //   ADMIN___ENROLLMENT
    admin_care_enrollment_view: 'ADMIN.CARE.ENROLLMENT.VIEW',
    // ENROLLMENT__BY__INDIVIDUAL
    admin_care_enrollment_byIndividual_update: 'ADMIN.CARE.ENROLLMENT.BY_INDIVIDUAL.UPDATE',
    admin_care_enrollment_byIndividual_create: 'ADMIN.CARE.ENROLLMENT.BY_INDIVIDUAL.CREATE',
    admin_care_enrollment_byIndividual_delete: 'ADMIN.CARE.ENROLLMENT.BY_INDIVIDUAL.DELETE',
    // ENROLLMENT__BY__PROGRAM
    admin_care_enrollment_byProgram_update: 'ADMIN.CARE.ENROLLMENT.BY_PROGRAM.UPDATE',
    admin_care_enrollment_byProgram_create: 'ADMIN.CARE.ENROLLMENT.BY_PROGRAM.CREATE',
    admin_care_enrollment_byProgram_delete: 'ADMIN.CARE.ENROLLMENT.BY_PROGRAM.DELETE',
    // ADMIN___CARE___CONTACT
    admin_care_contact_view: 'ADMIN.CARE.CONTACT.UPDATE',
    admin_care_contact_create: 'ADMIN.CARE.CONTACT.CREATE',
    admin_care_contact_delete: 'ADMIN.CARE.CONTACT.DELETE',
    //   ADMIN______GENERAL
    //  PROGRAMS
    admin_billing_program_create: 'ADMIN.ADMIN_GENERAL.PROGRAM.CREATE',
    admin_billing_program_update: 'ADMIN.ADMIN_GENERAL.PROGRAM.UPDATE',
    admin_billing_program_delete: 'ADMIN.ADMIN_GENERAL.PROGRAM.DELETE',
    //  SITE
    admin_billing_site_create: 'ADMIN.ADMIN_GENERAL.SITE.CREATE',
    admin_billing_site_update: 'ADMIN.ADMIN_GENERAL.SITE.UPDATE',
    admin_billing_site_delete: 'ADMIN.ADMIN_GENERAL.SITE.DELETE',
    //  SHARED__CONTACT
    admin_billing_sharedContact_create: 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.CREATE',
    admin_billing_sharedContact_update: 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.UPDATE',
    admin_billing_sharedContact_delete: 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.DELETE',
    admin_billing_sharedContactType_create : 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.CREATE',
    admin_billing_sharedContactType_update: 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.UPDATE',
    admin_billingsharedContactType_delete: 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.DELETE',
    admin_billing_sharedContactType_view : 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.VIEW',
    admin_billing_sharedContactInfo_view: 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.SHARED_CONTACT_LIST.VIEW',
    ///// MAIN_____BILLING
    // ___PROFESSIONAL__CLAIM
    billing_professionalClaim_create: "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.CREATE",
    billing_professionalClaim_update: "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.UPDATE",
    billing_professionalClaim_delete: "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.DELETE",
    /////////  ____SERVICE__AUTHORIZATION
    billing_professionalClaim_serviceAuthorization_create:'BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.CREATE',
    billing_professionalClaim_serviceAuthorization_view:'BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.VIEW',
    billing_professionalClaim_serviceAuthorization_search:'BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.SEARCH',
    billing_professionalClaim_serviceAuthorization_update:'BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.UPDATE',
    billing_professionalClaim_serviceAuthorization_delete:'BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.DELETE',
    ///// _____ATTENDANCE
    billing_attendance_new_view:'BILLING.ATTENDANCE.NEW.VIEW',
    billing_attendance_search_view:'BILLING.ATTENDANCE.SEARCH.VIEW',
    billing_attendance_summary_view:'BILLING.ATTENDANCE.SUMMARY.VIEW',
    /////// CARE__GIVER____SECTION
    care_giver_view : 'CARE_GIVER.GENERAL.VIEW',
    care_giver_create : 'CARE_GIVER.GENERAL.CREATE',
    care_giver_delete : 'CARE_GIVER.GENERAL.DELETE',
    care_giver_general_view : 'CARE_GIVER.GENERAL.UPDATE.VIEW',
    // CARE_GIVER____JOB_DETAILS
    care_giver_profile_jobDetails_view : 'CARE_GIVER.GENERAL.UPDATE.JOB_DETAILS.VIEW',
    care_giver_profile_jobDetails_update : 'CARE_GIVER.GENERAL.UPDATE.JOB_DETAILS.UPDATE',
    care_giver_profile_personalInformation_view : 'CARE_GIVER.GENERAL.UPDATE.PERSONAL_INFORMATION.VIEW',
    care_giver_profile_personalInformation_update : 'CARE_GIVER.GENERAL.UPDATE.PERSONAL_INFORMATION.UPDATE',
    care_giver_profile_documents_view : 'CARE_GIVER.GENERAL.UPDATE.DOCUMENTS.VIEW',
    care_giver_profile_documents_create : 'CARE_GIVER.GENERAL.UPDATE.DOCUMENTS.CREATE',
    care_giver_profile_documents_delete : 'CARE_GIVER.GENERAL.UPDATE.DOCUMENTS.DELETE',
    // CARE_GIVER_____SCHEDULES
    care_giver_schedules_view : 'CARE_GIVER.SCHEDULES.VIEW',
    // SCHEDULES_______SCHEDULE
    care_giver_schedules_schedules_view : 'CARE_GIVER.SCHEDULES.SCHEDULE.VIEW',
    care_giver_schedules_schedules_publish : 'CARE_GIVER.SCHEDULES.SCHEDULE.PUBLISH',
    care_giver_schedules_schedules_create : 'CARE_GIVER.SCHEDULES.SCHEDULE.CREATE',
    care_giver_schedules_schedules_update : 'CARE_GIVER.SCHEDULES.SCHEDULE.UPDATE',
    care_giver_schedules_schedules_delete : 'CARE_GIVER.SCHEDULES.SCHEDULE.DELETE',
    // SCHEDULES_______TEAM_AVAILABILITY
    care_giver_schedules_teamAvailability_view : 'CARE_GIVER.SCHEDULES.TEAM_AVAILABILITY.VIEW',
    // SCHEDULES________MY_AVAILABILITY
    care_giver_schedules_myAvailability_view : 'CARE_GIVER.SCHEDULES.MY_AVAILABILITY.VIEW',
    care_giver_schedules_myAvailability_create : 'CARE_GIVER.SCHEDULES.MY_AVAILABILITY.CREATE',
    care_giver_schedules_myAvailability_update : 'CARE_GIVER.SCHEDULES.MY_AVAILABILITY.UPDATE',
    care_giver_schedules_myAvailability_delete : 'CARE_GIVER.SCHEDULES.MY_AVAILABILITY.DELETE',
    /// SCHEDULES_____TIME_OFF_REQUEST
    care_giver_schedules_timeOff_view : 'CARE_GIVER.SCHEDULES.TIME_OFF.VIEW',
    care_giver_schedules_timeOff_approve : 'CARE_GIVER.SCHEDULES.TIME_OFF.APPROVE',
    care_giver_schedules_timeOff_reject : 'CARE_GIVER.SCHEDULES.TIME_OFF.REJECT',
    /// TIME_____SHEETS
    care_giver_time_sheets_view : 'CARE_GIVER.TIME_SHEETS.VIEW',
    care_giver_time_sheets_timeSheets_view : 'CARE_GIVER.TIME_SHEETS.TIME_SHEET.VIEW',
    care_giver_time_sheets_companySheet_view : 'CARE_GIVER.TIME_SHEETS.COMPANY_TIMESHEET.VIEW',
    care_giver_time_sheets_summaries_view : 'CARE_GIVER.TIME_SHEETS.SUMMARIES.VIEW',
    /// MEDICATION (MARS)
    mars_individual_medication_view : 'MARS.INDIVIDUAL_MEDICATION.VIEW',
    /// MEDICATION (MARS)   CONTINUED___MEDICATION
    mars_individual_medication_continued_medication_view : 'MARS.INDIVIDUAL_MEDICATION.CONTINUED_MEDICATION.VIEW',
    mars_individual_medication_continued_medication_create : 'MARS.INDIVIDUAL_MEDICATION.CONTINUED_MEDICATION.CREATE',
    mars_individual_medication_continued_medication_create_entry : 'MARS.INDIVIDUAL_MEDICATION.CONTINUED_MEDICATION.CREATE_ENTRY',
    mars_individual_medication_continued_medication_delete : 'MARS.INDIVIDUAL_MEDICATION.CONTINUED_MEDICATION.DELETE',
    mars_individual_medication_continued_medication_search : 'MARS.INDIVIDUAL_MEDICATION.CONTINUED_MEDICATION.SEARCH',
    mars_individual_medication_continued_medication_generate_pdf : 'MARS.INDIVIDUAL_MEDICATION.CONTINUED_MEDICATION.GENERATE_PDF',
    /// MEDICATION (MARS)  DISCONTINUED___MEDICATION
    mars_individual_medication_discontinued_medication_view: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.VIEW",
    mars_individual_medication_discontinued_medication_search: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.SEARCH",
    mars_individual_medication_discontinued_medication_generate_pdf: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.GENERATE_PDF",
    /// MEDICATION (MARS)  MISSED___MEDICATION
    mars_individual_medication_missed_medication_view: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.VIEW",
    mars_individual_medication_missed_medication_filter: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.FILTER",
    mars_individual_medication_missed_medication_search: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.SEARCH",
    mars_individual_medication_missed_medication_delete: "MARS.INDIVIDUAL_MEDICATION.DISCONTINUED_MEDICATION.DELETE",
    /// CAREGIVER_____NOTES
    care_giver_notes_view : 'NOTES.VIEW',
    care_giver_notes_note_view: 'NOTES.NOTE.VIEW',
    care_giver_notes_note_search: 'NOTES.NOTE.SEARCH',
    care_giver_notes_note_create: 'NOTES.NOTE.CREATE',
    care_giver_notes_note_update: 'NOTES.NOTE.UPDATE',
    care_giver_notes_note_create_entry: 'NOTES.NOTE.CREATE_ENTRY',
    care_giver_notes_note_delete: 'NOTES.NOTE.DELETE',
    /// CAREGIVER___NOTES___ENTRIES
    care_giver_notes_note_entries_create: 'NOTES.NOTES_ENTRIES.CREATE',
    care_giver_notes_note_entries_download: 'NOTES.NOTES_ENTRIES.DOWNLOAD',
    care_giver_notes_note_entries_update: 'NOTES.NOTES_ENTRIES.UPDATE',
    care_giver_notes_note_entries_search: 'NOTES.NOTES_ENTRIES.SEARCH',
    /// CAREGIVER___NOTES___SCORING___METHOD
    care_giver_notes_scoring_method_create: 'NOTES.SCORING_METHOD.CREATE',
    /// BEHAVIOR
    behavior_view : 'BEHAVIOR.VIEW',
    behavior_client_behavior_view : 'BEHAVIOR.CLIENT_BEHAVIOR.VIEW',
    behavior_client_behavior_create : 'BEHAVIOR.CLIENT_BEHAVIOR.CREATE',
    behavior_client_behavior_update : 'BEHAVIOR.CLIENT_BEHAVIOR.UPDATE',
    behavior_client_behavior_create_entry : 'BEHAVIOR.CLIENT_BEHAVIOR.CREATE_ENTRY',
    behavior_client_behavior_search : 'BEHAVIOR.CLIENT_BEHAVIOR.SEARCH',
    behavior_client_behavior_delete : 'BEHAVIOR.CLIENT_BEHAVIOR.DELETE',
    /// BEHAVIOR___TARGET
    behavior_target_view : 'BEHAVIOR.TARGET_BEHAVIOR.VIEW',
    behavior_target_update : 'BEHAVIOR.TARGET_BEHAVIOR.UPDATE',
    behavior_target_create : 'BEHAVIOR.TARGET_BEHAVIOR.CREATE',
    behavior_target_search : 'BEHAVIOR.TARGET_BEHAVIOR.SEARCH',
    /// BEHAVIOR___REPLACEMENT
    behavior_replacement_view : 'BEHAVIOR.REPLACEMENT_BEHAVIOR.VIEW',
    behavior_replacement_update : 'BEHAVIOR.REPLACEMENT_BEHAVIOR.UPDATE',
    behavior_replacement_create : 'BEHAVIOR.REPLACEMENT_BEHAVIOR.CREATE',
    behavior_replacement_search : 'BEHAVIOR.REPLACEMENT_BEHAVIOR.SEARCH',
    /// BEHAVIOR___EXPORT
    behavior_export_view : 'BEHAVIOR.EXPORT_BEHAVIOR.VIEW',
    behavior_export_export : 'BEHAVIOR.EXPORT_BEHAVIOR.EXPORT',
}



