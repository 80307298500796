import { useEffect, useState } from 'react'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { CLIENT_LOG_FORM_INPUTS } from '../../CARE_CONSTANTS'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getOneIncidentReport, updateIncidentReport } from '../store/action'
import { useHistory, useParams } from 'react-router-dom'
const selectAuth = (state) => state?.auth;


const ListOfClientLogsEdit = () => {
    const [clientLogData, setClientlogData] = useState({})
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;
    const parameters = useParams()
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                const resultTlog = await dispatch(getOneIncidentReport(parameters.id))
                const tempTlog = resultTlog.payload?.data?.data
                setClientlogData(tempTlog)
            }
        }
        fetchData()
    }, [dispatch, parameters.id])
    const handleSubmitForm = async (formData) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        const tempObj = {
            company_code: companyid,
            _id: formData._id,
            type: formData.type,
            summary: formData.summary,
            description: formData.description,
            reportedOn: formData.reportedOn,
            intensityLevel: formData.intensityLevel,
            clientID: formData.clientID,
        }
        try {
            if (formData.reportedOn <= currentDateFormatted) {
                const response = await dispatch(updateIncidentReport(tempObj))
                if(response.payload.status === 400 && response.payload.success === false) {
                    toast.error(response.payload.message)
                } else if (response?.payload.success) {
                    toast.success(response?.payload.message || 'Success', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                    history.push('/admin/care/incident-report/list')
                } else {
                    toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                }
            } else {
                toast.error('Reported Date should not be later than today')
            }

        } catch (err) {
            console.log(err)
        }
    }

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <div className="card">
            <FormWrapper
                title='Edit Incident Report'
                content={
                    <DynamicForm
                        fields={CLIENT_LOG_FORM_INPUTS}
                        data={clientLogData}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                }
            />

        </div>
    )
}

export default ListOfClientLogsEdit