import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import { LIST_OF_SHARED_CONTACTS_COLUMNS_DATA_MAPPING, LIST_OF_SHARED_CONTACTS_COLUMNS, LIST_OF_SHARED_CONTACTS_SORTING_COLUMNS } from '../../GENERAL_TABLE_CONSTANTS';
import { useHistory } from 'react-router-dom';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import BackButton from '../../../../../reusable-components/Buttons/BackButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: RootState) => state.auth;

const SCList = () => {
    const [sharedContacts, setSharedContacts] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState<any>('')
    const [filteredData, setFilteredData] = useState<any[]>([])
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;

    useEffect(() => {
            const companyid = auth.user?.company_code
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try{
            const response = await axios.get(`/shared/get/${companyid}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`)
            const { data, currentPage, totalCounts } = response.data;
            setSharedContacts(data)
            setFilteredData(data)
            setQueryData((prevState)=> {
                return {
                    ...prevState,
                    currentPage,
                    totalCounts,
                }
            })
            }catch(err){
                console.log(err)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

    const onView = ({ _id }: any) => {
        history.push({
            pathname: `/admin/general/shared-contact/info/${_id}`
        })
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Shared Contact List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setSearchTerm={setSearchTerm}
                            setQueryData={setQueryData}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        <BackButton />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_OF_SHARED_CONTACTS_COLUMNS}
                            data={filteredData}
                            canView={hasPermission(userPermissions, permissions.admin_billing_sharedContactInfo_view)}
                            canDelete={false}
                            columnDataMapping={LIST_OF_SHARED_CONTACTS_COLUMNS_DATA_MAPPING}
                            onView={onView}
                            sortingColumns={LIST_OF_SHARED_CONTACTS_SORTING_COLUMNS}
                            title='Are you sure you want to delete this Shared Contact?'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default SCList