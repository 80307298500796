import { useEffect, useState } from 'react'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { EDIT_CAREGIVER_FORM_INPUTS } from '../../CAREGIVER_FORM_CONSTANTS'
import { Button, Box, Typography } from '@mui/material'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
export default function EditCareGiver() {
    const [formData, setFormData] = useState({})
    const [rolesToShow, setRolesToShow] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [uiElementsList, setUiElementsList] = useState([])
    const [updateRolesFields, setUpdateRolesFields] = useState(false)
    const [error, setError] = useState([false])
    const [i, setI] = useState(0)
    const [clickCount, setClickCount] = useState(5)
    const [addClick, setAddClick] = useState(0)
    const [inputArr, setInputArr] = useState([
        {
            roleId: '',
            wage: '',
            wageUnit: '',
            effectiveDate: null,
        },
    ])
    const history = useHistory()
    const authUser = useSelector((state: { auth: any }) => state.auth)
    const userPermissions = authUser.user.roleId;
    const params : { id: string }= useParams()

    useEffect(() => {
        axios
            .get('roleManagement/get')
            .then((res) => {
                let tempRoles = []
                res.data.map((obj) => {
                    tempRoles.push({
                        label: obj.name,
                        value: obj._id,
                    })
                })
                setRolesToShow(tempRoles)
            })
            .catch((err) => {
                console.error(err)
            })

        axios.get(`caregivers/getById/${params.id}`).then((res) => {
            let tempArrForRoles = []
            let tempArrForInputArr = []
            setI(res.data.careGiver.role.length - 1)
            setUserRoles(res.data.careGiver.role)
            let prevFormData = formData
            res.data.careGiver.roles.map((eachRole, index) => {
                let wageKeyName = `${index}Wage`
                let wageKeyUnitName = `${index}wageUnit`
                let roleKeyName = `${index}Role`

                prevFormData[wageKeyName] = eachRole.wage
                prevFormData[wageKeyUnitName] = eachRole.wageUnit
                prevFormData[roleKeyName] = eachRole.roleId

                tempArrForInputArr.push({
                    roleId: res.data.careGiver.role[index]['_id'],
                    wage: eachRole.wage,
                    wageUnit: eachRole.wageUnit,
                    effectiveDate: eachRole?.effectiveDate,
                })
            })
            prevFormData['firstName'] = res?.data?.careGiver?.firstName
            prevFormData['lastName'] = res?.data?.careGiver?.lastName
            prevFormData['phone'] = res?.data?.careGiver?.phone
            prevFormData['email'] = res?.data?.careGiver?.email
            prevFormData['accessLevel'] = res?.data?.careGiver?.access?.accessLevel
            prevFormData['role'] = tempArrForRoles
            setFormData(prevFormData)
            setInputArr(tempArrForInputArr)
            setUpdateRolesFields((prevState) => {
                return !prevState
            })
        })
    }, [])

    useEffect(() => {
        const tempElementsList = EDIT_CAREGIVER_FORM_INPUTS(rolesToShow)
        setUiElementsList(tempElementsList)
        setUpdateRolesFields((prevstate) => {
            return !prevstate
        })
    }, [rolesToShow])

    useEffect(() => {
        let tempClickCount = clickCount
        let tempUIArray = uiElementsList
        userRoles.map((eachRole, index) => {
            tempClickCount = tempClickCount + 1
            const roleFieldToAdd = {
                index: index,
                label: 'Search Role',
                name: `${index}Role`,
                type: 'select',
                options: rolesToShow,
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleName,
                error: error[index],
                helperText: error[index] ? 'Please select a different role' : '',
            }
            const wageFieldToAdd = {
                index: index,
                label: 'Wage',
                name: `${index}Wage`,
                type: 'number',
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWage,
            }
            const wageUnitFieldToAdd = {
                index: index,
                label: `Unit`,
                name: `${index}wageUnit`,
                type: 'select',
                options: [
                    {
                        label: '/hour',
                        value: 'hour',
                    },
                   
                ],
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWageUnit,
            }
            const deleteButton = {
                index: index,
                label: 'Delete',
                variant: 'outlined',
                type: 'button',
                onClick: onRemoveRoleButtonClick,
                rowNumber: tempClickCount,
            }
            if (index == 0) {
                tempUIArray.push(roleFieldToAdd)
                tempUIArray.push(wageFieldToAdd)
                tempUIArray.push(wageUnitFieldToAdd)
            } else {
                tempUIArray.push(roleFieldToAdd)
                tempUIArray.push(wageFieldToAdd)
                tempUIArray.push(wageUnitFieldToAdd)
                tempUIArray.push(deleteButton)
            }
        })
        setClickCount(tempClickCount)
        setUiElementsList(tempUIArray)
    }, [updateRolesFields])

    useEffect(() => {
        if (addClick != 0) {
            let tempI = i + 1
            let tempClickCount = clickCount + 1
            const roleFieldToAdd = {
                index: tempI,
                label: 'Search Role',
                name: `role${tempClickCount - 5}`,
                value: inputArr[tempI]['name'],
                type: 'select',
                options: rolesToShow,
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleName,
            }
            const wageFieldToAdd = {
                index: tempI,
                label: 'Wage',
                name: `${tempI}Wage`,
                value: inputArr[tempI]['wage'],
                type: 'number',
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWage,
            }
            const wageUnitFieldToAdd = {
                index: tempI,
                label: `Unit`,
                value: inputArr[tempI]['wageUnit'],
                name: `${tempI}wageUnit`,
                type: 'select',
                options: [
                    {
                        label: '/hour',
                        value: 'hour',
                    },
                ],
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWageUnit,
            }
            const deleteButton = {
                index: tempI,
                label: 'Delete',
                variant: 'outlined',
                type: 'button',
                onClick:() => onRemoveRoleButtonClick(tempI),
                rowNumber: tempClickCount,
            }
            setUiElementsList((prevElements) => [
                ...prevElements,
                roleFieldToAdd,
                wageFieldToAdd,
                wageUnitFieldToAdd,
                deleteButton,
            ])
            setI(tempI)
            setClickCount(tempClickCount)
        }
    }, [addClick])

    const onChangeRoleName = (value, index) => {
        let prevState = inputArr
        prevState[index]['roleId'] = value
        setInputArr(prevState)
    }

    const onChangeRoleWage = (name, value, checked, type, index) => {
        let prevState = inputArr
        prevState[index]['wage'] = value
        setInputArr(prevState)
    }

    const onChangeRoleWageUnit = (value, index) => {
        let prevState = inputArr
        prevState[index]['wageUnit'] = value
        setInputArr(prevState)
    }

    const onRemoveRoleButtonClick = (index) => {
        setInputArr(prevInputArr => {
            const updatedArr = [...prevInputArr];
            updatedArr[index].roleId = '';
            return updatedArr;
        });
        setUiElementsList(prevState => {
            return prevState.filter(item => !item.index || (item.index && item.index !== index));
        });
    };

    const handleSubmitForm = async (formData) => {
        let { firstName, lastName, phone, accessLevel } = formData
        inputArr.map((eachElem) => {
            if (eachElem.effectiveDate == null) delete eachElem.effectiveDate
        })
        axios
            .patch('caregivers/update', {
                id: params.id,
                firstName,
                lastName,
                phone,
                access: {
                    accessLevel,
                },
                roles: inputArr.filter((item) => item.roleId != ''),
            })
            .then((res) => {
                if (res.status == 201) {
                    toast.success('Caregiver updated successfully')
                }
            })
            .catch((e) => toast.error("Couldn't update caregiver"))
    }

    const onAddAnotherRoleClick = () => {
        let prevInputArr = inputArr
        prevInputArr.push({ roleId: '', wage: '', wageUnit: '', effectiveDate: null })
        setInputArr(prevInputArr)
        setAddClick(addClick + 1)
    }

    const buttons = [
        {
            label: 'Add Another Role',
            variant: 'outlined',
            type: 'button',
            onClick: onAddAnotherRoleClick,
        },
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
        // {
        //     label: 'Edit Profile',
        //     variant: 'outlined',
        //     type: 'button',
        //     onClick: () => {
        //         history.push(`/caregivers/profile/jobDetails/${params.id}`)
        //     },
        // },
    ]

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '1.5rem',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                        }}
                    >
                        <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
                            Edit Caregivers
                        </Typography>
                       {
                        hasPermission(userPermissions, permissions.care_giver_profile_jobDetails_view) || 
                        hasPermission(userPermissions, permissions.care_giver_profile_personalInformation_view) || 
                        hasPermission(userPermissions, permissions.care_giver_profile_documents_view) ? 
                        <Button
                        variant='contained'
                        color='primary'
                        type='button'
                        style={{marginRight:'0.7rem'}}
                        onClick={() => {
                            if(hasPermission(userPermissions, permissions.care_giver_profile_jobDetails_view)){
                                history.push(`/caregivers/profile/jobDetails/${params.id}`)
                            } else if(hasPermission(userPermissions, permissions.care_giver_profile_personalInformation_view)){
                                history.push(`/caregivers/profile/personalInformation/${params.id}`)
                            } else if(hasPermission(userPermissions, permissions.care_giver_profile_documents_view)){
                                history.push(`/caregivers/profile/documents/${params.id}`)
                            }
                        }}

                    >
                        Edit Caregiver
                    </Button> : null
                       }
                       
                    </Box>
                    <DynamicForm
                        fields={uiElementsList}
                        data={formData}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                </div>
            </div>
        </>
    )
}
