import { useState, useEffect } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { LIST_OF_LEAVE_RULE_COLUMNS, LIST_OF_LEAVE_RULE_COLUMN_DATA_MAPPING, LIST_OF_LEAVE_RULE_SORTING_COLUMNS } from '../../BILLING_TABLE_CONSTANTS';
import { useHistory } from 'react-router-dom';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import { toast } from 'react-toastify';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { getLeaveRule, getSearchLeaveRule } from '../store/action';
const selectAuth = (state) => state.auth;

function List() {
    const [leaveRule, setLeaveRule] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deleteID, setDeleteID] = useState('')
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1
    })
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const companyid = auth.user?.company_code
    const history = useHistory()
    useEffect(() => {
        const fetchData = async (page, pageSize, sortBy, sortDir) => {
            try {
                const result = await dispatch(getLeaveRule({companyid, page: page, pageSize: pageSize, sortBy: sortBy, sortDir: sortDir }))
                const { data, currentPage, totalCounts } = result.payload.data;
                setLeaveRule(data)
                setFilteredData(data)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [deleteID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir])

    const onDeleteLeaveRule = (row) => {
        setDeleteID(row._id)
        axios.delete('/leaveRules/delete/' + row._id)
            .then((res) => {
                const response = res.data
                setLeaveRule((prevState) =>
                    prevState.filter((ind) => ind._id !== row._id)
                );
                if (response.status == 200 && response.success === true && response) {
                    toast.success('Leave Rule Deleted Successfully')
                } else {
                    toast.error('Error while Deleting Leave Rule')
                }
            }).catch(err => {
                console.log((err))
            })
    }
    
    const handleSearchChange = async (event) => {
        const searchTerm = event.target.value.toLowerCase()
        setSearchTerm(searchTerm)

        try{
            const response = await dispatch(getSearchLeaveRule(searchTerm))
            setFilteredData(response.payload.data)
        } catch(err) {
            console.log('Error fetching search result', err)
        }
    }
   

    const onUpdate = ({ _id }) => {
        history.push({
            pathname: `/admin/billing/leave-rule/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/billing/leave-rule/new')
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Leave Rules List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            handleSearchChange={handleSearchChange}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        !!hasPermission(userPermissions, permissions.admin_billing_leaveRule_create) &&
                        <AddButton
                            onClick={onClickAddButton}
                        />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_OF_LEAVE_RULE_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.admin_billing_leaveRule_update)}
                            canDelete={hasPermission(userPermissions, permissions.admin_billing_leaveRule_delete)}
                            columnDataMapping={LIST_OF_LEAVE_RULE_COLUMN_DATA_MAPPING}
                            sortingColumns={LIST_OF_LEAVE_RULE_SORTING_COLUMNS}
                            clickableRow={false}
                            onUpdate={onUpdate}
                            onDelete={onDeleteLeaveRule}
                            title='Are you sure you want to delete the Leave Rule'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default List