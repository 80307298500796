import { createSlice } from "@reduxjs/toolkit";
import { GetDescriptionCode } from "../action";


interface DescriptionCode {
    _id: String;
    company_code: String | undefined;
    accountNumber: String;
    serviceDescription: String;
    serviceCode: String;
    unitMeasure: String;
    rateHistory: String;
    rateHistoryValue: Array<object>;
    unitRate: String;
    useService: String;
    revenueCode: String;
    rateCode: String;
    rateValue: String;
    locatorCode: String;
    useLocator: String;
    claimType: String;
    procedureModifier: String;
    primaryDiagnosis: String;
    diagnosisPointer: String;
    electronicUnit: String;
    procedureQualifier: String;
    addService: String;
    validationTotalBillable: String;
    unitOfMeasure: String;
    roundingAlgorithem: String;
    allowedIncreament: String
}

interface DescriptionCodeState {
    descriptionCode: DescriptionCode[] | any;
    isLoading: boolean
}

const initialState: DescriptionCodeState = {
    descriptionCode: [],
    isLoading: false
}


export const DescriptionCodeInitialState = createSlice({
    name: 'descriptionCode',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(GetDescriptionCode.fulfilled, (state, action: any) => {
            state.descriptionCode = action.payload.data
            state.isLoading = false
        })
            .addCase(GetDescriptionCode.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(GetDescriptionCode.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default DescriptionCodeInitialState.reducer