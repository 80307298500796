import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import { useSelector } from 'react-redux'
import { TYPE_CONSTANT_INPUT } from '../../GENERAL_CONSTANTS';
const selectAuth = (state) => state.auth;

const SharedContactTypeEditForm = () => {
    const [formData, setFormData] = useState({});
    const { id } = useParams();
    const history = useHistory();
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/sharedtype/getOne/${id}`);
                const { data } = response.data
                const tempObj = {
                    _id: data._id,
                    name: data.name,
                    company_code: companyid
                };
                setFormData(tempObj);    
            } catch (error) {
                toast.error("Failed to fetch shared contact type");
            }
        };
    
        if (id) {
            fetchData();
        } else {
            history.push('/admin/general/shared-contact-type/list');
        }
    }, [id]);
    
    const handleSubmitForm = async (data) => {
        const tempObj = {
            _id: formData._id,
            name: data.name
        };
        try {
             const response = await axios.put(`/sharedtype/update`, tempObj);
                if (response.data && response.data.success) {
                    toast.success(response.data.message || "Success", {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    history.push({pathname:`/admin/general/shared-contact/type/list`});
                } else {
                    toast.error(response?.data?.message || "Error", {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];
    return (
        <FormWrapper
            title={'Edit Shared Contact Type' }
            content={
                <DynamicForm
                    fields={TYPE_CONSTANT_INPUT}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    );

}

export default SharedContactTypeEditForm;