import { useEffect, useState, SetStateAction } from 'react'
import axios from 'axios'
import '../../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { useHistory } from 'react-router-dom'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING, INDIVIDUAL_CONTACT_LIST_COLUMNS, INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS } from '../../care_table_constants'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'

const selectAuth = (state: RootState) => state.auth

const ContactNewFormTable = ({
    location,
}: {
    location?: {
        state: SetStateAction<{
            image: string
            title: string
            gender: string
            firstName: string
            lastName: string
            birthDate: string
            socialSecurityNumber: string
            medicaidNumber: string
            status: string
            email: string
            phoneNumber: string
            race: string
            ethnicity: string
            enteredDate: string
            enteredBy: string
            admittedBy: string
            lastUpdatedBy: string
            admissionDate: string
            addrInCareOf: string
            residentialAddr: boolean
            mailingAddr: boolean
            addrInfo: {
                st1: string
                st2: string
                city: string
                state: string
                zipCode: string
                country: string
                primaryPhone: string
                secondaryPhone: string
                additionalPhone: string
            }
            mailingInfo: {
                st1: string
                st2: string
                city: string
                state: string
                country: string
                zipCode: string
                primaryPhone: string
                secondaryPhone: string
                additionalPhone: string
            }
            insuranceInfo: {
                medicareNumber: string
                medicareDate: string
                medPlanDId: string
                medPlanDName: string
                medPlanDIssuer: string
                medPlanDRxBin: string
                medPlanDRxPcn: string
                medPlanDRxGrp: string
                otherBenefits: string
                insuranceCompany: string
                insuranceGroup: string
                insurancePolicyNumber: string
                insurancePolicyHolder: string
                files: string
            }
        }>
    }
}) => {
    const history = useHistory()
    const [clientsListing, setClientsListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: '_id',
        sortDir: 'desc',
        searchTerm: ''
    })

    const auth = useSelector(selectAuth)

    useEffect(() => {
        const fetchContants = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const companyid = auth.user?.company_code;
                const response = await axios.get(`/clients/get/${companyid}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`);
                const { data, currentPage, totalCounts } = response.data;
                setClientsListing(data);
                setFilteredData(data);
                setQueryData((prevState: any) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    };
                });
            } catch (err) {
                console.error('Error fetching contants:', err);
            }
        };
    
        fetchContants(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    
    const onView = ({ _id }: { _id: string }) => {
        history.push(`/admin/care/contact/new/${_id}`)
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Select A Client'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        <BackButton />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
                            data={filteredData}
                            canView={true}
                            onView={onView}
                            canDelete={false}
                            clickableRow={false}
                            columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
                            sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
                            title='Are you sure you want to delete this client?'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default ContactNewFormTable
