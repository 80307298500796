
import {useEffect, useState} from 'react'
import '../../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../setup'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import {
  INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS,
  INDIVIDUAL_CONTACT_LIST_COLUMNS,
  INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING,
} from '../../care_table_constants'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import TableButton from '../../../../../reusable-components/tables/TableButton'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'
const selectAuth = (state: RootState) => state.auth

// const headers = [
//   {label: 'First Name', key: 'firstName'},
//   {label: 'Last Name', key: 'lastName'},
//   {label: 'Social Security Number', key: 'socialSecurityNumber'},
//   {label: 'Medicaid Number', key: 'medicaidNumber'},
//   {label: 'Status', key: 'status'},
//   {label: 'Entered By', key: 'enteredBy'},
//   {label: 'Admitted By', key: 'admittedBy'},
//   {label: 'Admission Date', key: 'admissionDate'},
//   {label: 'Birth Date', key: 'birthDate'},
//   {label: 'Last Updated By', key: 'lastUpdatedBy'},
//   {label: 'Mailing Address', key: 'mailingaddress'},
// ]

// const headersDetails = [
//   {label: 'Title', key: 'title'},
//   {label: 'Gender', key: 'gender'},
//   {label: 'First Name', key: 'firstName'},
//   {label: 'Last Name', key: 'lastName'},
//   {label: 'Birth Date', key: 'birthDate'},
//   {label: 'Social Security Number', key: 'socialSecurityNumber'},
//   {label: 'Medicaid Number', key: 'medicaidNumber'},
//   {label: 'Status', key: 'status'},
//   {label: 'Email', key: 'email'},
//   {label: 'Phone Number', key: 'phoneNumber'},
//   {label: 'Race', key: 'race'},
//   {label: 'Ethnicity', key: 'ethnicity'},
//   {label: 'Entered By', key: 'enteredBy'},
//   {label: 'Admitted By', key: 'admittedBy'},
//   {label: 'Last Updated By', key: 'lastUpdatedBy'},
//   {label: 'Admission Date', key: 'admissionDate'},
//   {label: 'In Care Of', key: 'addrInCareOf'},
//   {label: 'Address Street 1', key: 'addrInfo.st1'},
//   {label: 'Address Street 2', key: 'addrInfo.st2'},
//   {label: 'Address City', key: 'addrInfo.city'},
//   {label: 'Address State', key: 'addrInfo.state'},
//   {label: 'Address Country', key: 'addrInfo.country'},
//   {label: 'Address Primary Phone', key: 'addrInfo.primaryPhone'},
//   {label: 'Address Secondary Phone', key: 'addrInfo.secondaryPhone'},
//   {label: 'Address ZipCode', key: 'addrInfo.zipCode'},
//   {label: 'Mailing Primary Phone', key: 'mailingInfo.primaryPhone'},
//   {label: 'Mailing Secondary Phone', key: 'mailingInfo.secondaryPhone'},
//   {label: 'Mailing Street 1', key: 'mailingInfo.st1'},
//   {label: 'Mailing Street 2', key: 'mailingInfo.st2'},
//   {label: 'Mailing City', key: 'mailingInfo.city'},
//   {label: 'Mailing Country', key: 'mailingInfo.country'},
//   {label: 'Mailing ZipCode', key: 'mailingInfo.zipCode'},
//   {label: 'Medicare Number', key: 'insuranceInfo.medicareNumber'},
//   {label: 'Medicare Date', key: 'insuranceInfo.medicareDate'},
//   {label: 'MedPlanDId', key: 'insuranceInfo.medPlanDId'},
//   {label: 'MedPlanDName', key: 'insuranceInfo.medPlanDName'},
//   {label: 'MedPlanDIssuer', key: 'insuranceInfo.medPlanDIssuer'},
//   {label: 'MedPlanDRxBin', key: 'insuranceInfo.medPlanDRxBin'},
//   {label: 'MedPlanDRxPcn', key: 'insuranceInfo.medPlanDRxPcn'},
//   {label: 'MedPlanDRxGrp', key: 'insuranceInfo.medPlanDRxGrp'},
//   {label: 'OtherBenefits', key: 'insuranceInfo.otherBenefits'},
//   {label: 'InsuranceCompany', key: 'insuranceInfo.insuranceCompany'},
//   {label: 'InsuranceGroup', key: 'insuranceInfo.insuranceGroup'},
//   {label: 'InsurancePolicyNumber', key: 'insuranceInfo.insurancePolicyNumber'},
//   {label: 'InsurancePolicyHolder', key: 'insuranceInfo.insurancePolicyHolder'},
//   {label: 'Files', key: 'insuranceInfo.files'},
// ]

const SearchData = ({formData, setShowForm}: any) => {
  const history = useHistory()
  const [clientsListing, setClientsListing] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [queryData, setQueryData] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: '_id',
    sortDir: 'desc',
    searchTerm: ''
  })

  const auth = useSelector(selectAuth)

  const companyid = auth.user?.company_code
  useEffect(() => {
    const fetchData = async (page, pageSize) => {
      const response = await axios.get(
        `/clients/get/${companyid}?page=${page}?pageSize=${pageSize}`
      )
      const {data, currentPage, totalCounts} = response.data
      let tempData: any[] = data
      let formUpdatedData: any = {}

      let objectKeysArray = Object.keys(formData)

      for (const element of objectKeysArray) {
        if (
          formData[element] !== '' &&
          formData[element] !== 'Select Status' &&
          formData[element] !== 'Select Individual ID Type' &&
          formData[element] !== 'Select Case Status'
        ) {
          formUpdatedData[element as keyof object] = formData[element as keyof object]
        }
      }

      let formDataKeys = Object.keys(formUpdatedData)
      let selectedData: any = {}

      if (formDataKeys.includes('birthDateTo') && formDataKeys.includes('birthDateFrom')) {
        tempData = tempData.filter(
          (ind) =>
            ind.birthDate >= formUpdatedData.birthDateFrom &&
            ind.birthDate <= formUpdatedData.birthDateTo
        )
      } else if (formDataKeys.includes('birthDateTo') && !formDataKeys.includes('birthDateFrom')) {
        tempData = tempData.filter((ind) => ind.birthDate <= formUpdatedData.birthDateTo)
      } else if (!formDataKeys.includes('birthDateTo') && formDataKeys.includes('birthDateFrom')) {
        tempData = tempData.filter((ind) => ind.birthDate >= formUpdatedData.birthDateFrom)
      }

      if (formDataKeys.includes('admissionDateTo') && formDataKeys.includes('admissionDateFrom')) {
        tempData = tempData.filter(
          (ind) =>
            ind.admissionDate >= formUpdatedData.admissionDateFrom &&
            ind.admissionDate <= formUpdatedData.admissionDateTo
        )
      } else if (
        formDataKeys.includes('admissionDateTo') &&
        !formDataKeys.includes('admissionDateFrom')
      ) {
        tempData = tempData.filter((ind) => ind.admissionDate <= formUpdatedData.admissionDateTo)
      } else if (
        !formDataKeys.includes('admissionDateTo') &&
        formDataKeys.includes('admissionDateFrom')
      ) {
        tempData = tempData.filter((ind) => ind.admissionDate >= formUpdatedData.admissionDateFrom)
      }
      formDataKeys = formDataKeys.filter(
        (key) =>
          key !== 'birthDateTo' &&
          key !== 'birthDateFrom' &&
          key !== 'admissionDateTo' &&
          key !== 'admissionDateFrom'
      )
      let tempFinalArray: any = []
      if (formDataKeys.length > 0) {
        for (const element of formDataKeys) {
          if (
            element === 'firstName' ||
            element === 'lastName' ||
            element === 'socialSecurityNumber' ||
            element === 'medicaidNumber' ||
            element === 'medicareNumber' ||
            element === '_id' ||
            element === 'individualIDNumber' ||
            element === 'enteredBy' ||
            element === 'status' ||
            element === 'individualIDType' ||
            element === 'caseStatus'
          ) {
            let lowerCaseLetter = formUpdatedData[element].toLowerCase()
            formUpdatedData[element as keyof object] = lowerCaseLetter
          }
        }
        for (let i = 0; i < tempData.length; i++) {
          if (selectedData[tempData[i]._id as keyof object] === 'deleted') {
            continue
          }
          for (const element of formDataKeys) {
            if (
              element === 'firstName' ||
              element === 'lastName' ||
              element === 'socialSecurityNumber' ||
              element === 'medicaidNumber' ||
              element === 'medicareNumber' ||
              element === '_id' ||
              element === 'individualIDNumber' ||
              element === 'enteredBy' ||
              element === 'status' ||
              element === 'individualIDType' ||
              element === 'caseStatus'
            ) {
              let lowerCaseLetter = tempData[i][element as keyof object].toLowerCase()
              if (lowerCaseLetter.includes(formUpdatedData[element as keyof object])) {
                selectedData[tempData[i]._id as keyof object] = tempData[i]
              }
            } else {
              if (
                tempData[i][element as keyof object] === formUpdatedData[element as keyof object]
              ) {
                selectedData[tempData[i]._id as keyof object] = tempData[i]
              } else {
                selectedData[tempData[i]._id as keyof object] = 'deleted'
                break
              }
            }
          }
        }

        let selectedDataKeys = Object.keys(selectedData)

        for (const element of selectedDataKeys) {
          if (selectedData[element] !== 'deleted') {
            tempFinalArray.push(selectedData[element])
          }
        }
      } else {
        tempFinalArray = tempData
      }
      let tempvar
      let tempName
      let tempMedicaId
      let tempSocialSecurityNumber
      for (let i = 0; i < tempFinalArray.length; i++) {
        tempName = tempFinalArray[i].firstName + ' ' + tempFinalArray[i].lastName
        tempvar =
          tempFinalArray[i].mailingInfo?.st1 +
          ' ' +
          tempFinalArray[i].mailingInfo?.st2 +
          ' ' +
          tempFinalArray[i].mailingInfo?.city +
          ' ' +
          tempFinalArray[i].mailingInfo?.state +
          ' ' +
          tempFinalArray[i].mailingInfo?.country

        tempMedicaId = tempFinalArray[i].medicaidNumber
          ? parseInt(tempFinalArray[i].medicaidNumber)
          : tempFinalArray[i].medicaidNumber
        tempSocialSecurityNumber = tempFinalArray[i].socialSecurityNumber
          ? parseInt(tempFinalArray[i].socialSecurityNumber)
          : tempFinalArray[i].socialSecurityNumber
        tempFinalArray[i].mailingaddress = tempvar
        tempFinalArray[i].fullName = tempName
        tempFinalArray[i].socialSecurityNumber = tempSocialSecurityNumber
        tempFinalArray[i].medicaidNumber = tempMedicaId
      }
      setClientsListing(tempFinalArray)
      setFilteredData(tempFinalArray)
      setQueryData((prevState) => {
        return {
          ...prevState,
          currentPage: currentPage,
          totalCount: totalCounts,
        }
      })
    }
    fetchData(queryData.pageNumber, queryData.pageSize)
  }, [queryData.pageNumber, queryData.pageSize])

  // const handleSearchChange = (event: any) => {
  //   event.persist()
  //   setSearchTerm(() => event.target.value)
  //   if (event.target?.value) {
  //     const filteredClients = clientsListing.filter(
  //       (client) =>
  //         client.firstName.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.lastName.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.birthDate.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.mailingaddress.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.socialSecurityNumber
  //           .toString()
  //           .toLowerCase()
  //           .includes(event.target?.value.toLowerCase()) ||
  //         client.medicaidNumber
  //           .toString()
  //           .toLowerCase()
  //           .includes(event.target?.value.toLowerCase()) ||
  //         client.status.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.enteredBy.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.admittedBy.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.admissionDate.toLowerCase().includes(event.target?.value.toLowerCase()) ||
  //         client.lastUpdatedBy.toLowerCase().includes(event.target?.value.toLowerCase())
  //     )
  //     setFilteredData(filteredClients)
  //   } else setFilteredData(clientsListing)
  // }
  // Function to handle deletion of a row by its ID
  const onDelete = async (row: any) => {
    try {
      const response = await axios.delete(`clients/delete`, {
        data: {
          deleteID: row._id,
          // imageID:
        },
      })
      if (response.status === 200) {
        setFilteredData((prevData) => prevData.filter((item) => item._id !== row._id))
        toast.success('Client deleted successfully!') // Display success toast
      }
    } catch (error) {
      console.error('Error deleting data:', error)
      toast.error('Error deleting client') // Display error toast
    }
  }

  const onUpdate = ({_id}) => {
    // ;<Link
    //   to={{
    //     pathname: `/admin/care/individual-intake/form/${_id}`,
    //     state: clientsListing.filter((client) => client._id === _id)[0],
    //   }}
    // >
    //   Go to Other Page
    // </Link>

    history.push({
      pathname: `/admin/care/individual-intake/form/${_id}`,
      //   state: location.state,
      state: clientsListing.filter((client) => client._id === _id)[0],
    })
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <TableWrapper
          searchField={
            <TableSearchBar
              label='Search'
              variant='outlined'
              size='small'
              value={searchTerm}
              margin='normal'
              inputAdornmentPosition='start'
            ></TableSearchBar>
          }
          tableButtons={
            <TableButton
              style={{marginRight: '4px'}}
              variant={'outlined'}
              color={'primary'}
              title='Cancel'
              onClick={() => history.push('/admin/care/individual-intake/list')}
            ></TableButton>
          }
          dynamicTable={
            <DynamicTable
              columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
              data={filteredData}
              canUpdate={true}
              canDelete={true}
              columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
              onDelete={onDelete}
              onUpdate={onUpdate}
              sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
              title='Are you sure you want to delete this client?'
              setQueryData={setQueryData}
              queryData={queryData}
            />
          }
        />
      </div>
    </div>
  )
}

export default SearchData
