import { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import AdminCareHeader from '../AdminCareHeader'
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu'
import { ALLERGY_LIST_COLUMNS, ALLERGY_LIST_COLUMNS_DATA_MAPPING, ALLERGY_LIST_SORTING_COLUMNS, allergyListColumns, allergyListcolumnDataMapping } from '../care_table_constants'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import { permissions } from '../../../../../_constants/permissions'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { useSelector } from 'react-redux'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../reusable-components/Buttons/AddButton'
import { toast } from 'react-toastify'
const selectAuth = (state) => state.auth;

const AllergyList = () => {
    const history = useHistory()
    const [newForm, setNewForm] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedID, setDeletedID] = useState('')
    const [allergyListing, setAllergyListing] = useState([])
    const [client, setClient] = useState()
    const parameters = useParams()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: '_id',
        sortDir: 'desc',
        searchTerm: ''
    })
    
    useEffect(() => {
        const fetchData = async (page, pageSize, sortBy, sortDir) => {
            try{
                let id =  parameters.clientID
                 if (id) {
                const [ClientsData, allergyInfo] = await Promise.all([
                    axios.get('/clients/getOne/' + id),
                    axios.get(`/health/getAllergy?clientID=${id}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`),
                ])

                const {data, currentPage, totalCounts} = allergyInfo.data
                setAllergyListing(data)
                setFilteredData(data)
                setClient(ClientsData.data.client)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            } else {
                history.push('/admin/care/health/list')
            }
            }catch(err){
                console.log(err)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

    const onUpdate = (row) => {
        history.push(`/admin/care/allergy/list/${client._id}/${row._id}`)
    }
    const onDelete = (row) => {
        if (row._id) {
            let tempobj = {
                clientID: client.length > 0 ? client._id : parameters.clientID,
                deleteID: row._id
            }
            axios.put("/health/deleteAllergy", tempobj)
                .then(res => {
                    setAllergyListing(prevState =>
                        prevState.filter(entry => entry._id !== row._id)
                    )
                    setNewForm(true)
                    setDeletedID(row._id)
                    toast.success('Allergy Info successfuly deleted')
                })
        }
    }
    const onClickAddButton = () => {
        history.push(`/admin/care/allergy/new/${parameters.clientID}`)
    }

    return (
        <div>
            <AdminCareHeader />
            <HealthProfileMenu />
            <div className='card my-10'>
                <div className='card-body'>
                    <div className='mt-5'>
                        <TableTitle title='Allergy Profile List'></TableTitle>
                        <TableWrapper
                            searchField={
                                <TableSearchBar
                                    label='Search'
                                    variant='outlined'
                                    size='small'
                                    value={searchTerm}
                                    setQueryData={setQueryData}
                                    setSearchTerm={setSearchTerm}
                                    margin='normal'
                                    inputAdornmentPosition='start'
                                />
                            }
                            tableButtons={
                                !!hasPermission(userPermissions, permissions.admin_care_allergy_create) &&
                                <AddButton
                                    onClick={onClickAddButton}
                                />
                            }
                            dynamicTable={
                                <DynamicTable
                                    columns={ALLERGY_LIST_COLUMNS}
                                    data={filteredData}
                                    canUpdate={hasPermission(userPermissions, permissions.admin_care_allergy_update)}
                                    onUpdate={onUpdate}
                                    canDelete={hasPermission(userPermissions, permissions.admin_care_allergy_delete)}
                                    onDelete={onDelete}
                                    columnDataMapping={ALLERGY_LIST_COLUMNS_DATA_MAPPING}
                                    sortingColumns={ALLERGY_LIST_SORTING_COLUMNS}
                                    updateModal={false}
                                    title='Are you sure you want to delete this contact?'
                                    setQueryData={setQueryData}
                                    queryData={queryData}
                                ></DynamicTable>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllergyList