
import { createSlice } from "@reduxjs/toolkit";
import { deleteAttendanceType, getAttendanceType } from "../action";

interface AttendanceType {
    _id: String;
    company_code: String | undefined;
    name: String;
    time: String;
    duration: String;
    bundleRules: String;
    bundling: String;
    leaveRules: String;
    targetService: String;
    ISPBillable: String;
    ispProgram: String;
    ISPData: String;
    totalUnits: String;
    billingDate: String;
    dataRangeGroup: String;
    directBilling: String;
    options: String;
    bundlingForm: String;
}

interface AttendanceTypeState {
    attendanceType: AttendanceType[] | any;
    isLoading: boolean
}

const initialState: AttendanceTypeState = {
    attendanceType: [],
    isLoading: false
}

export const attendanceInitialState = createSlice({
    name: "attendanceType",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getAttendanceType.fulfilled, (state, action: any) => {
            state.attendanceType = action.payload.data
            state.isLoading = false;
        })
            .addCase(getAttendanceType.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getAttendanceType.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default attendanceInitialState.reducer