
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import users from '../../app/pages/users/store'
import fundingSource from '../../app/pages/admin/billing/funding-source/store/reducer'
import rolesReducer from '../../app/modules/auth/redux/rolesSlice'
import payers from '../../app/pages/admin/billing/payers-setting/store/reducer'
import sites from '../../app/pages/admin/general/site/store/reduces'
import attendanceType from '../../app/pages/admin/billing/attendance-type/store/reducer'
import descriptionCode from '../../app/pages/admin/billing/description-code/store/reducer'
import program from '../../app/pages/admin/care/enrollment/store/reducer'
import serviceAuths from '../../app/pages/billing/professionalclaim/service-authorization/store/reducer'
import billingProvider from '../../app/pages/admin/billing/billing-provider/store/reducer'
import attendanceInfo from '../../app/pages/billing/attendance/store/reducer'
import incidentReport from '../../app/pages/admin/care/clientLog/store/reducer'

export const rootReducer = combineReducers({
    auth: auth.reducer,
    users,
    fundingSource,
    payers,
    sites,
    attendanceType,
    descriptionCode,
    program,
    serviceAuths,
    billingProvider,
    roles: rolesReducer,
    attendanceInfo,
    incidentReport
})


export type RootState = ReturnType<typeof rootReducer>