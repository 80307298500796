import { ChangeEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup'
import '../../../../../../_helper/cssfiles/reactSelectField.css'
import { Close } from "@mui/icons-material";
import {
    TextField, IconButton, Button, Autocomplete, Box, FormControlLabel, Checkbox, RadioGroup, Radio
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import '../../../../../reusable-components/styles/style.css'
import { title, gender, race, ethnicity, states, Country } from '../CONSTANTS'
import * as validator from '../../../../../reusable-components/Validation/Validations';
import { State, City }  from 'country-state-city';
import GoogleMaps from '../../../../../reusable-components/GoogleMaps/GoogleMaps';
const selectAuth = (state: RootState) => state.auth;

const options = [
    '- Select Contact Relation -',
    'Advocate',
    'Case Worker',
    'Parent',
    'Employer',
    'Day Program',
    'Guardian',
    'Partner',
    'Payee',
    'Relative',
    'Spouse',
    'Other',
];
const allergyField = {
    label: "Allergy",
    name: "allergy",
    type: "asyncSelectICD",
    options: [],
}
const diagnosisField = {
    label: "Primary Diagnosis",
    name: "icd10",
    type: "asyncSelectICD",
    options: [],
}

const NewIndividual = () => {
    const [showResAddr, setShowResAddr] = useState(false)
    const [showMailingAddr, setShowMailingAddr] = useState(false)
    const [showProgram, setShowProgram] = useState(false)
    const [showAllergy, setShowAllergy] = useState(false)
    const [showInsurance, setShowInsurance] = useState(false)
    const [showContact, setShowContact] = useState(false)
    const [showSharedContact, setShowSharedContact] = useState(false)
    const [sharedContact, setSharedContact] = useState([])
    const [program, setProgram] = useState<any[]>([])
    const [enrolledPrograms, setEnrolledPrograms] = useState<{ value: "", label: "" }[]>([])
    const [diagnosis, setDiagnosis] = useState(false)
    const [allergy, setAllergy] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [codeType, setCodeType] = useState('')
    const [allergies, setAllergies] = useState<any[]>([])
    const [diagnosisForm, setDiagnosisForm] = useState<any[]>([])
    const [images, setImages] = useState<any[]>([])
    const [imageURLs, setImageURLs] = useState<any[]>([])
    const [showImage, setShowImage] = useState<boolean>(false)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showMaps, setShowMaps] = useState(false)
    const [selectedState, setSelectedState] = useState(null);
    const [selectedMailingState, setSelectedMailingState] = useState(null);
    const [selectedMailingCountry, setSelectedMailingCountry] = useState(null);
    const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [mailingCity, setMailingCity] = useState([]);
  const [mailingStates, setMailingStates] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [error, setError] = useState('');
  const [birthDateError, setBirthDateError] = useState(false);

  
  
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code
    const [formData, setFormData] = useState({
        company_code: companyid,
        allergyName: '',
        primaryDiagnosis: '',
        diagnosisCodeType: '',
        allergyType: '',
        insuranceCompany: '',
        insurancePlanNumber: '',
        contactFirstName: '',
        contactLastName: '',
        contactType: '',
        contactPrimaryPhone: '',
        contactEmail: '',
        sharedContacts: [],
        programID: "",
        title: { label: '', value: '' },
        image: '',
        gender: { label: '', value: '' },
        firstName: '',
        relationtoind: '',
        lastName: '',
        birthDate: '',
        socialSecurityNumber: '',
        medicaidNumber: '',
        email: '',
        phoneNumber: '',
        race: { label: '', value: '' },
        ethnicity: { label: '', value: '' },
        residentialAddr: false,
        mailingAddr: false,
        addrInfo: {
            primaryPhone: '',
            secondaryPhone: '',
            additionalPhone: '',
            country: '',
            st1: '',
            st2: '',
            city: '',
            state: '',
            zipCode: '',
            accuracy:90,
        },
        mailingInfo: {
            primaryPhone: '',
            secondaryPhone: '',
            additionalPhone: '',
            country: '',
            st1: '',
            st2: '',
            city: '',
            state: '',
            zipCode: ''
        },
        insuranceInfo: {
            medicareNumber: "",
            medicareDate: "",
            medPlanDId: "",
            medPlanDName: "",
            medPlanDIssuer: "",
            medPlanDRxBin: "",
            medPlanDRxPcn: "",
            medPlanDRxGrp: "",
            otherBenefits: "",
            insuranceCompany: "",
            insuranceGroup: "",
            insurancePolicyNumber: "",
            insurancePolicyHolder: "",
            files: ""
        }

    });
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  })
  const [markerPosition, setMarkerPosition] = useState({
    lat: -3.745,
    lng: -38.523,
  })
    const dispatch = useDispatch()
    useEffect(() => {
        const source = axios.CancelToken.source();
        const fetchData = async () => {
            try {
                const [sharedContact, programs]: any = await Promise.all([
                    axios.get("/shared/get/" + companyid),
                    axios.get("/programs/get/" + companyid)
                ])
                const tempProgram = programs.data
                const tempContact = sharedContact.data

                let tempvar = []
                for (let i = 0; i < tempProgram.length; i++) {
                    let tempobj = {
                        label: '',
                        value: ''
                    }
                    tempobj.label = tempProgram[i].programName + " - " + tempProgram[i].siteName
                    tempobj.value = tempProgram[i]._id
                    tempvar.push(tempobj)
                }

                const sharedCOntacts = tempContact?.data?.map((item) => ({ value: item._id, label: `${item.firstName}  ${item.lastName}` }));
                setSharedContact(sharedCOntacts);
                setProgram(tempvar)


            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
        return () => {
            source.cancel('Component unmounted');
        };
    }, [])

    useEffect(() => {
        const fetchData = async () => {

            try{
                 const stateOptions = selectedCountry ? State.getStatesOfCountry(selectedCountry).map((state) =>  ({
                        label: state.name,
                        value: state.isoCode,
                      })) : [];

                const cityOptions = selectedState ? City.getCitiesOfState(selectedCountry, selectedState).map((city) => (
                    {
                            label: city.name,
                            value: city.name,
                          })) : [];

                          setStates(stateOptions)
                          setCity(cityOptions)
            } catch(err){
                console.log(err)
            }
        }
        fetchData()
      }, [selectedCountry, selectedState]);


      useEffect(() => {
        const fetchData = async () => {

            try{
                const mailingStateOptions = selectedMailingCountry ? State.getStatesOfCountry(selectedMailingCountry).map((state) =>  ({
                        label: state.name,
                        value: state.isoCode,
                })) : [];

                const mailingCityOptions = selectedMailingState ? City.getCitiesOfState(selectedMailingCountry, selectedMailingState).map((city) => (
                            {
                                    label: city.name,
                                    value: city.name,
                                  })) : [];
                          setMailingStates(mailingStateOptions)
                          setMailingCity(mailingCityOptions)
            } catch(err){
                console.log(err)
            }
        }
        fetchData()
      }, [selectedMailingCountry, selectedMailingState]);




    useEffect(() => {
        if (images.length < 1) return;
        const newImageUrls: string[] | ((prevState: never[]) => never[]) = []
        images.forEach(image => newImageUrls.push(
            URL.createObjectURL(image)
        ))
        setShowImage(true)
        setImageURLs(newImageUrls)
    }, [images])

    function onImageChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            setImages(files);
          }
    }
    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const codeTypes = [
        { value: 'ICD-9', label: 'ICD-9' },
        { value: 'ICD-10', label: 'ICD-10' },
    ];

    const getData = async (value) => {
        try {
            let response;
            if (codeType === 'ICD-9') {
                response = await axios.post(`/icd9/findICD`, { value });
            } else if (codeType === 'ICD-10') {
                response = await axios.post(`/icd10/findICD`, { value });
            }
            
            if (response && response.data) { // Check if response and response.data exist
                return response.data.map(item => ({
                    label: item.info,
                    value: item.info,
                }));
            } else {
                return []; // return an empty array if there's no data
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };
    

    const loadOptions = async (inputValue) => {
        if (inputValue.length >= 3) {
            const response = await getData(inputValue)
            setFilteredOptions(response);
        } else {
            setFilteredOptions([]);
        }
    };
    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((value, key) => value && value[key], obj);
    };

    const handleSubmit = async (values) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        if (!values.birthDate) {
            setBirthDateError(true); 
            return;
        }

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        const validations = [
            { field: 'firstName', validation: validator.NAME_VALIDATION },
            { field: 'lastName', validation: validator.NAME_VALIDATION },
            { field: 'socialSecurityNumber', validation: validator.SSN_VALIDATION },
            { field: 'email', validation: validator.EMAIL_VALIDATION },
            { field: 'phoneNumber', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'addrInfo.st1', validation: validator.STREET_VALIDATION },
            { field: 'addrInfo.st2', validation: validator.STREET_VALIDATION },
            { field: 'addrInfo.zipCode', validation: validator.ZIP_CODE_VALIDATION },
            { field: 'addrInfo.primaryPhone', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'addrInfo.secondaryPhone', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'addrInfo.additionalPhone', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'mailingInfo.st1', validation: validator.STREET_VALIDATION },
            { field: 'mailingInfo.st2', validation: validator.STREET_VALIDATION },
            { field: 'mailingInfo.zipCode', validation: validator.ZIP_CODE_VALIDATION },
            { field: 'mailingInfo.primaryPhone', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'mailingInfo.secondaryPhone', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'mailingInfo.additionalPhone', validation: validator.PHONE_NUMBER_VALIDATION },
            { field: 'addrInfo.accuracy', },
        ]
        for (const { field, validation } of validations) {
            const value = getNestedValue(values, field);
            if (value !== undefined && value !== null && value !== '') {
                if (validation && !validation.pattern.test(value)) {
                    toast.error(`Form Validation Failed for ${field}`);
                    return;
                }
            }
        }
        if (showMailingAddr === false) {
            const { addrInfo } = values;
            values.mailingInfo = {
                st1: addrInfo.st1,
                st2: addrInfo.st2,
                primaryPhone: addrInfo.primaryPhone,
                secondaryPhone: addrInfo.secondaryPhone,
                additionalPhone: addrInfo.additionalPhone,
                zipCode: addrInfo.zipCode,
                state: addrInfo.state,
                city: addrInfo.city,
                country: addrInfo.country,
            }
            values.residentialAddr = true;
            values.mailingAddr = true;
        } 
        // else if (showMailingAddr === false && showResAddr === false) {
        //     const fields = [
        //         'st1', 'st2', 'city', 'state', 'zipCode', 'country',
        //         'primaryPhone', 'secondaryPhone', 'additionalPhone',
        //     ];
        //     const mailingFields = [
        //         'st1',
        //         'st2', 'primaryPhone', 'additionalPhone',
        //         'zipCode',
        //         'state',
        //         'city',
        //         'country',
        //     ]
        //     fields.forEach(field => {
        //         values.addrInfo[field] = '';
        //     });
        //     mailingFields.forEach(field => {
        //         values.mailingInfo[field] = '';
        //     });
        //     values.residentialAddr = false;
        //     values.mailingAddr = false;
        // }
        try {
            try {
                if (values.birthDate <= currentDateFormatted) {
                    values.addrInfo.lat = markerPosition.lat
                    values.addrInfo.lng = markerPosition.lng
                    values.ethnicity = values?.ethnicity.value;
                    values.race = values?.race.value;
                    values.title = values?.title.value;
                    values.gender = values?.gender.value;
                    let data = values
                    if (images && showImage) {
                        const imagefilename = Date.now() + '-' + Math.round(Math.random() * 1e9);
                        values.image = imagefilename;
        
                        const formData = new FormData();
                        formData.append('customName', imagefilename);
                        formData.append('image', images[0]);
                        const responseOfImage = await axios.post('/clients/uploadImage', formData);
                    }

                    const response = await axios.post('/clients/create', data);
                    if(response.data.status === 400 && response.data.success === false) {
                        toast.error(response.data.message)
                    } else if (response.status == 200 && response.data && response.data.success) {
                        toast.success('New Client Created Successfully')
                        history.push('/admin/care/individual-intake/list')
                    }  
                } else {
                    toast.error('Date should not be later than today')
                }
            } catch (err) {
               if(err.toString().includes('MapsRequestError: GEOCODER_GEOCODE: ZERO_RESULTS'))
                toast.error('Please enter a valid address')
               else
                toast.error('Error Creating Client')
                console.log(err)
            }
        } catch (error) {
            console.error(error);
        }
    };


    const changeLocationOnGoogleMaps = async(showMap) => {
        if(showMap){
        const addressToCheck = formData.addrInfo['st1'] +' '+ formData.addrInfo['st2']+', '+formData.addrInfo['city']+', '+formData.addrInfo['state']+', '+formData.addrInfo['zipCode']+', '+formData.addrInfo['country']
        const geocoder = new window.google.maps.Geocoder()
        const geocode = await geocoder.geocode({address:addressToCheck})
        const newPosition = {
            lat:geocode.results[0].geometry.location.lat(),
            lng:geocode.results[0].geometry.location.lng()
        }
        setMarkerPosition(newPosition)
        setCenter(newPosition)
        }
        setShowMaps(showMap)
    }


    // const handleSubmit = (values) => {
    //     // if (allergies.length > 0) {
    //     //     for (let i = 0; i < allergies.length; i++) {
    //     //         let temp = {
    //     //             clientID: tempObj.formID,
    //     //             allergyInfo: {
    //     //                 formID: "ALLERGY-" + randomID(),
    //     //                 allergy: allergies[i].value,
    //     //                 allergyState: "",
    //     //                 description: "",
    //     //                 severity: "",
    //     //                 identificationDate: "",
    //     //                 reaction: "",
    //     //             }
    //     //         }
    //     //         if (i === 0) {
    //     //             axios.post("/health/create", temp)
    //     //                 .then((res) => {
    //     //                     if (codeType && diagnosisForm && i === 0) {
    //     //                         for (let j = 0; j < diagnosisForm.length; j++) {
    //     //                             let temp1 = {
    //     //                                 formID: "DIAGNOSIS-" + randomID(),
    //     //                                 diagnosisCodeType: codeType,
    //     //                                 diagnosisName: diagnosisForm[j].value,
    //     //                                 description: "",
    //     //                                 diagnosisDate: '',
    //     //                                 diagnosedBy: "",
    //     //                             }
    //     //                             let tempvar = {
    //     //                                 clientID: tempObj.formID,
    //     //                                 diagnosticsInfo: temp1
    //     //                             }
    //     //                             axios.put("/health/addDiagnostics", tempvar)
    //     //                                 .then(res => console.log(res.data))
    //     //                         }
    //     //                     }
    //     //                 })
    //     //         } else {
    //     //             axios.put("/health/addAllergy", temp)
    //     //                 .then((res) => {
    //     //                     console.log(res.data)
    //     //                 })
    //     //         }
    //     //     }
    //     // }

    //     // if (allergies.length === 0) {
    //     //     if (codeType && diagnosisForm) {
    //     //         for (let i = 0; i < diagnosisForm.length; i++) {
    //     //             let temp = {
    //     //                 formID: "DIAGNOSIS-" + randomID(),
    //     //                 diagnosisCodeType: codeType,
    //     //                 diagnosisName: diagnosisForm[i].value,
    //     //                 description: "",
    //     //                 diagnosisDate: '',
    //     //                 diagnosedBy: "",
    //     //             }
    //     //             let tempvar = {
    //     //                 clientID: tempObj.formID,
    //     //                 diagnosticsInfo: temp
    //     //             }
    //     //             axios.put("/health/addDiagnostics", tempvar)
    //     //                 .then(res => console.log(res.data))
    //     //         }
    //     //     }
    //     // }

    //     // if (enrolledPrograms) {
    //     //     for (let i = 0; i < enrolledPrograms.length; i++) {
    //     //         let temp = {
    //     //             programID: enrolledPrograms[i].value,
    //     //             clientID: tempObj.formID,
    //     //         }
    //     //         axios.put("programs/updateClient", temp)
    //     //     }
    //     // }

    //     // if (sharedContact.length > 0) {
    //     //     axios.post("/shared/addClient/" + tempObj.formID, sharedContact)
    //     // }

    //     // if (tempObj.contactFirstName && tempObj.contactLastName && tempObj.relationtoind) {
    //     //     let temp: any = {
    //     //         clientID: tempObj.formID,
    //     //         firstName: tempObj.contactFirstName,
    //     //         lastName: tempObj.contactLastName,
    //     //         formID: "CONTACT" + "-" + randomID(),
    //     //         isGuardian: false,
    //     //         guardianInfo: {
    //     //             guardianAuthority: "",
    //     //             guardianState: "",
    //     //             guardianEstablishDate: "",
    //     //             guardianEstablishEndDate: "",
    //     //             guardianType: ""
    //     //         },
    //     //         addressInfo: {
    //     //             st1: "",
    //     //             st2: "",
    //     //             primaryPhone: tempObj.contactPrimaryPhone,
    //     //             secondaryPhone: "",
    //     //             additionalPhone: "",
    //     //             faxNumber: "",
    //     //             email: tempObj.contactEmail,
    //     //             webaddress: "",
    //     //             zipCode: "",
    //     //             country: "",
    //     //             state: "",
    //     //             city: ""
    //     //         }
    //     //     }
    //     //     dispatch(addContact(temp))
    //     //     // axios.post("/contacts/create", temp)
    //     // }
    //     axios.post('/clients/create', tempObj)
    //     console.log(values)
    // }



    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h2 className='mt-2'>Client Intake Form (CIF) (New)</h2>
                        <Box sx={{
                            marginBottom: '10px',
                            marginRight: '8px',
                            position: 'relative',
                            backgroundColor: !showImage ? 'grey' : 'transparent',
                            width: '90px',
                            height: '90px',
                        }}>
                            {!showImage && <div style={{ justifyContent: 'center', marginTop: '40%', display: 'flex' }}>
                                No Image
                            </div>}
                            {showImage && (
                                <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                        <IconButton onClick={() => {
                                            setShowImage(false)
                                            setImages([])
                                        }}>
                                            <Close style={{ color: 'grey' }} />
                                        </IconButton>
                                    </div>
                                    <div>
                                        {imageURLs.map((imageSrc) => (
                                            <img
                                                key={imageSrc}
                                                src={imageSrc}
                                                style={{ height: '90px', width: '90px', objectFit: 'contain', overflow:'hidden' }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                        </Box>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(formData);
                    }}>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                            <input
                                type="file"
                                accept="image/*"
                                className='textField'
                                onChange={onImageChange}
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    padding: '4px',
                                    fontSize: '14px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                }}
                            />
                            </Box>
                            <Box className='fieldContainer'>
                                <Autocomplete
                                    id="title"
                                    size='small'
                                    options={title}
                                    style={{ maxWidth: '100%' }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={formData.title}
                                    onChange={(e, newValue) => {
                                        setFormData({ ...formData, title: newValue })
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Select Title" />
                                    )}
                                />
                            </Box>

                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <Autocomplete
                                    id="gender"
                                    size='small'
                                    options={gender}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={formData.gender || { label: '', value: '' }}
                                    style={{ maxWidth: '100%' }}
                                    onChange={(e, newValue) => {
                                        setFormData({ ...formData, gender: newValue })
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" required label="Select Gender" />
                                    )}
                                />
                            </Box>
                            <Box className='fieldContainer'>
                                <TextField
                                    type="text"
                                    name="firstName"
                                    size='small'
                                    className='textField'
                                    value={formData.firstName || ''}
                                    onChange={handleFieldChange}
                                    variant="outlined"
                                    label="First Name"
                                    autoComplete="off"
                                    required
                                    error={Boolean(formData.firstName && !validator.NAME_VALIDATION.pattern.test(formData.firstName))}
                                    helperText={formData.firstName && !validator.NAME_VALIDATION.pattern.test(formData.firstName) ? validator.NAME_VALIDATION.message : ''}
                                />
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <TextField
                                    type="text"
                                    name="lastName"
                                    size='small'
                                    className='textField'
                                    value={formData.lastName || ''}
                                    onChange={handleFieldChange}
                                    variant="outlined"
                                    label="Last Name"
                                    autoComplete="off"
                                    required
                                    error={Boolean(formData.lastName && !validator.NAME_VALIDATION.pattern.test(formData.lastName))}
                                    helperText={formData.lastName && !validator.NAME_VALIDATION.pattern.test(formData.lastName) ? validator.NAME_VALIDATION.message : ''}
                                />
                            </Box>
                            <Box className='fieldContainer'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dayjs(formData.birthDate)}
                                        format="YYYY-MM-DD"
                                        onChange={(newValue) => {
                                            if(newValue) {
                                                const newDate = newValue.format('YYYY-MM-DD');
                                                setFormData({ ...formData, birthDate: newDate });
                                                setBirthDateError(false);
                                            }
                                        }}
                                        className='textField'
                                        slotProps={{ textField: { label : 'Birth Date', required: true, size: 'small',  error: birthDateError,helperText: birthDateError ? 'Please fill in the required Birth Date' : ''} }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <TextField
                                    type="text"
                                    size='small'
                                    name="socialSecurityNumber"
                                    className='textField'
                                    value={formData.socialSecurityNumber}
                                    onChange={(e)=> {
                                        const { name, value } = e.target
                                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 9);

                                        if (formattedValue.length > 3) {
                                            formattedValue = formattedValue.slice(0, 3) + '-' + formattedValue.slice(3);
                                        }
                                        if (formattedValue.length > 6) {
                                            formattedValue = formattedValue.slice(0, 6) + '-' + formattedValue.slice(6);
                                        }
                                        
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            [name]: formattedValue,
                                        }));
                                        
                                    }}
                                    variant="outlined"
                                    placeholder="xxxxxxxxx"
                                    required
                                    label="Social Security Number"
                                    autoComplete="off"
                                    error={Boolean(formData.socialSecurityNumber && !validator.SSN_VALIDATION.pattern.test(formData.socialSecurityNumber))}
                                    helperText={formData.socialSecurityNumber && !validator.SSN_VALIDATION.pattern.test(formData.socialSecurityNumber) ? validator.SSN_VALIDATION.message : ''}
                                />
                            </Box>
                            <Box className='fieldContainer'>
                                <TextField
                                    type="text"
                                    name="medicaidNumber"
                                    value={formData.medicaidNumber}
                                    className='textField'
                                    onChange={(e)=> {
                                        const { name, value } = e.target
                                        const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 12);
                                        if (formattedValue.length > 0 && formattedValue.length < 8) {
                                            setError('Medicaid number must be at least 8 digits long.');
                                          } else {
                                            setError('');
                                          }
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            [name]: formattedValue,
                                        }));
                                    }}
                                    variant="outlined"
                                    size='small'
                                    label="Medicaid Number"
                                    autoComplete="off"
                                    error={!!error}
                                    helperText={error}
                                />

                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <TextField
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    size='small'
                                    className='textField'
                                    onChange={handleFieldChange}
                                    variant="outlined"
                                    label="Email"
                                    autoComplete="off"
                                    error={Boolean(formData.email && !validator.EMAIL_VALIDATION.pattern.test(formData.email))}
                                    helperText={formData.email && !validator.EMAIL_VALIDATION.pattern.test(formData.email) ? validator.EMAIL_VALIDATION.message : ''}
                                />
                            </Box>
                            <Box className='fieldContainer'>
                                <TextField
                                    type="text"
                                    name="phoneNumber"
                                    size='small'
                                    className='textField'
                                    value={formData.phoneNumber}
                                    onChange={(e)=> {
                                        const { name, value } = e.target
                                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            [name]: formattedValue,
                                        }));
                                    }}
                                    variant="outlined"
                                    label="Phone Number"
                                    autoComplete="off"
                                    error={Boolean(formData.phoneNumber && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.phoneNumber))}
                                    helperText={formData.phoneNumber && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.phoneNumber) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                />
                            </Box>

                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <Autocomplete
                                    options={race}
                                    value={formData.race || { label: '', value: '' }}
                                    size='small'
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    onChange={(e, newValue) => {
                                        setFormData({ ...formData, race: newValue })
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" label='Select Race' />}
                                />
                            </Box>
                            <Box className='fieldContainer'>
                                <Autocomplete
                                    options={ethnicity}
                                    value={formData.ethnicity || { label: '', value: '' }}
                                    size='small'
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    onChange={(_, newValue) => {
                                        setFormData({ ...formData, ethnicity: newValue })
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" label='- Select Ethinicity -' />}
                                />
                            </Box>

                        </Box>
                       
                                <label style={{ fontSize: '14px', fontWeight: '500', marginLeft: '15px', marginBottom:'1rem' }}>
                                    Residential Address
                                </label>
                            <Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            required={true}
                                            type="text"
                                            name="st1"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.st1}
                                            onChange={(e) => {
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            st1: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Street 1"
                                            autoComplete="off"
                                            error={Boolean(formData.addrInfo.st1 && !validator.STREET_VALIDATION.pattern.test(formData.addrInfo.st1))}
                                            helperText={formData.addrInfo.st1 && !validator.STREET_VALIDATION.pattern.test(formData.addrInfo.st1) ? validator.STREET_VALIDATION.message : ''}
                                        />
                                    </Box>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            type="text"
                                            name="st2"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.st2}
                                            onChange={(e) => {
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            st2: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Street 2"
                                            autoComplete="off"
                                            error={Boolean(formData.addrInfo.st2 && !validator.STREET_VALIDATION.pattern.test(formData.addrInfo.st2))}
                                            helperText={formData.addrInfo.st2 && !validator.STREET_VALIDATION.pattern.test(formData.addrInfo.st2) ? validator.STREET_VALIDATION.message : ''}
                                        />
                                    </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                    <Box className='fieldContainer'>
                                        <Autocomplete
                                            disabled={formData.addrInfo.st1=='' && formData.addrInfo.st2==''}
                                            options={Country}
                                            size='small'
                                            className='textField'
                                            value={Country?.find(option  => option.label === formData.addrInfo.country)}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            onChange={(_, newValue) => {
                                                const SelectedValue = newValue ? newValue : ''
                                                setSelectedCountry(SelectedValue ? SelectedValue.value : '')
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            country: SelectedValue ? SelectedValue.label : ''
                                                        }
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label="- Select Country -" required={true} />}
                                        />
                                    </Box>
                                    <Box className='fieldContainer'>
                                        <Autocomplete
                                            disabled={formData.addrInfo.country==''}
                                            options={states}
                                            value={formData.addrInfo.state}
                                            size='small'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            onChange={(_, newValue) => {
                                                const SelectedValue = newValue ? newValue : ''
                                                setSelectedState(SelectedValue ? SelectedValue.value : '')
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            state: SelectedValue ?  SelectedValue.label : ''
                                                        }
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label="- Select State -" required={true}/>}
                                        />
                                    </Box>

                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                        <Autocomplete
                                            disabled={formData.addrInfo.state==''}
                                            options={city}
                                            value={formData.addrInfo.city}
                                            size='small'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            onChange={(_, newValue) => {
                                                const SelectedValue = newValue ? newValue : ''
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            city: SelectedValue ? SelectedValue.value : ''
                                                        }
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label="- Select City -" required={true}/>}
                                        />
                                    </Box>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            required={true}
                                            disabled={formData.addrInfo.city==''}
                                            type="text"
                                            name="zipCode"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.zipCode}
                                            onChange={(e) => {
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            zipCode: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Zip Code"
                                            autoComplete="off"
                                            error={Boolean(formData.addrInfo.zipCode && !validator.ZIP_CODE_VALIDATION.pattern.test(formData.addrInfo.zipCode))}
                                            helperText={formData.addrInfo.zipCode && !validator.ZIP_CODE_VALIDATION.pattern.test(formData.addrInfo.zipCode) ? validator.ZIP_CODE_VALIDATION.message : ''}
                                        />
                                    </Box>
                                </Box>
                                
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            type="number"
                                            name="accuracy"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.accuracy}
                                            onChange={(e) => {
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            accuracy: parseInt(e.target.value)
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Accuracy (in yards)"
                                            autoComplete="off"
                                            
                                            // error={Boolean(formData.addrInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.primaryPhone))}
                                            // helperText={formData.addrInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.primaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                        />
                                    </Box>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            type="text"
                                            name="primaryPhone"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.primaryPhone}
                                            onChange={(e) => {
                                                const { value } = e.target
                                                let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            primaryPhone: formattedValue
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Primary Phone"
                                            autoComplete="off"
                                            error={Boolean(formData.addrInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.primaryPhone))}
                                            helperText={formData.addrInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.primaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                        />
                                    </Box>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            type="text"
                                            name="secondaryPhone"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.secondaryPhone}
                                            onChange={(e) => {
                                                const { value } = e.target
                                                let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            secondaryPhone: formattedValue
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Secondary Phone"
                                            autoComplete="off"
                                            error={Boolean(formData.addrInfo.secondaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.secondaryPhone))}
                                            helperText={formData.addrInfo.secondaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.secondaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                        />
                                    </Box>
                                </Box>
                                
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                    <Box className='fieldContainer'>
                                        <TextField
                                            type="text"
                                            name="additionalPhone"
                                            size='small'
                                            className='textField'
                                            value={formData.addrInfo.additionalPhone}
                                            onChange={(e) => {
                                                const { value } = e.target
                                                let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        addrInfo: {
                                                            ...prevState.addrInfo,
                                                            additionalPhone: formattedValue
                                                        }
                                                    }
                                                })
                                            }}
                                            variant="outlined"
                                            label="Additional Phone"
                                            autoComplete="off"
                                            error={Boolean(formData.addrInfo.additionalPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.additionalPhone))}
                                            helperText={formData.addrInfo.additionalPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.additionalPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                        />
                                    </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    label=''
                                    control={
                                        <Checkbox
                                            disabled={formData.addrInfo['zipCode']==''}
                                            checked={showMaps}
                                            onChange={() => {
                                                if (showMaps) {
                                                    changeLocationOnGoogleMaps(false)
                                                } else {
                                                    changeLocationOnGoogleMaps(true)
                                                }
                                            }}
                                        />
                                    }
                                />
                                 <label style={{ fontSize: '14px', fontWeight: '500', marginLeft: '-15px' }}>
                                   Show Location on Map
                                </label>
                        </Box>
                        </Box>
               {showMaps &&  <Box>
                   <GoogleMaps center={center} markerPosition={markerPosition} 
                   ></GoogleMaps>
                </Box>}

                                <Box display='flex' flexDirection='row' className='fieldRow' sx={{marginTop:'2rem'}}>
                                    <Box className='fieldContainer'>
                                        <label className="fw-bold fs-6">Is Mailing Address the same as Residential?</label>
                                        <RadioGroup value={showMailingAddr ? 'no' : 'yes'} onChange={() => {
                                            setShowMailingAddr(!showMailingAddr)
                                            if (!showMailingAddr) {
                                                setFormData((prevData) => {
                                                    return {
                                                        ...prevData,
                                                        mailingInfo: {
                                                            st1: '',
                                                            st2: '',
                                                            primaryPhone: '',
                                                            secondaryPhone: '',
                                                            additionalPhone: '',
                                                            zipCode: '',
                                                            state: '',
                                                            city: '',
                                                            country: '',
                                                        }
                                                    }
                                                })
                                            }

                                        }} row>
                                            <FormControlLabel
                                                value='yes'
                                                control={<Radio />}
                                                label='Yes'
                                                labelPlacement='end'
                                            />
                                            <FormControlLabel
                                                value='no'
                                                control={<Radio />}
                                                label='No'
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </Box>
                                </Box>
                                {showMailingAddr ? (
                                    <Box>
                                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                            <Box className='fieldContainer'>
                                                <TextField
                                                    type="text"
                                                    name="st1"
                                                    size='small'
                                                    className='textField'
                                                    value={formData.mailingInfo.st1}
                                                    onChange={(e) => {
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    st1: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    variant="outlined"
                                                    label="Street 1"
                                                    autoComplete="off"
                                                    error={Boolean(formData.mailingInfo.st1 && !validator.STREET_VALIDATION.pattern.test(formData.mailingInfo.st1))}
                                                    helperText={formData.mailingInfo.st1 && !validator.STREET_VALIDATION.pattern.test(formData.mailingInfo.st1) ? validator.STREET_VALIDATION.message : ''}
                                                />
                                            </Box>
                                            <Box className='fieldContainer'>
                                                <TextField
                                                    type="text"
                                                    name="st2"
                                                    size='small'
                                                    className='textField'
                                                    value={formData.mailingInfo.st2}
                                                    onChange={(e) => {
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    st2: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    variant="outlined"
                                                    label="Street 2"
                                                    autoComplete="off"
                                                    error={Boolean(formData.mailingInfo.st2 && !validator.STREET_VALIDATION.pattern.test(formData.mailingInfo.st2))}
                                                    helperText={formData.mailingInfo.st2 && !validator.STREET_VALIDATION.pattern.test(formData.mailingInfo.st2) ? validator.STREET_VALIDATION.message : ''}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                                <Autocomplete
                                                    disabled={formData.mailingInfo.st1 == '' && formData.mailingInfo.st2 == ''}
                                                    options={Country}
                                                    size='small'
                                                    className='textField'
                                                    value={Country?.find(option  => option.label === formData.mailingInfo.country)}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    onChange={(_, newValue) => {
                                                        const SelectedValue = newValue ? newValue : null
                                                        setSelectedMailingCountry(SelectedValue.value || '')
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    country: SelectedValue ? SelectedValue.label : ''
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" label="- Select Country -" />}
                                                />
                                            </Box>
                                            <Box className='fieldContainer'>
                                                <Autocomplete
                                                    disabled={formData.mailingInfo.country==''}
                                                    options={mailingStates}
                                                    value={formData.mailingInfo.state}
                                                    size='small'
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    onChange={(_, newValue) => {
                                                        const SelectedValue = newValue ? newValue : ''
                                                        setSelectedMailingState(SelectedValue.value)
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    state: SelectedValue ? SelectedValue.label : ''
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" label="- Select State -" />}
                                                />
                                            </Box>

                                        </Box>
                                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                        <Autocomplete
                                            disabled={formData.mailingInfo.state==''}
                                            options={mailingCity}
                                            value={formData.mailingInfo.city}
                                            size='small'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            onChange={(_, newValue) => {
                                                const SelectedValue = newValue ? newValue : ''
                                                setFormData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        mailingInfo: {
                                                            ...prevState.mailingInfo,
                                                            city: SelectedValue ? SelectedValue.value : ''
                                                        }
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label="- Select City -" />}
                                        />
                                    </Box>
                                            <Box className='fieldContainer'>
                                                <TextField
                                                    required={true}
                                                    disabled={formData.mailingInfo.city==''}
                                                    type="text"
                                                    name="zipCode"
                                                    size='small'
                                                    className='textField'
                                                    value={formData.mailingInfo.zipCode}
                                                    onChange={(e) => {
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    zipCode: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    variant="outlined"
                                                    label="Zip Code"
                                                    autoComplete="off"
                                                    error={Boolean(formData.mailingInfo.zipCode && !validator.ZIP_CODE_VALIDATION.pattern.test(formData.mailingInfo.zipCode))}
                                                    helperText={formData.mailingInfo.zipCode && !validator.ZIP_CODE_VALIDATION.pattern.test(formData.mailingInfo.zipCode) ? validator.ZIP_CODE_VALIDATION.message : ''}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                            <Box className='fieldContainer'>
                                                <TextField
                                                    type="text"
                                                    name="primaryPhone"
                                                    size='small'
                                                    className='textField'
                                                    value={formData.mailingInfo.primaryPhone}
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    primaryPhone: formattedValue
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    variant="outlined"
                                                    label="Primary Phone"
                                                    autoComplete="off"
                                                    error={Boolean(formData.mailingInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.mailingInfo.primaryPhone))}
                                                    helperText={formData.mailingInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.mailingInfo.primaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                                />
                                            </Box>
                                            <Box className='fieldContainer'>
                                                <TextField
                                                    type="text"
                                                    name="secondaryPhone"
                                                    size='small'
                                                    className='textField'
                                                    value={formData.mailingInfo.secondaryPhone}
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    secondaryPhone: formattedValue
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    variant="outlined"
                                                    label="Secondary Phone"
                                                    autoComplete="off"
                                                    error={Boolean(formData.mailingInfo.secondaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.mailingInfo.secondaryPhone))}
                                                    helperText={formData.mailingInfo.secondaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.mailingInfo.secondaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                            <Box className='fieldContainer'>
                                                <TextField
                                                    type="text"
                                                    name="additionalPhone"
                                                    size='small'
                                                    className='textField'
                                                    value={formData.mailingInfo.additionalPhone}
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                mailingInfo: {
                                                                    ...prevState.mailingInfo,
                                                                    additionalPhone: formattedValue
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    variant="outlined"
                                                    label="Additional Phone"
                                                    autoComplete="off"
                                                    error={Boolean(formData.mailingInfo.additionalPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.mailingInfo.additionalPhone))}
                                                    helperText={formData.mailingInfo.additionalPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.mailingInfo.additionalPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : null
                                }
                            </Box>
                        {/* <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name='programName'
                                            checked={showProgram}
                                            onChange={() => setShowProgram(!showProgram)}
                                        />
                                    }
                                    label={
                                        <label className="fw-bold fs-6">
                                            Add Program?
                                        </label>
                                    }
                                />
                                {
                                    showProgram ? (
                                        <Box className='fieldContainer'>
                                            <Autocomplete
                                                id='programID'
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                size='small'
                                                className='textField'
                                                options={program}
                                                freeSolo={true}
                                                forcePopupIcon={true}
                                                value={formData.programID || ''}
                                                onChange={(e: { target: any }, newValue) => {
                                                    setFormData({ ...formData, programID: newValue ? newValue : '' });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder='Program Name - Site Name'
                                                        required
                                                    />
                                                )}
                                            />
                                        </Box>
                                    ) : null
                                }
                            </Box>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showInsurance}
                                            onChange={() => setShowInsurance(!showInsurance)}
                                        />
                                    }
                                    label={
                                        <label className="fw-bold fs-6">
                                            Add Insurance ?
                                        </label>
                                    }
                                />
                                {
                                    showInsurance ? (
                                        <Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <TextField
                                                        name='insuranceInfo.insuranceGroup'
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        className='textField'
                                                        autoComplete='off'
                                                        label='Insurance Group'
                                                        value={formData.insuranceInfo.insuranceGroup}
                                                        onChange={(e) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    insuranceInfo: {
                                                                        ...prevState.insuranceInfo,
                                                                        insuranceGroup: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <TextField
                                                        name='insuranceInfo.insuranceCompany'
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        className='textField'
                                                        autoComplete='off'
                                                        label='Insurance Company'
                                                        value={formData.insuranceInfo.insuranceCompany}
                                                        onChange={(e) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    insuranceInfo: {
                                                                        ...prevState.insuranceInfo,
                                                                        insuranceCompany: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : null
                                }
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showContact}
                                            onChange={() => setShowContact(!showContact)}
                                        />
                                    }
                                    label={
                                        <label className="fw-bold fs-6">
                                            Add Contact ?
                                        </label>
                                    }
                                />
                                {
                                    showContact ? (
                                        <Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <TextField
                                                        name='contactFirstName'
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        className='textField'
                                                        autoComplete='off'
                                                        label='Contact First Name'
                                                        value={formData.contactFirstName}
                                                        onChange={(e) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    contactFirstName: e.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <TextField
                                                        name='contactLastName'
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        className='textField'
                                                        autoComplete='off'
                                                        label='Contact Last Name'
                                                        value={formData.contactLastName}
                                                        onChange={(e) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    contactLastName: e.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <TextField
                                                        name='contactPrimaryPhone'
                                                        fullWidth
                                                        variant='outlined'
                                                        type='number'
                                                        size='small'
                                                        className='textField'
                                                        autoComplete='off'
                                                        label='Contact Primary Phone'
                                                        value={formData.contactPrimaryPhone}
                                                        onChange={(e) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    contactPrimaryPhone: e.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <TextField
                                                        name='contactEmail'
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        className='textField'
                                                        autoComplete='off'
                                                        label='Contact Email'
                                                        value={formData.contactEmail}
                                                        onChange={(e) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    contactEmail: e.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row'>
                                                <Box className='fieldContainer'>
                                                    <Autocomplete
                                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        size='small'
                                                        className='textField'
                                                        options={options}
                                                        value={formData.relationtoind}
                                                        onChange={(event, newValue) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    relationtoind: newValue ? newValue : ''
                                                                }
                                                            })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="- Select Contact Relation -" variant="outlined" />}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : null
                                }
                            </Box>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showSharedContact}
                                            onChange={() => setShowSharedContact(!showSharedContact)}
                                        />
                                    }
                                    label={
                                        <label className="fw-bold fs-6">
                                            Add Shared Contact ?
                                        </label>
                                    }
                                />
                                {
                                    showSharedContact ?
                                        <Box display='flex' flexDirection='row'>
                                            <Box className='fieldContainer'>
                                                <Autocomplete
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    size='small'
                                                    className='textField'
                                                    multiple
                                                    options={sharedContact}
                                                    value={formData.sharedContacts}
                                                    onChange={(event, newValue) => {
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                sharedContacts: newValue ? newValue : ''
                                                            }
                                                        })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="- Select Contact Relation -" variant="outlined" />}
                                                />
                                            </Box>
                                        </Box> : null
                                }
                            </Box>

                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showAllergy}
                                            onChange={() => setShowAllergy(!showAllergy)}
                                        />
                                    }
                                    label={
                                        <label className="fw-bold fs-6">
                                            Add Allergy?
                                        </label>
                                    }
                                />
                                {
                                    showAllergy ?

                                        <Box display='flex' flexDirection='row'>
                                            <Box className='fieldContainer'>
                                                <GetICDValues
                                                    field={allergyField}
                                                    handleInputChange={(event, newValue) => {
                                                        setFormData((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                allergyName: newValue ? newValue : ''
                                                            }
                                                        })
                                                    }}
                                                ></GetICDValues>
                                            </Box>
                                        </Box> : null
                                }
                            </Box>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={diagnosis}
                                            onChange={() => setDiagnosis(!diagnosis)}
                                        />
                                    }
                                    label={
                                        <label className="fw-bold fs-6">
                                            Add Diagnosis?
                                        </label>
                                    }
                                />
                                {
                                    diagnosis ? (
                                        <Box>
                                            <Box display='flex' flexDirection='row' sx={{ marginBottom: '7px' }}>
                                                <Box className='fieldContainer'>
                                                    <Autocomplete
                                                        options={codeTypes}
                                                        size='small'
                                                        id='diagnosisCodeType'
                                                        getOptionLabel={(option) => option.label}
                                                        value={codeTypes.find((option) => option.value === codeType) || null}
                                                        onChange={(_, newValue) => {
                                                            if (newValue) {
                                                                setCodeType(newValue.value);
                                                                setFormData((prevState) => {
                                                                    return {
                                                                        ...prevState,
                                                                        primaryDiagnosis: '',
                                                                        diagnosisCodeType: newValue.value,
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label='Diagnosis Code Type' />}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row'>
                                                <Box className='fieldContainer'>
                                                    <Autocomplete
                                                        id='primaryDiagnosis'
                                                        size="small"
                                                        options={filteredOptions}
                                                        onInputChange={(_, newValue) => loadOptions(newValue)}
                                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        onChange={(_, newValue) => {
                                                            setFormData((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    primaryDiagnosis: newValue ? newValue : ''
                                                                }
                                                            })
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`Search for ${codeType}`}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                            
                                                                />
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : null
                                }
                            </Box>
                        </Box> */}
                      

                        <Box style={{ marginTop: '24px', }}>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'right',
                                borderBottomLeftRadius: '4px',
                                borderBottomRightRadius: '4px',
                                padding: '10px 0',
                            }}> 
                                <Button
                                    type='button'
                                    sx={{ padding: '4px', margin: '4px' }}
                                    variant='contained'
                                    style={{
                                        color: '#000',
                                        backgroundColor: '#fff'
                                    }}
                                    onClick={() => history.push('/admin/care/individual-intake/list')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    sx={{ padding: '4px', margin: '4px' }}
                                    variant='contained'
                                    style={{
                                        marginLeft: '6px',
                                        backgroundColor: '#28a745',
                                        color: '#fff'
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default NewIndividual
