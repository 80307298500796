import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addSite } from '../store/action'
import { toast } from 'react-toastify'
import * as validator from '../../../../../reusable-components/Validation/Validations'
import { SITE_ADDRESS_INPUT } from '../../GENERAL_CONSTANTS'
import { useEffect, useState } from 'react'
import {
    TextField, Button, Box, FormControlLabel, Checkbox
} from '@mui/material'
import { State, City }  from 'country-state-city';
import GoogleMaps from '../../../../../reusable-components/GoogleMaps/GoogleMaps';
import AddressForm from '../../../../../reusable-components/Adress/AddressForm';
const selectAuth = (state) => state.auth;
const New = () => {
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;
    const dispatch = useDispatch();
    const history = useHistory()
    const [uiElements, setUiElements] = useState([])
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState([]);
    const [showMaps, setShowMaps] = useState(false)
    const [formData, setFormData] = useState({
        company_code: companyid,
        siteName:'',
        siteID:'',
        siteCode:'',
        programCostCenterNumber:'',
            primaryPhone: '',
            secondaryPhone: '',
            additionalPhone: '',
            country: '',
            st1: '',
            st2: '',
            city: '',
            state: '',
            zipCode: '',
            accuracy:90,
    });
    const [center, setCenter] = useState({
        lat: -3.745,
        lng: -38.523,
      })
      const [markerPosition, setMarkerPosition] = useState({
        lat: -3.745,
        lng: -38.523,
      })
      const changeLocationOnGoogleMaps = async(showMap) => {
        if(showMap){
        const { st1, st2, city, state, country, zipCode } = formData?.addrInfo
        const addressToCheck = st1 +' '+ st2 +', '+ city +', '+ state +', '+ zipCode +', '+ country
        const geocoder = new window.google.maps.Geocoder()
        const geocode = await geocoder.geocode({address:addressToCheck})
        const newPosition = {
            lat:geocode.results[0].geometry.location.lat(),
            lng:geocode.results[0].geometry.location.lng()
        }
        setMarkerPosition(newPosition)
        setCenter(newPosition)
        }
        setShowMaps(showMap)
    }

    useEffect(() => {
        const fetchData = async () => {

            try{
                 const stateOptions = selectedCountry ? State.getStatesOfCountry(selectedCountry).map((state) =>  ({
                        label: state.name,
                        value: state.isoCode,
                      })) : [];

                 const cityOptions = selectedState ? City.getCitiesOfState(selectedCountry, selectedState).map((city) => (
                    {
                            label: city.name,
                            value: city.name,
                          })) : [];
                          setStates(stateOptions)
                          setCity(cityOptions)
            } catch(err){
                console.log(err)
            }
        }
        fetchData()
      }, [selectedCountry, selectedState]);

    const handleSubmitForm = async (formData) => {
        try {
        let temp = {
            company_code: companyid,
            siteID: Number(formData.siteID),
            siteName: formData.siteName,
            siteCode: formData.siteCode,
            st1: formData?.addrInfo?.st1,
            st2: formData?.addrInfo?.st2,
            city: formData?.addrInfo?.city,
            state: formData?.addrInfo?.state,
            country: formData?.addrInfo?.country,
            programCostCenterNumber: Number(formData.programCostCenterNumber),
            primaryPhone: Number(formData?.addrInfo?.primaryPhone),
            secondaryPhone: Number(formData?.secondaryPhone),
            zipCode: Number(formData?.addrInfo?.zipCode),
            accuracy: formData?.accuracy
        }

        temp.lat = markerPosition.lat;
        temp.lng = markerPosition.lng;
        const response = await dispatch(addSite(temp))
        if(response.payload.status === 400 && response.payload.success === false) {
            toast.error(response.payload.message)
        } else if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/general/site/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    } catch (err) {
        if(err.toString().includes('MapsRequestError: GEOCODER_GEOCODE: ZERO_RESULTS'))
         toast.error('Please enter a valid address')
        else
         toast.error('Error Adding Site')
         console.log(err)
     }
    
    };

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <div>
        <div className="card">
            <div className="card-body">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2 className='pb-2'>Add Site</h2>
                </div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitForm(formData);
                }}>
                        <Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        required={true}
                                        type="text"
                                        name="siteName"
                                        size='small'
                                        className='textField'
                                        value={formData.siteName}
                                        onChange={(e) => {
                                            setFormData((prevState) => {
                                                return {
                                                    ...prevState,
                                                        siteName: e.target.value
                                                }
                                            })
                                        }}
                                        variant="outlined"
                                        label="Site Name"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="number"
                                        name="siteID"
                                        size='small'
                                        className='textField'
                                        value={formData.siteID}
                                        onChange={(e) => {
                                            setFormData((prevState) => {
                                                return {
                                                    ...prevState,
                                                        siteID: e.target.value
                                                }
                                            })
                                        }}
                                        variant="outlined"
                                        label="Site ID"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="siteCode"
                                        size='small'
                                        className='textField'
                                        value={formData.siteCode}
                                        onChange={(e) => {
                                            setFormData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    siteCode: e.target.value
                                                }
                                            })
                                        }}
                                        variant="outlined"
                                        label="Site Code"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="number"
                                        name="programCostCenterNumber"
                                        size='small'
                                        className='textField'
                                        value={formData.programCostCenterNumber}
                                        onChange={(e) => {
                                            setFormData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    programCostCenterNumber: e.target.value
                                                }
                                            })
                                        }}
                                        variant="outlined"
                                        label="Program Cost Center Number"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <AddressForm
                                addressInfo={SITE_ADDRESS_INPUT[0]}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="number"
                                        name="accuracy"
                                        size='small'
                                        className='textField'
                                        value={formData.accuracy}
                                        onChange={(e) => {
                                            setFormData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    // addrInfo: {
                                                        // ...prevState.addrInfo,
                                                        accuracy: e.target.value
                                                    // }
                                                }
                                            })
                                        }}
                                        variant="outlined"
                                        label="Accuracy (in yards)"
                                        autoComplete="off"
                                        
                                        // error={Boolean(formData.addrInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.primaryPhone))}
                                        // helperText={formData.addrInfo.primaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.addrInfo.primaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="secondaryPhone"
                                        size='small'
                                        className='textField'
                                        value={formData.secondaryPhone}
                                        onChange={(e) => {
                                            const phoneNumber = e?.target?.value?.replace(/[^0-9]/g, '').slice(0, 10)
                                            setFormData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    secondaryPhone: phoneNumber
                                                }
                                            })
                                        }}
                                        variant="outlined"
                                        label="Secondary Phone"
                                        autoComplete="off"
                                        error={Boolean(formData.secondaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.secondaryPhone))}
                                        helperText={formData.secondaryPhone && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formData.secondaryPhone) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' className='fieldRow'>
                            <Box className='fieldContainer'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={formData?.addrInfo?.zipCode == ''}
                                            checked={showMaps}
                                            onChange={() => {
                                                if (showMaps) {
                                                    changeLocationOnGoogleMaps(false)
                                                } else {
                                                    changeLocationOnGoogleMaps(true)
                                                }
                                            }}
                                        />
                                    }
                                />
                                 <label style={{ fontSize: '14px', fontWeight: '500', marginLeft: '-15px' }}>
                                   Show Location on Map
                                </label>
                        </Box>
                        </Box>
               {showMaps &&  <Box>
                   <GoogleMaps center={center} markerPosition={markerPosition} 
                   ></GoogleMaps>
                </Box>}

                    <Box style={{ marginTop: '24px', }}>
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'right',
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            padding: '10px 0',
                        }}>
                            <Button
                                type='submit'
                                sx={{ padding: '4px', margin: '4px' }}
                                variant='contained'
                                style={{
                                    marginLeft: '6px',
                                    backgroundColor: '#28a745',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#218838',
                                    },
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    </div >
    )
}

export default New


