import { useState, useEffect } from 'react'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'
import { getSites } from '../../../../admin/general/site/store/action'
import { Link, useHistory } from 'react-router-dom'
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action';
import { getBillingProvider } from '../../../../admin/billing/billing-provider/store/action';
import { addProfessionalClaim } from '../store/action';
const selectAuth = (state) => state.auth;

const NewProfessionalClaimForm = () => {

    const [individual, setIndividual] = useState(false)
    const [sites, setSites] = useState([])
    const [serviceDescription, setServiceDescription] = useState([])
    const [billingProvider, setBillingProvider] = useState([])
    const [desCode, setDesCode] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code;

    const handleChange = () => {
        setIndividual(!individual)
    }

    useEffect(() => {
        const fetchData = async () => {
            const siteResult = await dispatch(getSites(companyid))
            const tempSite = siteResult.payload.data
            const serviceResult = await dispatch(GetDescriptionCode(companyid))
            const tempDes = serviceResult.payload?.data
            const BPResult = await dispatch(getBillingProvider(companyid))
            const tempBP = BPResult.payload?.data
            setBillingProvider(tempBP)
            setDesCode(tempDes)
            setSites(tempSite)

            const options = tempDes.map((data) => (
                { label: data.serviceDescription, value: data.serviceDescription }
            ));
            setDesCode(options);
        }

        fetchData()
    }, [])

    const initialValues = {
        billingProvider: '',
        renderingProvider: '',
        payer: '',
        referringProvider: '',
        serviceFacilityLocation: '',
        signatureOnFire: '',
        placeOfService: '',
        TCNNumber: '',
        claimFrequencyTypeCode: '',
        medicalAssignmentCode: '',
        filingIndicator: '',
        benefitsIndicator: '',
        payerResponsibility: '',
        relationToIndividual: '',
        specialProgramCode: '',
        delayReasonCode: '',
        providerServiceLine: false,
        informationCode: '',
        patientSignature: '',
        claimGenSearch: {
            serviceDescription: [],
            serviceDateFrom: '',
            serviceDateTo: '',
        },
        noteInfo: {
            noteReferenceCode: '',
            note: '',
        },
        comment: '',
    }



    const style = {
        control: (provided, state) => ({
            ...provided,
            minHeight: "45px",
            border: "1px solid #ced4da",
            borderRadius: "0.50rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(0, 123, 255, 0.25)" : null,
            "&:hover": {
                borderColor: "#b8c2cc",
            },
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "#6c757d",
        }),
        menu: (provided, state) => ({
            ...provided,
            margin: 0,
            borderRadius: "0.25rem",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#007bff" : state.isFocused ? "#e7eaf1" : "white",
            color: state.isSelected ? "white" : "black",
            fontWeight: state.isSelected ? "bold" : "normal",
            "&:hover": {
                backgroundColor: state.isSelected ? "#007bff" : "#f8f9fa",
                color: state.isSelected ? "white" : "black",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "black",
            fontWeight: "bold",
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "black",
            fontWeight: "500",
            fontSize: "15px",
        }),
    };






    return (
        <Formik
            // validationSchema={registrationSchema}
            enableReinitialize
            initialValues={
                initialValues
            }
            onSubmit={(values, { isSubmitting }) => {
                values.claimGenSearch.serviceDescription = serviceDescription
                console.log(values)
                dispatch(addProfessionalClaim(values))
                history.push('/professionalclaim/professional-claim/list')
            }}
        >
            {({ isSubmitting, resetForm }) => (
                <Form>
                    <div className='fv-row mb-7 fv-plugins-icon-container p-5 card'>
                        <div className='card-body'>
                            <h1 className='mb-5'>Professional Claim Generator</h1>
                            <div className='row'>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Select Payer</label>
                                    <Field
                                        as='select'
                                        name='payer'
                                        autoComplete='off'
                                        placeholder='- Select Payer -'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden>- Select Payer -</option>
                                        <option value='FL MMIS: Florida Medicaid - 77027 (Prof.)' >FL MMIS: Florida Medicaid - 77027 (Prof.)</option>
                                        <option value='THERAP-MANUAL: Manual Billing - 99 (Prof. & Inst.)' >THERAP-MANUAL: Manual Billing - 99 (Prof. & Inst.)</option>
                                    </Field>
                                    <ErrorMessage name='payer' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Billing Provider</label>
                                    <Field
                                        as='select'
                                        name='billingProvider'
                                        autoComplete='off'
                                        className='form-select form-select-lg mb-3'
                                        placeholder='Select Billing Provider'
                                    >
                                        <option hidden >- Select Billing Provider -</option>
                                        {billingProvider.map((data, index) => (
                                            <option value={data.organizationName} key={index}>{data.organizationName}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name='billingProvider' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Rendering Provider</label>
                                    <Field
                                        as='select'
                                        name='renderingProvider'
                                        autoComplete='off'
                                        className='form-select form-select-lg mb-3'
                                        placeholder='Select Rendering Provider'
                                    >
                                        <option hidden >- Select Rendering Provider -</option>
                                        {sites.map((data, index) => (
                                            <option value={data.siteName} key={index}>{data.siteName}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name='renderingProvider' component='div' className='text-danger' />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-0 mt-14'>
                                    <label className='fw-bold fs-6 mb-0'>
                                        Use Rendering Provider in Service Line
                                        <Field
                                            type='checkbox'
                                            name='providerServiceLine'
                                            className='form-check-input mx-6'
                                        ></Field>
                                    </label>
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Referring Provider</label>
                                    <Field
                                        as='select'
                                        name='referringProvider'
                                        autoComplete='off'
                                        className='form-select form-select-lg mb-3'
                                        placeholder='Select Referring Provider'
                                    >
                                        <option hidden >- Select Rendering Provider -</option>
                                        {sites.map((data, index) => (
                                            <option value={data.siteName} key={index}>{data.siteName}</option>
                                        ))}
                                    </Field>
                                </div>
                                {/* <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 mt-6 ' >
                                    <label className='fw-bold fs-6 mb-2' >Service Facility Location</label>
                                    <Field
                                        as='select'
                                        name='serviceFacilityLocation'
                                        autoComplete='off'
                                        options={ServiceFacilityOptions}
                                        component={SelectField}
                                        placeholder='Select Service Facility Location'
                                    />
                                </div> */}
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Signature On File</label>
                                    <Field
                                        as='select'
                                        name='signatureOnFire'
                                        autoComplete='off'
                                        placeholder='Select Signature On Fire'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Signature -</option>
                                        <option value='Yes' >Yes</option>
                                        <option value='No' >No</option>
                                    </Field>
                                    <ErrorMessage name='signatureOnFire' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Place Of Service</label>
                                    <Field
                                        as='select'
                                        name='placeOfService'
                                        autoComplete='off'
                                        placeholder='Select Place Of Service'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Place of Service -</option>
                                        <option value='Yes' >Yes</option>
                                        <option value='No' >No</option>
                                    </Field>
                                    <ErrorMessage name='placeOfService' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Claim Frequency Type Code</label>
                                    <Field
                                        as='select'
                                        name='claimFrequencyTypeCode'
                                        autoComplete='off'
                                        placeholder='Select Claim Frequency Type Code'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Claim Frequency Code -</option>
                                        <option value="01-Hospital">01-Hospital</option>
                                        <option value="02-TeleHealth">02-TeleHealth</option>
                                        <option value="03-School">03-School</option>
                                        <option value="04-Homeless Shelter">04-Homeless Shelter</option>
                                        <option value="05-Indian Health Service Free-standing Facility">05-Indian Health Service Free-standing Facility</option>
                                        <option value="06-Indian Health Service Provider-based Facility">06-Indian Health Service Provider-based Facility</option>
                                        <option value="07-Tribal 638 Free-standing Facility">07-Tribal 638 Free-standing Facility</option>
                                        <option value="08-Tribal 638 Provider-based Facility">08-Tribal 638 Provider-based Facility</option>
                                        <option value="10-Unassigned">10-Unassigned</option>
                                        <option value="11-Office">11-Office</option>
                                        <option value="12-Home">12-Home</option>
                                        <option value="13-Assisted Living Facility">13-Assisted Living Facility</option>
                                        <option value="14-Group Home">14-Group Home</option>
                                        <option value="15-Mobile Unit">15-Mobile Unit</option>
                                        <option value="18-Place of Employment-Worksite">18-Place of Employment-Worksite</option>
                                        <option value="21-Inpatient Hospital">21-Inpatient Hospital</option>
                                        <option value="22-Outpatient Hospital">22-Outpatient Hospital</option>
                                        <option value="31-Skilled Nursing Facility">31-Skilled Nursing Facility</option>
                                        <option value="32-Nursing Facility">32-Nursing Facility</option>
                                        <option value="33-Custodial Care Facility">33-Custodial Care Facility</option>
                                        <option value="34-Hospice">34-Hospice</option>
                                        <option value="49-Independent Clinic">49-Independent Clinic</option>
                                        <option value="50-Federally Qualified Health Center">50-Federally Qualified Health Center</option>
                                        <option value="51-Non-Residential Substance Abuse Treatment Facility">51-Non-Residential Substance Abuse Treatment Facility</option>
                                        <option value="52-Residential Substance Abuse Treatment Facility">52-Residential Substance Abuse Treatment Facility</option>
                                        <option value="53-Community Mental Health Center">53-Community Mental Health Center</option>
                                        <option value="54-Intermediate Care Facility/Intellectual Disability">54-Intermediate Care Facility/Intellectual Disability</option>
                                        <option value="55-Residential Substance Abuse Treatment Facility with Withdrawal Management">55-Residential Substance Abuse Treatment Facility with Withdrawal Management</option>
                                        <option value="56-Psychiatric Residential Treatment Facility">56-Psychiatric Residential Treatment Facility</option>
                                        <option value="57-Non-Residential Substance Abuse Treatment Facility with Withdrawal Management">57-Non-Residential Substance Abuse Treatment Facility with Withdrawal Management</option>
                                        <option value="58-Surgical Center">58-Surgical Center</option>
                                        <option value="60-Mass Immunization Center">60-Mass Immunization Center</option>
                                        <option value="61-Comprehensive Inpatient Rehabilitation Facility">61-Comprehensive Inpatient Rehabilitation Facility</option>
                                        <option value="65-End-Stage Renal Disease Treatment Facility">65-End-Stage Renal Disease Treatment Facility</option>
                                    </Field>
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Original TCN/ICN Number</label>
                                    <Field
                                        type='number'
                                        name='TCNNumber'
                                        autoComplete='off'
                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                        placeholder='Original TCN/ICN Number'
                                    ></Field>
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Medicare Assignment Code</label>
                                    <Field
                                        as='select'
                                        name='medicalAssignmentCode'
                                        autoComplete='off'
                                        placeholder='Select Medicare Assignment Code'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Medicare Assignment Code -</option>
                                        <option value='A-Assigned' >A-Assigned</option>
                                        <option value='B-Assignment Accepted on Clinical Lab Services Only' >B-Assignment Accepted on Clinical Lab Services Only</option>
                                        <option value='C-Not Assigned' >C-Not Assigned</option>
                                        <option value='P-Patient Refuses to Assign Benefits' >P-Patient Refuses to Assign Benefits</option>

                                    </Field>
                                    <ErrorMessage name='medicalAssignmentCode' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Release of Information Code</label>
                                    <Field
                                        as='select'
                                        name='informationCode'
                                        autoComplete='off'
                                        placeholder='Select Release of Information Code'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Information Code -</option>
                                        <option value='A-Appropriate Release of Information on File at Health Care Service Provider' >A-Appropriate Release of Information on File at Health Care Service Provider</option>
                                        <option value='I-Informed Consent to Release Medical Information for Diagnoses' >I-Informed Consent to Release Medical Information for Diagnoses</option>
                                        <option value='M-The Provider has Limited or Restricted Ability to Release Data Related to a Claim' >M-The Provider has Limited or Restricted Ability to Release Data Related to a Claim</option>
                                        <option value='N-No, Provider is not Allowed to Release Data' >N-No, Provider is not Allowed to Release Data</option>
                                        <option value='O-On File at Payor or at Plan Sponsor' >O-On File at Payor or at Plan Sponsor</option>
                                        <option value='Y-Yes, Provider has a Signed Statement Permitting Release of Medical Billing Related to a Claim' >Y-Yes, Provider has a Signed Statement Permitting Release of Medical Billing Related to a Claim</option>
                                    </Field>
                                    <ErrorMessage name='informationCode' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Patient Signature Source Code</label>
                                    <Field
                                        as='select'
                                        name='patientSignature'
                                        autoComplete='off'
                                        placeholder='Select Patient Signature Source Code'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Patient Signature -</option>
                                        <option value='B-Signed Signature Authorization Form or Forms are on File' >B-Signed Signature Authorization Form or Forms are on File</option>
                                        <option value='C-Signed HCFA-1500 Claim Form on File' >C-Signed HCFA-1500 Claim Form on File</option>
                                        <option value='P-Signature generated by Provider because the Patient was not Physically Present for Services' >P-Signature generated by Provider because the Patient was not Physically Present for Services</option>
                                    </Field>
                                    <ErrorMessage name='patientSignature' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Claim Filing Indicator</label>
                                    <Field
                                        as='select'
                                        name='filingIndicator'
                                        autoComplete='off'
                                        placeholder='Select Claim Filing Indicator'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option>- Select Filling Indicator -</option>
                                        <option value='Medicaid'>Medicaid</option>
                                    </Field>
                                    <ErrorMessage name='fillingIndicator' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Assignment of Benefits Indicator</label>
                                    <Field
                                        as='select'
                                        name='benefitsIndicator'
                                        autoComplete='off'
                                        placeholder='Select Assignment of Benefits Indicator'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Benefits Indicator -</option>
                                        <option value='Yes' >Yes</option>
                                        <option value='No' >No</option>
                                    </Field>
                                    <ErrorMessage name='benefitsIndicator' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Payer Responsibility</label>
                                    <Field
                                        as='select'
                                        name='payerResponsibility'
                                        autoComplete='off'
                                        placeholder='Select Payer Responsibility'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Payer Responsibility -</option>
                                        <option value='Primary' >Primary</option>
                                        <option value='Secondary' >Secondary</option>
                                        <option value='Tertiary' >Tertiary</option>
                                        <option value='Unknown' >Unknown</option>
                                    </Field>
                                    <ErrorMessage name='payerResponsibility' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >RelationShip To Individual</label>
                                    <Field
                                        as='select'
                                        name='relationToIndividual'
                                        autoComplete='off'
                                        placeholder='Select RelationShip To Individual'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select RelationShip To Individual -</option>
                                        <option value='Spouse' >Spouse</option>
                                        <option value='Self' >Self</option>
                                        <option value='Child' >Child</option>
                                        <option value='Employee' >Employee</option>
                                        <option value='Unknown' >Unknown</option>
                                        <option value='Life Partner' >Life Partner</option>
                                        <option value='Other RelationShip' >Other RelationShip</option>
                                    </Field>
                                    <ErrorMessage name='relationToIndividual' component='div' className='text-danger' />
                                </div>
                                {/* <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Special Program Code</label>
                                    <Field
                                        as='select'
                                        name='specialProgramCode'
                                        autoComplete='off'
                                        placeholder='Select Special Program Code'
                                    >
                                    <option  hidden >- Select  -</option>
                                        <option value='' ></option>
                                        <option value='' ></option>
                                    </Field>
                                </div> */}
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Delay Reason Code</label>
                                    <Field
                                        as='select'
                                        name='delayReasonCode'
                                        autoComplete='off'
                                        placeholder='Select Special Program Code'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Delay Reason Code -</option>
                                        <option value='1-Proof of Eligibility Unknown or Unavailable' >1-Proof of Eligibility Unknown or Unavailable</option>
                                        <option value='2-Litigation' >2-Litigation</option>
                                        <option value='3-Authorization Delays' >3-Authorization Delays</option>
                                        <option value='4-Delay in Certifying Provider' >4-Delay in Certifying Provider</option>
                                        <option value='5-Delay in Supplying Billing Forms' >5-Delay in Supplying Billing Forms</option>
                                        <option value='6-Delay in Delivery of Custom-made Appliances' >6-Delay in Delivery of Custom-made Appliances</option>
                                        <option value='7-Third Party Processing Delay' >7-Third Party Processing Delay</option>
                                        <option value='8-Delay in Eligibility Determination' >8-Delay in Eligibility Determination</option>
                                        <option value='9-Original Claim Rejected or Denied Due to a Reason Unrelated to the Billing Limitation Rules' >9-Original Claim Rejected or Denied Due to a Reason Unrelated to the Billing Limitation Rules</option>
                                        <option value='10-Administration Delay in the Prior Approval Process' >10-Administration Delay in the Prior Approval Process</option>
                                        <option value='11-Other' >11-Other</option>
                                        <option value='15-Natural Disaster/State of Emergency' >15-Natural Disaster/State of Emergency</option>
                                    </Field>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='fv-row mb-7 fv-plugins-icon-container p-5 card'>
                        <div className='card-body'>
                            <h1 className='mb-5'>Claim Generator Search</h1>
                            <div className="mt-3 alert alert-success">
                                Select Date Range and Service
                            </div>
                            <div className='row'>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 '>
                                    <label className='fw-bold fs-6 mb-2'>Service Date From</label>
                                    <Field
                                        type='date'
                                        name='claimGenSearch.serviceDateFrom'
                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                        placeholder=''
                                    ></Field>
                                    <ErrorMessage name='claimGenSearch.serviceDateFrom' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 '>
                                    <label className='fw-bold fs-6 mb-2'>Service Date To</label>
                                    <Field
                                        type='date'
                                        name='claimGenSearch.serviceDateTo'
                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                        placeholder=''
                                    ></Field>
                                    <ErrorMessage name='claimGenSearch.serviceDateTo' component='div' className='text-danger' />
                                </div>
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Service Description/Code</label>
                                    <Select
                                        as='select'
                                        name='claimGenSearch.serviceDescription'
                                        placeholder='- Select Service Description -'
                                        options={desCode}
                                        isMulti
                                        styles={style}
                                        onChange={(e) => setServiceDescription(e)}
                                    ></Select>
                                    <ErrorMessage name='claimGenSearch.serviceDescription' component='div' className='text-danger' />
                                </div>


                            </div>
                            <div className='col'>
                                <button
                                    className='btn  btn-success float-end'
                                    type='button'
                                >
                                    Next
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className='fv-row mb-7 fv-plugins-icon-container p-5 card'>
                        <div className='card-body'>
                            <h1 className='mb-5'>X12-837 Note</h1>
                            <div className="mt-3 alert alert-success">
                                This note will be added to X12-837.
                            </div>
                            <div className="row">
                                <div className='col-col-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 ' >
                                    <label className='fw-bold fs-6 mb-2' >Note Reference Code</label>
                                    <Field
                                        as='select'
                                        name='noteInfo.noteReferenceCode'
                                        autoComplete='off'
                                        placeholder='- Please Select -'
                                        className='form-select form-select-lg mb-3'
                                    >
                                        <option hidden >- Select Note Reference Code -</option>
                                        <option value='ADD-Additional Information' >ADD-Additional Information</option>
                                        <option value='UPI-Updated Information' >UPI-Updated Information</option>
                                    </Field>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                    <label className=' fw-bold fs-6 mb-2'>Note</label>
                                    <Field
                                        type="text"
                                        name='noteInfo.note'
                                        className="form-control form-control-solid "
                                        placeholder="">
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fv-row mb-7 fv-plugins-icon-container p-5 card'>
                        <div className='card-body'>
                            <h1 className='mb-5'>Claim Generator Search</h1>
                            <div className="mt-3 alert alert-success">
                                Select Date Range and Service
                            </div>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                    <label className=' fw-bold fs-6 mb-2'>Comments</label>
                                    <Field
                                        as="textarea"
                                        name='comment'
                                        rows='6'
                                        className="form-control form-control-solid "
                                        placeholder="">
                                    </Field>
                                </div>

                            </div>
                        </div>
                        <div className='d-flex justify-content-start pb-6 mt-6'>
                            <div className='mx-3'>
                                <Link
                                    to='/billing/professionalclaim'
                                    className='btn btn-primary btn-sm'
                                >
                                    Cancel
                                </Link>
                            </div>
                            <div>
                                <button
                                    className='btn btn-sm btn-success'
                                    type='submit'
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>


                </Form>
            )}
        </Formik>
    )
}

export default NewProfessionalClaimForm