import { useEffect, useState } from 'react'
import axios from 'axios'
import '../../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteContact, getClientContacts } from '../store/action'
import ContactNew from '../new/New'
import EditContacts from './Edit'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { CONTACT_LIST_COLUMNS_DATA_MAPPING, CONTACT_LIST_COLUMNS, CONTACT_LIST_SORTING_COLUMNS } from '../../care_table_constants'
import { permissions } from '../../../../../../_constants/permissions'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'
import AddButton from '../../../../../reusable-components/Buttons/AddButton'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'
import { AppDispatch } from '../../../../../../setup/redux/Store'

const selectAuth = (state: RootState) => state.auth

const ListContacts = () => {
    const history = useHistory()
    const parameters: { id: string } = useParams()
    const [contactsListing, setContactsListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [showEditingForm, setShowEditingForm] = useState(true)
    const [selectedContact, setSelectedContact] = useState<any>({})
    const [client, setClient] = useState<any>({})
    const [deletedId, setDeletedId] = useState('')
    const [showNewForm, setShowNewForm] = useState(false)
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })
    const dispatch = useDispatch<AppDispatch>()

    const auth = useSelector(selectAuth)
    const userPermissions = auth.user.roleId;
    useEffect(() => {
        const companyId: string = auth.user?.company_code
        if (!parameters.id) {
            history.push('/admin/care/contact/list')
        } else {
            const fetchContact = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
                try {
                    const clientID = parameters.id
                    const [ClientsData, ContactData]: any = await Promise.all([
                        axios.get('/clients/getOne/' + parameters.id),
                        dispatch(getClientContacts({ clientID, page, pageSize, sortBy, sortDir, searchTerm: queryData.searchTerm })),
                    ])
                    const { data , currentPage, totalCounts } = ContactData.payload.data;
                    setFilteredData(data)
                    setContactsListing(data)
                    setClient(ClientsData.data?.client)
                    setQueryData((prevState)=> {
                        return {
                            ...prevState,
                            currentPage,
                            totalCounts,
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
            fetchContact(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
        }
    }, [ deletedId, showEditingForm, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

    const onDelete = async (row: { _id: string }) => {
        try {
           const response = await dispatch(deleteContact(row._id))
           const { success, status }  = response?.payload
            if (status === 200 && success === true) {
            toast.success('Contact deleted successfully!')
            setDeletedId(row._id)
            }
        } catch (error) {
            console.error('Error deleting data:', error)
            toast.error('Error deleting client') // Display error toast
        }
    }

    const onUpdate = (row: any) => {
        setSelectedContact(row)
        setShowEditingForm(false)
    }

    const onClickAddButton = () => {
        history.push(`/admin/care/contact/new/`+ parameters.id)
    }

    return (
        <>
            {showNewForm === true && showEditingForm === false ? (
                <ContactNew
                    setShowEditingForm={setShowEditingForm}
                    setShowNewForm={setShowNewForm}
                    clientInfo={client}
                />
            ) : showNewForm === false && showEditingForm === false ? (
                <EditContacts setShowEditingForm={setShowEditingForm} selectedContact={selectedContact} />
            ) : showNewForm === false && showEditingForm === true ? (
                <>
                    <div className='card'>
                        <div className='card-body'>
                            <TableTitle
                                title={`List of Contacts under: ${client.firstName} ${client.lastName}`}
                            ></TableTitle>
                            <TableWrapper
                                searchField={
                                    <TableSearchBar
                                        label='Search'
                                        variant='outlined'
                                        size='small'
                                        value={searchTerm}
                                        setQueryData={setQueryData}
                                        setSearchTerm={setSearchTerm}
                                        margin='normal'
                                        inputAdornmentPosition='start'
                                        />
                                }
                                tableButtons={
                                    <>
                                    <AddButton
                                     onClick={onClickAddButton}/>
                                    <BackButton />
                                    </>

                                }
                                dynamicTable={
                                    <DynamicTable
                                        columns={CONTACT_LIST_COLUMNS}
                                        data={filteredData}
                                        canUpdate={true}
                                        canDelete={hasPermission(userPermissions, permissions.admin_care_contact_delete)}
                                        columnDataMapping={CONTACT_LIST_COLUMNS_DATA_MAPPING}
                                        onDelete={onDelete}
                                        onUpdate={onUpdate}
                                        sortingColumns={CONTACT_LIST_SORTING_COLUMNS}
                                        title='Are you sure you want to delete this contact?'
                                        setQueryData={setQueryData}
                                        queryData={queryData}
                                    />
                                }
                            />
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default ListContacts
