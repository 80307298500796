import React, {useEffect, useState} from 'react'
import {ADD_NOTE_ENTRY_FORM_INPUTS} from '../../ADD_NOTES_FORM_CONSTANTS'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {useHistory, useParams} from 'react-router-dom'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import '../../Style.css'

export default function NewNoteEntries() {
  const authUser = useSelector((state: {auth: any}) => state.auth)
  const history = useHistory()
  const params: { id: string } = useParams()
  const [uiElements, setUIElements] = useState(ADD_NOTE_ENTRY_FORM_INPUTS('', '', ''))
  const [userAPIResponse, setUserAPIResponse] = useState({
    firstName: '',
    lastName: ''
  })
  const [noteAPIResponse, setNoteAPIResponse] = useState({
    scoringMethod: {scoringLevels: []},
    tasks: [],
    location: {
      address: ''
    },
    clientId: '',
    clients: []
  })

  const [taskAndScoreList, setTaskAndScoreList] = useState([])
  const [formData, setFormData] = useState({
    startDate: new Date(),
    beginTime: new Date(),
    endTime: new Date(),
    location: '',
    enteredBy: '',
  })

  const buttons = [
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]
  // use effect to fetch data
  useEffect(() => {
    fetchData()
  }, [])

  // useeffect to set UI and data in UI
  useEffect(() => {
    setUIAndData()
  }, [noteAPIResponse])

  const fetchData = () => {
    let axiosArray = []

    let newPromise
    newPromise = axios({
      method: 'get',
      url: `/user/getOne/${authUser.user._id}`,
    })
    axiosArray.push(newPromise)
    newPromise = axios({
      method: 'get',
      url: `/notes/getNoteById/${params.id}`,
    })
    axiosArray.push(newPromise)

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          let localList = []
          responses[1].data.tasks.map((eachTask) => {
            localList.push({
              taskId: eachTask._id,
              score: '',
              description: '',
            })
          })

          setTaskAndScoreList(localList)
          setUserAPIResponse(responses[0].data)
          setNoteAPIResponse(responses[1].data)
        })
      )
      .catch((error) => console.error(error))
  }

  const setUIAndData = () => {
    let scoringMethodLevels = []
    let uiArray = []
    let lastRow = 7
    let i = 0
    const nameOfUser = `${userAPIResponse.firstName
      .toString()
      .toUpperCase()} ${userAPIResponse.lastName.toString().toUpperCase()}`
      const location = `${noteAPIResponse?.location?.address.toString()
        .toUpperCase()}` || ''
        const clientId = noteAPIResponse.clientId;
        const client = noteAPIResponse.clients?.find(client => client._id === clientId);
       
        const clientName = client ? `${client.firstName.toUpperCase()} ${client.lastName.toUpperCase()}` : '';
    uiArray = ADD_NOTE_ENTRY_FORM_INPUTS(nameOfUser, location,clientName)
    if (noteAPIResponse.scoringMethod.scoringLevels) {
      noteAPIResponse.scoringMethod?.scoringLevels.map((eachLevel) => {
        scoringMethodLevels.push({
          label: eachLevel.levelAcronym,
          value: eachLevel._id,
        })
      })
    }
    if (noteAPIResponse.tasks.length > 0) {
      noteAPIResponse.tasks.map((eachTask, indexForTask) => {
        uiArray.push(
          {
            label: 'Name:',
            name: `${i}Name`,
            type: 'text',
            required: true,
            rowNumber: lastRow,
            // value: ``,
            value: `${eachTask.taskName}`,
            disabled: true,
            sx: {
              width: '100%',
              marginTop:"0.5rem"
            },
            className:`col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`
          },
          {
            index: indexForTask,
            label: 'Score',
            name: `${i}Score`,
            type: 'select',
            options: scoringMethodLevels,
            required: true,
            rowNumber: lastRow,
            value: taskAndScoreList[indexForTask]['score'],
            onChange: onChangeScore,
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
            sx: {
              // marginLeft: '3rem',
              width: '100%',
              marginTop:"0.5rem"
            },
          },
          {
            label: 'Description:',
            name: `${i}Description`,
            required: true,
            rowNumber: lastRow,
            value: eachTask.taskDescription,
            type: 'textarea',
            disabled: true,
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
            // value: ``,
            // textAreaClassName: 'notes-entry-task-description-method-div',
            sx:{
              resize: "none"
            }
          },
          {
            index: indexForTask,
            label: 'Description',
            name: `${i}Description`,
            type: 'textarea',
            required: false,
            rowNumber: lastRow,
            value: taskAndScoreList[indexForTask]['description'],
            onChange: onChangeDescription,
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
            // textAreaClassName: 'notes-entry-task-remarks-method-div',
          }
        )
        i++
        lastRow++
      })
    }
    setUIElements(uiArray)
  }

  const onChangeScore = (value, index) => {
    setTaskAndScoreList((prevInputArr) => {
      const newState = [...prevInputArr];
      newState[index]['score'] = value;
      return newState;
    });
  };

  const onChangeDescription = (name, value, checked, type, index) => {
    setTaskAndScoreList((prevInputArr) => {
      const newState = [...prevInputArr];
      newState[index]['description'] = value;
      return newState;
    });
  };

  const handleSubmitForm = async (formData) => {
    if (new Date(formData['beginTime']).getTime() == new Date(formData['endTime']).getTime()) {
      toast.error('Start time and end time can not be same')
      return
    }
    if (new Date(formData['beginTime']).getTime() > new Date(formData['endTime']).getTime()) {
      toast.error('Start time can not be greater than end time ')
      return
    }
    await axios
      .post('/notesEntry/create', {
        noteId: params.id,
        creationDate: formData['startDate'],
        beginTime: formData['beginTime'],
        endTime: formData['endTime'],
        location: {
          address: formData['location'],
          lat: '31.5245054',
          lng: '74.3033196',
          typeOfLocation: 'client',
          idOfDoc: '65ae6e8b682092bf5446fd8b',
        },
        tasks: taskAndScoreList,
      })
      .then((res) => {
        const {message, success} = res?.data
          if (!success) {
           return toast.error(message)
          } 
          toast.success(message)
          history.push('/caregivers/notes')
      })
      .catch((e) => toast.error("Couldn't add note entry"))
  }
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <DynamicForm
            fields={uiElements}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </div>
      </div>
    </>
  )
}
