import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import { getOneDescriptionCode, updateDescriptionCode } from '../store/action';
import { getAttendanceType } from '../../attendance-type/store/action';
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import {
    TextField, Button, Grid,
FormControlLabel, Autocomplete,
    Typography, Checkbox, Box
} from '@mui/material';
import * as validator from '../../../../../reusable-components/Validation/Validations'
import RateHistoryDialog from '../../../../../reusable-components/RateHistory/RateHistoryDialog'

const selectAuth = (state) => state.auth;
const EditDescription = () => {
    const [diagnosis, setDiagnosis] = useState('')
    const [attendanceType, setAttendanceType] = useState([])
    const [selectedDescription, setSelectedDescription] = useState({})
    const [filteredOptions, setFilteredOptions] = useState([]);
    const auth = useSelector(selectAuth);
    const dispatch = useDispatch()
    const companyid = auth.user?.company_code
    const history = useHistory()
    const [formValues, setFormValues] = useState({
        company_code: companyid,
        accountNumber: '',
        serviceDescription: '',
        serviceCode: '',
        rateHistory: '',
        rateHistoryValue: [],
        unitMeasure: '',
        unitRate: '',
        claimType: '',
        primaryDiagnosis: '',
        procedureModifier: ['', '', '', ''],
        diagnosisPointer: ['1', '', '', ''],
        electronicUnit: '',
        useService: '',
        revenueCode: '',
        rateCode: '',
        rateValue: '',
        locatorCode: '',
        useLocator: '',
        procedureQualifier: '',
        addService: '',
        validationTotalBillable: '',
        unitOfMeasure: '',
        roundingAlgorithm: '',
        allowedIncrement: '',
        attendanceType: '',
        deactivateStatus: false,
    });
    const field = {
        label: "ICD-10",
        name: "icd10",
        type: "asyncSelectICD",
        options: [],
    }

    const parameters = useParams()

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                const [attendanceTypeResult, descriptionCodeResult] = await Promise.all([
                    dispatch(getAttendanceType({companyid})),
                    dispatch(getOneDescriptionCode(parameters.id))
                ])
                const tempAT = attendanceTypeResult.payload.data;
                const data = descriptionCodeResult.payload.data.data
                const updatedAT = [
                    ...tempAT.map((AT) => ({ label: AT.name, value: AT._id }))
                ]
                const matchingAT = updatedAT.find((AT) => AT.value === data.attendanceType);
                const filteredAT = matchingAT
                    ? { label: matchingAT.label, value: matchingAT.value }
                    : null;
                setAttendanceType(updatedAT)
                const tempObj = {
                    _id: data._id,
                    accountNumber: data.accountNumber,
                    serviceDescription: data.serviceDescription,
                    serviceCode: data.serviceCode,
                    rateHistory: data.rateHistory,
                    rateHistoryValue: data.rateHistoryValue,
                    unitMeasure: data.unitMeasure,
                    unitRate: data.unitRate,
                    claimType: data.claimType,
                    primaryDiagnosis: data.primaryDiagnosis,
                    procedureModifier: data.procedureModifier,
                    diagnosisPointer: data.diagnosisPointer,
                    electronicUnit: data.electronicUnit,
                    useService: data.useService,
                    revenueCode: data.revenueCode,
                    rateCode: data.rateCode,
                    rateValue: data.rateValue,
                    locatorCode: data.locatorCode,
                    useLocator: data.useLocator,
                    procedureQualifier: data.procedureQualifier,
                    addService: data.addService,
                    validationTotalBillable: data.validationTotalBillable,
                    unitOfMeasure: data.unitOfMeasure,
                    roundingAlgorithm: data.roundingAlgorithm,
                    allowedIncrement: data.allowedIncrement,
                    attendanceType: filteredAT.value,
                    deactivateStatus: data.deactivateStatus,
                }
                setSelectedDescription(tempObj)
                setFormValues(tempObj)

            } else {
                history.push('/admin/billing/description-code/list')
            }
        }
        fetchData()
    }, [])


    const getData = async (value) => {
        try {
            let response = []
            if (field.name === 'icd10') {
                response = await axios.post(`/icd10/findICD`, {
                    value: value
                })
            }
            return response?.data?.data?.map(item => ({
                label: item.info,
                value: item.info
            }));
        } catch (error) {
            console.error("Error fetching allergies:", error);
            return [];
        }
    };

    const loadOptions = async (inputValue) => {
        if (inputValue.length >= 3) {
            const response = await getData(inputValue)
            setFilteredOptions(response);
        } else {
            setFilteredOptions([]);
        }
    };

    const handleAutocompleteChange = (_, newValue) => {
        if (newValue) {
            setDiagnosis(newValue.value)
        }
    };
    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: checked
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
        }

    };

    const handleSubmit = async (values) => {
        const validations = [
            { field: 'serviceDescription', validation: validator.NAME_VALIDATION },
            { field: 'serviceCode', validation: validator.NUMBER_VALIDATION },
        ]
        for (const { field, validation } of validations) {
            if (values[field] !== undefined && values[field] !== null && values[field] !== '') {
                if (!validation.pattern.test(values[field])) {
                    toast.error('Form Validation Failed');
                    return;
                }
            }
        }

        values.primaryDiagnosis = diagnosis && diagnosis
        values.serviceCode = Number(values.serviceCode)
        values.unitRate = Number(values.unitRate)
        const response = await dispatch(updateDescriptionCode(values))
        if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
        history.push('/admin/billing/description-code/list')
    }

    return (
        <>

            <div className='card'>
                <form className='card-body' onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(formValues)
                }}>
                    <div className='row'>
                        <TableTitle title='Edit Description Code'></TableTitle>
                        <Grid container sx={{ marginTop: '1rem' }} spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                <TextField
                                    fullWidth
                                    id="serviceDescription"
                                    name="serviceDescription"
                                    type='text'
                                    label="Service Description"
                                    size='small'
                                    value={formValues.serviceDescription || ''}
                                    onChange={handleFormChange}
                                    error={Boolean(formValues.serviceDescription && !validator.NAME_VALIDATION.pattern.test(formValues.serviceDescription))}
                                    helperText={formValues.serviceDescription && !validator.NAME_VALIDATION.pattern.test(formValues.serviceDescription) ? validator.NAME_VALIDATION.message : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                <Autocomplete
                                    id="attendanceType"
                                    fullWidth
                                    options={attendanceType}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    freeSolo={true}
                                    forcePopupIcon={true}
                                    size='small'
                                    value={ 
                                        formValues.attendanceType !== undefined ? attendanceType.find((option) => option.value === formValues.attendanceType)?.label || '' : ''
                                     }
                                    onChange={(e, newValue) => {
                                        handleFormChange({
                                            target: {
                                                name: "attendanceType",
                                                value: newValue ? newValue.value : ''
                                            }
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            autoComplete="off"
                                            required
                                            name="attendanceType"
                                            label="- Select Attendance Type -"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: '.6rem' }}>
                                <TextField
                                    fullWidth
                                    id='serviceCode'
                                    type='number'
                                    name='serviceCode'
                                    variant='outlined'
                                    autoComplete='off'
                                    label='Service Code'
                                    size='small'
                                    value={formValues.serviceCode}
                                    onChange={handleFormChange}
                                    error={Boolean(formValues.serviceCode && !validator.NUMBER_VALIDATION.pattern.test(formValues.serviceCode))}
                                    helperText={formValues.serviceCode && !validator.NUMBER_VALIDATION.pattern.test(formValues.serviceCode) ? validator.NUMBER_VALIDATION.message : ''}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: '.6rem' }}>
                                <TextField
                                    fullWidth
                                    id='accountNumber'
                                    type='text'
                                    name='accountNumber'
                                    variant='outlined'
                                    label='Account Number'
                                    size='small'
                                    value={formValues.accountNumber}
                                    onChange={handleFormChange}
                                />
                            </Grid>

                            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: '.6rem' }}>
                                <TextField
                                    fullWidth
                                    id='unitMeasure'
                                    type='text'
                                    name='unitMeasure'
                                    variant='outlined'
                                    label='Unit of Measure'
                                    size='small'
                                    value={formValues.unitMeasure}
                                    onChange={handleFormChange}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: '.6rem' }}>
                                <TextField
                                    fullWidth
                                    id='unitRate'
                                    type='number'
                                    name='unitRate'
                                    variant='outlined'
                                    placeholder='0.00'
                                    label='Unit Rate ($)'
                                    size='small'
                                    value={formValues.unitRate}
                                    onChange={handleFormChange}
                                />
                            </Grid>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-3 pt-3'>
              <Grid
                item
                style={{
                  backgroundColor: 'BlanchedAlmond',
                  maxWidth: 'fit-content',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <Typography style={{fontWeight: 'bold', fontSize:'16Dpx'}} className='pt-2 pb-2'>
                  Unit of Measure (1 hour)
                </Typography>
              </Grid>
            </div>
                            

                            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} marginLeft='2px'>
                                <FormControl component="fieldset">
                                    <label className='fw-bold fs-6 mt-2'>Claim Type</label>
                                    <RadioGroup
                                        name="claimType"
                                        value={formValues.claimType}
                                        onChange={handleFormChange}
                                    >
                                        <FormControlLabel
                                            value="Professional Claim"
                                            control={<Radio />}
                                            label="Professional Claim"
                                            onClick={() => {
                                                setFormValues((prevValues) => {
                                                    return {
                                                        ...prevValues,
                                                        claimType: 'Professional Claim',
                                                        revenueCode: '',
                                                        rateCode: '',
                                                        rateValue: '',
                                                        locatorCode: '',
                                                        useLocator: '',
                                                        useService: '',
                                                    }
                                                });
                                                handleFormChange({
                                                    target: {
                                                        name: 'useService',
                                                        value: '',
                                                        type: 'checkbox',
                                                        checked: false,
                                                    },
                                                });
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Institutional Claim"
                                            control={<Radio />}
                                            label="Institutional Claim"
                                            onClick={() => {
                                                setFormValues((prevValues) => {
                                                    setDiagnosis('')
                                                    return {
                                                        ...prevValues,
                                                        claimType: 'Institutional Claim',
                                                        diagnosisPointer: ['', '', '', ''],
                                                        primaryDiagnosis: '',
                                                    }
                                                });

                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid> */}
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='row mt-2 mx-1'>
                                <label className='fw-bold fs-6 mb-2'>Procedure Modifier</label>
                                {formValues.procedureModifier.map((value, index) => (
                                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'>
                                        <TextField
                                            type='text'
                                            name={`procedureModifier[${index}]`}
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            placeholder=''
                                            value={value}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    procedureModifier: prevValues.procedureModifier.map((item, i) =>
                                                        i === index ? newValue : item
                                                    ),
                                                }));
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            </div>
                            {/* {formValues.claimType === 'Institutional Claim' ? (
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-4 mt-2'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                type='checkbox'
                                                name='useService'
                                                checked={formValues.useService}
                                                onChange={handleFormChange}
                                            />
                                        }
                                        label='Use Service Code and Procedure Modifier in 837 from Service'
                                        className='form-check-label'
                                    />
                                </div>
                            ) : ( */}
                                <>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mx-3'>
                                        <label className='fw-bold fs-6 mb-2'>Primary Diagnosis Code</label>
                                        <p>ICD-10</p>
                                        <Autocomplete
                                            id={field.name}
                                            size="small"
                                            options={filteredOptions}
                                            value={formValues.primaryDiagnosis}
                                            onInputChange={(_, newValue) => loadOptions(newValue)}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            onChange={handleAutocompleteChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Search for ${field.name}`}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3 mx-3'>
                                        <div className='row'>
                                            <label className='fw-bold fs-6 mb-2'>Diagnosis Code Pointer
                                            </label>
                                            {formValues.diagnosisPointer.map((value, index) => (
                                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                                    <TextField
                                                        type='text'
                                                        name={`diagnosisPointer[${index}]`}
                                                        variant='outlined'
                                                        fullWidth
                                                        size='small'
                                                        placeholder=''
                                                        value={value}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                diagnosisPointer: prevValues.diagnosisPointer.map((item, i) =>
                                                                    i === index ? newValue : item
                                                                ),
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            {/* {
                                formValues.claimType === "Institutional Claim" ? (
                                    <div className='row mt-3 mx-2'>
                                        <h3 className='mb-2 text-primary display-7'>Institutional Claim</h3>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} marginBottom='5px'>
                                            <Autocomplete
                                                options={[]}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                freeSolo={true}
                                                forcePopupIcon={true}
                                                className='mb-2'
                                                size='small'
                                                onChange={(e, newValue) => {
                                                    const event = {
                                                        target: {
                                                            name: 'revenueCode',
                                                            value: newValue,
                                                        },
                                                    };
                                                    handleFormChange(event);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder='Select Unit for Electronic Submission'
                                                    />
                                                )} />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} marginBottom='5px'>
                                            <TextField
                                                type='number'
                                                name='rateCode'
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                label='Rate Code'
                                                className='mb-2'
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <TextField
                                                type='number'
                                                name='rateValue'
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                label='Rate Value'
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <TextField
                                                type='number'
                                                name='locatorCode'
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                label='Locator Code'
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className='mt-2'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        type='checkbox'
                                                        name="useLocator"
                                                        onChange={handleFormChange}
                                                    />
                                                }
                                                label="Use Locator Code from Service Form"
                                            />
                                        </Grid>
                                    </div>
                                ) : null
                            }
                            <div className='row mt-4 mx-2'>
                                <h3 className='mb-2 text-primary display-7'>Electronic Billing</h3>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: "BlanchedAlmond", marginLeft: '7px', maxWidth: 'fit-content' }}>
                                    <Typography className='mt-2 mb-2'>
                                        Do not change the Unit for Electronic Submission from Unit to Day or Minute unless it's required by the State Medicaid System.
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className='mt-6'>
                                    <label className='fw-bold fs-6 mb-2'>Unit for Electronic Submission</label>
                                    <Autocomplete
                                        options={['Unit', 'Minute', 'Day']}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        freeSolo={true}
                                        forcePopupIcon={true}
                                        value={formValues.electronicUnit}
                                        size='small'
                                        onChange={(_, newValue) => {
                                            handleFormChange({ target: { name: 'electronicUnit', value: newValue } });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                placeholder='Select Unit for Electronic Submission'
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className='mt-6'>
                                    <label className='fw-bold fs-6 mb-2'>Procedure Qualifier</label>
                                    <Autocomplete
                                        options={['HC - HCPCS', 'ZZ - Mutually Defined', 'ER - Jurisdiction Specific Procedure and Supply Codes']}
                                        value={formValues.procedureQualifier}
                                        size='small'
                                        isOptionEqualToValue={(option, value) => option === value}
                                        freeSolo={true}
                                        forcePopupIcon={true}
                                        onChange={(_, newValue) => {
                                            handleFormChange({ target: { name: 'procedureQualifier', value: newValue } });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                placeholder='Select Procedure Qualifier'
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='mt-2'>
                                    <div className='row'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    type='checkbox'
                                                    name="addService"
                                                    checked={formValues.addService}
                                                    onChange={(e) => {
                                                        handleFormChange(e);
                                                    }}
                                                />
                                            }
                                            label="Add Service Description in 837"
                                            className='fw-bold fs-6'
                                        />
                                    </div>
                                </Grid>
                            </div> */}
                            <div>
                            <Box sx={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ backgroundColor: 'BlanchedAlmond', padding: '10px', marginBottom: '10px' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Service Dates that do not fall under any of the following date ranges will use
                                        the default Unit Rate.
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                    control={
                                        <Checkbox
                                        type='checkbox'
                                        name="rateHistory"
                                        checked={formValues.rateHistory}
                                        onChange={(e) => {
                                            handleFormChange(e)
                                        }}
                                        />
                                    }
                                            label={
                                                <Typography variant="body2" sx={{ color: 'black' }}>
                                                Enable Rate History
                                                </Typography>
                                            }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RateHistoryDialog
                                            formValues={formValues}
                                            setFormValues={setFormValues}
                                            isEnabled={formValues.rateHistory}
                                            />
                                        </Grid>
                                        </Grid>
                                    </Box>
                                    </div>

                            {/* <div>
                                <div className='row mx-1'>
                                    <h3 className='mb-2 text-primary display-7'>Validation for Total Billable Units</h3>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1'>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                name='validationTotalBillable'
                                                value={formValues.validationTotalBillable || ''}
                                                onChange={handleFormChange}
                                            >
                                                <FormControlLabel
                                                    value='Restrict'
                                                    control={<Radio />}
                                                    label='Restrict (Create Billing Data with available remaining Units. Any unit in excess of the Total Billable Units will be recorded as non-billable unit. No Billing Data will be generated if remaining unit is zero)'
                                                />
                                                <FormControlLabel
                                                    value='Restrict & Continue'
                                                    control={<Radio />}
                                                    label='Restrict & Continue (Create Billing Data with available remaining units. Any unit in excess of the Total Billable Units will be recorded as non-billable unit. Billing Data will be generated with non-billable unit if remaining unit is zero)'
                                                />
                                                <FormControlLabel
                                                    value='Block'
                                                    control={<Radio />}
                                                    label='Block (Block creation of any Billing Data if Total Billable Units is exceeded)'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div> */}


                            {/* <div className='mx-2'>
                                <h3 className='mb-5 text-primary display-7 mt-4'>Automatic Unit Calculation From Time In/Out</h3>
                                <Grid item style={{ backgroundColor: "BlanchedAlmond", maxWidth: 'fit-content' }}>
                                    <Typography className='pt-2 pb-2 fw-bold'>
                                        Please check <b>Billable Unit Calculator</b> to make sure you have chosen the desired settings for Automatic Unit Calculation From Time In/Out.
                                    </Typography>
                                </Grid>
                                <div>
                                    <FormControl component="fieldset" style={{ width: '100%' }} >
                                        <label component="legend" className='fw-bold fs-6 mb-2 mt-3'>Unit of Measure (Calculation)</label>
                                        <Autocomplete
                                            value={formValues.unitOfMeasure}
                                            size="small"
                                            isOptionEqualToValue={(option, value) => option === value}
                                            forcePopupIcon={true}
                                            onChange={(_, newValue) => {
                                                handleFormChange({ target: { name: 'unitOfMeasure', value: newValue } });
                                            }}
                                            options={['5 Minutes', '6 Minutes', '15 Minutes', '20 Minutes', '30 Minutes', '1 Hour', '2 Hours']}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth placeholder='Select Unit of Measure (Calculation)' variant="outlined" />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                <div className='row'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5'>
                                        <FormControl component="fieldset">
                                            <label component="legend" className='fw-bold fs-6 mb-2'>Rounding Algorithm</label>
                                            <RadioGroup
                                                row
                                                name="roundingAlgorithm"
                                                value={formValues.roundingAlgorithm}
                                                onChange={handleFormChange}
                                            >
                                                <FormControlLabel value="Half Up" control={<Radio />} label="Half Up" />
                                                <FormControlLabel value="Half Down" control={<Radio />} label="Half Down" />
                                                <FormControlLabel value="Quarter Up" control={<Radio />} label="Quarter Up" />
                                                <FormControlLabel value="Quarter Down" control={<Radio />} label="Quarter Down" />
                                                <FormControlLabel value="Two Third Up" control={<Radio />} label="Two Third Up" />
                                                <FormControlLabel value="Two Third Down" control={<Radio />} label="Two Third Down" />
                                                <FormControlLabel value="Three Quarter Up" control={<Radio />} label="Three Quarter Up" />
                                                <FormControlLabel value="Three Quarter Down" control={<Radio />} label="Three Quarter Down" />
                                                <FormControlLabel value="None" control={<Radio />} label="None" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3'>
                                        <FormControl component='fieldset'>
                                            <label className='fw-bold fs-6 mb-2'>Smallest Allowed Increment</label>
                                            <RadioGroup
                                                row
                                                name='allowedIncrement'
                                                value={formValues.allowedIncrement}
                                                onChange={handleFormChange}
                                            >
                                                <FormControlLabel value='5 minutes' control={<Radio />} label='5 Minutes' />
                                                <FormControlLabel value='6 minutes' control={<Radio />} label='6 Minutes' />
                                                <FormControlLabel value='7.5 minutes' control={<Radio />} label='7.5 Minutes' />
                                                <FormControlLabel value='10 minutes' control={<Radio />} label='10 Minutes' />
                                                <FormControlLabel value='15 minutes' control={<Radio />} label='15 Minutes' />
                                                <FormControlLabel value='20 minutes' control={<Radio />} label='20 Minutes' />
                                                <FormControlLabel value='30 minutes' control={<Radio />} label='30 Minutes' />
                                                <FormControlLabel value='45 minutes' control={<Radio />} label='45 Minutes' />
                                                <FormControlLabel value='60 minutes' control={<Radio />} label='60 Minutes' />
                                                <FormControlLabel value='120 minutes' control={<Radio />} label='120 Minutes' />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div> */}
                        </Grid>
                                <Box sx={{ marginTop: '24px', }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        borderBottomLeftRadius: '4px',
                                        borderBottomRightRadius: '4px',
                                        padding: '6px 0',
                                    }} >
                                        <Button
                                            type='button'
                                            variant='outlined'
                                            sx={{
                                                color: '#000',
                                                backgroundColor: '#f2f2f2',
                                                '&:hover': {
                                                    backgroundColor: '#f2f2f2',
                                                },
                                            }}
                                            onClick={() => history.push('/admin/billing/description-code/list')}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type='submit'
                                            sx={{
                                                marginLeft: '10px',
                                                backgroundColor: '#28a745',
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: '#218838',
                                                },
                                            }}
                                            variant='outlined'
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                    </div>
                </form >
            </div >
        </>
    )
}

export default EditDescription
















