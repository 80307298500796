import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { ADDED_CLIENTS_LIST_COLUMNS, LIST_OF_CLIENT_COLUMNS_DATA_MAPPING, LIST_OF_CLIENT_SORTING_COLUMNS } from '../../GENERAL_TABLE_CONSTANTS';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import BackButton from '../../../../../reusable-components/Buttons/BackButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: { auth: any }) => state.auth;
type Params = { id: string; };

const SCClientList = () => {
    const history = useHistory();
    const [selectedClients, setSelectedClients] = useState<any[]>([])
    const [nonSelectedClients, setNonSelectedClients] = useState<any[]>([])
    const [selectedSharedContact, setSelectedSharedContact] = useState({
        _id: '', firstName: '', lastName: ''
    })
    const [deleteClient, setDeleteClient] = useState({
        _id: ''
    })
    const [addedClient, setAddedClient] = useState({
        _id: ''
    })
    const [searchSelectedTerm, setSearchSelectedTerm] = useState<any>('')
    const [searchNonSelectedTerm, setSearchNonSelectedTerm] = useState<any>('')
    const [selectedFilteredData, setSelectedFilteredData] = useState<any[]>([])
    const [notSelectedFilteredData, setNotSelectedFilteredData] = useState<any[]>([])
    const [queryDataForAddClient, setQueryDataForAddClient] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 0,
        sortBy: 'name',
        sortDir: 'desc',
        searchTerm: ''
    })
    const [queryDataForAddedClient, setQueryDataForAddedClient] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 0,
        sortBy: 'name',
        sortDir: 'desc',
        searchTerm: ''
    })
    const auth = useSelector(selectAuth);
    const companyid = auth.user?.company_code
    const parameters = useParams<Params>();

    useEffect(() => {
        const fetchSelectedData = async () => {
            try {
                const response = await axios.post("/shared/getOne", {
                    id: parameters.id,
                    company_code: companyid,
                    page: queryDataForAddedClient.pageNumber,
                    pageSize: queryDataForAddedClient.pageSize,
                    sortBy: queryDataForAddedClient.sortBy,
                    sortDir: queryDataForAddedClient.sortDir,
                    searchSelectedTerm : queryDataForAddedClient.searchTerm,
                });
                const { selected } = response.data;
                setSelectedClients(selected.data);
                setSelectedFilteredData(selected.data);
                setQueryDataForAddedClient(prevState => ({
                    ...prevState,
                    currentPage: selected.currentPage,
                    totalPages: selected.totalPages,
                    totalCounts: selected.totalCounts,
                }));
                setSelectedSharedContact(selected.selectedContactDetails)
            } catch (err) {
                console.log(err);
                history.push('/admin/general/shared-contact/info');
            }
        };
        
        const fetchNonSelectedData = async () => {
            try {
                const response = await axios.post("/shared/getOne", {
                    id: parameters.id,
                    company_code: companyid,
                    page: queryDataForAddClient.pageNumber,
                    pageSize: queryDataForAddClient.pageSize,
                    sortBy: queryDataForAddClient.sortBy,
                    sortDir: queryDataForAddClient.sortDir,
                    searchNonSelectedTerm: queryDataForAddClient.searchTerm
                });
                const { nonSelected } = response.data;
                setNonSelectedClients(nonSelected.data);
                setNotSelectedFilteredData(nonSelected.data);
                setQueryDataForAddClient(prevState => ({
                    ...prevState,
                    currentPage: nonSelected.currentPage,
                    totalPages: nonSelected.totalPages,
                    totalCounts: nonSelected.totalCounts,
                }));
            } catch (err) {
                console.log(err);
                history.push('/admin/general/shared-contact/info');
            }
        };
        if(parameters.id) {
            fetchSelectedData()
            fetchNonSelectedData()
        }        
    }, [addedClient, deleteClient, queryDataForAddClient.pageNumber, 
        queryDataForAddClient.pageSize, queryDataForAddedClient.pageNumber, 
        queryDataForAddedClient.pageSize, queryDataForAddClient.sortDir, 
        queryDataForAddClient.sortBy, queryDataForAddedClient.sortDir, queryDataForAddedClient.sortBy, queryDataForAddedClient.searchTerm, queryDataForAddClient.searchTerm ]);

    const handleAddBox = (row: { _id: string; firstName: string; lastName: string; }) => {
        if (row._id) {
            setAddedClient(row);
            handleAddClients(row);
            setQueryDataForAddClient((prevState)=> {
                return {
                  ...prevState,
                  searchTerm : '',
                  pageNumber : 1
                }
              })
        }
    }
    const handleDelete = (row: { _id: string; firstName: string; lastName: string; }) => {
        if (row._id) {
            setDeleteClient(row)
            handleDeleteClient(row)
            setQueryDataForAddedClient((prevState)=> {
                return {
                  ...prevState,
                  searchTerm : '',
                  pageNumber: 1
                }
              })
        }
    }

    const handleAddClients = (row: { _id: string; firstName: string; lastName: string; }) => {
        axios.post("/shared/addSingleClient/" + row._id, { _id: selectedSharedContact._id })
            .then(() => {
                setSelectedFilteredData((prevState) => [...prevState, row]);
                const tempData = notSelectedFilteredData.filter((contact: any) =>
                    contact._id !== row._id
                );
                setNotSelectedFilteredData(tempData);
                setNonSelectedClients(tempData);
            });
        setAddedClient({
            _id: ''
        });
    };
    const handleDeleteClient = (row: { _id: string; firstName: string; lastName: string; }) => {
        axios.post('/shared/deleteClient/' + row._id, { sharedContactId: selectedSharedContact._id })
            .then(() => {
                setNotSelectedFilteredData(prevState => [...prevState, row])
                const tempData = selectedFilteredData.filter((contact: any) =>
                    contact._id !== row._id
                )
                setSelectedFilteredData(tempData)
                setSelectedClients(tempData)
            })
        setDeleteClient({
            _id: ''
        })
    }
    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <TableTitle title={`Clients Added to ${selectedSharedContact &&  selectedSharedContact.firstName + ' ' + selectedSharedContact.lastName }`}></TableTitle>
                    <TableWrapper
                        searchField={
                            <TableSearchBar
                                label='Search'
                                variant='outlined'
                                size='small'
                                value={searchSelectedTerm}
                                setSearchTerm={setSearchSelectedTerm}
                                setQueryData={setQueryDataForAddedClient}
                                margin='normal'
                                inputAdornmentPosition='start'
                            ></TableSearchBar>
                        }
                        tableButtons={
                            <BackButton />
                        }
                        dynamicTable={
                            <DynamicTable
                                columns={ADDED_CLIENTS_LIST_COLUMNS}
                                data={selectedFilteredData}
                                canDelete={true}
                                onDelete={handleDelete}
                                columnDataMapping={LIST_OF_CLIENT_COLUMNS_DATA_MAPPING}
                                title='Are you sure you want to delete this client?'
                                sortingColumns={LIST_OF_CLIENT_SORTING_COLUMNS}
                                setQueryData={setQueryDataForAddedClient}
                                queryData={queryDataForAddedClient}
                            />
                        }
                    />

                    <TableTitle title={`Add Clients to ${selectedSharedContact &&  selectedSharedContact.firstName + ' ' + selectedSharedContact.lastName }`}></TableTitle>
                    <TableWrapper
                        searchField={
                            <TableSearchBar
                                label='Search'
                                variant='outlined'
                                size='small'
                                value={searchNonSelectedTerm}
                                setSearchTerm={setSearchNonSelectedTerm}
                                setQueryData={setQueryDataForAddClient}
                                margin='normal'
                                inputAdornmentPosition='start'
                            ></TableSearchBar>
                        }
                        tableButtons={''}
                        dynamicTable={
                            <DynamicTable
                                columns={ADDED_CLIENTS_LIST_COLUMNS}
                                data={notSelectedFilteredData}
                                canAddBox={true}
                                sortingColumns={LIST_OF_CLIENT_SORTING_COLUMNS}
                                onAddBox={handleAddBox}
                                columnDataMapping={LIST_OF_CLIENT_COLUMNS_DATA_MAPPING}
                                title='Are you sure you want to add this client?'
                                setQueryData={setQueryDataForAddClient}
                                queryData={queryDataForAddClient}
                            />
                        }
                    />


                </div>
            </div>
        </div>
    )
}

export default SCClientList

