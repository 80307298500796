import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Button,
  TextField,
  Typography,
  Tooltip,
  Box,
  InputAdornment,
} from '@mui/material'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import {
  MISSED_MEDICATION_COLUMNS,
  MISSED_MEDICATION_DATA_MAPPING,
  MISSED_MEDICATION_SORTING_COLUMNS,
} from '../../mars_table_contants'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import MissedMedicationDialog from '../Dialog/missed-medication-dialog'
import {toast} from 'react-toastify'
import moment from 'moment'
import {MoonLoader} from 'react-spinners'
import SearchIcon from '@mui/icons-material/Search';

const MissedMedicationListing = () => {
  const today = moment().format('YYYY-MM-DD')
  const last7thDay = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const [missedMedicationListing, setMissedMedicationListing] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [dialogOpen, setDialogOpen] = useState(true)
  const [selectedClient, setSelectedClient] = useState('')
  const [startDate, setStartDate] = useState(last7thDay)
  const [loading, setLoading] = useState(true)
  const [endDate, setEndDate] = useState(today)
  const [ordersOfColumns, setOrdersOfColumns] = useState({})
  const rowsPerPageOptions = [5, 10, 25, 50]
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])
  const [searchResults, setSearchResults] = useState([])
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 0,
    sortBy: 'medicationName',
    sortDir: 'asc',
    searchTerm: '',
  })
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/medicationEntries/missedMedications/${selectedClient}?startDate=${startDate}&endDate=${endDate}`)
        const {missedDosageTimings} = response.data
        setMissedMedicationListing(missedDosageTimings)
        setQueryData((prevState) => ({
          ...prevState,
          totalCounts: missedDosageTimings.length,
        }))
        if (!missedDosageTimings.length) {
          toast.error('Medication records do not exist for the selected client')
          setDialogOpen(true)
        } else {
          setDialogOpen(false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (selectedClient) {
      fetchData()
    }
  }, [selectedClient, startDate, endDate])

  useEffect(() => {
    const dataToFilter = searchTerm ? searchResults : missedMedicationListing
    const start = (queryData.pageNumber - 1) * queryData.pageSize
    const end = start + queryData.pageSize
    const sortedData = sortData(dataToFilter, queryData.sortBy, queryData.sortDir)
    const slicedData = sortedData.slice(start, end)
    setFilteredData(slicedData)
  }, [
    queryData.pageNumber,
    queryData.pageSize,
    missedMedicationListing,
    queryData.sortBy,
    queryData.sortDir,
    searchResults,
  ])

  useEffect(() => {
    if (
      MISSED_MEDICATION_SORTING_COLUMNS &&
      Object.keys(MISSED_MEDICATION_SORTING_COLUMNS).length > 0
    ) {
      const initialOrders = {}
      for (const column of Object.keys(MISSED_MEDICATION_SORTING_COLUMNS)) {
        if (!ordersOfColumns[column]) {
          initialOrders[column] = 'asc'
        }
      }
      setOrdersOfColumns((prevOrders) => ({...prevOrders, ...initialOrders}))
      setTimeout(() => {
        setLoading(false)
      }, 100)
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 100)
    }
  }, [queryData.sortBy, queryData.sortDir])

  const sortData = (data, sortBy, sortDir) => {
    return [...data].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return sortDir === 'asc' ? -1 : 1
      }
      if (a[sortBy] > b[sortBy]) {
        return sortDir === 'asc' ? 1 : -1
      }
      return 0
    })
  }

  const handleSearchChange = (event) => {
    event.persist()
    setSearchTerm(() => event.target.value)
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (searchTerm) {
        const filteredRoles = missedMedicationListing.filter((medication) =>
          medication.medicationName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setSearchResults(filteredRoles)
        setQueryData((prevState) => ({
          ...prevState,
          totalCounts: filteredRoles.length,
          pageNumber: 1,
          skip: 0,
        }))
      } else {
        setSearchResults(missedMedicationListing)
        setQueryData((prevState) => ({
          ...prevState,
          totalCounts: missedMedicationListing.length,
          pageNumber: 1,
          skip: 0,
        }))
      }
    }
  }

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  const handleChangePage = (event, newPage) => {
    const newSkip = newPage * queryData.pageSize
    setQueryData((prevState) => ({
      ...prevState,
      pageNumber: newPage + 1,
      skip: newSkip,
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10)
    setRowsPerPage(newPageSize)
    setQueryData((prevState) => ({
      ...prevState,
      pageSize: newPageSize,
      pageNumber: 1,
      skip: 0,
    }))
  }

  const handleSort = (colName) => {
    const columnName = typeof colName === 'object' ? Object.keys(colName)[0] : colName
    const currentSortDir = ordersOfColumns[columnName]
    const newSortDir = currentSortDir === 'asc' ? 'desc' : 'asc'
    const dbColumnName = MISSED_MEDICATION_SORTING_COLUMNS[columnName]

    setQueryData((prevState) => ({
      ...prevState,
      sortBy: dbColumnName,
      sortDir: newSortDir,
    }))
    setOrdersOfColumns((prevOrders) => ({
      ...prevOrders,
      [columnName]: newSortDir,
    }))
  }

  const handleFocus = () => {
    setTooltipOpen(true);
  };
  const handleBlur = () => {
    setTooltipOpen(false);
  };
  return (
    <div className='card'>
      <div className='card-body'>
        <TableTitle title={`Missed Medications`}></TableTitle>
        <div>
          <MissedMedicationDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            setSelectedClient={setSelectedClient}
          />
        </div>
        <TableWrapper
          searchField={
            <Box width="100%" padding={1}>
              <Tooltip
                title={
                  <Typography fontSize={10}  fontWeight={'bold'} fontFamily='helvetica'>
                    Type to search and press Enter
                </Typography>
                }
                open={tooltipOpen}
                placement="right-start"
                arrow
              >
                <TextField
                  label={'Search'}
                  variant={'outlined'}
                  size={'small'}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  margin={'normal'}
                />
              </Tooltip>
            </Box>
          }
          tableButtons={
            <Button
              variant='contained'
              color='primary'
              sx={{height: '2.3rem', minWidth: '86px', float: 'right', marginRight: '5px'}}
              onClick={handleOpenDialog}
            >
              Filters
            </Button>
          }
          dynamicTable={
            <div className='table-responsive'>
              {loading ? (
                <div className='d-flex justify-content-center'>
                  <MoonLoader color='#9db2fc' size={80} />
                </div>
              ) : (
                <>
                  <Table className='table-container' stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Object.keys(MISSED_MEDICATION_SORTING_COLUMNS).map((column, index) => (
                          <TableCell key={index} className='table-header-cell'>
                            {MISSED_MEDICATION_SORTING_COLUMNS[column] ? (
                              <TableSortLabel
                                direction={queryData.sortDir}
                                active={
                                  queryData.sortBy === MISSED_MEDICATION_SORTING_COLUMNS[column]
                                }
                                onClick={() => handleSort(column)}
                              >
                                {column}
                              </TableSortLabel>
                            ) : (
                              <TableCell key={index} className='table-header-cell'>
                                {column}
                              </TableCell>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.length === 0 ? (
                        <TableRow className='table-row-even'>
                          <TableCell
                            className='table-cell-action'
                            colSpan={MISSED_MEDICATION_COLUMNS.length + 1}
                          >
                            <span
                              className='text-gray-800 mb-1'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              No Data Found
                            </span>
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredData.map((row, rowIndex) => (
                          <TableRow
                            className={rowIndex % 2 === 0 ? 'table-row-even' : 'table-row-odd'}
                            key={rowIndex}
                          >
                            {MISSED_MEDICATION_COLUMNS.map((column, colIndex) => (
                              <TableCell key={colIndex} className='table-cell'>
                                {row[MISSED_MEDICATION_DATA_MAPPING[column]]?.toString()}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </>
              )}
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                component='div'
                count={queryData.totalCounts || 0}
                rowsPerPage={rowsPerPage}
                page={(queryData.pageNumber || 1) - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='MuiTablePagination-toolbar'
              />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default MissedMedicationListing
