import {useState, useEffect} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import TableButton from '../../../../reusable-components/tables/TableButton'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import {columnDataMapping, columns, htmlColumns, sortingColumns} from '../../CAREGIVER_TABLE_CONSTANTS'
import {toast} from 'react-toastify'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import { QueryData } from '../../../../reusable-components/tables/DynamicTableTypes'
import { RootState } from '../../../../../setup'

export const CaregiversList = () => {
  const history = useHistory()
  const [filteredData, setFilteredData] = useState([])
  const [careGiversListing, setCareGiversListing] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [queryData, setQueryData] = useState<QueryData>({
      pageNumber: 1,
      pageSize: 5,
      currentPage: 1,
      totalCounts: 1,
      sortBy: '_id',
      sortDir: 'desc',
      searchTerm: ''
  })

  const authUser = useSelector((state:  RootState) => state.auth)
  const userPermissions = authUser.user.roleId;

  useEffect(() => {
    const companyid = authUser.user?.company_code
    const fetchData = async (page: number, pageSize: number, sortBy : string, sortDir : string)=> {
      const response = await axios.get(`/caregivers/get/all?company_code=${companyid}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`)
      const { data, currentPage, totalCounts } = response.data;
          data?.forEach((item, i) => {
            const tempRole = item?.role?.map((eachRole) => {
              return (
                <p>
                  {eachRole.name} <br />
                </p>
              );
            });
          
            const tempWage = item?.roles?.map((eachRole) => {
              return (
                <p>
                  {'$' + eachRole.wage + '/' + eachRole.wageUnit} <br />
                </p>
              );
            });
          
            item.fullRole = tempRole;
            item.wage = tempWage;
          });
          
          setCareGiversListing(data)
          setFilteredData(data)
          setQueryData((prevState)=> {
              return {
                  ...prevState,
                  currentPage,
                  totalCounts,
              }
          })
    }
    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
   
  }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])
  

  const onClickAddButton = () => {
    history.push('/caregivers/new')
  }

  const onView = (row: any) => {
    history.push(`/caregivers/edit/${row._id}`)
  }

  const onDelete = (row: any) => {
    axios
      .delete(`/caregivers/delete/${row._id}`)
      .then((res) => {
        setFilteredData((prevState) => prevState.filter((entry) => entry._id !== row._id))
        toast.success('Caregiver deleted successfully')
      })
      .catch((e) => toast.error("Couldn't delete caregiver"))
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <TableTitle title='Caregivers Listing'></TableTitle>
        <TableWrapper 
            searchField={
                <TableSearchBar
          label='Search'
          variant='outlined'
          size='small'
          value={searchTerm}
          setQueryData={setQueryData}
          setSearchTerm={setSearchTerm}
          margin='normal'
          inputAdornmentPosition='start'
        ></TableSearchBar>
            }
            tableButtons={
                hasPermission(userPermissions, permissions.care_giver_create) && (
        <TableButton
          variant={'contained'}
          color={'primary'}
          title='Add Caregiver'
          onClick={onClickAddButton}
        />
        )
            }
            dynamicTable={
        <DynamicTable
          htmlColumns={htmlColumns}
          columns={columns}
          data={filteredData}
          canView={hasPermission(userPermissions, permissions.care_giver_general_view)}
          canDelete={hasPermission(userPermissions, permissions.care_giver_delete)}
          onView={onView}
          onDelete={onDelete}
          columnDataMapping={columnDataMapping}
          sortingColumns={sortingColumns}
          title='Are you sure you want to delete this caregiver?'
          setQueryData={setQueryData}
          queryData={queryData}
        />
            }
        />
      </div>
    </div>
  )
}
