import axios from 'axios'
import React, {useEffect, useState} from 'react'
import SchedulesDynamicTable from '../../../../reusable-components/tables/SchedulesDynamicTable'
import {
  caregiverSchedulescolumns,
  caregiverSchedulescolumnsDataMapping,
} from '../../CAREGIVER_SCHEDULES_TABLE_CONSTANTS'
import DynamicAddModal from '../../../../reusable-components/Modals/DynamicModal'
import {CAREGIVER_ADD_SCHEDULE_FORM_INPUTS} from '../../CAREGIVER_ADD_SCHEDULE_FORM_CONSTANTS'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import dayjs from 'dayjs'
import CareGiverScheduleTabs from '../Tabs/SchedulesTabs'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'

const selectAuth = (state: RootState) => state.auth

export default function MySchedules() {
  const [schedulesList, setSchedulesList] = useState([])
  const [selectedDay, setSelectedDay] = useState('')

  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false)
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
  let curr = new Date()
  const [startDate, setStartDate] = useState(new Date(curr.setDate(curr.getDate() - curr.getDay())))
  const [lastDate, setLastDate] = useState(
    new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
  )
  const [uiElements, setUIElements] = useState([])
  const [rolesToShow, setRolesToShow] = useState([])
  const [rolesWithWages, setRolesWithWages] = useState([])
  const [rolesWithNames, setRolesWithNames] = useState([])
  const [dataSwitch, setDataSwitch] = useState(false)
  const [formData, setFormData] = useState({})
  const [eligibleForOpenShifts, setEligibleForOpenShifts] = useState(false)
  const [locationsData, setLocationsData] = useState([])
  const [showInSchedule, setShowInSchedule] = useState(false)

  const auth = useSelector(selectAuth)
  const isCaregiver = auth?.user?.isCaregiver
  const userPermissions = auth?.user?.roleId

  useEffect(() => {
    let axiosArray = []
    let postData = {}
    let newPromise
    newPromise = axios({
      method: 'get',
      url: `caregivers/getByUserId/${auth.user._id}`,
    })
    axiosArray.push(newPromise)
    postData['company_code'] = auth.user.company_code
    postData['startDate'] = startDate
    postData['endDate'] = lastDate
    newPromise = axios({
      method: 'post',
      url: `/schedules/getEmployeScheduleByUserId/${auth.user._id}`,
      data: postData,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'post',
      url: `/openshifts/getAllOpenShifts`,
      data: postData,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'get',
      url: `/schedules/getAllLocations?company_code=` + auth.user.company_code,
    })
    axiosArray.push(newPromise)
    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          if (responses[0].data?.careGiver?.roles) {
            let tempRoles = []
            responses[0].data?.careGiver?.roles?.map((role, index) => {
              tempRoles.push({
                label: responses[0]?.data?.careGiver?.role[index]?.name,
                value: role,
              })
            })
            setEligibleForOpenShifts(
              responses[0]?.data?.careGiver?.locationSettings?.eligibleForOpenShifts || false
            )
            setShowInSchedule(
              responses[0]?.data?.careGiver?.locationSettings?.showInSchedule || false
            )
            setRolesToShow(tempRoles)
            setRolesWithWages(responses[0].data.careGiver.roles)
            console.log(responses[0].data.careGiver.role)
            setRolesWithNames(responses[0].data.careGiver.role)
          }
          if (responses[3].data) {
            let tempLocations = []
            responses[3].data?.sites.map((eachSite) => {
              tempLocations.push({
                label: `${eachSite.siteName.toString().toUpperCase()} (${eachSite.st1} ${
                  eachSite.st2
                }, ${eachSite.city}, ${eachSite.state}, ${eachSite.country})`,
                value: {
                  address: `${eachSite.siteName.toString().toUpperCase()} (${eachSite.st1} ${
                    eachSite.st2
                  }, ${eachSite.city}, ${eachSite.state}, ${eachSite.country})`,
                  lat: eachSite.lat,
                  lng: eachSite.lng,
                  typeOfLocation: 'site',
                  idOfDoc: eachSite._id,
                },
              })
            })
            responses[3].data?.clients.map((eachClient) => {
              tempLocations.push({
                label: `${eachClient.firstName.toString().toUpperCase()} ${eachClient.lastName
                  .toString()
                  .toUpperCase()} (${eachClient.addrInfo.st1} ${eachClient.addrInfo.st2}, ${
                  eachClient.addrInfo.city
                }, ${eachClient.addrInfo.state}, ${eachClient.addrInfo.country})`,
                value: {
                  address: `${eachClient.firstName.toString().toUpperCase()} ${eachClient.lastName
                    .toString()
                    .toUpperCase()} (${eachClient.addrInfo.st1} ${eachClient.addrInfo.st2}, ${
                    eachClient.addrInfo.city
                  }, ${eachClient.addrInfo.state}, ${eachClient.addrInfo.country})`,
                  lat: eachClient.addrInfo.lat,
                  lng: eachClient.addrInfo.lng,
                  typeOfLocation: 'client',
                  idOfDoc: eachClient._id,
                },
              })
            })
            setLocationsData(tempLocations)
          }
          await prepareSchdeules(responses[2].data, responses[1].data.employes)
        })
      )
      .catch((error) => console.error(error))
  }, [startDate, dataSwitch])

  useEffect(() => {
    const elements = CAREGIVER_ADD_SCHEDULE_FORM_INPUTS({
      day: 'Monday',
      rolesToShow,
      locations: locationsData,
    })
    setUIElements(elements)
  }, [rolesToShow])

  const prepareSchdeules = (openShifts, employes) => {
    setSchedulesList((prevState) => [openShifts])
    employes.map((eachEmploye, index) => {
      let firstName,
        lastName,
        days,
        scheduleId,
        isPublished,
        mondayShifts = [],
        tuesdayShifts = [],
        wednesdayShifts = [],
        thursdayShifts = [],
        fridayShifts = [],
        saturdayShifts = [],
        sundayShifts = [],
        showInSchedule
      firstName = eachEmploye.firstName
      lastName = eachEmploye.lastName
      days = eachEmploye.scheduleDetails[0]?.days
      isPublished = eachEmploye.scheduleDetails[0]?.isPublished
      scheduleId = eachEmploye.scheduleDetails[0]?._id
      showInSchedule = eachEmploye?.locationSettings?.showInSchedule || false
      if (eachEmploye.scheduleDetails.length > 0) {
        if (eachEmploye.scheduleDetails[0].days.length > 0) {
          eachEmploye.scheduleDetails[0].days.map((eachDay) => {
            if (eachDay.name == 'monday') {
              mondayShifts = eachDay.shifts
              mondayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'tuesday') {
              tuesdayShifts = eachDay.shifts
              tuesdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'wednesday') {
              wednesdayShifts = eachDay.shifts
              wednesdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'thursday') {
              thursdayShifts = eachDay.shifts
              thursdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'friday') {
              fridayShifts = eachDay.shifts
              fridayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'saturday') {
              saturdayShifts = eachDay.shifts
              saturdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'sunday') {
              sundayShifts = eachDay.shifts
              sundayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
          })
        }
      }
      if (
        showInSchedule ||
        (!showInSchedule &&
          (mondayShifts.length != 0 ||
            tuesdayShifts.length != 0 ||
            wednesdayShifts.length != 0 ||
            thursdayShifts.length != 0 ||
            fridayShifts.length != 0 ||
            saturdayShifts.length != 0 ||
            sundayShifts.length != 0))
      )
        setSchedulesList((prevState) => [
          ...prevState,
          {
            firstName: firstName,
            lastName: lastName,
            mondayShifts: mondayShifts,
            tuesdayShifts: tuesdayShifts,
            wednesdayShifts: wednesdayShifts,
            thursdayShifts: thursdayShifts,
            fridayShifts: fridayShifts,
            saturdayShifts: saturdayShifts,
            sundayShifts: sundayShifts,
            scheduleId: scheduleId,
            isPublished: isPublished,
            showInSchedule: showInSchedule,
            hideFullRow:
              !showInSchedule &&
              mondayShifts.length == 0 &&
              tuesdayShifts.length == 0 &&
              wednesdayShifts.length == 0 &&
              thursdayShifts.length == 0 &&
              fridayShifts.length == 0 &&
              saturdayShifts.length == 0 &&
              sundayShifts.length == 0,
          },
        ])
    })
  }

  const moveDatesForward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() + 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() + 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const moveDatesBackward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() - 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() - 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const onClickCell = (index: number, rowIndex: string | number) => {
    console.log(index, rowIndex)
    let shiftAlreadyCreated = false
    let day
    if (index % 7 == 0) {
      day = 'Saturday'
      shiftAlreadyCreated = schedulesList[rowIndex]['saturdayShifts'].length > 0
    } else if (index % 6 == 0) {
      day = 'Friday'
      shiftAlreadyCreated = schedulesList[rowIndex]['fridayShifts'].length > 0
    } else if (index % 5 == 0) {
      day = 'Thursday'
      shiftAlreadyCreated = schedulesList[rowIndex]['thursdayShifts'].length > 0
    } else if (index % 4 == 0) {
      day = 'Wednesday'
      shiftAlreadyCreated = schedulesList[rowIndex]['wednesdayShifts'].length > 0
    } else if (index % 3 == 0) {
      day = 'Tuesday'
      shiftAlreadyCreated = schedulesList[rowIndex]['tuesdayShifts'].length > 0
    } else if (index % 2 == 0) {
      day = 'Monday'
      shiftAlreadyCreated = schedulesList[rowIndex]['mondayShifts'].length > 0
    } else {
      day = 'Sunday'
      shiftAlreadyCreated = schedulesList[rowIndex]['sundayShifts'].length > 0
    }
    setSelectedDay(day)
    let prevData = formData
    prevData = {}
    let timeStart = new Date(startDate)
    let days = index - 1

    timeStart.setDate(timeStart.getDate() + days)
    let timeEnd = new Date(startDate)
    timeEnd.setDate(timeEnd.getDate() + days)
    timeStart.setHours(0)
    timeStart.setMinutes(0)
    timeStart.setSeconds(0)
    timeStart.setMilliseconds(0)
    timeEnd.setHours(2)
    timeEnd.setMinutes(0)
    timeEnd.setSeconds(0)
    timeEnd.setMilliseconds(0)
    prevData['timeStart'] = dayjs(timeStart).toDate()
    prevData['timeEnd'] = dayjs(timeEnd).toDate()
    setFormData(prevData)
    const elements = CAREGIVER_ADD_SCHEDULE_FORM_INPUTS({
      day,
      rolesToShow,
      locations: locationsData,
      shiftAlreadyCreated,
    })
    setUIElements(elements)
    setShowAddScheduleModal(true)
  }

  const handleDiscardAdd = () => {
    setShowAddScheduleModal(false)
  }

  const handleDiscardEdit = () => {
    setShowEditScheduleModal(false)
  }

  const handleSubmitForm = async (formData) => {
    let timeStartTimeStamp = formData['timeStart']
    let timeEndTimeStamp = formData['timeEnd']
    let diff = formData['timeEnd'].getTime() - formData['timeStart'].getTime()
    let diffInSec = diff / 1000
    let diffInMins = diffInSec / 60
    let numberOfHours = diffInMins / 60
    let numberOfMinutes = diffInMins % 60
    if (numberOfHours < 0) numberOfHours = numberOfHours + 24
    if (numberOfMinutes < 0) numberOfMinutes = numberOfMinutes + 60
    numberOfHours = parseInt(numberOfHours.toString())
    numberOfMinutes = parseInt(numberOfMinutes.toString())

    let startingHours = formData['timeStart'].getHours()
    let startingMins = formData['timeStart'].getMinutes()
    let startingAmOrPm = startingHours >= 12 ? 'pm' : 'am'
    if (startingHours > 12) {
      startingHours = startingHours - 12
    } else if (startingHours == 0) {
      startingHours = 12
    }

    let endingHours = formData['timeEnd'].getHours()
    let endingMins = formData['timeEnd'].getMinutes()
    let endingAmOrPm = endingHours >= 12 ? 'pm' : 'am'
    if (endingHours > 12) {
      endingHours = endingHours - 12
    } else if (endingHours == 0) {
      endingHours = 12
    }

    let timeStart = `${startingHours < 10 ? '0' + startingHours : startingHours}:${
      startingMins < 10 ? '0' + startingMins : startingMins
    } ${startingAmOrPm}`
    let timeEnd = `${endingHours < 10 ? '0' + endingHours : endingHours}:${
      endingMins < 10 ? '0' + endingMins : endingMins
    } ${endingAmOrPm}`
    const roleId = formData['roleName']?.roleId;
    const Role = rolesWithNames?.find(role => role._id === roleId);
    const RoleWage = rolesWithWages?.find(role => role.roleId === roleId);
    await axios
      .post('/schedules/create', {
        userId: auth.user._id,
        company_code: auth?.user?.company_code,
        startDate: startDate,
        endDate: lastDate,
        numberOfHours: numberOfHours,
        day: selectedDay.toLowerCase(),
        timeStart: timeStart,
        timeEnd: timeEnd,
        timeStartTimeStamp: timeStartTimeStamp,
        timeEndTimeStamp: timeEndTimeStamp,
        isPreferred: formData['availability'] == 'preferred' ? true : false,
        isUnavailable: formData['availability'] == 'unavailable' ? true : false,
        isWholeDay: formData['wholeDay'] ? true : false,
        applyTo: formData['applyTo'],
        locations: formData['locations'] ? formData['locations'] : [],
        note: formData['note'],
        roleName: Role?.name,
        roleId: Role?._id,
        wage: RoleWage?.wage,
        wageUnit: RoleWage?.wageUnit,
        numberOfMinutes: numberOfMinutes,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        handleDiscardAdd()
        if (res.status == 201) toast.success('Schedule added successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't add schedule`)
      })
  }

  const handleSubmitEditForm = async (formData) => {
    let timeStartTimeStamp = formData['timeStart']
    let timeEndTimeStamp = formData['timeEnd']
    formData['timeEnd'] = formData['timeEnd'].$d ? formData['timeEnd'].$d : formData['timeEnd']
    formData['timeStart'] = formData['timeStart'].$d
      ? formData['timeStart'].$d
      : formData['timeStart']
    let diff = formData['timeEnd'].getTime() - formData['timeStart'].getTime()
    let diffInSec = diff / 1000
    let diffInMins = diffInSec / 60
    let numberOfHours = diffInMins / 60
    let numberOfMinutes = diffInMins % 60
    if (numberOfHours < 0) numberOfHours = numberOfHours + 24
    if (numberOfMinutes < 0) numberOfMinutes = numberOfMinutes + 60
    numberOfHours = parseInt(numberOfHours.toString())
    numberOfMinutes = parseInt(numberOfMinutes.toString())

    let startingHours = formData['timeStart'].getHours()
    let startingMins = formData['timeStart'].getMinutes()
    let startingAmOrPm = startingHours >= 12 ? 'pm' : 'am'
    if (startingHours > 12) {
      startingHours = startingHours - 12
    } else if (startingHours == 0) {
      startingHours = 12
    }

    let endingHours = formData['timeEnd'].getHours()
    let endingMins = formData['timeEnd'].getMinutes()
    let endingAmOrPm = endingHours >= 12 ? 'pm' : 'am'
    if (endingHours > 12) {
      endingHours = endingHours - 12
    } else if (endingHours == 0) {
      endingHours = 12
    }

    let timeStart = `${startingHours < 10 ? '0' + startingHours : startingHours}:${
      startingMins < 10 ? '0' + startingMins : startingMins
    } ${startingAmOrPm}`
    let timeEnd = `${endingHours < 10 ? '0' + endingHours : endingHours}:${
      endingMins < 10 ? '0' + endingMins : endingMins
    } ${endingAmOrPm}`
    const roleId = formData['roleName']?.roleId;
    const Role = rolesWithNames?.find(role => role._id === roleId);
    const RoleWage = rolesWithWages?.find(role => role.roleId === roleId);
    await axios
      .patch('/schedules/editSchedule', {
        userId: auth.user._id,
        numberOfHours: numberOfHours,
        timeStart: timeStart,
        timeEnd: timeEnd,
        timeStartTimeStamp: timeStartTimeStamp,
        timeEndTimeStamp: timeEndTimeStamp,
        isPreferred: formData['availability'] == 'preferred' ? true : false,
        isUnavailable: formData['availability'] == 'unavailable' ? true : false,
        isWholeDay: formData['wholeDay'] ? true : false,
        applyTo: formData['applyTo'],
        locations: formData['locations'] ? formData['locations'] : [],
        note: formData['note'],
        roleId: Role?._id,
        roleName: Role?.name,
        wage: RoleWage?.wage,
        wageUnit: RoleWage?.wageUnit,
        dayId: formData['dayId'],
        shiftId: formData['shiftId'],
        scheduleId: formData['scheduleId'],
        numberOfMinutes: numberOfMinutes,
        prevTimeStart: formData['prevTimeStart'],
        prevIsUnavailable: formData['prevIsUnavailable'],
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        handleDiscardEdit()
        if (res.status == 201) toast.success('Schedule updated successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't update schedule`)
      })
  }

  const onDelete = async (shift: any, row: any) => {
    await axios
      .patch('/schedules/delete', {
        userId: auth.user._id,
        scheduleId: row.scheduleId,
        dayId: shift.dayId,
        shiftId: shift._id,
        isUnavailable: shift.isUnavailable,
        startTime: shift.timeStartTimeStamp,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        if (res.status == 201) toast.success('Schedule deleted Successfully')
      })
      .catch((e) => toast.error("Couldn't delete schdedule"))
  }

  const onEdit = async (shift: any, row: any, index: number) => {
    let shiftAlreadyCreated = false
    const prevData = formData
    let timeStart = shift.timeStartTimeStamp || new Date()
    let timeEnd = shift.timeEndTimeStamp || new Date()
    prevData['timeEnd'] = dayjs(timeEnd)
    prevData['timeStart'] = dayjs(timeStart)
    prevData['prevTimeStart'] = dayjs(timeStart)
    prevData['prevIsUnavailable'] = shift.isUnavailable
    prevData['wholeDay'] = shift.isWholeDay ? 'wholeDay' : undefined
    prevData['isPreferred'] = shift.isPreferred
    prevData['availability'] = shift.isPreferred ? 'preferred' : 'unavailable'
    prevData['applyTo'] = locationsData.filter(
      (eachLocation) => eachLocation.value.idOfDoc == shift.applyTo.idOfDoc
    )[0]?.value
    prevData['dayId'] = shift.dayId
    prevData['shiftId'] = shift._id
    prevData['scheduleId'] = row.scheduleId
    prevData['roleName'] = rolesToShow.filter(
      (eachShift) => eachShift.label == shift.roleName
    )[0]?.value
    setFormData(prevData)
    let day = 'Sunday'
    shiftAlreadyCreated = row['sundayShifts'].length > 1
    if (index % 7 == 0) {
      day = 'Saturday'
      shiftAlreadyCreated = row['saturdayShifts'].length > 1
    } else if (index % 6 == 0) {
      day = 'Friday'
      shiftAlreadyCreated = row['fridayShifts'].length > 1
    } else if (index % 5 == 0) {
      day = 'Thursday'
      shiftAlreadyCreated = row['thursdayShifts'].length > 1
    } else if (index % 4 == 0) {
      day = 'Wednesday'
      shiftAlreadyCreated = row['wednesdayShifts'].length > 1
    } else if (index % 3 == 0) {
      day = 'Tuesday'
      shiftAlreadyCreated = row['tuesdayShifts'].length > 1
    } else if (index % 2 == 0) {
      day = 'Monday'
      shiftAlreadyCreated = row['mondayShifts'].length > 1
    }
    setSelectedDay(day)
    const elements = CAREGIVER_ADD_SCHEDULE_FORM_INPUTS({
      day,
      rolesToShow,
      locations: locationsData,
      shiftAlreadyCreated,
    })
    setUIElements(elements)
    setShowEditScheduleModal(true)
  }

  const assignOpenShift = async (shiftId: string) => {
    await axios
      .post('/openshifts/assignToMe', {
        userId: auth.user._id,
        shiftId: shiftId,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        handleDiscardEdit()
        if (res.status == 201) toast.success('Shift assigned successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't update schedule`)
      })
  }

  const buttons = [
    {
      label: 'Cancel',
      variant: 'outlined',
      type: 'button',
      onClick: handleDiscardAdd,
    },
    {
      label: 'Save',
      variant: 'contained',
      type: 'submit',
    },
  ]

  const editButtons = [
    {
      label: 'Cancel',
      variant: 'outlined',
      type: 'button',
      onClick: handleDiscardEdit,
    },
    {
      label: 'Save',
      variant: 'contained',
      type: 'submit',
    },
  ]
  return (
    <>
      <CareGiverScheduleTabs></CareGiverScheduleTabs>
      {isCaregiver && (
        <div className='card'>
          <div className='card-body'>
            <SchedulesDynamicTable
              columns={caregiverSchedulescolumns}
              data={schedulesList}
              columnDataMapping={caregiverSchedulescolumnsDataMapping}
              title={'Are you sure you want to delete this schedule?'}
              startDate={startDate}
              lastDate={lastDate}
              moveDatesForward={moveDatesForward}
              moveDatesBackward={moveDatesBackward}
              onClickCell={onClickCell}
              onDelete={onDelete}
              onEdit={onEdit}
              canAdd={hasPermission(
                userPermissions,
                permissions.care_giver_schedules_myAvailability_create
              )}
              canDelete={hasPermission(
                userPermissions,
                permissions.care_giver_schedules_myAvailability_delete
              )}
              canEdit={hasPermission(
                userPermissions,
                permissions.care_giver_schedules_myAvailability_update
              )}
              typeOfSchedules={'mySchedule'}
              assignOpenShift={assignOpenShift}
              eligibleForOpenShifts={eligibleForOpenShifts}
              showInSchedule={showInSchedule}
            />
          </div>
        </div>
      )}

      <DynamicAddModal
        title={'Add New Schedule'}
        showAddScheduleModal={showAddScheduleModal}
        formInputs={uiElements}
        handleSubmitForm={handleSubmitForm}
        buttons={buttons}
        data={formData}
      ></DynamicAddModal>
      <DynamicAddModal
        title={'Edit Schedule'}
        showAddScheduleModal={showEditScheduleModal}
        formInputs={uiElements}
        handleSubmitForm={handleSubmitEditForm}
        buttons={editButtons}
        data={formData}
      ></DynamicAddModal>
    </>
  )
}
